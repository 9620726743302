import {
  forwardRef,
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ColumnType } from "../../../types";
import { CalendarIcon, ClockIcon, FilterIcon } from "../../icons";
import { Button, TextField } from "../../ui";
import { useDataTableContext } from "../DataTableContext";

type Props = {
  element: string;
  type: ColumnType;
  onFacetClick: () => void;
};

const DataTableFilterInput = forwardRef<HTMLDivElement, Props>(
  ({ element, type, onFacetClick }, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const {
      updateFilter,
      criteria,
      setFilterFocusedColumnId,
      filterFocusedColumnId,
    } = useDataTableContext();

    const initialLikeValue =
      ((criteria.like?.[0] ?? {})[element]?.[0] as string) ?? "";

    const [inputValue, setInputValue] = useState(initialLikeValue);

    const isFilterApplied = useMemo(() => {
      if (element) {
        if (type === "text" || type === "multiText" || type === "boolean") {
          return !!criteria.eq?.[0][element] || !!criteria.like?.[0][element];
        }
        if (type === "date") {
          return (
            !!criteria.gt?.[0][element] ||
            !!criteria.lt?.[0][element] ||
            !!criteria.eq?.[0][element]
          );
        }
        if (
          ["integer", "currency", "decimal", "percentage", "time"].includes(
            type,
          )
        ) {
          return !!criteria.ge?.[0][element] || !!criteria.le?.[0][element];
        }
      }
      return false;
    }, [criteria, element, type]);

    const onFilterInputKeydown = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
          const { value } = e.target as HTMLInputElement;
          updateFilter([
            {
              field: element,
              key: "eq",
              values: [],
            },
            {
              field: element,
              key: "like",
              values: value ? [value] : [],
            },
          ]);
          inputRef.current?.blur();
        }
        if (e.key === "Escape") {
          inputRef.current?.blur();
        }
      },
      [element, updateFilter],
    );

    useEffect(() => {
      setInputValue(initialLikeValue);
    }, [initialLikeValue]);

    const hasButtonOnly = [
      "boolean",
      "date",
      "integer",
      "currency",
      "decimal",
      "percentage",
      "time",
    ].includes(type);

    if (hasButtonOnly) {
      return (
        <Button
          data-testid="dt-column-filter-facet-btn"
          sx={(theme) => ({
            "&:hover": {
              borderColor: isFilterApplied
                ? theme.palette.blue["500"]
                : undefined,
            },
            borderBottomWidth: isFilterApplied ? 2 : 1,
            borderColor: isFilterApplied
              ? theme.palette.blue["400"]
              : undefined,
            color: isFilterApplied ? theme.palette.blue["400"] : undefined,
            gap: theme.spacing(1),
            opacity:
              filterFocusedColumnId && filterFocusedColumnId !== element
                ? ".3"
                : "1",
          })}
          variant="outlined"
          onClick={onFacetClick}
        >
          {type === "date" && <CalendarIcon sx={{ width: 14 }} />}
          {type === "time" && <ClockIcon sx={{ width: 14 }} />}
          <FilterIcon sx={{ width: 14 }} />
        </Button>
      );
    }

    return (
      <TextField
        ref={ref}
        InputProps={{
          endAdornment: filterFocusedColumnId !== element && (
            <Button
              data-testid="dt-column-filter-facet-btn"
              sx={(theme) => ({
                "&:hover": {
                  borderLeft: isFilterApplied
                    ? `1px solid ${theme.palette.blue["400"]}`
                    : undefined,
                  borderRight: "none",
                  color: isFilterApplied
                    ? theme.palette.blue["400"]
                    : undefined,
                },
                borderLeft: isFilterApplied
                  ? `1px solid ${theme.palette.blue["400"]}`
                  : undefined,
                borderRight: "none",
                color: isFilterApplied ? theme.palette.blue["400"] : undefined,
                outline: "none",
                width: 26,
              })}
              variant="outlined"
              onClick={onFacetClick}
            >
              <FilterIcon sx={{ width: 14 }} />
            </Button>
          ),
        }}
        data-testid={`dt-column-filter-${element}`}
        inputProps={{
          "data-filter": isFilterApplied ? "applied" : undefined,
          "data-testid": "dt-column-filter-input",
        }}
        inputRef={inputRef}
        sx={(theme) => ({
          "& .MuiInputBase-input": {
            borderRight: "none",
            color: isFilterApplied ? theme.palette.blue["700"] : undefined,
          },
          "& .MuiInputBase-root:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              ...(isFilterApplied
                ? {
                    borderColor: theme.palette.blue["500"],
                  }
                : {}),
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            ...(isFilterApplied
              ? {
                  borderColor: theme.palette.blue["400"],
                }
              : {}),
          },
          borderBottom: isFilterApplied
            ? `1px solid ${theme.palette.blue["400"]}`
            : "none",
          borderLeft: "none",
          borderRight: "none",
          borderTop: "none",
          minWidth: filterFocusedColumnId === element ? "240px" : undefined,
          opacity:
            filterFocusedColumnId && filterFocusedColumnId !== element
              ? ".3"
              : "1",
        })}
        value={inputValue}
        onBlur={() => setFilterFocusedColumnId("")}
        onChange={(e) => setInputValue(e.target.value)}
        onFocus={() => setFilterFocusedColumnId(element)}
        onKeyDown={onFilterInputKeydown}
      />
    );
  },
);

export default memo(DataTableFilterInput);
