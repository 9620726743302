import { createSvgIcon } from "@mui/material";

export const FileCsvIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 15 19" xmlns="http://www.w3.org/2000/svg">
    <title>file-csv</title>
    <defs>
      <path
        d="M7.5,0 L9.57016536,2.36268872 L12,5.13586957 L12,15.9782609 C12,17.2389108 11.1045695,18.2608696 10,18.2608696 L9,18.26 L9,17.119 L10,17.1195652 C10.5154658,17.1195652 10.9398399,16.6744454 10.9941321,16.1026186 L11,15.9782609 L11,5.13586957 L9,5.13586957 C8.17157288,5.13586957 7.5,4.36940052 7.5,3.42391304 L7.5,1.14130435 L2,1.14130435 C1.44771525,1.14130435 1,1.65228371 1,2.2826087 L1,12.173 L0,12.173913 L0,2.2826087 C0,1.02195872 0.8954305,0 2,0 L7.5,0 Z"
        id="path-1"
      ></path>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
      id="Page-1"
      stroke="none"
      stroke-width="1"
    >
      <g id="main-nav" transform="translate(-1111, -331)">
        <g id="Group-25" transform="translate(1111.22, 331)">
          <g id="Rectangle" transform="translate(2.78, 0)">
            <mask fill="white" id="mask-2">
              <use xlinkHref="#path-1"></use>
            </mask>
            <use
              fill="#242021"
              fill-rule="nonzero"
              id="Combined-Shape"
              xlinkHref="#path-1"
            ></use>
          </g>
          <g
            fill="#242021"
            fill-rule="nonzero"
            id="CSV"
            transform="translate(-0, 13.235)"
          >
            <path
              d="M1.785,3.82 C1.18166667,3.82 0.733333333,3.66416667 0.44,3.3525 C0.146666667,3.04083333 0,2.56333333 0,1.92 C0,1.27666667 0.145,0.7975 0.435,0.4825 C0.725,0.1675 1.16333333,0.01 1.75,0.01 C1.86333333,0.01 1.99083333,0.0166666667 2.1325,0.03 C2.27416667,0.0433333333 2.43,0.0633333333 2.6,0.09 L2.6,0.665 C2.50666667,0.651666667 2.42333333,0.640833333 2.35,0.6325 C2.27666667,0.624166667 2.21,0.6175 2.15,0.6125 C2.09,0.6075 2.03416667,0.603333333 1.9825,0.6 C1.93083333,0.596666667 1.88,0.595 1.83,0.595 C1.63,0.595 1.45916667,0.618333333 1.3175,0.665 C1.17583333,0.711666667 1.06166667,0.7875 0.975,0.8925 C0.888333333,0.9975 0.825,1.13416667 0.785,1.3025 C0.745,1.47083333 0.725,1.67666667 0.725,1.92 C0.725,2.16 0.745,2.36333333 0.785,2.53 C0.825,2.69666667 0.8875,2.8325 0.9725,2.9375 C1.0575,3.0425 1.16833333,3.11833333 1.305,3.165 C1.44166667,3.21166667 1.60833333,3.235 1.805,3.235 C1.85833333,3.235 1.92166667,3.2325 1.995,3.2275 C2.06833333,3.2225 2.14166667,3.21666667 2.215,3.21 C2.28833333,3.20333333 2.35916667,3.19416667 2.4275,3.1825 C2.49583333,3.17083333 2.55333333,3.16 2.6,3.15 L2.6,3.72 C2.48333333,3.75 2.35,3.77416667 2.2,3.7925 C2.05,3.81083333 1.91166667,3.82 1.785,3.82 Z"
              id="Path"
            ></path>
            <path
              d="M4.255,3.825 C4.165,3.825 4.06666667,3.82083333 3.96,3.8125 C3.85333333,3.80416667 3.74416667,3.79333333 3.6325,3.78 C3.52083333,3.76666667 3.40916667,3.75 3.2975,3.73 C3.18583333,3.71 3.07833333,3.68833333 2.975,3.665 L2.975,3.085 C3.20166667,3.13833333 3.42,3.18 3.63,3.21 C3.84,3.24 4.02833333,3.255 4.195,3.255 C4.70166667,3.255 4.955,3.09666667 4.955,2.78 C4.955,2.69 4.9375,2.615 4.9025,2.555 C4.8675,2.495 4.81833333,2.44333333 4.755,2.4 C4.69166667,2.35666667 4.61333333,2.32 4.52,2.29 C4.42666667,2.26 4.32333333,2.23166667 4.21,2.205 C4.00666667,2.15833333 3.83083333,2.10416667 3.6825,2.0425 C3.53416667,1.98083333 3.41083333,1.905 3.3125,1.815 C3.21416667,1.725 3.14166667,1.61916667 3.095,1.4975 C3.04833333,1.37583333 3.025,1.23166667 3.025,1.065 C3.025,0.901666667 3.0575,0.754166667 3.1225,0.6225 C3.1875,0.490833333 3.28,0.379166667 3.4,0.2875 C3.52,0.195833333 3.66583333,0.125 3.8375,0.075 C4.00916667,0.025 4.20333333,0 4.42,0 C4.57333333,0 4.7325,0.01 4.8975,0.03 C5.0625,0.05 5.26166667,0.085 5.495,0.135 L5.495,0.71 C5.33166667,0.663333333 5.16,0.6275 4.98,0.6025 C4.8,0.5775 4.63666667,0.565 4.49,0.565 C4.26,0.565 4.08083333,0.6075 3.9525,0.6925 C3.82416667,0.7775 3.76,0.896666667 3.76,1.05 C3.76,1.12666667 3.77416667,1.195 3.8025,1.255 C3.83083333,1.315 3.875,1.3675 3.935,1.4125 C3.995,1.4575 4.07333333,1.49833333 4.17,1.535 C4.26666667,1.57166667 4.385,1.60666667 4.525,1.64 C4.70833333,1.68333333 4.87166667,1.73333333 5.015,1.79 C5.15833333,1.84666667 5.28,1.91666667 5.38,2 C5.48,2.08333333 5.55666667,2.18416667 5.61,2.3025 C5.66333333,2.42083333 5.69,2.56333333 5.69,2.73 C5.69,3.08666667 5.56833333,3.35833333 5.325,3.545 C5.08166667,3.73166667 4.725,3.825 4.255,3.825 Z"
              id="Path"
            ></path>
            <polygon
              id="Path"
              points="5.865 0.065 6.61 0.065 7.4 2.35 7.615 3.095 7.665 3.095 7.885 2.35 8.675 0.065 9.41 0.065 8.11 3.765 7.165 3.765"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "FileCsv",
);
