/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AbstractUserDto } from '../models/AbstractUserDto';
import type { ChildUserResponseDto } from '../models/ChildUserResponseDto';
import type { PasswordResetRequestDto } from '../models/PasswordResetRequestDto';
import type { UserLoginEndpointDto } from '../models/UserLoginEndpointDto';
import type { UserLoginLogResponseDto } from '../models/UserLoginLogResponseDto';
import type { UserPatchRequestDto } from '../models/UserPatchRequestDto';
import type { UserPermissionsDto } from '../models/UserPermissionsDto';
import type { UserPermissionsPatchDto } from '../models/UserPermissionsPatchDto';
import type { UserPostRequestDto } from '../models/UserPostRequestDto';
import type { UserRequestLogDto } from '../models/UserRequestLogDto';
import type { UserRightsDto } from '../models/UserRightsDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UsersResourceService {
  /**
   * Get user data
   * Get user data
   * @returns AbstractUserDto Success
   * @throws ApiError
   */
  public static getUserUsingGet(): CancelablePromise<AbstractUserDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/users',
      errors: {
        401: `Unauthorized`,
        403: `Unauthorized`,
        404: `Customer Not Found`,
        500: `Internal Error Getting user data`,
      },
    });
  }
  /**
   * create
   * @param userPostDto userPostDto
   * @param accountId accountId
   * @returns AbstractUserDto Created
   * @throws ApiError
   */
  public static createUsingPost7(
    userPostDto: UserPostRequestDto,
    accountId?: number,
  ): CancelablePromise<AbstractUserDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/users',
      query: {
        'accountId': accountId,
      },
      body: userPostDto,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Patch a User
   * Patch a User
   * @param userData userData
   * @returns AbstractUserDto OK
   * @throws ApiError
   */
  public static patchUsingPatch3(
    userData: UserPatchRequestDto,
  ): CancelablePromise<AbstractUserDto> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/users',
      body: userData,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Unauthorized`,
        404: `Not Found`,
        500: `Internal Error Patching User`,
      },
    });
  }
  /**
   * Get current user app login endpoint
   * Get current user app login endpoint
   * @returns UserLoginEndpointDto Success
   * @throws ApiError
   */
  public static getCurrentUserAppLoginsUsingGet(): CancelablePromise<Array<UserLoginEndpointDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/users/appLogins',
      errors: {
        401: `Unauthorized`,
        403: `Unauthorized`,
        404: `Not Found`,
        500: `Internal Error Getting current user app login`,
      },
    });
  }
  /**
   * Get child users
   * Get child users
   * @returns ChildUserResponseDto Success
   * @throws ApiError
   */
  public static getChildUsersUsingGet(): CancelablePromise<Array<ChildUserResponseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/users/childUsers',
      errors: {
        401: `Unauthorized`,
        403: `Unauthorized`,
        404: `User Not Found`,
        500: `Internal Error Getting child users`,
      },
    });
  }
  /**
   * Get user data by email
   * Get user data by email
   * @param email email
   * @returns AbstractUserDto Success
   * @throws ApiError
   */
  public static getByEmailUsingGet(
    email: string,
  ): CancelablePromise<Array<AbstractUserDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/users/email/{email}',
      path: {
        'email': email,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `User Not Found`,
        500: `Internal Error Getting user data`,
      },
    });
  }
  /**
   * Password reset endpoint
   * Password reset endpoint
   * @param passwordResetRequest passwordResetRequest
   * @returns any OK
   * @throws ApiError
   */
  public static resetPasswordUsingPost(
    passwordResetRequest: PasswordResetRequestDto,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/users/passwordReset',
      body: passwordResetRequest,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get logged in customer rights
   * Get logged in customer rights
   * @returns UserRightsDto Success
   * @throws ApiError
   */
  public static getUserRightsUsingGet(): CancelablePromise<Array<UserRightsDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/users/rights',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `User Not Found`,
        500: `Internal Error Getting user rights`,
      },
    });
  }
  /**
   * Patch a User
   * Patch a User
   * @param userData userData
   * @param accountId accountId
   * @returns any OK
   * @throws ApiError
   */
  public static validateUsingPost(
    userData: UserPatchRequestDto,
    accountId?: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/users/validation',
      query: {
        'accountId': accountId,
      },
      body: userData,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Unauthorized`,
        404: `User Not Found`,
        500: `Internal Error Patching User`,
      },
    });
  }
  /**
   * Get user data
   * Get user data
   * @param userId userId
   * @returns AbstractUserDto Success
   * @throws ApiError
   */
  public static getUserByIdUsingGet(
    userId: number,
  ): CancelablePromise<AbstractUserDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/users/{userId}',
      path: {
        'userId': userId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Unauthorized`,
        404: `User Not Found`,
        500: `Internal Error Getting user data`,
      },
    });
  }
  /**
   * Patch a User
   * Patch a User
   * @param userData userData
   * @param userId userId
   * @returns AbstractUserDto OK
   * @throws ApiError
   */
  public static patchUsingPatch4(
    userData: UserPatchRequestDto,
    userId: number,
  ): CancelablePromise<AbstractUserDto> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/users/{userId}',
      path: {
        'userId': userId,
      },
      body: userData,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Unauthorized`,
        404: `User Not Found`,
        500: `Internal Error Patching User`,
      },
    });
  }
  /**
   * Get user request logs
   * Get user request log
   * @param limit limit
   * @param userId userId
   * @param endDate endDate
   * @param startDate startDate
   * @returns UserRequestLogDto Success
   * @throws ApiError
   */
  public static getUserRightsByIdUsingGet1(
    limit: number,
    userId: number,
    endDate?: string,
    startDate?: string,
  ): CancelablePromise<Array<UserRequestLogDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/users/{userId}/apiLog',
      path: {
        'userId': userId,
      },
      query: {
        'endDate': endDate,
        'limit': limit,
        'startDate': startDate,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Unauthorized`,
        404: `User Not Found`,
        500: `Internal Error Getting user request logs`,
      },
    });
  }
  /**
   * Get user app login endpoint
   * Get user app login endpoint
   * @param userId userId
   * @returns UserLoginEndpointDto Success
   * @throws ApiError
   */
  public static getUserAppLoginsUsingGet(
    userId: number,
  ): CancelablePromise<Array<UserLoginEndpointDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/users/{userId}/appLogins',
      path: {
        'userId': userId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Unauthorized`,
        404: `Not Found`,
        500: `Internal Error Getting user app login`,
      },
    });
  }
  /**
   * Get child users
   * Get child users
   * @param userId userId
   * @returns ChildUserResponseDto Success
   * @throws ApiError
   */
  public static getChildUsersUsingGet1(
    userId: number,
  ): CancelablePromise<Array<ChildUserResponseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/users/{userId}/childUsers',
      path: {
        'userId': userId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Unauthorized`,
        404: `User Not Found`,
        500: `Internal Error Getting child users`,
      },
    });
  }
  /**
   * Get user login log
   * Get user login log
   * @param userId userId
   * @param endDate endDate
   * @param limit limit
   * @param startDate startDate
   * @returns UserLoginLogResponseDto Success
   * @throws ApiError
   */
  public static getUserLoginLogUsingGet(
    userId: number,
    endDate?: string,
    limit?: number,
    startDate?: string,
  ): CancelablePromise<Array<UserLoginLogResponseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/users/{userId}/loginLog',
      path: {
        'userId': userId,
      },
      query: {
        'endDate': endDate,
        'limit': limit,
        'startDate': startDate,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Unauthorized`,
        404: `User Not Found`,
        500: `Internal Error Getting user login log`,
      },
    });
  }
  /**
   * Get logged in customer rights
   * Get logged in customer rights
   * @param userId userId
   * @returns UserPermissionsDto Success
   * @throws ApiError
   */
  public static getUserRightsByIdUsingGet(
    userId: number,
  ): CancelablePromise<Array<UserPermissionsDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/users/{userId}/rights',
      path: {
        'userId': userId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Unauthorized`,
        404: `User Not Found`,
        500: `Internal Error Getting user rights`,
      },
    });
  }
  /**
   * Patch User Permissions
   * Patch User Permissions
   * @param userId userId
   * @param userPermissions userPermissions
   * @returns any OK
   * @throws ApiError
   */
  public static updatePermissionsUsingPatch(
    userId: number,
    userPermissions: Array<UserPermissionsPatchDto>,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/users/{userId}/rights',
      path: {
        'userId': userId,
      },
      body: userPermissions,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Unauthorized`,
        404: `Not Found`,
        500: `Internal Error Patching User`,
      },
    });
  }
}
