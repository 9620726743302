/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SalesByAccountResponseDto } from '../models/SalesByAccountResponseDto';
import type { SalesByDateResponseDto } from '../models/SalesByDateResponseDto';
import type { SalesSummaryResponseDto } from '../models/SalesSummaryResponseDto';
import type { TransactionStatistics } from '../models/TransactionStatistics';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TransactionStatisticsResourceService {
  /**
   * Get most popular products
   * Get most popular products
   * @param endDate endDate
   * @param startDate startDate
   * @param accountCode accountCode
   * @param showAll showAll
   * @returns TransactionStatistics Success
   * @throws ApiError
   */
  public static getMostPopularProductsUsingGet(
    endDate: string,
    startDate: string,
    accountCode?: string,
    showAll: boolean = false,
  ): CancelablePromise<Array<TransactionStatistics>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/transactions/most_popular_products',
      query: {
        'accountCode': accountCode,
        'endDate': endDate,
        'showAll': showAll,
        'startDate': startDate,
      },
      errors: {
        400: `Savings by Category Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal error finding Most Popular Products chart.`,
      },
    });
  }
  /**
   * Get sales by account
   * Get sales by account
   * @param endDate endDate
   * @param startDate startDate
   * @param directorUserId directorUserId
   * @param limit limit
   * @param offset offset
   * @param repUserId repUserId
   * @param vpUserId vpUserId
   * @returns SalesByAccountResponseDto Success
   * @throws ApiError
   */
  public static getSalesByAccountUsingGet(
    endDate: string,
    startDate: string,
    directorUserId?: number,
    limit: number = 10,
    offset?: number,
    repUserId?: number,
    vpUserId?: number,
  ): CancelablePromise<Array<SalesByAccountResponseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/transactions/sales_by_account',
      query: {
        'directorUserId': directorUserId,
        'endDate': endDate,
        'limit': limit,
        'offset': offset,
        'repUserId': repUserId,
        'startDate': startDate,
        'vpUserId': vpUserId,
      },
      errors: {
        400: `Sales by account Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal error getting Sales by account.`,
      },
    });
  }
  /**
   * Get sales by category
   * Get sales by category
   * @param endDate endDate
   * @param startDate startDate
   * @param accountCode accountCode
   * @param showAll showAll
   * @returns TransactionStatistics Success
   * @throws ApiError
   */
  public static getSalesByCategoryUsingGet(
    endDate: string,
    startDate: string,
    accountCode?: string,
    showAll: boolean = false,
  ): CancelablePromise<Array<TransactionStatistics>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/transactions/sales_by_category',
      query: {
        'accountCode': accountCode,
        'endDate': endDate,
        'showAll': showAll,
        'startDate': startDate,
      },
      errors: {
        400: `Savings by Category Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal error finding Sales By Category chart.`,
      },
    });
  }
  /**
   * Get sales by country
   * Get sales by country
   * @param endDate endDate
   * @param startDate startDate
   * @param accountCode accountCode
   * @returns TransactionStatistics Success
   * @throws ApiError
   */
  public static getSalesByCountryUsingGet(
    endDate: string,
    startDate: string,
    accountCode?: string,
  ): CancelablePromise<Array<TransactionStatistics>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/transactions/sales_by_country',
      query: {
        'accountCode': accountCode,
        'endDate': endDate,
        'startDate': startDate,
      },
      errors: {
        400: `Sales by Country Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal error finding Sales By Country`,
      },
    });
  }
  /**
   * Get sales by date
   * Get sales by date
   * @param endDate endDate
   * @param startDate startDate
   * @param directorUserId directorUserId
   * @param limit limit
   * @param offset offset
   * @param repUserId repUserId
   * @param vpUserId vpUserId
   * @returns SalesByDateResponseDto Success
   * @throws ApiError
   */
  public static getSalesByDateUsingGet(
    endDate: string,
    startDate: string,
    directorUserId?: number,
    limit: number = 10,
    offset?: number,
    repUserId?: number,
    vpUserId?: number,
  ): CancelablePromise<Array<SalesByDateResponseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/transactions/sales_by_date',
      query: {
        'directorUserId': directorUserId,
        'endDate': endDate,
        'limit': limit,
        'offset': offset,
        'repUserId': repUserId,
        'startDate': startDate,
        'vpUserId': vpUserId,
      },
      errors: {
        400: `Sales by date Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal error getting Sales by date.`,
      },
    });
  }
  /**
   * Get sales by location
   * Get sales by location
   * @param endDate endDate
   * @param startDate startDate
   * @param accountCode accountCode
   * @returns TransactionStatistics Success
   * @throws ApiError
   */
  public static getSalesByLocationUsingGet(
    endDate: string,
    startDate: string,
    accountCode?: string,
  ): CancelablePromise<Array<TransactionStatistics>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/transactions/sales_by_location',
      query: {
        'accountCode': accountCode,
        'endDate': endDate,
        'startDate': startDate,
      },
      errors: {
        400: `Savings by Category Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal error finding Sales By Location chart.`,
      },
    });
  }
  /**
   * Get sales by manufacturer
   * Get sales by manufacturer
   * @param endDate endDate
   * @param startDate startDate
   * @param accountCode accountCode
   * @param showAll showAll
   * @returns TransactionStatistics Success
   * @throws ApiError
   */
  public static getSalesByManufacturerUsingGet(
    endDate: string,
    startDate: string,
    accountCode?: string,
    showAll: boolean = false,
  ): CancelablePromise<Array<TransactionStatistics>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/transactions/sales_by_manufacturer',
      query: {
        'accountCode': accountCode,
        'endDate': endDate,
        'showAll': showAll,
        'startDate': startDate,
      },
      errors: {
        400: `Savings by Manufacturer Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal error finding Sales By Manufacturer chart.`,
      },
    });
  }
  /**
   * Get sales by quarter
   * Get sales by quarter
   * @param endDate endDate
   * @param startDate startDate
   * @param accountCode accountCode
   * @returns TransactionStatistics Success
   * @throws ApiError
   */
  public static getSalesByQuarterUsingGet(
    endDate: string,
    startDate: string,
    accountCode?: string,
  ): CancelablePromise<Array<TransactionStatistics>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/transactions/sales_by_quarter',
      query: {
        'accountCode': accountCode,
        'endDate': endDate,
        'startDate': startDate,
      },
      errors: {
        400: `Savings by Category Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal error finding Sales By Quarter chart.`,
      },
    });
  }
  /**
   * Get sales by state
   * Get sales by state
   * @param endDate endDate
   * @param startDate startDate
   * @param accountCode accountCode
   * @returns TransactionStatistics Success
   * @throws ApiError
   */
  public static getSalesByStateUsingGet(
    endDate: string,
    startDate: string,
    accountCode?: string,
  ): CancelablePromise<Array<TransactionStatistics>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/transactions/sales_by_state',
      query: {
        'accountCode': accountCode,
        'endDate': endDate,
        'startDate': startDate,
      },
      errors: {
        400: `Savings by State Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal error finding Sales By State chart.`,
      },
    });
  }
  /**
   * Get sales by year and month
   * Get sales by year and month
   * @param endDate endDate
   * @param startDate startDate
   * @param accountCode accountCode
   * @returns TransactionStatistics Success
   * @throws ApiError
   */
  public static getSalesByYearAndMonthUsingGet(
    endDate: string,
    startDate: string,
    accountCode?: string,
  ): CancelablePromise<Array<TransactionStatistics>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/transactions/sales_by_year_and_month',
      query: {
        'accountCode': accountCode,
        'endDate': endDate,
        'startDate': startDate,
      },
      errors: {
        400: `Savings by Year and Month Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal error finding Sales By Year And Month chart.`,
      },
    });
  }
  /**
   * Get sales summary
   * Get sales summary
   * @param endDate endDate
   * @param startDate startDate
   * @param limit limit
   * @param offset offset
   * @returns SalesSummaryResponseDto Success
   * @throws ApiError
   */
  public static getSalesSummaryUsingGet(
    endDate: string,
    startDate: string,
    limit: number = 10,
    offset?: number,
  ): CancelablePromise<SalesSummaryResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/transactions/sales_summary',
      query: {
        'endDate': endDate,
        'limit': limit,
        'offset': offset,
        'startDate': startDate,
      },
      errors: {
        400: `Sales Summary Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal error finding Sales Summary.`,
      },
    });
  }
  /**
   * Get savings by category
   * Get savings by category
   * @param endDate endDate
   * @param startDate startDate
   * @param accountCode accountCode
   * @param category category
   * @returns TransactionStatistics Success
   * @throws ApiError
   */
  public static getSavingsByCategoryUsingGet(
    endDate: string,
    startDate: string,
    accountCode?: string,
    category?: string,
  ): CancelablePromise<Array<TransactionStatistics>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/transactions/savings_by_category',
      query: {
        'accountCode': accountCode,
        'category': category,
        'endDate': endDate,
        'startDate': startDate,
      },
      errors: {
        400: `Savings by Category Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal error finding Savings By Category chart.`,
      },
    });
  }
  /**
   * Get savings by manufacturer
   * Get savings by manufacturer
   * @param endDate endDate
   * @param startDate startDate
   * @param accountCode accountCode
   * @param manufacturer manufacturer
   * @returns TransactionStatistics Success
   * @throws ApiError
   */
  public static getSavingsByManufacturerUsingGet(
    endDate: string,
    startDate: string,
    accountCode?: string,
    manufacturer?: string,
  ): CancelablePromise<Array<TransactionStatistics>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/transactions/savings_by_manufacturer',
      query: {
        'accountCode': accountCode,
        'endDate': endDate,
        'manufacturer': manufacturer,
        'startDate': startDate,
      },
      errors: {
        400: `Savings By Manufacturer Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal error finding Savings By Manufacturer chart.`,
      },
    });
  }
  /**
   * Get savings Top 5
   * Get savings Top 5
   * @param endDate endDate
   * @param groupBy groupBy
   * @param startDate startDate
   * @param accountCode accountCode
   * @returns TransactionStatistics Success
   * @throws ApiError
   */
  public static getTop5UsingGet(
    endDate: string,
    groupBy: 'category_name' | 'manufacturer_name',
    startDate: string,
    accountCode?: string,
  ): CancelablePromise<Array<TransactionStatistics>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/transactions/savings_top_5',
      query: {
        'accountCode': accountCode,
        'endDate': endDate,
        'groupBy': groupBy,
        'startDate': startDate,
      },
      errors: {
        400: `Savings top 5  Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal error finding top 5 chart.`,
      },
    });
  }
}
