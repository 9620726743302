import {
  horizontalListSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import { TableHead, TableRow } from "@mui/material";
import { Fragment } from "react";
import {
  CHECKBOX_COLUMN_ID,
  TABLE_ACTION_COLUMN_WIDTH,
  TABLE_FILTER_HEIGHT,
} from "../../constants/dataTable";
import { ThreeDotsIcon } from "../icons";
import { TableHeaderCell } from "../ui";
import DataTableColumn from "./DataTableColumn";
import { useDataTableContext } from "./DataTableContext";
import DataTableFilter from "./DataTableFilter";

const DataTableHeader = ({ isScrolled }: { isScrolled: boolean }) => {
  const { table, additionalColumnId, filterFocusedColumnId, hasActionColumn } =
    useDataTableContext();

  return (
    <TableHead
      data-testid="dt-header"
      sx={(theme) => ({
        boxShadow: isScrolled ? "0px 2px 4px rgba(86,86,86, 0.5)" : undefined,
        position: "sticky",
        top: 0,
        zIndex: theme.zIndex.appBar,
      })}
    >
      {table?.getHeaderGroups().map((headerGroup) => {
        const renderingHeaders = headerGroup.headers.filter(
          (header) => header.id !== additionalColumnId,
        );

        return renderingHeaders.length > 0 ? (
          <Fragment key={headerGroup.id}>
            <SortableContext
              items={renderingHeaders}
              strategy={horizontalListSortingStrategy}
            >
              <TableRow
                data-testid="dt-column-names"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.palette.grey["900"]}`,
                  display: "flex",
                })}
              >
                {renderingHeaders.map((header) => (
                  <DataTableColumn key={header.id} header={header} />
                ))}
                {hasActionColumn && (
                  <TableHeaderCell
                    component="th"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      p: 0,
                      width: TABLE_ACTION_COLUMN_WIDTH,
                    }}
                  >
                    <ThreeDotsIcon
                      sx={(theme) => ({
                        height: theme.spacing(3),
                        width: theme.spacing(3),
                      })}
                    />
                  </TableHeaderCell>
                )}
              </TableRow>
            </SortableContext>
            <TableRow
              data-testid="dt-column-filters"
              sx={{ borderBottom: "none ", display: "flex" }}
            >
              {renderingHeaders.map((header) => (
                <TableHeaderCell
                  key={header.id}
                  component="th"
                  data-testid={`dt-column-${header.column.columnDef.id}`}
                  sx={(theme) => ({
                    background: theme.palette.grey["50"],
                    columnSpan: header.colSpan,
                    height: TABLE_FILTER_HEIGHT,
                    overflow: "visible",
                    width: `${header.getSize()}px`,
                    zIndex:
                      filterFocusedColumnId === header.column.columnDef.id
                        ? theme.zIndex.drawer
                        : "auto",
                  })}
                >
                  {header.isPlaceholder ||
                  header.id === CHECKBOX_COLUMN_ID ? null : (
                    <DataTableFilter header={header} />
                  )}
                </TableHeaderCell>
              ))}
              {hasActionColumn && (
                <TableHeaderCell
                  component="th"
                  sx={(theme) => ({
                    background: theme.palette.grey["50"],
                    height: TABLE_FILTER_HEIGHT,
                    overflow: "visible",
                    width: TABLE_ACTION_COLUMN_WIDTH,
                  })}
                />
              )}
            </TableRow>
          </Fragment>
        ) : null;
      })}
    </TableHead>
  );
};

export default DataTableHeader;
