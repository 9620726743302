import { Alert as MuiAlert, styled } from "@mui/material";

export const Alert = styled(MuiAlert)(({ theme, severity }) => {
  if (severity === "info") {
    return {
      backgroundColor: theme.palette.green["700"],
      borderInlineStartWidth: "4px",
      borderLeft: `2px solid ${theme.palette.blue["800"]}!important`,
      color: theme.palette.green["100"],
      fontSize: "16px",
    };
  }
});
