import { useQuery } from "@tanstack/react-query";
import { UsersResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useUser = (userId: number) => {
  return useQuery(
    [QUERY_KEYS["user"], userId],
    () => UsersResourceService.getUserByIdUsingGet(userId),
    { enabled: !!userId },
  );
};
