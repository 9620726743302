import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { type RecentQuoteResponseDto } from "../../api";
import { TABLE_ROW_HEIGHT } from "../../constants";
import { useCookiesLive } from "../../hooks";
import { useRecentQuotes } from "../../hooks/api/queries/useRecentQuotes";
import theme from "../../theme";
import { getFormattedDate } from "../../utils/datetime";
import { getAppLink } from "../../utils/link";
import { TableCell, TableHeaderCell } from "../ui/Table";

const RecentQuotesTable = memo(() => {
  const [accountId] = useCookiesLive(["accountId"]);
  const { data: recentQuotes, isLoading } = useRecentQuotes(
    accountId ? +accountId : 0,
  );

  const handleQuoteRowClick = (quote: RecentQuoteResponseDto) => {
    window.location.href = getAppLink(
      `/CBSDirectRep/ProposalDetailsX.php?ordernum=${encodeURIComponent(
        quote?.orderNum ?? "",
      )}`,
    );
  };

  return (
    <Box
      data-testid="recent-quotes-table-container"
      sx={{ maxHeight: "600px", overflowY: `${isLoading ? "hidden" : "auto"}` }}
    >
      <Table data-testid="recent-quotes-table">
        <TableHead data-testid="recent-quotes-table-header">
          <TableRow>
            <TableHeaderCell>Number</TableHeaderCell>
            <TableHeaderCell>PO</TableHeaderCell>
            <TableHeaderCell>Date</TableHeaderCell>
            <TableHeaderCell>Attention</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody data-testid="recent-quotes-table-body">
          {isLoading && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={4}
                data-testid="recent-quotes-table-loading-spinner-cell"
                sx={{ borderBottomWidth: "0" }}
              >
                <CircularProgress className="homepage-table-loading-spinner" />
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            recentQuotes &&
            recentQuotes.map((quote) => (
              <TableRow
                key={quote.orderNum}
                sx={{
                  "&:hover": {
                    backgroundColor: theme.palette.grey["100"],
                    cursor: "pointer",
                  },
                  height: TABLE_ROW_HEIGHT,
                }}
                onClick={() => handleQuoteRowClick(quote)}
              >
                <TableCell>
                  <Typography
                    className="text-link"
                    component="span"
                    sx={{
                      "&:hover": { textDecoration: "underline" },
                      color: theme.palette.blue["400"],
                      fontSize: "14px",
                    }}
                  >
                    {quote.orderNum}
                  </Typography>
                </TableCell>
                <TableCell>{quote.custPo}</TableCell>
                <TableCell>{getFormattedDate(quote.orderDate)}</TableCell>
                <TableCell>{quote.attention}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
});

export default RecentQuotesTable;
