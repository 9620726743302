import { useEffect, useState } from "react";
import { OrderLineSolrDto } from "../../api";
import ReturnTrackingCodesModal from "../modals/ReturnTrackingCodesModal";

const ReturnTrackingCodesWrapper = () => {
  const [selectedOrderLine, setSelectedOrderLine] =
    useState<OrderLineSolrDto>();

  useEffect(() => {
    document.addEventListener(
      "selectOrderLineForReturnTrackingCodesEvent",
      (event: any) => {
        const entity = event.detail as OrderLineSolrDto;
        setSelectedOrderLine(entity);
      },
    );
  }, []);

  return (
    <>
      {selectedOrderLine && (
        <ReturnTrackingCodesModal
          isOpen
          orderLine={selectedOrderLine}
          onClose={() => setSelectedOrderLine(undefined)}
        />
      )}
    </>
  );
};

export default ReturnTrackingCodesWrapper;
