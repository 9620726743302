import { useQuery } from "@tanstack/react-query";
import { SearchResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useSearchFacets = ({
  accountCode,
  criteria,
  term,
}: {
  accountCode?: string;
  criteria?: object;
  term?: string;
}) => {
  return useQuery(
    [QUERY_KEYS["search-facets"], accountCode, criteria, term],
    () =>
      SearchResourceService.getSearchResultsFacetsUsingGet(
        accountCode,
        JSON.stringify(criteria),
        undefined,
        1,
        50,
        undefined,
        term,
      ),
    { keepPreviousData: true },
  );
};
