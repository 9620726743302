/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BundleLineDetailDto } from '../models/BundleLineDetailDto';
import type { BundleLinePatchRequestDto } from '../models/BundleLinePatchRequestDto';
import type { BundleLinePatchResponseDto } from '../models/BundleLinePatchResponseDto';
import type { BundleLinePriceChangeResponseDto } from '../models/BundleLinePriceChangeResponseDto';
import type { BundleLineRequestDto } from '../models/BundleLineRequestDto';
import type { PrimaryPackageDto } from '../models/PrimaryPackageDto';
import type { RestCollection_StandardLineSolrDto_ } from '../models/RestCollection_StandardLineSolrDto_';
import type { RestMessage } from '../models/RestMessage';
import type { UnspscOrderLine } from '../models/UnspscOrderLine';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class StandardLinesResourceService {
  /**
   * Create Bundle Line
   * Create Bundle Line
   * @param bundleLineRequestDto bundleLineRequestDto
   * @returns BundleLineDetailDto Created
   * @throws ApiError
   */
  public static createBundleLineUsingPost(
    bundleLineRequestDto: BundleLineRequestDto,
  ): CancelablePromise<BundleLineDetailDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/bundleLines',
      body: bundleLineRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Bundle Not Found`,
        409: `Product is already present`,
        500: `Internal Error Creating the Bundle Line`,
      },
    });
  }
  /**
   * Find All Bundle Line Details By Bundle Line Id
   * Find All Bundle Line Details By Bundle Line Id
   * @param bundleLineId bundleLineId
   * @returns PrimaryPackageDto Success
   * @throws ApiError
   */
  public static getBundleLineUsingGet(
    bundleLineId: number,
  ): CancelablePromise<PrimaryPackageDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/bundleLines/{bundleLineId}',
      path: {
        'bundleLineId': bundleLineId,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `No Bundle Line Found`,
        500: `Internal Error Finding Bundle Line Details`,
      },
    });
  }
  /**
   * Delete Bundle Line
   * Delete Bundle Line
   * @param bundleLineId bundleLineId
   * @returns BundleLinePriceChangeResponseDto Success
   * @throws ApiError
   */
  public static deleteBundleLineUsingDelete(
    bundleLineId: number,
  ): CancelablePromise<Array<BundleLinePriceChangeResponseDto>> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/service/rest/bundleLines/{bundleLineId}',
      path: {
        'bundleLineId': bundleLineId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Bundle Line Not Found`,
        500: `Internal Error Deleting Bundle Line`,
      },
    });
  }
  /**
   * Patch End User License for Bundle Line by bundle line ID
   * Patch Bundle Line
   * @param bundleLineId bundleLineId
   * @param patchRequest patchRequest
   * @returns BundleLinePatchResponseDto Success
   * @throws ApiError
   */
  public static updateBundleLineUsingPatch(
    bundleLineId: number,
    patchRequest: BundleLinePatchRequestDto,
  ): CancelablePromise<BundleLinePatchResponseDto> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/bundleLines/{bundleLineId}',
      path: {
        'bundleLineId': bundleLineId,
      },
      body: patchRequest,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Bundle Line Not Found`,
        409: `Conflict`,
        500: `Internal Error Patching the Bundle Line`,
      },
    });
  }
  /**
   * list
   * @param criteria criteria
   * @param page page
   * @param returnAllLabels returnAllLabels
   * @param returnPagination returnPagination
   * @param returnSavedViews returnSavedViews
   * @param size size
   * @param useDefaultView useDefaultView
   * @returns RestCollection_StandardLineSolrDto_ OK
   * @throws ApiError
   */
  public static listUsingGet24(
    criteria: string = '{}',
    page: number = 1,
    returnAllLabels: boolean = false,
    returnPagination: boolean = true,
    returnSavedViews: boolean = true,
    size: number = 50,
    useDefaultView: boolean = false,
  ): CancelablePromise<RestCollection_StandardLineSolrDto_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/listing/standard_lines',
      query: {
        'criteria': criteria,
        'page': page,
        'returnAllLabels': returnAllLabels,
        'returnPagination': returnPagination,
        'returnSavedViews': returnSavedViews,
        'size': size,
        'useDefaultView': useDefaultView,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * updateUnspcForOrderLines
   * @param unspscOrderLine unspscOrderLine
   * @returns RestMessage OK
   * @throws ApiError
   */
  public static updateUnspcForOrderLinesUsingPatch(
    unspscOrderLine: UnspscOrderLine,
  ): CancelablePromise<RestMessage> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/standard_lines',
      body: unspscOrderLine,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
