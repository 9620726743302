import { Box } from "@mui/material";
import { memo } from "react";

const DialPlate = memo(
  ({
    min,
    max,
    step,
    size = 300,
    value,
    invisibleTickersClickable,
    onChange,
  }: {
    min: number;
    max: number;
    step: number;
    size?: number;
    value: number;
    invisibleTickersClickable?: boolean;
    onChange: (value: number) => void;
  }) => {
    const allTicks = Array(max - min)
      .fill(0)
      .map((_, index) => index + min);

    const isTickVisible = (tick: number) => tick % step === 0;

    const pointerAngle = (360 * (value - min)) / (max - min);

    return (
      <Box
        sx={{
          borderRadius: "50%",
          boxShadow: "0px 0px 6px 0px rgba(185,185,185, 0.693182)",
          height: `${size}px`,
          position: "relative",
          width: `${size}px`,
        }}
      >
        <Box
          sx={{
            height: "100%",
            position: "relative",
            width: "100%",
            zIndex: 1,
          }}
        >
          {allTicks.map((tick, index) => {
            const angle = (360 * index) / allTicks.length;
            return isTickVisible(tick) || invisibleTickersClickable ? (
              <Box
                key={tick}
                sx={{
                  left: "50%",
                  position: "absolute",
                  top: "18px",
                  transform: `translateX(-50%) rotate(${angle}deg)`,
                  transformOrigin: `center ${size / 2 - 18}px`,
                }}
              >
                <Box
                  sx={(theme) => ({
                    alignItems: "center",
                    color: value === tick ? "white" : "gray.700",
                    cursor: "pointer",
                    display: "inline-flex",
                    fontSize: theme.spacing(1.75),
                    fontWeight: 500,
                    height: "30px",
                    justifyContent: "center",
                    opacity: isTickVisible(tick) || value === tick ? 1 : 0,
                    transform: `rotate(${-angle}deg)`,
                    width: "30px",
                  })}
                  onClick={() => onChange(tick)}
                >
                  {tick === 0 ? "00" : tick}
                </Box>
              </Box>
            ) : null;
          })}
        </Box>
        <Box
          sx={{
            inset: "18px",
            position: "absolute",
            zIndex: 0,
          }}
        >
          <Box
            sx={{
              height: "100%",
              position: "relative",
              transform: `rotate(${pointerAngle}deg)`,
              transition: "all .3s",
              width: "100%",
            }}
          >
            <Box
              sx={(theme) => ({
                background: theme.palette.blue["800"],
                borderRadius: "50%",
                display: "inline-block",
                height: "30px",
                left: "50%",
                position: "absolute",
                transform: "translateX(-50%)",
                width: "30px",
              })}
            />
            <Box
              sx={(theme) => ({
                background: theme.palette.blue["800"],
                display: "inline-block",
                height: size / 2 - 18,
                left: "50%",
                position: "absolute",
                transform: "translateX(-50%)",
                width: "2px",
              })}
            />
            <Box
              sx={(theme) => ({
                background: theme.palette.blue["800"],
                borderRadius: "50%",
                display: "inline-block",
                height: 8,
                left: "50%",
                position: "absolute",
                top: "50%",
                transform: "translate(-50%, -50%)",
                width: 8,
              })}
            />
          </Box>
        </Box>
      </Box>
    );
  },
);

export default DialPlate;
