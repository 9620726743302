import { useQuery } from "@tanstack/react-query";
import { ProductsResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useProductDetails = (productId?: number) => {
  return useQuery(
    [QUERY_KEYS["product-details"], productId],
    () =>
      ProductsResourceService.getProductDetailsByProductIdUsingGet(productId!),
    { enabled: !!productId },
  );
};
