import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ChangeEvent, useCallback, useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { useOrderLine } from "../../../hooks/api/queries";
import { Button, TextField } from "../../ui";
import { useRmaContext } from "./RmaContext";

type FormValues = {
  inputSerialNumbers?: { name?: string; value?: string }[];
  checkboxSerialNumbers?: string[];
  selectNumberToReturn?: number | undefined;
  isUserSerialNumberCheckboxesEnabled: boolean;
};

type NewInput = {
  name: string;
};

const schema = yup.object({
  checkboxSerialNumbers: yup
    .array()
    .when(
      "isUserSerialNumberCheckboxesEnabled",
      ([isUserSerialNumberCheckboxesEnabled], schema) => {
        return isUserSerialNumberCheckboxesEnabled
          ? schema.min(1, "Please check at least one Serial Number")
          : schema;
      },
    ),
  inputSerialNumbers: yup.array().of(
    yup.object({
      name: yup.string(),
    }),
  ),
  isUserSerialNumberCheckboxesEnabled: yup.boolean().required(),
  selectNumberToReturn: yup
    .number()
    .when(
      "isUserSerialNumberCheckboxesEnabled",
      ([selectNumberToReturn], schema) => {
        return selectNumberToReturn
          ? schema
          : schema.required("Please select the number of items to return.");
      },
    )
    .transform((_, val) => (val !== "" ? Number(val) : undefined)),
});

const BasicDeviceInformation = ({ onNext }: { onNext: () => void }) => {
  const {
    onClose,
    setOrderLine,
    orderLineId,
    setNumberToReturn,
    setSerialNumbers,
    orderId,
    partDetails,
  } = useRmaContext();
  const { data: orderLineData, isLoading } = useOrderLine(orderLineId);
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
    getValues,
    setFocus,
  } = useForm<FormValues>({
    defaultValues: {
      checkboxSerialNumbers: [],
      isUserSerialNumberCheckboxesEnabled: !!(
        orderLineData?.serialNumbers && orderLineData?.serialNumbers.length
      ),
      selectNumberToReturn:
        orderLineData?.serialNumbers && orderLineData?.serialNumbers.length
          ? orderLineData?.serialNumbers.length
          : undefined,
    },
    resolver: yupResolver<FormValues>(schema),
  });
  const numberOfItemsToReturn = watch("selectNumberToReturn");
  const { fields, replace } = useFieldArray({
    control,
    name: "inputSerialNumbers",
    shouldUnregister: true,
  });

  const updateSerialInputs = useCallback(
    (numberOfInputs: number) => {
      const newInputs: NewInput[] = [];
      for (let index = 0; index < numberOfInputs; index++) {
        newInputs.push({ name: "" });
      }
      replace(newInputs);
    },
    [replace],
  );

  useEffect(() => {
    if (!orderLineData) {
      return;
    }

    if (!orderLineData?.qtyReturnable) {
      enqueueSnackbar({
        message: "There are no items to return",
        variant: "error",
      });
      setOrderLine(undefined);
      onClose();
      return;
    }

    if (
      !orderLineData?.serialNumbers &&
      ![
        "DESKTOP COMPUTERS",
        "THIN CLIENT COMPUTER",
        "SERVER COMPUTERS",
        "LAPTOPS & NOTEBOOKS",
        "HANDHELD COMPUTERS",
        "PRINTERS",
        "TELEPHONY PRODUCTS",
        "MONITORS & PROJECTORS",
      ].find((categoryName) => categoryName === partDetails?.category)
    ) {
      setNumberToReturn(orderLineData?.qtyReturnable);
      onNext();
      return;
    }

    setValue(
      "isUserSerialNumberCheckboxesEnabled",
      !!(orderLineData?.serialNumbers && orderLineData?.serialNumbers.length),
    );
    if (orderLineData?.qtyReturnable === 1) {
      setValue("selectNumberToReturn", 1);
    }

    if (
      !(orderLineData?.serialNumbers && orderLineData?.serialNumbers.length)
    ) {
      setFocus("inputSerialNumbers.0.name");
    }

    setOrderLine(orderLineData);
  }, [
    orderLineData,
    enqueueSnackbar,
    setOrderLine,
    onClose,
    onNext,
    partDetails,
    setValue,
    setFocus,
    numberOfItemsToReturn,
    setNumberToReturn,
  ]);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const allCheckboxes =
      orderLineData?.serialNumbers &&
      orderLineData?.serialNumbers.map((serialNumber, index) => serialNumber);

    if (checked) {
      setValue("checkboxSerialNumbers", allCheckboxes);
    } else {
      setValue("checkboxSerialNumbers", []);
    }
  };

  const onSubmit = useCallback(
    (values: FormValues) => {
      if (values.inputSerialNumbers?.length) {
        setSerialNumbers(
          values.inputSerialNumbers
            .filter((serialNumber) => !!serialNumber.name)
            .map((serialNumber) => serialNumber.name ?? ""),
        );
        setNumberToReturn(values.selectNumberToReturn);
        onNext();
        return;
      }
      setNumberToReturn(values.checkboxSerialNumbers?.length);
      setSerialNumbers(values.checkboxSerialNumbers);
      onNext();
    },
    [onNext, setNumberToReturn, setSerialNumbers],
  );
  const checkedCheckboxes = getValues("checkboxSerialNumbers");

  return (
    <Dialog
      fullWidth
      open
      data-testid="rma-request-device-info-modal"
      maxWidth="sm"
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          data-testid="rma-request-device-info-modal-title"
          sx={(theme) => ({
            fontSize: theme.spacing(2.25),
            pb: 0,
            pt: 3.5,
            px: 2,
          })}
        >
          Request RMA for Order{" "}
          <Typography
            component="span"
            sx={(theme) => ({
              fontSize: theme.spacing(2.25),
              fontWeight: "bold",
            })}
          >
            {orderId}
          </Typography>{" "}
          Part{" "}
          <Typography
            component="span"
            sx={(theme) => ({
              fontSize: theme.spacing(2.25),
              fontWeight: "bold",
            })}
          >
            {partDetails?.supplierCode}|{partDetails?.supplierPart}
          </Typography>
        </DialogTitle>
        <IconButton
          data-testid="rma-request-device-info-modal-close-icon"
          sx={{
            color: (theme) => theme.palette.grey[900],
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent data-testid="rma-request-device-info-modal-body">
          {isLoading ? (
            <Box
              sx={(theme) => ({
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                py: theme.spacing(2.5),
              })}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box
                sx={(theme) => ({
                  alignItems: `${
                    orderLineData?.serialNumbers &&
                    orderLineData?.serialNumbers.length
                      ? "center"
                      : "normal"
                  }`,
                  display: "flex",
                  flexDirection: "column",
                  gap: theme.spacing(1),
                  width: 261,
                })}
              >
                <Typography
                  sx={(theme) => ({
                    fontSize: 16,
                    mb: theme.spacing(0.5),
                  })}
                >
                  Select the unit(s) you wish to return
                </Typography>
                {orderLineData?.serialNumbers &&
                orderLineData?.serialNumbers.length ? (
                  <>
                    <Box>
                      {orderLineData?.serialNumbers.length > 1 && (
                        <Box
                          sx={{
                            height: 26,
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  orderLineData?.serialNumbers &&
                                  checkedCheckboxes &&
                                  orderLineData?.serialNumbers.length ===
                                    checkedCheckboxes.length
                                }
                                indeterminate={
                                  !!(
                                    checkedCheckboxes &&
                                    checkedCheckboxes.length &&
                                    orderLineData?.serialNumbers.length !==
                                      checkedCheckboxes.length
                                  )
                                }
                                size="small"
                                onChange={(e) => handleSelectAll(e)}
                              />
                            }
                            data-testid="rma-request-device-info-modal-serialnumber-checkbox"
                            label={
                              <Typography
                                sx={{
                                  fontSize: 14,
                                }}
                              >
                                Select All
                              </Typography>
                            }
                          />
                        </Box>
                      )}
                      {orderLineData?.serialNumbers.map(
                        (serialNumber, index) => (
                          <Box
                            key={index}
                            sx={(theme) => ({
                              height: 26,
                            })}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    checkedCheckboxes &&
                                    checkedCheckboxes.includes(serialNumber)
                                  }
                                  size="small"
                                  {...register("checkboxSerialNumbers")}
                                  {...watch("checkboxSerialNumbers")}
                                  value={serialNumber}
                                />
                              }
                              data-testid="rma-request-device-info-modal-serialnumber-checkbox"
                              label={
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                  }}
                                >
                                  {serialNumber}
                                </Typography>
                              }
                            />
                          </Box>
                        ),
                      )}
                      {errors.checkboxSerialNumbers && (
                        <Box
                          sx={(theme) => ({
                            mt: theme.spacing(1.5),
                          })}
                        >
                          <FormHelperText error>
                            {errors.checkboxSerialNumbers.message}
                          </FormHelperText>
                        </Box>
                      )}
                    </Box>
                  </>
                ) : (
                  orderLineData?.qtyReturnable && (
                    <>
                      {orderLineData?.qtyReturnable > 1 && (
                        <>
                          <Box
                            sx={() => ({
                              margin: "6px 0 10px 0",
                              width: "100%",
                            })}
                          >
                            <FormLabel
                              sx={() => ({
                                fontSize: 14,
                                mr: 1,
                              })}
                            >
                              Qty:
                            </FormLabel>

                            <Controller
                              control={control}
                              name="selectNumberToReturn"
                              render={({ field }) => (
                                <Select
                                  data-testid="rma-request-device-info-modal-returnable-qty-dropdown"
                                  defaultValue=""
                                  size="small"
                                  sx={() => ({
                                    width: 60,
                                  })}
                                  {...register("selectNumberToReturn")}
                                  onChange={(e) => {
                                    updateSerialInputs(
                                      e.target.value as unknown as number,
                                    );
                                    field.onChange(e as ChangeEvent);
                                  }}
                                >
                                  {Array.from(
                                    Array(orderLineData?.qtyReturnable),
                                    (e, qty) => (
                                      <MenuItem key={qty} value={qty + 1}>
                                        {qty + 1}
                                      </MenuItem>
                                    ),
                                  )}
                                </Select>
                              )}
                            />
                            {errors.selectNumberToReturn && (
                              <Box
                                sx={(theme) => ({
                                  mt: theme.spacing(1.5),
                                })}
                              >
                                <FormHelperText error>
                                  {errors.selectNumberToReturn.message}
                                </FormHelperText>
                              </Box>
                            )}
                          </Box>
                        </>
                      )}
                      {orderLineData?.qtyReturnable === 1 && (
                        <>
                          <TextField
                            autoFocus
                            data-testid="rma-request-device-info-modal-serialnumber-text"
                            placeholder="Serial number"
                            size="small"
                            {...register("inputSerialNumbers.0.name")}
                          />
                          {errors.inputSerialNumbers &&
                            errors.inputSerialNumbers[0]?.name && (
                              <FormHelperText error>
                                {errors.inputSerialNumbers[0]?.name?.message}
                              </FormHelperText>
                            )}
                        </>
                      )}
                      {orderLineData?.qtyReturnable > 1 &&
                        fields.map((field, index) => (
                          <section key={field.id}>
                            <TextField
                              data-testid="rma-request-device-info-modal-serialnumber-text"
                              defaultValue=""
                              placeholder="Serial number"
                              size="small"
                              {...register(
                                `inputSerialNumbers.${index}.name` as const,
                              )}
                            />
                            {errors.inputSerialNumbers &&
                              errors.inputSerialNumbers[index]?.name && (
                                <FormHelperText error>
                                  {
                                    errors.inputSerialNumbers[index]?.name
                                      ?.message
                                  }
                                </FormHelperText>
                              )}
                          </section>
                        ))}
                    </>
                  )
                )}
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", pb: 3, px: 2 }}>
          <Button
            data-testid="rma-request-device-info-modal-close-btn"
            sx={(theme) => ({ width: theme.spacing(12.5) })}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            data-testid="rma-request-device-info-modal-next-btn"
            sx={(theme) => ({ width: theme.spacing(12.5) })}
            type="submit"
            variant="contained"
          >
            Next
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BasicDeviceInformation;
