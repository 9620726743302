import { Link, Typography } from "@mui/material";
import { memo } from "react";
import {
  INVOICE_DISP_COLUMNS,
  ORDER_LINES_DISP_COLUMNS,
} from "../../../constants";
import { useBlanketPO } from "../../../hooks/api/queries";
import theme from "../../../theme";
import { buildLegacyCriteriaUrl } from "../../../utils/criteria";
import { formatCurrency } from "../../../utils/currency";
import { getAppLink } from "../../../utils/link";

const BlanketPOField = memo(
  ({
    id,
    fieldKey,
    fs,
  }: {
    id: number | undefined;
    fieldKey: "invoiceTotal" | "openOrderValue" | "availableValue";
    fs?: Record<string, string>;
  }) => {
    const { data } = useBlanketPO(id);

    const currencyValue = formatCurrency(data?.[fieldKey]);

    if (fieldKey === "availableValue") {
      return (
        <Typography
          component="span"
          sx={{
            color:
              (data?.[fieldKey] as number) > 0
                ? theme.palette.grey["900"]
                : theme.palette.red["500"],
          }}
        >
          {currencyValue}
        </Typography>
      );
    }

    const fsKey = Object.keys(fs ?? {})[0];
    if (currencyValue && fieldKey === "invoiceTotal") {
      return (
        <Link
          href={`${getAppLink("/app/Invoice")}?${buildLegacyCriteriaUrl(
            fs ?? {},
            INVOICE_DISP_COLUMNS.includes(fsKey) || !fsKey
              ? INVOICE_DISP_COLUMNS
              : [...INVOICE_DISP_COLUMNS, fsKey],
            "invoiceNum",
          )}`}
          sx={{
            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
            color: theme.palette.blue["700"],
            textDecoration: "none",
          }}
        >
          {currencyValue}
        </Link>
      );
    }

    if (currencyValue && fieldKey === "openOrderValue") {
      return (
        <Link
          href={`${getAppLink("/app/OrderLine")}?${buildLegacyCriteriaUrl(
            fs ?? {},
            ORDER_LINES_DISP_COLUMNS.includes(fsKey) || !fsKey
              ? ORDER_LINES_DISP_COLUMNS
              : [...ORDER_LINES_DISP_COLUMNS, fsKey],
            "orderNum",
          )}`}
          sx={{
            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
            color: theme.palette.blue["700"],
            textDecoration: "none",
          }}
        >
          {currencyValue}
        </Link>
      );
    }

    return null;
  },
);

export default BlanketPOField;
