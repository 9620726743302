import { useEffect, useState } from "react";
import { StaffListing } from "../../api";
import EditStaffRights from "../modals/EditStaffRights";

const EditStaffRightsWrapper = () => {
  const [selectedStaff, setSelectedStaff] = useState<StaffListing>();

  useEffect(() => {
    document.addEventListener("selectStaffForEditStaffRights", (event: any) => {
      const entity = event.detail as StaffListing;
      setSelectedStaff(entity);
    });
  }, []);

  return (
    <>
      {selectedStaff && (
        <EditStaffRights
          isOpen
          staff={selectedStaff}
          onClose={() => setSelectedStaff(undefined)}
        />
      )}
    </>
  );
};

export default EditStaffRightsWrapper;
