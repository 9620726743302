import { useQuery } from "@tanstack/react-query";
import { ElectronicPoResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useRecentElectronicPos = (
  accountId: number,
  onlyPending: boolean = true,
) => {
  return useQuery(
    [QUERY_KEYS["recent-electronic-pos"], accountId],
    () =>
      ElectronicPoResourceService.getRecentPosUsingGet(
        accountId,
        onlyPending,
        1,
        500,
      ),
    { enabled: !!accountId },
  );
};
