import { Box } from "@mui/material";
import MostPopularProducts from "../../components/charts/MostPopularProducts";
import SavingsByCategory from "../../components/charts/SavingsByCategory";
import SavingsByManufacturer from "../../components/charts/SavingsByManufacturer";
import SpendByCategory from "../../components/charts/SpendByCategory";
import SpendByLocation from "../../components/charts/SpendByLocation";
import SpendByManufacturer from "../../components/charts/SpendByManufacturer";
import SpendByState from "../../components/charts/SpendByState";
import SpendByYearAndMonth from "../../components/charts/SpendByYearAndMonth";
import { PrinterIcon } from "../../components/icons";
import { Button } from "../../components/ui";
import { PurchaseAnalysisContextProvider } from "../../contexts/PurchaseAnalysisContext";
import { withPageTitle } from "../../hocs";

const PurchaseAnalysis = () => {
  return (
    <PurchaseAnalysisContextProvider>
      <Box
        sx={(theme) => ({
          "@media print": { display: "none" },
          display: "flex",
          gap: theme.spacing(2),
          justifyContent: "end",
          left: 0,
          paddingX: theme.spacing(4),
          paddingY: theme.spacing(2),
        })}
      >
        <Button
          aria-label="Print"
          sx={{ minWidth: 40 }}
          variant="outlined"
          onClick={() => window.print()}
        >
          <PrinterIcon sx={{ fontSize: 14 }} />
        </Button>
      </Box>
      <Box
        sx={(theme) => ({
          "@media print": { gridTemplateColumns: "repeat(1, 1fr)" },
          display: "grid",
          gap: theme.spacing(4),
          gridTemplateColumns: {
            xs: "repeat(2, 1fr)",
          },
        })}
      >
        <SpendByCategory />
        <SpendByManufacturer />
        <SpendByState />
        <SpendByYearAndMonth />
        <SpendByLocation />
        <MostPopularProducts />
        <SavingsByCategory />
        <SavingsByManufacturer />
      </Box>
    </PurchaseAnalysisContextProvider>
  );
};

export default withPageTitle("Purchase Analysis", PurchaseAnalysis);
