import { useEffect, useState } from "react";
import { ManufacturerProductSolrDto } from "../../api";
import SkuAndLicenseProgram from "../modals/SkuAndLicenseProgram";

const SkuAndLicenseProgramWrapper = () => {
  const [selectedProduct, setSelectedProduct] =
    useState<ManufacturerProductSolrDto>();

  useEffect(() => {
    document.addEventListener(
      "selectProductForSkuAndLicenseProgramEvent",
      (event: any) => {
        const entity = event.detail as ManufacturerProductSolrDto;
        setSelectedProduct(entity);
      },
    );
  }, []);

  return (
    <>
      {selectedProduct && (
        <SkuAndLicenseProgram
          isOpen
          product={selectedProduct}
          onClose={() => setSelectedProduct(undefined)}
        />
      )}
    </>
  );
};

export default SkuAndLicenseProgramWrapper;
