import { Box, CircularProgress, Typography } from "@mui/material";
import { ReactElement, useEffect, useRef, useState } from "react";

const ChartWidget = ({
  children,
  title,
  actions,
  hasNoData,
  isLoading,
  id,
}: {
  children: ReactElement | ReactElement[];
  title: string;
  actions: ReactElement;
  hasNoData: boolean;
  isLoading: boolean;
  id: string;
}) => {
  const [isEnlarged, setIsEnlarged] = useState(false);
  const onToggle = () => setIsEnlarged((prev) => !prev);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isEnlarged) {
      setTimeout(
        () => ref.current?.scrollIntoView({ behavior: "smooth" }),
        100,
      );
    }
  }, [isEnlarged]);

  return (
    <Box
      key={isEnlarged + ""} // re-render chart component on enlarge
      ref={ref}
      data-testid={`chart-widget-${id}`}
      sx={(theme) => ({
        "@media print": {
          boxShadow: "0px 3px 8px rgba(0,0,0, 0.20494)",
          margin: "8px",
          pageBreakBefore: "auto",
          pageBreakInside: "avoid",
        },
        borderRadius: theme.spacing("sm"),
        boxShadow: "0px 3px 8px rgba(0,0,0, 0.20494)",
        gridColumn: isEnlarged ? "span 2" : "span 1",
        overflow: "hidden",
        px: theme.spacing(2),
        py: theme.spacing(1.5),
      })}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          data-testid="chart-title"
          sx={{ cursor: "pointer" }}
          onClick={onToggle}
        >
          {title}
        </Typography>
        {actions}
      </Box>
      <Box
        sx={(theme) => ({
          alignItems: "center",
          display: "flex",
          height: isEnlarged ? "500px" : "400px",
          justifyContent: "center",
          width: "100%",
        })}
      >
        {isLoading && <CircularProgress />}
        {hasNoData && <Typography>No Data</Typography>}
        {!isLoading && !hasNoData && children}
      </Box>
    </Box>
  );
};

export default ChartWidget;
