import {
  Box,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  alpha,
} from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import { get } from "lodash";
import { Fragment, useEffect, useMemo, useRef } from "react";
import { ManufacturerPartSearchResultDto } from "../../api";
import {
  SEARCH_RESULT_ENTITY_HEIGHT,
  SEARCH_RESULT_ENTITY_SPACING,
  SEARCH_RESULT_OVERSCAN,
  SEARCH_RESULT_SCROLL_TO_TOP_AVAILABLE_AT,
} from "../../constants/searchResults";
import { useCookiesLive } from "../../hooks";
import { useInfiniteSearchResults } from "../../hooks/api/queries";
import { SearchSortType } from "../../types";
import { buildCriteriaFromFacets } from "../../utils/searchFacet";
import { ChevronUpIcon } from "../icons";
import { Button } from "../ui";
import { SearchResultEntity, SearchFilters, useSearchResultsContext } from ".";

export const SearchResultsContent = () => {
  const [accountCode] = useCookiesLive(["accountCode"]);
  const { sort, terms, selectedFacets, onSortChange } =
    useSearchResultsContext();

  const searchResultsRef = useRef<HTMLDivElement | null>(null);
  const criteria = buildCriteriaFromFacets(selectedFacets);

  const {
    data,
    isLoading: isDataLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteSearchResults(
    accountCode,
    JSON.stringify(criteria),
    sort,
    terms,
  );

  const isLoading = isDataLoading || (isFetching && !isFetchingNextPage);

  const rows = useMemo(
    () =>
      data?.pages.flatMap<ManufacturerPartSearchResultDto>(
        (page) => page?.results ?? [],
      ) ?? [],
    [data?.pages],
  );

  const rowVirtualizer = useVirtualizer<any, any>({
    count: hasNextPage ? rows.length + 1 : rows.length,
    estimateSize: () =>
      SEARCH_RESULT_ENTITY_HEIGHT + SEARCH_RESULT_ENTITY_SPACING,
    getScrollElement: () => searchResultsRef.current,
    overscan: SEARCH_RESULT_OVERSCAN,
  });

  const { startIndex } = (rowVirtualizer as any).range ?? 0;
  const virtualRows = rowVirtualizer.getVirtualItems();

  useEffect(() => {
    const [lastItem] = [...virtualRows].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= rows.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    rows.length,
    virtualRows,
  ]);

  const totalCount = useMemo(
    () => get(data, "pages[0].searchInfo[0].numFound"),
    [data],
  );

  const handleSortChange = (event: SelectChangeEvent) =>
    onSortChange(event.target.value as SearchSortType);

  return (
    <Box
      data-testid="sr-container"
      sx={(theme) => ({
        display: "flex",
        gap: theme.spacing(3),
        height: "100%",
      })}
    >
      {(selectedFacets.length > 0 || (totalCount && totalCount > 1)) && (
        <Box
          sx={(theme) => ({
            height: "full",
            pt: theme.spacing(6),
            width: 300,
          })}
        >
          <SearchFilters />
        </Box>
      )}
      <Box
        sx={(theme) => ({
          flex: "1",
          height: "100%",
          overflow: "hidden",
          px: theme.spacing(1),
        })}
      >
        <Box
          data-testid="sr-controls"
          sx={(theme) => ({
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          })}
        >
          {isLoading ? (
            <Typography></Typography>
          ) : (
            <Typography
              data-testid="sr-pagination-result"
              sx={{ fontSize: 14 }}
            >
              {totalCount
                ? `Showing 1-${rows.length} of ${totalCount}`
                : `No results found`}
            </Typography>
          )}

          <Box
            sx={(theme) => ({
              alignItems: "center",
              display: "flex",
              gap: theme.spacing(2),
            })}
          >
            <Typography sx={{ fontSize: 14 }}>Sort By:</Typography>
            <Select
              data-testid="sr-sort-dropdown"
              size="small"
              sx={(theme) => ({
                width: 170,
              })}
              value={sort}
              onChange={handleSortChange}
            >
              <MenuItem value="account_standards">Account Standards</MenuItem>
              <MenuItem value="manufacturer_asc">Manufacturer Asc</MenuItem>
              <MenuItem value="manufacturer_desc">Manufacturer Desc</MenuItem>
              <MenuItem value="parts_sold">Parts Sold</MenuItem>
              <MenuItem value="price_asc">Price Asc</MenuItem>
              <MenuItem value="price_desc">Price Desc</MenuItem>
            </Select>
          </Box>
        </Box>
        <Box
          data-testid="sr-results"
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            py: theme.spacing(2),
          })}
        >
          <Box
            ref={searchResultsRef}
            data-testid="sr-scrollable"
            sx={(theme) => ({
              flex: "1 1 0",
              minH: "500px",
              overflow: isLoading ? "hidden" : "auto",
              px: theme.spacing(0.5),
            })}
          >
            <Box
              data-testid="sr-content"
              sx={() => ({
                position: "relative",
              })}
            >
              <Box
                sx={(theme) => ({
                  height: `${rowVirtualizer.getTotalSize()}px`,
                  overflowX: "unset",
                  overflowY: "unset",
                  position: "relative",
                })}
              >
                {virtualRows.map((virtualRow) => {
                  const isLoaderRow = virtualRow.index > rows.length - 1;
                  const row = rows[virtualRow.index];

                  return (
                    <Fragment key={virtualRow.index}>
                      {!isLoaderRow && (
                        <Box
                          sx={(theme) => ({
                            bgColor: theme.palette.white,
                            borderColor: "rgba(223, 222, 223, 0.1)",
                            borderWidth: 1,
                            boxShadow: "1px 2px 8px 0 rgba(0, 0, 0, 0.15)",
                            height:
                              virtualRow.size - SEARCH_RESULT_ENTITY_SPACING,
                            left: 0,
                            position: "absolute",
                            top: 0,
                            transform: `translateY(${virtualRow.start}px)`,
                            width: "100%",
                          })}
                        >
                          <SearchResultEntity entity={row} />
                        </Box>
                      )}
                    </Fragment>
                  );
                })}
              </Box>

              {startIndex > SEARCH_RESULT_SCROLL_TO_TOP_AVAILABLE_AT && (
                <Button
                  data-testid="sr-back-to-top"
                  startIcon={<ChevronUpIcon sx={{ width: 14 }} />}
                  sx={(theme) => ({
                    bottom: theme.spacing(6),
                    opacity: 0.67,
                    position: "fixed",
                    right: theme.spacing(6),
                  })}
                  variant="outlined"
                  onClick={() =>
                    rowVirtualizer.scrollToIndex(0, { behavior: "smooth" })
                  }
                >
                  Back to Top
                </Button>
              )}
            </Box>
            {isLoading && (
              <Box
                sx={(theme) => ({
                  alignItems: "center",
                  backgroundColor: alpha("#000", 0.08),
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  left: 0,
                  position: "absolute",
                  right: 0,
                  top: 0,
                  zIndex: 1000,
                })}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
