import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CSVLink } from "react-csv";
import { TaxExemptDto } from "../../types";
import { Button, VCenterBox } from "../ui";

export type TaxExemptDtoWithError = TaxExemptDto & { error?: string };

const ImportResult = ({
  succeededTaxExempts,
  failedTaxExempts,
  onClose,
}: {
  succeededTaxExempts: TaxExemptDtoWithError[];
  failedTaxExempts: TaxExemptDtoWithError[];
  onClose: () => void;
}) => {
  return (
    <Dialog fullWidth open maxWidth="md" onClose={onClose}>
      <DialogTitle sx={{ px: 2, py: 4 }}>Imported Tax Exempt Parts</DialogTitle>
      <IconButton
        sx={{
          color: (theme) => theme.palette.grey[900],
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ px: 2, py: 0 }}>
        {failedTaxExempts.length > 0 && (
          <VCenterBox
            sx={(theme) => ({
              background: theme.palette.red["200"],
              justifyContent: "space-between",
              mb: 2,
              px: 3,
              py: 1,
            })}
          >
            <Typography variant="body1">
              There were exemptions that did not successfully import
            </Typography>

            <CSVLink
              data={failedTaxExempts}
              filename="failed-exemptions.csv"
              headers={[
                { key: "manufacturerName", label: "Mfg Name" },
                { key: "manufacturerPart", label: "Mfg Part" },
                { key: "exemptStates", label: "Exempt States" },
                { key: "error", label: "Error" },
              ]}
            >
              <Button color="error" variant="contained">
                Download Exemption Import Failures
              </Button>
            </CSVLink>
          </VCenterBox>
        )}

        {succeededTaxExempts.length > 0 && (
          <>
            <Alert severity="success" sx={{ mb: 2 }}>
              The following exemptions were successfully imported:
            </Alert>
            <TableContainer>
              <Table sx={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow
                    sx={(theme) => ({
                      background: theme.palette.grey["50"],
                      fontSize: theme.spacing(1.75),
                    })}
                  >
                    <TableCell
                      component="th"
                      sx={{ textTransform: "uppercase" }}
                    >
                      manufacturer name
                    </TableCell>
                    <TableCell
                      component="th"
                      sx={{ textTransform: "uppercase" }}
                    >
                      manufacturer part
                    </TableCell>
                    <TableCell
                      component="th"
                      sx={{ textTransform: "uppercase" }}
                    >
                      states
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {succeededTaxExempts.map((taxExempt) => (
                    <TableRow
                      key={`${taxExempt.manufacturerPart}-${taxExempt.manufacturerName}`}
                    >
                      <TableCell>{taxExempt.manufacturerName}</TableCell>
                      <TableCell>{taxExempt.manufacturerPart}</TableCell>
                      <TableCell>{taxExempt.exemptStates?.join(",")}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: "flex-end", pb: 3, px: 2 }}>
        <Button
          sx={(theme) => ({ width: theme.spacing(12.5) })}
          variant="outlined"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportResult;
