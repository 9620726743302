/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingAddressResponseDto } from './BillingAddressResponseDto';
import type { BillToDto } from './BillToDto';
import type { CreditCardAuthorizationResponseDto } from './CreditCardAuthorizationResponseDto';
import type { DealLineDto } from './DealLineDto';
import type { DeliveryDetailDto } from './DeliveryDetailDto';
import type { InvoiceBalanceDto } from './InvoiceBalanceDto';
import type { LabelValueDto } from './LabelValueDto';
import type { OrderHeaderDto } from './OrderHeaderDto';
import type { OrderLineDto } from './OrderLineDto';
import type { OrderTypeDetailsDto } from './OrderTypeDetailsDto';
import type { OrderTypeDto } from './OrderTypeDto';
import type { ShipmentDto } from './ShipmentDto';
import type { ShippingAddressDto } from './ShippingAddressDto';
import type { UserResponseDto } from './UserResponseDto';
export type OrderDto = {
  _labels?: Record<string, string>;
  billTo?: BillToDto;
  billingAddress?: BillingAddressResponseDto;
  confirmationEmails?: Array<string>;
  creditCardAuth?: CreditCardAuthorizationResponseDto;
  customOrderHeaders?: Array<LabelValueDto>;
  dealLines?: Array<DealLineDto>;
  deliveryDetails?: DeliveryDetailDto;
  endUserLicenses?: Array<Record<string, string>>;
  firstSupplierInvoiceDate?: string;
  invoices?: Array<InvoiceBalanceDto>;
  modifiedBy?: string;
  modifiedOn?: string;
  orderCreatedBy?: UserResponseDto;
  orderHeader?: OrderHeaderDto;
  orderLines?: Array<OrderLineDto>;
  orderTypeDetails?: OrderTypeDetailsDto;
  precomputedFreight?: boolean;
  precomputedFreightNotes?: string;
  repDisplay?: OrderDto.repDisplay;
  shipments?: Array<ShipmentDto>;
  shippingAddress?: ShippingAddressDto;
  source?: OrderTypeDto;
};
export namespace OrderDto {
  export enum repDisplay {
    CANCELLED = 'Cancelled',
    CARD_DECLINED = 'Card Declined',
    LOW_MARGIN_HOLD = 'Low Margin Hold',
    OVER_SHIPPED = 'Over shipped',
    PARTIALLY_SHIPPED = 'Partially Shipped',
    PENDING = 'Pending',
    REP_HOLD = 'Rep Hold',
    SHIPPED = 'Shipped',
  }
}

