import { createSvgIcon } from "@mui/material";

export const BookmarkHeartIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 12 16" xmlns="http://www.w3.org/2000/svg">
    <title>bookmark-heart</title>
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g fillRule="nonzero" id="pg1" transform="translate(-416, -309)">
        <g id="Group-8" transform="translate(416, 308)">
          <g id="bookmark-heart" transform="translate(0, 1)">
            <path
              d="M6,4.41 C7.387,2.985 10.854,5.48 6,8.687 C1.146,5.48 4.613,2.986 6,4.412 L6,4.41 Z"
              fill="#0E98D6"
              id="Path"
            ></path>
            <path
              d="M0,2 C0,0.8954305 0.8954305,0 2,0 L10,0 C11.1045695,0 12,0.8954305 12,2 L12,15.5 C12,15.6842759 11.8984578,15.8535623 11.7360006,15.9405407 C11.5735433,16.0275191 11.3764127,16.0180889 11.223,15.916 L6,13.101 L0.777,15.916 C0.623587275,16.0180889 0.426456685,16.0275191 0.263999435,15.9405407 C0.101542185,15.8535623 0,15.6842759 0,15.5 L0,2 Z M2,1 C1.44771525,1 1,1.44771525 1,2 L1,14.566 L5.723,12.084 C5.89078901,11.9723444 6.10921099,11.9723444 6.277,12.084 L11,14.566 L11,2 C11,1.44771525 10.5522847,1 10,1 L2,1 Z"
              fill="#231F20"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "BookmarkHeart",
);
