import { useQuery } from "@tanstack/react-query";
import {
  QuotesResourceService,
  type RestCollection_QuoteSolrDto_,
} from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";
import { ListingMaxIdQueryHook } from "../../../types";
import { getMaxIdCriteria } from "../../../utils/criteria";

export const useQuotesListMaxId: ListingMaxIdQueryHook<
  RestCollection_QuoteSolrDto_
> = (identifier: string) => {
  const maxIdCriteria = getMaxIdCriteria(identifier);

  return useQuery([QUERY_KEYS["quote-list-max-id"], identifier], () => {
    return QuotesResourceService.listUsingGet16(
      JSON.stringify(maxIdCriteria),
      1,
      false,
      true,
      false,
      1,
    );
  });
};
