import { createSvgIcon } from "@mui/material";

export const EyeIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 16 11" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="pg4"
        transform="translate(-363, -702)"
      >
        <g id="eye" transform="translate(363, 702)">
          <path
            d="M16,5.5 C16,5.5 13,0 8,0 C3,0 0,5.5 0,5.5 C0,5.5 3,11 8,11 C13,11 16,5.5 16,5.5 Z M1.173,5.5 C1.65651466,4.7651235 2.2126409,4.0806862 2.833,3.457 C4.12,2.168 5.88,1 8,1 C10.12,1 11.879,2.168 13.168,3.457 C13.7883591,4.0806862 14.3444853,4.7651235 14.828,5.5 C14.77,5.587 14.706,5.683 14.633,5.788 C14.298,6.268 13.803,6.908 13.168,7.543 C11.879,8.832 10.119,10 8,10 C5.88,10 4.121,8.832 2.832,7.543 C2.21164592,6.91930928 1.65552011,6.23487251 1.172,5.5 L1.173,5.5 Z"
            id="Shape"
          ></path>
          <path
            d="M8,3 C6.61928813,3 5.5,4.11928813 5.5,5.5 C5.5,6.88071187 6.61928813,8 8,8 C9.38071187,8 10.5,6.88071187 10.5,5.5 C10.5,4.11928813 9.38071187,3 8,3 Z M4.5,5.5 C4.5,3.56700338 6.06700338,2 8,2 C9.93299662,2 11.5,3.56700338 11.5,5.5 C11.5,7.43299662 9.93299662,9 8,9 C6.06700338,9 4.5,7.43299662 4.5,5.5 L4.5,5.5 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>,
  "Eye",
);
