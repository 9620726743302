/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DealDto } from './DealDto';
import type { EulPatchRequestDto } from './EulPatchRequestDto';
import type { ShipmentTrackingPatchRequestDto } from './ShipmentTrackingPatchRequestDto';
import type { WarrantyDto } from './WarrantyDto';
export type OrderLinePatchRequestDto = {
  backorderNotes?: string;
  backorderNotesClientVisible?: boolean;
  componentType?: OrderLinePatchRequestDto.componentType;
  custom1?: string;
  custom2?: string;
  custom3?: string;
  custom4?: string;
  deal?: DealDto;
  endUserLicenses: EulPatchRequestDto;
  estShipDate?: string;
  expirationDate?: string;
  mfgName?: string;
  mfgPart?: string;
  prodDesc?: string;
  quantity?: number;
  quantityBundle1?: number;
  quantityBundle10?: number;
  quantityBundle2?: number;
  quantityBundle3?: number;
  quantityBundle4?: number;
  quantityBundle5?: number;
  quantityBundle6?: number;
  quantityBundle7?: number;
  quantityBundle8?: number;
  quantityBundle9?: number;
  quantityShipped?: number;
  returnTrackingCodes?: Array<string>;
  shipments?: Array<ShipmentTrackingPatchRequestDto>;
  supplierProductId?: number;
  unitCost?: number;
  unitPrice?: number;
  warranty?: WarrantyDto;
};
export namespace OrderLinePatchRequestDto {
  export enum componentType {
    COMPONENT_PICK_PACK_FEE_MASTER = 'Component|Pick-Pack|Fee|Master',
  }
}

