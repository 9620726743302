import { Search as SearchIcon } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { Header } from "@tanstack/react-table";
import { kebabCase, keys, omit } from "lodash";
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDebounce } from "use-debounce";
import { ListingFacetValueDto } from "../../../api";
import { useListingFacets } from "../../../hooks/api/queries";
import { Button, CenterBox, TextField } from "../../ui";
import { useDataTableContext } from "../DataTableContext";

type Facet = {
  isKeyword?: boolean;
} & ListingFacetValueDto;

const FILTER_OPTIONS = ["Selected", "Blank", "Not Blank"];
const FILTER_OPTION_VALUES = ["_blank_", "_not_blank_"];

const TextFilter = <TData, TValue>({
  header,
  onClose,
}: {
  header: Header<TData, TValue>;
  onClose: () => void;
}) => {
  const { listingName, criteria, updateFilter } = useDataTableContext();
  const { header: label, id: element } = header.column.columnDef;
  const [filterOption, setFilterOption] = useState<
    (typeof FILTER_OPTIONS)[number]
  >(FILTER_OPTIONS[0]);
  const [term, setTerm] = useState("");
  const [debouncedTerm] = useDebounce(term, 500);
  const initialEqValues = useMemo(
    () => ((criteria.eq?.[0] ?? {})[element ?? ""] ?? []) as string[],
    [criteria.eq, element],
  );
  const initialLikeValues = useMemo(
    () => ((criteria.like?.[0] ?? {})[element ?? ""] ?? []) as string[],
    [criteria.like, element],
  );
  const mapInitialLikeValuesToSelected = useCallback(
    (checked: boolean) =>
      initialLikeValues.reduce((prev, curr) => {
        const key = `*${curr}*`;
        return {
          ...prev,
          [key]: checked,
        };
      }, {}),
    [initialLikeValues],
  );

  const [selectedValues, setSelectedValues] = useState<Record<string, boolean>>(
    {},
  );

  useEffect(() => {
    if (initialLikeValues.length) {
      setSelectedValues(mapInitialLikeValuesToSelected(true));
    } else {
      setSelectedValues(
        initialEqValues.reduce(
          (prev, curr) => ({
            ...prev,
            [curr]: true,
          }),
          {},
        ),
      );
    }
  }, [
    element,
    initialEqValues,
    initialLikeValues,
    mapInitialLikeValuesToSelected,
  ]);

  const { data, isLoading } = useListingFacets({
    column: element ?? "",
    criteria: {
      eq: [
        {
          ...omit(criteria.eq?.[0], [element ?? ""]),
        },
      ],
    },
    enabled: !!listingName && criteria.fields?.includes(element ?? ""),
    listingName: listingName!,
    term: debouncedTerm.trim(),
  });

  const facets = useMemo<Facet[]>(
    () => [
      ...initialLikeValues.map((value) => ({
        isKeyword: true,
        value: `*${value}*`,
      })),
      ...(data?.facets?.filter((facet) => facet.count) ?? []),
    ],
    [data?.facets, initialLikeValues],
  );
  const hasBlank = data?.hasBlanks === true;

  const onApply = useCallback(
    (option: string) => {
      if (element) {
        const cleanedSelectedValues = keys(selectedValues)
          .filter((value) => selectedValues[value])
          .reduce<Record<string, boolean>>(
            (prev, curr) => ({ ...prev, [curr]: true }),
            {},
          );

        let filterValues: string[];
        if (option === FILTER_OPTIONS[0]) {
          filterValues = keys(cleanedSelectedValues).filter(
            (value) => !FILTER_OPTION_VALUES.includes(value),
          );
        } else if (option === FILTER_OPTIONS[1]) {
          filterValues = ["_blank_"];
        } else {
          filterValues = ["_not_blank_"];
        }
        updateFilter([
          {
            field: element,
            key: "eq",
            values: filterValues,
          },
          {
            field: element,
            key: "like",
            values: [],
          },
        ]);
      }
      onClose();
    },
    [element, onClose, selectedValues, updateFilter],
  );

  const onClear = useCallback(() => {
    if (element) {
      updateFilter([
        {
          field: element,
          key: "eq",
          values: [],
        },
        {
          field: element,
          key: "like",
          values: [],
        },
      ]);
    }
    setTerm("");
    setFilterOption(FILTER_OPTIONS[0]);
    setSelectedValues({});
    onClose();
  }, [element, onClose, updateFilter]);

  const handleOptionClick = useCallback(
    (option: string) => {
      setFilterOption(option);
      if (option !== FILTER_OPTIONS[0]) {
        onApply(option);
      }
    },
    [onApply],
  );

  const onFacetChange = useCallback(
    (facet: Facet, checked: boolean) => {
      if (facet.isKeyword) {
        setSelectedValues(mapInitialLikeValuesToSelected(checked));
      } else {
        setSelectedValues((prev) => ({
          ...prev,
          ...mapInitialLikeValuesToSelected(false),
          [facet.value ?? ""]: checked,
        }));
      }
    },
    [mapInitialLikeValuesToSelected],
  );

  useEffect(() => {
    if (debouncedTerm.length === 0 || isLoading || facets.length === 0) {
      if (initialLikeValues.length) {
        setSelectedValues(mapInitialLikeValuesToSelected(true));
      } else {
        setSelectedValues(
          initialEqValues.reduce(
            (prev, curr) => ({
              ...prev,
              [curr]: true,
            }),
            {},
          ),
        );
      }
    } else {
      setSelectedValues({
        ...mapInitialLikeValuesToSelected(true),
        ...facets
          .filter((facet) => !facet.isKeyword && facet.value)
          .reduce<Record<string, boolean>>(
            (prev, curr) => ({
              ...prev,
              [curr.value!]: true,
            }),
            {},
          ),
      });
    }
  }, [
    facets,
    isLoading,
    debouncedTerm,
    mapInitialLikeValuesToSelected,
    initialLikeValues,
    initialEqValues,
  ]);

  useEffect(() => {
    if (criteria?.eq && element) {
      const appliedFilters = criteria.eq[0]?.[element] ?? [];
      if (appliedFilters.includes(FILTER_OPTION_VALUES[0])) {
        setFilterOption(FILTER_OPTIONS[1]);
      } else if (appliedFilters.includes(FILTER_OPTION_VALUES[1])) {
        setFilterOption(FILTER_OPTIONS[2]);
      }
    }
  }, [criteria, element]);

  return (
    <Fragment>
      {hasBlank && (
        <Grid
          container
          spacing={1}
          sx={(theme) => ({ background: theme.palette.grey["100"], p: 1 })}
        >
          {FILTER_OPTIONS.map((option) => (
            <Grid key={option} item>
              <Button
                color={filterOption === option ? "blue" : undefined}
                data-selected={filterOption === option}
                data-testid={`dt-text-filter-option-${kebabCase(option)}`}
                sx={{
                  width: "100%",
                }}
                variant="outlined"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </Button>
            </Grid>
          ))}
        </Grid>
      )}

      <Box
        sx={{
          px: 1,
          py: 1.5,
        }}
      >
        <TextField
          fullWidth
          InputProps={{
            startAdornment: (
              <SearchIcon
                sx={(theme) => ({ color: theme.palette.grey["300"] })}
              />
            ),
          }}
          data-testid="dt-text-filter-facet-input"
          defaultValue={filterOption === FILTER_OPTIONS[0] ? undefined : ""}
          disabled={filterOption !== FILTER_OPTIONS[0]}
          placeholder={`Search for ${label}`}
          sx={(theme) => ({
            "& .MuiInputBase-input": {
              padding: `0 ${theme.spacing(1)}`,
            },
            "& .MuiInputBase-root": {
              pl: 1,
            },
          })}
          value={term}
          onChange={(e) => setTerm(e.target.value)}
        />

        <Typography
          fontWeight="500"
          sx={(theme) => ({
            color:
              filterOption !== FILTER_OPTIONS[0]
                ? theme.palette.grey["400"]
                : undefined,
            marginTop: theme.spacing(2),
          })}
          variant="body2"
        >{`Filter ${label}:`}</Typography>

        <Box sx={{ pb: 1 }}>
          <CenterBox
            sx={{
              display: isLoading || !facets.length ? "flex" : "block",
              maxHeight: 240,
              minHeight: 120,
              overflow: "auto",
            }}
          >
            {isLoading ? (
              <CircularProgress
                size={24}
                sx={(theme) => ({ color: theme.palette.grey["400"] })}
                variant="indeterminate"
              />
            ) : !facets.length ? (
              <Typography
                fontWeight="500"
                sx={(theme) => ({ color: theme.palette.grey["400"] })}
                variant="body2"
              >
                Not Found
              </Typography>
            ) : (
              facets.map((facet) => (
                <Fragment key={facet.value}>
                  {facet.value ? (
                    <Box
                      data-disabled={filterOption !== FILTER_OPTIONS[0]}
                      sx={(theme) => ({
                        height: theme.spacing(3),
                      })}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              filterOption === FILTER_OPTIONS[0]
                                ? !!selectedValues[facet.value]
                                : filterOption === FILTER_OPTIONS[2]
                            }
                            data-testid={`dt-text-filter-facet-item-${kebabCase(
                              facet.value,
                            )}`}
                            disabled={filterOption !== FILTER_OPTIONS[0]}
                            size="small"
                            sx={{
                              overflow: "hidden",
                            }}
                            onChange={(e) =>
                              onFacetChange(facet, e.target.checked)
                            }
                          />
                        }
                        label={
                          <Tooltip
                            placement="bottom-start"
                            title={facet.value.toUpperCase()}
                          >
                            <Typography
                              textTransform="uppercase"
                              variant="body2"
                            >
                              {facet.value}
                            </Typography>
                          </Tooltip>
                        }
                        sx={(theme) => ({
                          "& .MuiFormControlLabel-label": {
                            whiteSpace: "nowrap",
                          },
                          "& .MuiFormControlLabel-label.Mui-disabled .MuiTypography-root":
                            {
                              color: theme.palette.grey["400"],
                            },
                        })}
                      />
                    </Box>
                  ) : null}
                </Fragment>
              ))
            )}
          </CenterBox>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between", px: 1 }}>
          <Button
            data-testid="dt-text-filter-clear-btn"
            variant="outlined"
            onClick={onClear}
          >
            Clear
          </Button>
          <Button
            color="primary"
            data-testid="dt-text-filter-apply-btn"
            variant="contained"
            onClick={() => onApply(filterOption)}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

export default memo(TextFilter) as typeof TextFilter;
