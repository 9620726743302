import { Menu, MenuItem, styled } from "@mui/material";
import download from "downloadjs";
import React from "react";
import FileCsvSvg from "../../../assets/svgs/file-csv.svg";
import FileExcelSvg from "../../../assets/svgs/file-excel.svg";
import FilePdfSvg from "../../../assets/svgs/file-pdf.svg";
import FileTxtSvg from "../../../assets/svgs/file-txt.svg";
import {
  EXPORT_LISTING_CONTENT_TYPES,
  EXPORT_LISTING_FILE_EXTENSIONS,
} from "../../../constants";
import { useCookiesLive } from "../../../hooks";
import { useExportBlanketPos } from "../../../hooks/api/mutations";
import { ExportListingType } from "../../../types";
import { DownloadIcon } from "../../icons";
import { LoadingButton } from "../../ui/LoadingButton/LoadingButton";

const DownloadImage = styled("img")(() => ({
  marginRight: "12px",
}));

const ExportBlanketPOButton = () => {
  const [accountId] = useCookiesLive(["accountId"]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { mutate: exportListing, isLoading } = useExportBlanketPos();

  if (!accountId) {
    return null;
  }

  const handleExportExcel = async (exportListingType: ExportListingType) => {
    exportListing(
      {
        accountId: +accountId,
        exportListingType,
      },
      {
        onSuccess: ({ data }) => {
          download(
            data,
            `BlanketPos.${EXPORT_LISTING_FILE_EXTENSIONS[exportListingType]}`,
            EXPORT_LISTING_CONTENT_TYPES[exportListingType],
          );
        },
      },
    );
    setAnchorEl(null);
  };

  return (
    <>
      <LoadingButton
        aria-controls={open ? "basic-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        aria-label="Download"
        data-testid="dt-controls-download"
        loading={isLoading}
        variant="outlined"
        onClick={handleClick}
      >
        <DownloadIcon sx={{ fontSize: 14 }} />
      </LoadingButton>
      <Menu
        MenuListProps={{
          "aria-labelledby": "download-button",
        }}
        anchorEl={anchorEl}
        id="download-menu"
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleExportExcel("excel")}>
          <DownloadImage alt="Download Excel" src={FileExcelSvg} />
          <span>Excel</span>
        </MenuItem>
        <MenuItem onClick={() => handleExportExcel("comma-separated")}>
          <DownloadImage alt="Comma-separated" src={FileCsvSvg} />
          <span>Comma-separated</span>
        </MenuItem>
        <MenuItem onClick={() => handleExportExcel("tab-separated")}>
          <DownloadImage alt="Tab-separated" src={FileTxtSvg} />
          <span>Tab-separated</span>
        </MenuItem>
        <MenuItem onClick={() => handleExportExcel("pdf")}>
          <DownloadImage alt="PDF" src={FilePdfSvg} />
          <span>PDF</span>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ExportBlanketPOButton;
