import { createSvgIcon } from "@mui/material";

export const PrinterIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 16 13" xmlns="http://www.w3.org/2000/svg">
    <title>printer</title>
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fillRule="nonzero"
        id="main-nav"
        transform="translate(-1276.000000, -265.000000)"
      >
        <g id="Group-16" transform="translate(29.000000, 254.000000)">
          <g id="printer" transform="translate(1226.000000, 0.000000)">
            <g transform="translate(21.000000, 11.000000)">
              <path
                d="M2.5,7 C2.77614237,7 3,6.77614237 3,6.5 C3,6.22385763 2.77614237,6 2.5,6 C2.22385763,6 2,6.22385763 2,6.5 C2,6.77614237 2.22385763,7 2.5,7 Z"
                fill="currentColor"
                id="Path"
              ></path>
              <path
                d="M5,0 C3.8954305,0 3,0.8954305 3,2 L3,4 L2,4 C0.8954305,4 0,4.8954305 0,6 L0,9 C0,10.1045695 0.8954305,11 2,11 L3,11 L3,12 C3,13.1045695 3.8954305,14 5,14 L11,14 C12.1045695,14 13,13.1045695 13,12 L13,11 L14,11 C15.1045695,11 16,10.1045695 16,9 L16,6 C16,4.8954305 15.1045695,4 14,4 L13,4 L13,2 C13,0.8954305 12.1045695,0 11,0 L5,0 Z M4,2 C4,1.44771525 4.44771525,1 5,1 L11,1 C11.5522847,1 12,1.44771525 12,2 L12,4 L4,4 L4,2 Z M5,7 C3.8954305,7 3,7.8954305 3,9 L3,10 L2,10 C1.44771525,10 1,9.55228475 1,9 L1,6 C1,5.44771525 1.44771525,5 2,5 L14,5 C14.5522847,5 15,5.44771525 15,6 L15,9 C15,9.55228475 14.5522847,10 14,10 L13,10 L13,9 C13,7.8954305 12.1045695,7 11,7 L5,7 Z M12,9 L12,12 C12,12.5522847 11.5522847,13 11,13 L5,13 C4.44771525,13 4,12.5522847 4,12 L4,9 C4,8.44771525 4.44771525,8 5,8 L11,8 C11.5522847,8 12,8.44771525 12,9 Z"
                fill="currentColor"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "Printer",
);
