import { useQuery } from "@tanstack/react-query";
import {
  RestCollection_StandardLineSolrDto_,
  StandardLinesResourceService,
} from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";
import { ListingMaxIdQueryHook } from "../../../types";
import { getMaxIdCriteria } from "../../../utils/criteria";

export const useStandardLineMaxId: ListingMaxIdQueryHook<
  RestCollection_StandardLineSolrDto_
> = (identifier: string) => {
  const maxIdCriteria = getMaxIdCriteria(identifier);

  return useQuery([QUERY_KEYS["standard-line-max-id"], identifier], () => {
    return StandardLinesResourceService.listUsingGet24(
      JSON.stringify(maxIdCriteria),
      1,
      false,
      false,
      false,
      1,
    );
  });
};
