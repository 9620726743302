/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlanketPoPatchRequestDto } from '../models/BlanketPoPatchRequestDto';
import type { BlanketPoRequestDto } from '../models/BlanketPoRequestDto';
import type { BlanketPoResponseDto } from '../models/BlanketPoResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BlanketPoResourceService {
  /**
   * Gets blanket po data for account
   * Gets blanket po data for account
   * @param accountId accountId
   * @returns BlanketPoResponseDto Success
   * @throws ApiError
   */
  public static getBlanketPoByAccountUsingGet(
    accountId?: number,
  ): CancelablePromise<Array<BlanketPoResponseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/blanketPo',
      query: {
        'accountId': accountId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Blanket Po(s) Not Found`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Create Blanket Po
   * Create Blanket Po
   * @param requestDto requestDto
   * @param accountId accountId
   * @returns BlanketPoResponseDto Created
   * @throws ApiError
   */
  public static createBlanketPoUsingPost(
    requestDto: BlanketPoRequestDto,
    accountId?: number,
  ): CancelablePromise<BlanketPoResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/blanketPo',
      query: {
        'accountId': accountId,
      },
      body: requestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error creating blanket po`,
      },
    });
  }
  /**
   * Export blanket pos by account
   * Export blanket pos for account
   * @param accountId accountId
   * @returns any Success
   * @throws ApiError
   */
  public static exportUsingGet(
    accountId: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/blanketPo/export',
      query: {
        'accountId': accountId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Report Not Found`,
        500: `There was an error exporting blanket pos`,
      },
    });
  }
  /**
   * Gets blanket po data
   * Gets blanket po data
   * @param blanketPoId blanketPoId
   * @returns BlanketPoResponseDto Success
   * @throws ApiError
   */
  public static getBlanketPoUsingGet(
    blanketPoId: number,
  ): CancelablePromise<BlanketPoResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/blanketPo/{blanketPoId}',
      path: {
        'blanketPoId': blanketPoId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Blanket Po Not Found`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Delete blanket po data
   * Delete blanket po data
   * @param blanketPoId blanketPoId
   * @returns any Success
   * @throws ApiError
   */
  public static deleteBlanketPoUsingDelete(
    blanketPoId: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/service/rest/blanketPo/{blanketPoId}',
      path: {
        'blanketPoId': blanketPoId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Blanket Po Not Found`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Patch blanket po data
   * Patch blanket po data
   * @param blanketPoId blanketPoId
   * @param requestDto requestDto
   * @returns BlanketPoResponseDto Success
   * @throws ApiError
   */
  public static updateBlanketPoUsingPatch(
    blanketPoId: number,
    requestDto: BlanketPoPatchRequestDto,
  ): CancelablePromise<BlanketPoResponseDto> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/blanketPo/{blanketPoId}',
      path: {
        'blanketPoId': blanketPoId,
      },
      body: requestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Blanket Po Not Found`,
        500: `Internal Server Error`,
      },
    });
  }
}
