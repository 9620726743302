import { ExternalLicenseDto } from "../api";
import { ImportWizardFileRecord } from "../types";
import { getExpirations, getNumberValue, getStringValue } from "./importWizard";

export const generateExternalResourceEntity = (
  columnMapping: Record<string, number | null>,
  record: ImportWizardFileRecord,
): ExternalLicenseDto => ({
  endUser: {
    companyName: getStringValue(record, columnMapping.companyName),
    email: getStringValue(record, columnMapping.email),
    name: getStringValue(record, columnMapping.name),
    phone: getStringValue(record, columnMapping.phone),
  },
  expirations: getExpirations(columnMapping, record),
  licenseKey: getStringValue(record, columnMapping.licenseKey),
  manufacturer: getStringValue(record, columnMapping.manufacturer),
  manufacturerPart: getStringValue(record, columnMapping.manufacturerPart),
  notes: getStringValue(record, columnMapping.notes),
  quantity: getNumberValue(record, columnMapping.quantity),
  reference: getStringValue(record, columnMapping.reference),
});
