import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Menu, MenuItem, TableCell } from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { memo, useMemo } from "react";
import { TABLE_ACTION_COLUMN_WIDTH } from "../../constants/dataTable";
import { Button } from "../ui";
import { useDataTableContext } from "./DataTableContext";

const DataTableActionCell = <TData extends Record<string, any>>({
  entity,
  onActionMenuOpened,
  onActionMenuClosed,
  isActiveRow,
}: {
  entity: TData;
  onActionMenuOpened: () => void;
  onActionMenuClosed: () => void;
  isActiveRow: boolean;
}) => {
  const { actionColumnItems } = useDataTableContext();

  const visibleActionColumnItems = useMemo(
    () =>
      actionColumnItems?.filter((actionColumnItem) =>
        actionColumnItem.isVisible(entity),
      ) || [],
    [actionColumnItems, entity],
  );

  return (
    <TableCell
      data-testid="dt-action-cell"
      sx={(theme) => ({
        alignItems: "center",
        border: "none",
        color: theme.palette.grey["900"],
        display: "flex",
        fontSize: theme.spacing(1.5),
        justifyContent: "center",
        overflow: "hidden",
        py: theme.spacing(1.5),
        width: TABLE_ACTION_COLUMN_WIDTH,
      })}
    >
      {visibleActionColumnItems.length > 0 && (
        <PopupState variant="popover">
          {(popupState) => (
            <>
              <Button
                variant="contained"
                {...bindTrigger(popupState)}
                data-testid="dt-action-cell-menu-button"
                sx={(theme) => ({ height: theme.spacing(4), padding: 0 })}
                onClick={(e) => {
                  e.stopPropagation();
                  bindTrigger(popupState).onClick(e);
                  onActionMenuOpened();
                }}
              >
                {isActiveRow ? <ExpandLess /> : <ExpandMore />}
              </Button>

              <Menu
                data-testid="dt-action-cell-menu"
                {...bindMenu(popupState)}
                onClose={() => {
                  popupState.close();
                  onActionMenuClosed();
                }}
              >
                {visibleActionColumnItems.map((actionColumnItem, index) => (
                  <MenuItem
                    key={index}
                    data-testid={`dt-action-cell-menu-item-${actionColumnItem.label}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      bindMenu(popupState).onClose();
                      onActionMenuClosed();
                      actionColumnItem.onClick?.(entity);
                    }}
                  >
                    {actionColumnItem.label}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </PopupState>
      )}
    </TableCell>
  );
};

export default memo(DataTableActionCell) as typeof DataTableActionCell;
