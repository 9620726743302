import { styled } from "@mui/material";
import { MaterialDesignContent } from "notistack";

export const SnackbarContent = styled(MaterialDesignContent)(({ theme }) => ({
  "&.notistack-MuiContent-default": {
    "& > div": {
      padding: 0,
      width: "100%",
    },
    padding: 0,
  },
  fontFamily: theme.typography.fontFamily,
}));
