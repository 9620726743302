import { useQuery } from "@tanstack/react-query";
import { AccountsResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useSuggestedAccounts = (terms: string) => {
  return useQuery(
    [QUERY_KEYS["suggested-accounts"], terms],
    () => AccountsResourceService.getSuggestsUsingGet(terms, 10, "active"),
    { enabled: !!terms && terms.length >= 2 },
  );
};
