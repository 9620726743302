/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FacetsResultsResponseDto } from '../models/FacetsResultsResponseDto';
import type { SearchCategoryDto } from '../models/SearchCategoryDto';
import type { SearchExceptionDto } from '../models/SearchExceptionDto';
import type { SearchExceptionRequestDto } from '../models/SearchExceptionRequestDto';
import type { SearchExceptionResponseDto } from '../models/SearchExceptionResponseDto';
import type { SearchResultsResponseDto } from '../models/SearchResultsResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SearchResourceService {
  /**
   * Search inventory
   * Search inventory
   * @param accountCode accountCode
   * @param criteria criteria
   * @param page page
   * @param size size
   * @param sort sort
   * @param term term
   * @returns SearchResultsResponseDto Success
   * @throws ApiError
   */
  public static getSearchResultsUsingGet(
    accountCode?: string,
    criteria: string = '{}',
    page: number = 1,
    size: number = 36,
    sort: 'account_standards' | 'manufacturer_asc' | 'manufacturer_desc' | 'parts_sold' | 'price_asc' | 'price_desc' = 'parts_sold',
    term?: string,
  ): CancelablePromise<SearchResultsResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/search',
      query: {
        'accountCode': accountCode,
        'criteria': criteria,
        'page': page,
        'size': size,
        'sort': sort,
        'term': term,
      },
      errors: {
        400: `Invalid request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `There was an error getting the search results`,
      },
    });
  }
  /**
   * Get search categories
   * Get search categories
   * @param sort sort
   * @returns SearchCategoryDto Success
   * @throws ApiError
   */
  public static getSearchCategoriesUsingGet(
    sort: 'displayOrder' | 'name' = 'name',
  ): CancelablePromise<Array<SearchCategoryDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/search/categories',
      query: {
        'sort': sort,
      },
      errors: {
        400: `Invalid request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `There was an error getting the categories`,
      },
    });
  }
  /**
   * Get search exceptions for account
   * Get search exceptions for account
   * @param accountId accountId
   * @returns SearchExceptionDto Success
   * @throws ApiError
   */
  public static getSearchExceptionsByAccountUsingGet(
    accountId: number,
  ): CancelablePromise<SearchExceptionDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/search/exceptions',
      query: {
        'accountId': accountId,
      },
      errors: {
        400: `Invalid request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `There was an error getting the exceptions`,
      },
    });
  }
  /**
   * Create a search exception
   * create a search exceptions
   * @param accountId accountId
   * @param request request
   * @returns SearchExceptionResponseDto Success
   * @throws ApiError
   */
  public static createSearchExceptionsUsingPost(
    accountId: number,
    request: SearchExceptionRequestDto,
  ): CancelablePromise<SearchExceptionResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/search/exceptions',
      query: {
        'accountId': accountId,
      },
      body: request,
      errors: {
        400: `Invalid request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        409: `Duplicate record`,
        500: `There was an error getting the exceptions`,
      },
    });
  }
  /**
   * Get search exceptions by id
   * Get search exceptions by id
   * @param searchExceptionId searchExceptionId
   * @returns SearchExceptionResponseDto Success
   * @throws ApiError
   */
  public static getSearchExceptionsByIdUsingGet(
    searchExceptionId: number,
  ): CancelablePromise<SearchExceptionResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/search/exceptions/{searchExceptionId}',
      path: {
        'searchExceptionId': searchExceptionId,
      },
      errors: {
        400: `Invalid request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `There was an error getting the exceptions`,
      },
    });
  }
  /**
   * Delete search exceptions by id
   * Delete search exceptions by id
   * @param searchExceptionId searchExceptionId
   * @returns any Success
   * @throws ApiError
   */
  public static deleteSearchExceptionsByIdUsingDelete(
    searchExceptionId: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/service/rest/search/exceptions/{searchExceptionId}',
      path: {
        'searchExceptionId': searchExceptionId,
      },
      errors: {
        400: `Invalid request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `There was an error deleting the exceptions`,
      },
    });
  }
  /**
   * Search results facets
   * Search results facets
   * @param accountCode accountCode
   * @param criteria criteria
   * @param facetSort facetSort
   * @param page page
   * @param size size
   * @param sort sort
   * @param term term
   * @returns FacetsResultsResponseDto Success
   * @throws ApiError
   */
  public static getSearchResultsFacetsUsingGet(
    accountCode?: string,
    criteria: string = '{}',
    facetSort: 'Alphabetical' | 'Count' = 'Count',
    page: number = 1,
    size: number = 36,
    sort: 'account_standards' | 'manufacturer_asc' | 'manufacturer_desc' | 'parts_sold' | 'price_asc' | 'price_desc' = 'parts_sold',
    term?: string,
  ): CancelablePromise<FacetsResultsResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/search/facets',
      query: {
        'accountCode': accountCode,
        'criteria': criteria,
        'facetSort': facetSort,
        'page': page,
        'size': size,
        'sort': sort,
        'term': term,
      },
      errors: {
        400: `Invalid request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `There was an error getting the facets results`,
      },
    });
  }
}
