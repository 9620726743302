import { useQuery } from "@tanstack/react-query";
import { TransactionStatisticsResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useSalesByYearAndMonth = (
  startDate: string,
  endDate: string,
  accountCode?: string,
) => {
  return useQuery(
    [QUERY_KEYS["sales-by-category"], startDate, endDate, accountCode],
    () =>
      TransactionStatisticsResourceService.getSalesByYearAndMonthUsingGet(
        endDate,
        startDate,
        accountCode,
      ),
  );
};
