import { createSvgIcon } from "@mui/material";

export const InfoCircleIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <title>info-circle</title>
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="order-details"
        transform="translate(-605, -214)"
      >
        <g id="header" transform="translate(0, 28)">
          <g id="info-circle" transform="translate(605, 186)">
            <path
              d="M8,15 C4.13400675,15 1,11.8659932 1,8 C1,4.13400675 4.13400675,1 8,1 C11.8659932,1 15,4.13400675 15,8 C15,11.8659932 11.8659932,15 8,15 Z M8,16 C12.418278,16 16,12.418278 16,8 C16,3.581722 12.418278,0 8,0 C3.581722,0 0,3.581722 0,8 C0,12.418278 3.581722,16 8,16 Z"
              id="Shape"
            ></path>
            <path
              d="M8.93,6.588 L6.64,6.875 L6.558,7.255 L7.008,7.338 C7.302,7.408 7.36,7.514 7.296,7.807 L6.558,11.275 C6.364,12.172 6.663,12.594 7.366,12.594 C7.911,12.594 8.544,12.342 8.831,11.996 L8.919,11.58 C8.719,11.756 8.427,11.826 8.233,11.826 C7.958,11.826 7.858,11.633 7.929,11.293 L8.93,6.588 Z M9,4.5 C9,5.05228475 8.55228475,5.5 8,5.5 C7.44771525,5.5 7,5.05228475 7,4.5 C7,3.94771525 7.44771525,3.5 8,3.5 C8.55228475,3.5 9,3.94771525 9,4.5 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "InfoCircle",
);
