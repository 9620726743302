/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenericOrderResponseDto } from '../models/GenericOrderResponseDto';
import type { InputStreamResource } from '../models/InputStreamResource';
import type { LogDto } from '../models/LogDto';
import type { OrderDetailEmailRequestDto } from '../models/OrderDetailEmailRequestDto';
import type { OrderDto } from '../models/OrderDto';
import type { OrderNotes } from '../models/OrderNotes';
import type { OrderPatchRequestDto } from '../models/OrderPatchRequestDto';
import type { OrderPostRequestDto } from '../models/OrderPostRequestDto';
import type { OrderResponseDto } from '../models/OrderResponseDto';
import type { RestCollection_OrderListing_ } from '../models/RestCollection_OrderListing_';
import type { ShipmentNotificationEmailAddressesResponseDto } from '../models/ShipmentNotificationEmailAddressesResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OrdersResourceService {
  /**
   * Get Order list
   * Get an order list
   * @param criteria criteria
   * @param page page
   * @param returnAllLabels returnAllLabels
   * @param returnPagination returnPagination
   * @param returnSavedViews returnSavedViews
   * @param size size
   * @param useDefaultView useDefaultView
   * @returns RestCollection_OrderListing_ Success
   * @throws ApiError
   */
  public static listUsingGet12(
    criteria: string = '{}',
    page: number = 1,
    returnAllLabels: boolean = false,
    returnPagination: boolean = true,
    returnSavedViews: boolean = true,
    size: number = 50,
    useDefaultView: boolean = false,
  ): CancelablePromise<RestCollection_OrderListing_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/listing/orders',
      query: {
        'criteria': criteria,
        'page': page,
        'returnAllLabels': returnAllLabels,
        'returnPagination': returnPagination,
        'returnSavedViews': returnSavedViews,
        'size': size,
        'useDefaultView': useDefaultView,
      },
      errors: {
        401: `Unauthorized`,
        403: `You are not authorized to access this account.`,
        404: `Order list not found.`,
        500: `Internal Error retrieving order list`,
      },
    });
  }
  /**
   * Process Order from Cart
   * Process order
   * @param orderRequestDto orderRequestDto
   * @param accountId accountId
   * @param approverGroupId approverGroupId
   * @returns OrderResponseDto Success
   * @throws ApiError
   */
  public static processOrderV1UsingPost(
    orderRequestDto: OrderPostRequestDto,
    accountId?: number,
    approverGroupId?: number,
  ): CancelablePromise<OrderResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/orders',
      query: {
        'accountId': accountId,
        'approverGroupId': approverGroupId,
      },
      body: orderRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        409: `Conflict`,
        500: `There was an error while creating the order`,
        503: `Service unavailable`,
      },
    });
  }
  /**
   * Send Order Details Email
   * Send Order Details Email
   * @param orderDetailEmailRequestDto orderDetailEmailRequestDto
   * @returns any Success
   * @throws ApiError
   */
  public static sendOrderDetailsEmailUsingPost(
    orderDetailEmailRequestDto: OrderDetailEmailRequestDto,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/orders/email',
      body: orderDetailEmailRequestDto,
      errors: {
        400: `Order Detail Email Bad Request`,
        401: `Unauthorized`,
        403: `You are not authorized to access this Order.`,
        404: `Not Found`,
        500: `Internal Error Order Detail Email`,
      },
    });
  }
  /**
   * Validate order inputs
   * Validate order inputs
   * @param orderRequestDto orderRequestDto
   * @param accountId accountId
   * @returns any Success
   * @throws ApiError
   */
  public static validateOrderUsingPost(
    orderRequestDto: OrderPostRequestDto,
    accountId?: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/orders/validation',
      query: {
        'accountId': accountId,
      },
      body: orderRequestDto,
      errors: {
        400: `Order Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal Error Validating Order`,
      },
    });
  }
  /**
   * Delete Order
   * Delete Order
   * @param orderNumber orderNumber
   * @returns any Success
   * @throws ApiError
   */
  public static deleteUsingDelete6(
    orderNumber: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/service/rest/orders/{orderNumber}',
      path: {
        'orderNumber': orderNumber,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Order Not Found`,
        500: `Internal Error Deleting Order`,
      },
    });
  }
  /**
   * Update Order
   * Update order
   * @param orderNumber orderNumber
   * @param orderRequestDto orderRequestDto
   * @returns GenericOrderResponseDto Success
   * @throws ApiError
   */
  public static updateOrderUsingPatch(
    orderNumber: number,
    orderRequestDto: OrderPatchRequestDto,
  ): CancelablePromise<GenericOrderResponseDto> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/orders/{orderNumber}',
      path: {
        'orderNumber': orderNumber,
      },
      body: orderRequestDto,
      errors: {
        400: `Bad request.`,
        401: `Unauthorized`,
        403: `You are not authorized to access this account.`,
        404: `Order with orderNum {orderNum} not found.`,
        409: `Conflict`,
        500: `Internal Error retrieving order`,
        503: `Service unavailable`,
      },
    });
  }
  /**
   * Find Email Addresses By Order Number
   * Find Email Addresses By Order Number
   * @param orderNumber orderNumber
   * @returns ShipmentNotificationEmailAddressesResponseDto Success
   * @throws ApiError
   */
  public static getShippingNotificationEmailAddressesByOrderNumberUsingGet(
    orderNumber: number,
  ): CancelablePromise<Array<ShipmentNotificationEmailAddressesResponseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/orders/{orderNumber}/shippingNotificationEmails',
      path: {
        'orderNumber': orderNumber,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal Error Finding Email Addresses By Order Number`,
      },
    });
  }
  /**
   * Get an order by order number
   * Get an order by order number
   * @param orderNum orderNum
   * @param restrictedAccessToken restrictedAccessToken
   * @returns OrderDto Success
   * @throws ApiError
   */
  public static getOrderUsingGet(
    orderNum: number,
    restrictedAccessToken?: string,
  ): CancelablePromise<OrderDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/orders/{orderNum}',
      path: {
        'orderNum': orderNum,
      },
      query: {
        'restrictedAccessToken': restrictedAccessToken,
      },
      errors: {
        401: `Unauthorized`,
        403: `You are not authorized to access this account.`,
        404: `Order with orderNum {orderNum} not found.`,
        500: `Internal Error retrieving order`,
      },
    });
  }
  /**
   * Export Order.
   * Export Order.
   * @param orderNum orderNum
   * @returns InputStreamResource Success
   * @throws ApiError
   */
  public static exportUsingGet3(
    orderNum: number,
  ): CancelablePromise<InputStreamResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/orders/{orderNum}/export',
      path: {
        'orderNum': orderNum,
      },
      errors: {
        400: `Bad request.`,
        401: `Unauthorized`,
        403: `You are not authorized to access this Order.`,
        404: `Order with orderNum {orderNum} not found.`,
        500: `Internal Error exporting order`,
      },
    });
  }
  /**
   * Get Order Logs By Id
   * Get Order Logs By Id
   * @param orderNum orderNum
   * @returns LogDto Success
   * @throws ApiError
   */
  public static findOrderLogByOrderIdUsingGet(
    orderNum: number,
  ): CancelablePromise<Array<LogDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/orders/{orderNum}/log',
      path: {
        'orderNum': orderNum,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Order Not Found`,
        500: `Internal Error Finding Order Log`,
      },
    });
  }
  /**
   * Get Order Notes
   * Get an order notes
   * @param orderNum orderNum
   * @returns OrderNotes Success
   * @throws ApiError
   */
  public static viewNotesUsingGet1(
    orderNum: number,
  ): CancelablePromise<OrderNotes> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/orders/{orderNum}/notes',
      path: {
        'orderNum': orderNum,
      },
      errors: {
        401: `Unauthorized`,
        403: `You are not authorized to access this account.`,
        404: `Order Notes not found.`,
        500: `Internal Error retrieving order notes`,
      },
    });
  }
}
