import { MenuItem, Select } from "@mui/material";
import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  setMonth,
  setQuarter,
  setYear,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subYears,
} from "date-fns";
import { forEach, groupBy, isNull } from "lodash";
import { useEffect, useMemo, useRef } from "react";
import { Chart, ReactGoogleChartEvent } from "react-google-charts";
import { CHART_COLORS } from "../../constants/chart";
import { GOOGLE_CHART_VERSION } from "../../constants/chat";
import { usePurchaseAnalysisContext } from "../../contexts/PurchaseAnalysisContext";
import { useCookiesLive } from "../../hooks";
import { useSalesByYearAndMonth } from "../../hooks/api/queries";
import { PurchaseAnalysisChartOption2 } from "../../types";
import { formatCurrency } from "../../utils/currency";
import { getMonthName } from "../../utils/datetime";
import { getAppLink } from "../../utils/link";
import ChartWidget from "./ChartWidget";

const getFormattedDateString = (date: Date) => format(date, "yyyy-MM-dd");

const startYearDate = startOfYear(subYears(new Date(), 4));
const startYear = startYearDate.getFullYear();
const startDate = getFormattedDateString(startYearDate);
const endDate = getFormattedDateString(endOfYear(new Date()));

const SalesByYearAndMonth = () => {
  const [accountCode] = useCookiesLive(["accountCode"]);
  const { config, updateConfig } = usePurchaseAnalysisContext();
  const { data, isFetching } = useSalesByYearAndMonth(
    startDate,
    endDate,
    accountCode,
  );
  const chartConfig =
    config.yearOverYearSpend as PurchaseAnalysisChartOption2 | null;
  const chartConfigRef = useRef<PurchaseAnalysisChartOption2 | null>(null);

  useEffect(() => {
    if (!chartConfig) {
      updateConfig("yearOverYearSpend", "monthly");
    }
    chartConfigRef.current = chartConfig;
  }, [chartConfig, updateConfig]);

  const chartData = useMemo(() => {
    const dataMap = groupBy(data, "salesYear");

    if (!chartConfig || chartConfig === "monthly") {
      forEach(dataMap, (_, key) => {
        (dataMap as any)[key] = groupBy(
          dataMap[key],
          (row: any) => row.salesMonth,
        );
      });

      return [
        [
          "Month",
          ...Array(5)
            .fill(0)
            .map((_, offset) => (startYear + offset).toString()),
        ],
        ...(data
          ? Array(12)
              .fill(0)
              .map((_, index) => [
                { f: getMonthName(index + 1), v: getMonthName(index + 1) },
                ...Array(5)
                  .fill(0)
                  .map((_, offset) => {
                    const value =
                      (dataMap[startYear + offset]?.[index + 1] as any)?.[0]
                        ?.sales ?? 0;
                    return {
                      f: formatCurrency(value, 0),
                      v: value,
                    };
                  }),
              ])
          : []),
      ];
    } else {
      return [
        [
          "Quarter",
          ...Array(5)
            .fill(0)
            .map((_, offset) => (startYear + offset).toString()),
        ],
        ...(data
          ? Array(4)
              .fill(0)
              .map((_, index) => [
                { f: `Q${index + 1}`, v: `Q${index + 1}` },
                ...Array(5)
                  .fill(0)
                  .map((_, offset) => {
                    const yearData = dataMap[startYear + offset];
                    const quarterSum = yearData.reduce<number>(
                      (prev, curr: any) => {
                        if (
                          curr.salesMonth > 3 * index &&
                          curr.salesMonth <= 3 * (index + 1)
                        ) {
                          return prev + curr.sales;
                        }
                        return prev;
                      },
                      0,
                    );

                    return {
                      f: formatCurrency(quarterSum, 0),
                      v: quarterSum,
                    };
                  }),
              ])
          : []),
      ];
    }
  }, [chartConfig, data]);

  const chartEvents: ReactGoogleChartEvent[] = [
    {
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper?.getChart();
        const selection = chart?.getSelection();

        if (selection && selection.length === 1) {
          const [selectedItem] = selection;
          const { row, column } = selectedItem;
          if (!isNull(row) && !isNull(column)) {
            const year = startYear + column - 1;
            const yearFirstDay = startOfYear(setYear(new Date(), year));
            const startDate = getFormattedDateString(
              chartConfigRef.current === "monthly"
                ? startOfMonth(setMonth(yearFirstDay, row))
                : startOfQuarter(setQuarter(yearFirstDay, row + 1)),
            );
            const endDate = getFormattedDateString(
              chartConfigRef.current === "monthly"
                ? endOfMonth(setMonth(yearFirstDay, row))
                : endOfQuarter(setQuarter(yearFirstDay, row + 1)),
            );

            const searchParams = new URLSearchParams();
            searchParams.set(
              "params",
              JSON.stringify({
                d: 1,
                disp: [
                  "reserved_select_row",
                  "account",
                  "invoiceNum",
                  "custPo",
                  "invoiceDate",
                  "orderNum",
                  "reference",
                  "subtotal",
                  "shipAndHand",
                  "salesTax",
                  "fees",
                  "total",
                  "balance",
                  "reserved_actions",
                ],
                fs: { invoiceDate: { end: endDate, start: startDate } },
                fs_clear: false,
                o: "invoiceNum",
                p: "1",
                r: "50",
              }),
            );
            window.location.href = getAppLink(
              `app/Invoice?${searchParams.toString()}`,
            );
          }
        }
      },
      eventName: "select",
    },
  ];

  return (
    <ChartWidget
      actions={
        <Select
          fullWidth
          data-testid="chart-period-select"
          size="small"
          sx={(theme) => ({ width: theme.spacing(18) })}
          tabIndex={-1}
          value={chartConfig ?? "monthly"}
          onChange={(e) =>
            updateConfig(
              "yearOverYearSpend",
              e.target.value as PurchaseAnalysisChartOption2,
            )
          }
        >
          <MenuItem value="monthly">By Month</MenuItem>
          <MenuItem value="quarterly">By Quarter</MenuItem>
        </Select>
      }
      hasNoData={!isFetching && !data?.length}
      id="yearOverYearSpend"
      isLoading={isFetching}
      title="Year Over Year Spend"
    >
      <Chart
        chartEvents={chartEvents}
        chartType="ColumnChart"
        chartVersion={GOOGLE_CHART_VERSION}
        data={chartData}
        graphID="yearOverYearSpend"
        height="100%"
        options={{
          colors: CHART_COLORS,
          vAxis: {
            format: "$###,###,###",
          },
        }}
        width="100%"
      />
    </ChartWidget>
  );
};
export default SalesByYearAndMonth;
