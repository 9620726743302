import { useEffect, useState } from "react";
import { ManufacturerProductSolrDto } from "../../api";
import EditEolDate from "../modals/EditEolDate";

const EditEolDateWrapper = () => {
  const [selectedProduct, setSelectedProduct] =
    useState<ManufacturerProductSolrDto>();

  useEffect(() => {
    document.addEventListener("selectProductForEolDateEvent", (event: any) => {
      const entity = event.detail as ManufacturerProductSolrDto;
      setSelectedProduct(entity);
    });
  }, []);

  return (
    <>
      {selectedProduct && (
        <EditEolDate
          isOpen
          product={selectedProduct}
          onClose={() => setSelectedProduct(undefined)}
        />
      )}
    </>
  );
};

export default EditEolDateWrapper;
