/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListingFacetDto } from '../models/ListingFacetDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ListingsFacetResourceService {
  /**
   * Get Listing Facets
   * Get Listing Facets
   * @param column column
   * @param listingName listingName
   * @param criteria criteria
   * @param facetSort facetSort
   * @param page page
   * @param size size
   * @param term term
   * @returns ListingFacetDto Success
   * @throws ApiError
   */
  public static listingsFacetUsingGet(
    column: string,
    listingName: 'account' | 'accounts' | 'ami_list' | 'amis' | 'asset_list' | 'assets' | 'crm_client' | 'data_map' | 'datamap' | 'datamaps' | 'deal_list' | 'deals' | 'electronic_pos' | 'electronicpo' | 'email_log' | 'expiration_list' | 'expirations' | 'global_invoice_line' | 'inventory' | 'inventory_list' | 'invoice_line_list' | 'invoice_list' | 'invoicelines' | 'invoices' | 'lcds' | 'lowest_cost_list' | 'order_line_list' | 'order_lines' | 'order_list' | 'orderlines' | 'orders' | 'par_list' | 'pars' | 'product' | 'products' | 'quote_line_list' | 'quote_list' | 'quotelines' | 'quotes' | 'reg_deal_list' | 'registereddeals' | 'returns' | 'search_banners' | 'search_list' | 'searches' | 'service_order_list' | 'serviceorders' | 'ship_address_list' | 'shipment_list' | 'shipments' | 'shippingaddresses' | 'staff' | 'staff_listing' | 'standard_line_list' | 'standard_list' | 'standardlines' | 'standards' | 'stocking_order' | 'stocking_order_line' | 'stocking_order_lines' | 'stocking_order_list' | 'stocking_orders' | 'stockingorder' | 'stockingorderline' | 'stockingorderlines' | 'stockingorders' | 'user_list' | 'users' | 'views',
    criteria: string = '{}',
    facetSort: 'Alphabetical' | 'Count' = 'Count',
    page: number = 1,
    size: number = 50,
    term?: string,
  ): CancelablePromise<ListingFacetDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/listing/{listingName}/facets',
      path: {
        'listingName': listingName,
      },
      query: {
        'column': column,
        'criteria': criteria,
        'facetSort': facetSort,
        'page': page,
        'size': size,
        'term': term,
      },
      errors: {
        401: `Unauthorized`,
        403: `You are not authorized to access this resource.`,
        404: `Facets not found.`,
        500: `Internal Error retrieving facets`,
      },
    });
  }
}
