/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NoteRequestDto } from '../models/NoteRequestDto';
import type { RestCollection_ReturnedItemsSolrDto_ } from '../models/RestCollection_ReturnedItemsSolrDto_';
import type { RmaRequestDto } from '../models/RmaRequestDto';
import type { RmaResponseDto } from '../models/RmaResponseDto';
import type { RmaUpdateRequestDto } from '../models/RmaUpdateRequestDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ReturnedItemsResourceService {
  /**
   * Get returns listing report
   * Get Returns listing report
   * @param criteria criteria
   * @param page page
   * @param returnAllLabels returnAllLabels
   * @param returnPagination returnPagination
   * @param returnSavedViews returnSavedViews
   * @param size size
   * @param useDefaultView useDefaultView
   * @returns RestCollection_ReturnedItemsSolrDto_ Ok
   * @throws ApiError
   */
  public static listingUsingGet1(
    criteria: string = '{}',
    page: number = 1,
    returnAllLabels: boolean = false,
    returnPagination: boolean = true,
    returnSavedViews: boolean = true,
    size: number = 50,
    useDefaultView: boolean = false,
  ): CancelablePromise<RestCollection_ReturnedItemsSolrDto_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/listing/returns',
      query: {
        'criteria': criteria,
        'page': page,
        'returnAllLabels': returnAllLabels,
        'returnPagination': returnPagination,
        'returnSavedViews': returnSavedViews,
        'size': size,
        'useDefaultView': useDefaultView,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Return(s) Not Found`,
        500: `Internal Error fetching Returns`,
      },
    });
  }
  /**
   * Creates new return
   * Creates new return
   * @param rmaRequestDto rmaRequestDto
   * @returns RmaResponseDto Success
   * @throws ApiError
   */
  public static createReturnUsingPost(
    rmaRequestDto: RmaRequestDto,
  ): CancelablePromise<RmaResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/returns',
      body: rmaRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Return Not Found`,
        500: `Internal Server Error`,
        503: `Service unavailable`,
      },
    });
  }
  /**
   * Gets returned item data
   * Gets returned item data
   * @param returnId returnId
   * @returns RmaResponseDto Success
   * @throws ApiError
   */
  public static getReturnUsingGet(
    returnId: number,
  ): CancelablePromise<RmaResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/returns/{returnId}',
      path: {
        'returnId': returnId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Return Not Found`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Update a return
   * Update a return
   * @param returnId returnId
   * @param updateRequestDto updateRequestDto
   * @returns RmaResponseDto Success
   * @throws ApiError
   */
  public static updateReturnUsingPatch(
    returnId: number,
    updateRequestDto: RmaUpdateRequestDto,
  ): CancelablePromise<RmaResponseDto> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/returns/{returnId}',
      path: {
        'returnId': returnId,
      },
      body: updateRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Return Not Found`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Adds new note to the return
   * Adds new note to the return
   * @param noteRequestDto noteRequestDto
   * @param returnId returnId
   * @returns RmaResponseDto Success
   * @throws ApiError
   */
  public static createNoteUsingPost(
    noteRequestDto: NoteRequestDto,
    returnId: number,
  ): CancelablePromise<RmaResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/returns/{returnId}/notes',
      path: {
        'returnId': returnId,
      },
      body: noteRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Return Not Found`,
        500: `Internal Server Error`,
      },
    });
  }
}
