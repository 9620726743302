import { Box, Popover } from "@mui/material";
import { Header } from "@tanstack/react-table";
import PopupState, {
  anchorRef,
  bindPopover,
  bindTrigger,
} from "material-ui-popup-state";
import { memo } from "react";
import { ColumnType } from "../../../types";
import BooleanFilter from "./BooleanFilter";
import DataTableFilterInput from "./DataTableFilterInput";
import DateFilter from "./DateFilter";
import NumberFilter from "./NumberFilter";
import TextFilter from "./TextFilter";
import TimeFilter from "./TimeFilter";

const DataTableFilter = <TData, TValue>({
  header,
}: {
  header: Header<TData, TValue>;
}) => {
  const { meta, id: element } = header.column.columnDef;
  const type = meta as ColumnType;

  const isNumberFilter = [
    "integer",
    "currency",
    "decimal",
    "percentage",
  ].includes(type);

  return (
    <PopupState variant="popover">
      {(popupState) => (
        <>
          <Box
            {...bindTrigger(popupState)}
            ref={anchorRef(popupState)}
            onClick={() => {}}
          >
            <DataTableFilterInput
              element={element ?? ""}
              type={type}
              onFacetClick={popupState.open}
            />
          </Box>

          <Popover
            {...bindPopover(popupState)}
            keepMounted
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          >
            <Box
              data-testid={`dt-text-filter-${element}`}
              sx={{
                width:
                  type === "date"
                    ? "50rem"
                    : type === "time"
                      ? "44rem"
                      : isNumberFilter
                        ? "24rem"
                        : "20rem",
              }}
            >
              {(type === "text" || type === "multiText") && (
                <TextFilter header={header} onClose={popupState.close} />
              )}
              {type === "boolean" && (
                <BooleanFilter header={header} onClose={popupState.close} />
              )}
              {type === "date" && (
                <DateFilter header={header} onClose={popupState.close} />
              )}
              {isNumberFilter && (
                <NumberFilter header={header} onClose={popupState.close} />
              )}
              {type === "time" && (
                <TimeFilter header={header} onClose={popupState.close} />
              )}
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default memo(DataTableFilter) as typeof DataTableFilter;
