import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { uniqWith } from "lodash";
import { useState } from "react";
import { ExternalLicenseDto } from "../../api";
import { ImportWizardDto } from "../../types";
import { useImportWizardContext } from "../ImportWizard/ImportWizardContext";
import { Button } from "../ui";
import { MapCatalogRow } from "./MapCatalogRow";

export const MapCatalog = ({
  onImport,
  onNext,
}: {
  onImport: (records: ImportWizardDto[]) => void;
  onNext: () => void;
}) => {
  const { records, onClose } = useImportWizardContext();
  const [mfgPairs, setMfgPairs] = useState<ExternalLicenseDto[]>(
    uniqWith(
      (records as ExternalLicenseDto[]).map(
        ({ manufacturer, manufacturerPart }) => ({
          manufacturer,
          manufacturerPart,
        }),
      ),
      (a, b) =>
        a.manufacturer === b.manufacturer &&
        a.manufacturerPart === b.manufacturerPart,
    ),
  );

  const handleChange = (updatedPair: ExternalLicenseDto) => {
    setMfgPairs((prevPairs) =>
      prevPairs.map((prevPair) => ({
        ...prevPair,
        manufacturerProductId:
          prevPair.manufacturer === updatedPair.manufacturer &&
          prevPair.manufacturerPart === updatedPair.manufacturerPart
            ? updatedPair.manufacturerProductId
            : prevPair.manufacturerProductId,
      })),
    );
  };
  const handleClickImport = () => {
    onNext();
    onImport(
      (records as ExternalLicenseDto[]).map((externalLicense) => {
        const manufacturerProductId = mfgPairs.find(
          (mfgPair) =>
            mfgPair.manufacturerPart === externalLicense.manufacturerPart &&
            mfgPair.manufacturer === externalLicense.manufacturer,
        )?.manufacturerProductId;

        return {
          ...externalLicense,
          ...(manufacturerProductId
            ? {
                manufacturer: undefined,
                manufacturerPart: undefined,
                manufacturerProductId,
              }
            : {}),
        };
      }),
    );
  };

  return (
    <Dialog fullWidth open maxWidth="md" onClose={onClose}>
      <DialogTitle sx={{ px: 2, py: 4 }}>
        Import Licenses - Map Parts to Connection Catalog
      </DialogTitle>
      <IconButton
        sx={{
          color: (theme) => theme.palette.grey[900],
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ px: 2, py: 0 }}>
        <TableContainer>
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow
                sx={(theme) => ({
                  background: theme.palette.grey["50"],
                  fontSize: theme.spacing(1.75),
                })}
              >
                <TableCell component="th" sx={{ textTransform: "uppercase" }}>
                  your manufacturer
                </TableCell>
                <TableCell component="th" sx={{ textTransform: "uppercase" }}>
                  your part
                </TableCell>
                <TableCell component="th" sx={{ textTransform: "uppercase" }}>
                  our product
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mfgPairs.map(({ manufacturer, manufacturerPart }) => (
                <MapCatalogRow
                  key={`${manufacturer}-${manufacturerPart}`}
                  manufacturer={manufacturer}
                  manufacturerPart={manufacturerPart}
                  onChange={(manufacturerProductId) =>
                    handleChange({
                      manufacturer,
                      manufacturerPart,
                      manufacturerProductId,
                    })
                  }
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between", pb: 3, px: 2 }}>
        <Button
          sx={(theme) => ({ width: theme.spacing(12.5) })}
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          sx={(theme) => ({ width: theme.spacing(12.5) })}
          variant="contained"
          onClick={handleClickImport}
        >
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
};
