import { IconButton as MuiIconButton, styled } from "@mui/material";

export const IconButton = styled(MuiIconButton)(({
  theme,
  color = "primary",
  size = "small",
}) => {
  return {
    "&:hover svg": {
      color: theme.palette.blue["700"],
    },
    color: theme.palette.grey["900"],
  };
});
