import {
  Box,
  CssBaseline,
  ScopedCssBaseline,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { useLayoutEffect } from "react";
import { Outlet } from "react-router-dom";
import useResizeObserver from "use-resize-observer";
import globalStyles from "../../globalStyles";
import { useGlobalState } from "../../hooks/store/base";
import usePageTitle from "../../hooks/store/usePageTitle";
import theme from "../../theme";
import Header from "../Header/Header";
import { SearchResultsContextProvider } from "../SearchResult";
import { SnackbarContent } from "../ui";

const LayoutContainer = styled(Stack)({
  minHeight: "100vh",
});

const Layout = () => {
  const [title] = usePageTitle();
  const { ref, width = 0 } = useResizeObserver<HTMLDivElement>();
  const [, setContentWidth] = useGlobalState("contentWidth");

  useLayoutEffect(() => {
    setContentWidth(width);
  }, [setContentWidth, width]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        Components={{
          default: SnackbarContent,
          error: SnackbarContent,
          info: SnackbarContent,
          success: SnackbarContent,
          warning: SnackbarContent,
        }}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={5000}
      >
        {globalStyles}
        <SearchResultsContextProvider>
          <ScopedCssBaseline>
            <LayoutContainer data-testid="page-root">
              <Header />

              <Box sx={{ mt: 4 }} />
              {title && (
                <Typography
                  data-testid="page-title"
                  sx={{ fontWeight: "normal", px: 5 }}
                  variant="h6"
                >
                  {title}
                </Typography>
              )}

              <Box
                ref={ref}
                data-testid="page-content"
                sx={{
                  flex: "1 1 0",
                  mt: 2,
                  mx: 4,
                  position: "relative",
                }}
              >
                <Outlet />
              </Box>

              <Box mt={8} />
            </LayoutContainer>
          </ScopedCssBaseline>
          <CssBaseline />
        </SearchResultsContextProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default Layout;
