import { useEffect, useState } from "react";
import { DealSolrDto } from "../../api";
import DealsEditSelected from "../modals/DealsEditSelected";

const DealsEditSelectedWrapper = () => {
  const [selectedDeals, setSelectedDeals] = useState<DealSolrDto[]>();

  useEffect(() => {
    const handleShowEditSelectedDealsModalEvent = (event: Event) => {
      const rows = (event as CustomEvent).detail;
      setSelectedDeals(rows);
    };

    document.addEventListener(
      "showEditSelectedDealsModal",
      handleShowEditSelectedDealsModalEvent,
    );

    return () =>
      document.removeEventListener(
        "showEditSelectedDealsModal",
        handleShowEditSelectedDealsModalEvent,
      );
  }, []);

  return (
    <>
      {selectedDeals?.length ? (
        <DealsEditSelected
          isOpen
          refreshAfterSuccess
          deals={selectedDeals}
          onClose={() => setSelectedDeals([])}
        />
      ) : null}
    </>
  );
};

export default DealsEditSelectedWrapper;
