import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Box,
  styled,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Popover,
  IconButton,
  TableContainer,
} from "@mui/material";
import { format } from "date-fns";
import download from "downloadjs";
import { chunk, kebabCase } from "lodash";
import { DateTime } from "luxon";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import {
  OrderLineDealDetailsPopoverButton,
  OrderLineShippingInfoPopoverButton,
} from "../../components/OrderDetail";
import { DownloadIcon, EmailIcon } from "../../components/icons/";
import EmailDetailModal from "../../components/modals/EmailDetailModal";
import { Button, LoadingButton } from "../../components/ui";
import { useOrderEmail, useOrderExport } from "../../hooks/api/mutations";
import { useOrder, useOrderLog } from "../../hooks/api/queries";
import usePageTitle from "../../hooks/store/usePageTitle";
import { formatCurrency } from "../../utils/currency";

const InfoPanelBox = styled(Box)(({ theme }) => ({
  borderColor: theme.palette.grey["400"],
  borderRadius: 3,
  borderWidth: 1,
  boxShadow: "0px 2px 4px rgba(227,227,227, 0.5)",
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(2),
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey["50"],
  fontSize: 14,
  fontWeight: "medium",
  py: theme.spacing(2),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  lineHeight: theme.spacing(1.5),
  verticalAlign: "top",
}));

const paymentTypeMapping: Record<string, string> = {
  C: "Credit Card",
  T: "Terms",
};

const getFormatedDateString = (isoDate?: string) =>
  isoDate
    ? DateTime.fromISO(isoDate, { zone: "America/New_York" })
        .toFormat("M/d/yyyy 'at' h:mma 'ET'")
        .replace("AM", "am")
        .replace("PM", "pm")
    : "";

const OrderDetail = () => {
  const [, setPageTitle] = usePageTitle();
  const [isEmailModalOpened, setIsEmailModalOpened] = useState(false);
  const { orderId } = useParams();
  const orderIdNum = orderId ? +orderId : undefined;
  const { data: orderData } = useOrder(orderIdNum);
  const { data: orderLogData } = useOrderLog(orderIdNum);
  const { mutate: exportOrder, isLoading } = useOrderExport();
  const [editHistoryAnchor, setEditHistoryAnchor] =
    useState<HTMLButtonElement | null>(null);

  const customOrderData = useMemo(
    () => [
      [
        {
          label: orderData?._labels?.custPo,
          value: orderData?.orderHeader?.custPo,
        },
        {
          label: "Payment Type",
          value: orderData?.orderHeader?.paymentType
            ? paymentTypeMapping[orderData?.orderHeader?.paymentType]
            : "",
        },
        { label: "Shipment Method", value: orderData?.orderHeader?.shipMethod },
      ],
      [
        {
          label: orderData?._labels?.project,
          value: orderData?.orderHeader?.project,
        },
        {
          label: orderData?._labels?.refNum,
          value: orderData?.orderHeader?.refNum,
        },
        {
          label: orderData?._labels?.costCtr,
          value: orderData?.orderHeader?.costCtr,
        },
      ],
      ...chunk(orderData?.customOrderHeaders, 3).map((slice) =>
        slice.map((field) => ({
          label: field.fieldLabel,
          value: field.fieldValue,
        })),
      ),
    ],
    [orderData],
  );

  const onExport = useCallback(() => {
    if (orderIdNum) {
      exportOrder(orderIdNum, {
        onSuccess: ({ data }) => {
          download(data, `Order#${orderIdNum}.pdf`, "application/pdf");
        },
      });
    }
  }, [exportOrder, orderIdNum]);

  useEffect(() => {
    const orderDate = orderData?.orderHeader?.orderDate
      ? new Date(orderData?.orderHeader?.orderDate)
      : new Date();
    setPageTitle(
      <Box alignItems="center" display="flex" gap={1}>
        Order #{orderId} created by {orderData?.orderCreatedBy?.name} on{" "}
        {format(orderDate, "MMM d, yyyy")}
        <IconButton
          aria-label="Order Info"
          onClick={(e) => setEditHistoryAnchor(e.currentTarget)}
        >
          <ErrorOutlineIcon
            sx={(theme) => ({ color: theme.palette.blue["600"] })}
          />
        </IconButton>
        <Popover
          anchorEl={editHistoryAnchor}
          anchorOrigin={{
            horizontal: "center",
            vertical: "bottom",
          }}
          open={Boolean(editHistoryAnchor)}
          transformOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          onClose={() => setEditHistoryAnchor(null)}
        >
          <Box sx={{ p: 2 }}>
            <Typography color="grey.700" fontSize="medium" fontWeight="medium">
              Edit History
            </Typography>
            <TableContainer
              sx={(theme) => ({
                maxHeight: theme.spacing(56),
                mt: theme.spacing(1.25),
                overFlowY: "auto",
              })}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={(theme) => ({
                        backgroundColor: theme.palette.grey["50"],
                        color: theme.palette.grey["700"],
                        fontSize: 14,
                        fontWeight: 600,
                      })}
                    >
                      USER LOG
                    </TableCell>
                    <TableCell
                      sx={(theme) => ({
                        backgroundColor: theme.palette.grey["50"],
                        color: theme.palette.grey["700"],
                        fontSize: 14,
                        fontWeight: 600,
                      })}
                    >
                      CHANGE DESCRIPTION
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderLogData?.map((log) => (
                    <TableRow
                      key={`${log.userId}-${log.action}-${log.timestamp}`}
                    >
                      <TableCell
                        sx={(theme) => ({
                          color: theme.palette.grey["600"],
                          verticalAlign: "top",
                        })}
                      >
                        <Typography sx={{ fontSize: 14 }}>
                          {log.userName}
                        </Typography>
                        <Typography sx={{ fontSize: 14 }}>
                          {getFormatedDateString(log.timestamp)}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <Box
                          sx={(theme) => ({
                            backgroundColor: log.updates
                              ? theme.palette.grey["500"]
                              : theme.palette.blue["700"],
                            borderRadius: 2,
                            color: theme.palette.white,
                            display: "inline-flex",
                            fontSize: 12,
                            fontWeight: "medium",
                            px: theme.spacing(1.5),
                            py: theme.spacing(0.25),
                            whiteSpace: "nowrap",
                          })}
                        >
                          {log.action}
                        </Box>
                        {log.updates ? (
                          <Box
                            sx={(theme) => ({
                              color: theme.palette.grey["600"],
                              display: "flex",
                              flexDirection: "column",
                              fontSize: 14,
                              gap: 2,
                              mt: 2,
                            })}
                          >
                            {log.updates.map((update, index) => (
                              <Box key={index}>
                                <Box alignItems="center" display="flex" gap={1}>
                                  <Typography fontWeight="600">
                                    Field:
                                  </Typography>{" "}
                                  {update.fieldCode}
                                </Box>
                                <Box alignItems="center" display="flex" gap={1}>
                                  <Typography fontWeight="600">
                                    Changed from:
                                  </Typography>{" "}
                                  {update.oldValue}
                                </Box>
                                <Box alignItems="center" display="flex" gap={1}>
                                  <Typography fontWeight="600">
                                    Changed to:
                                  </Typography>{" "}
                                  {update.newValue}
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Popover>
      </Box>,
    );
  }, [orderData, orderId, orderLogData, setPageTitle, editHistoryAnchor]);

  return (
    <>
      <Box sx={(theme) => ({ color: theme.palette.grey["700"] })}>
        <Box display="flex" gap={2} justifyContent="end" pr={2}>
          <Button
            aria-label="Email"
            data-testid="order-email-btn"
            sx={{ minWidth: 58 }}
            variant="outlined"
            onClick={() => setIsEmailModalOpened(true)}
          >
            <EmailIcon sx={{ fontSize: 14 }} />
          </Button>
          <LoadingButton
            aria-label="Download"
            data-testid="order-download-btn"
            loading={isLoading}
            sx={{ minWidth: 58 }}
            variant="outlined"
            onClick={onExport}
          >
            <DownloadIcon sx={{ fontSize: 14 }} />
          </LoadingButton>
        </Box>
        <Box display="flex" gap={2} mt={4}>
          <Box display="flex" flex="1 1 auto" flexDirection="column" gap={4}>
            <Box display="flex" gap={4}>
              <InfoPanelBox sx={{ width: "100%" }}>
                <Typography fontWeight="medium" mb={2} sx={{ fontSize: 18 }}>
                  Billing Address
                </Typography>
                <Box data-testid="order-billing-address" lineHeight={6}>
                  <Typography>
                    Attention: {orderData?.billingAddress?.attention}
                  </Typography>
                  <Typography>
                    {orderData?.billingAddress?.companyName}
                  </Typography>
                  <Typography>{orderData?.billingAddress?.address}</Typography>
                  {orderData?.billingAddress?.address2 && (
                    <Typography>
                      {orderData?.billingAddress?.address2}
                    </Typography>
                  )}
                  <Typography>
                    {orderData?.billingAddress?.city},{" "}
                    {orderData?.billingAddress?.state}{" "}
                    {orderData?.billingAddress?.zip}
                  </Typography>
                </Box>
              </InfoPanelBox>

              <InfoPanelBox sx={{ width: "100%" }}>
                <Typography fontWeight="medium" mb={2} sx={{ fontSize: 18 }}>
                  Shipping Address
                </Typography>
                <Box data-testid="order-shipping-address" lineHeight={6}>
                  <Typography>
                    Attention: {orderData?.orderHeader?.attention}
                  </Typography>
                  <Typography>
                    {orderData?.shippingAddress?.compname}
                  </Typography>
                  <Typography>{orderData?.shippingAddress?.address}</Typography>
                  {orderData?.shippingAddress?.address2 && (
                    <Typography>
                      {orderData?.shippingAddress?.address2}
                    </Typography>
                  )}
                  <Typography>
                    {orderData?.shippingAddress?.city},{" "}
                    {orderData?.shippingAddress?.state}{" "}
                    {orderData?.shippingAddress?.zip}
                  </Typography>
                </Box>
              </InfoPanelBox>
            </Box>

            <InfoPanelBox>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: `repeat(${customOrderData.length}, 1fr)`,
                }}
              >
                {customOrderData.map((row, rowIndex) => (
                  <Box key={rowIndex}>
                    {row.map((field, fieldIndex) => (
                      <Typography
                        key={fieldIndex}
                        data-testid={`order-custom-${kebabCase(field.label)}`}
                        sx={(theme) => ({ lineHeight: theme.spacing(3) })}
                      >
                        <Typography
                          component="span"
                          fontWeight="medium"
                          sx={{ display: "inline" }}
                        >
                          {field.label}:{" "}
                        </Typography>
                        {field.value}
                      </Typography>
                    ))}
                  </Box>
                ))}
              </Box>
            </InfoPanelBox>
          </Box>

          <Box display="flex" flexDirection="column" gap={4}>
            <Box
              data-testid="order-total-gp"
              sx={(theme) => ({
                alignItems: "center",
                backgroundColor: theme.palette.green["600"],
                borderRadius: 2,
                display: "flex",
                gap: 2,
                minHeight: 118,
                minWidth: 64,
                px: theme.spacing(6),
                py: theme.spacing(3.5),
              })}
            >
              <Typography sx={{ fontSize: 18 }}>Total GP</Typography>
              <Box flex="1 1 auto" textAlign="center">
                <Typography fontWeight="medium" sx={{ fontSize: 24 }}>
                  {formatCurrency(orderData?.orderHeader?.repGp)}
                </Typography>
                <Typography>
                  ({orderData?.orderHeader?.repGpPercent}%)
                </Typography>
              </Box>
            </Box>

            <Box
              data-testid="order-status"
              sx={(theme) => ({
                alignItems: "center",
                backgroundColor: theme.palette.cyan["100"],
                borderRadius: 2,
                display: "flex",
                gap: 2,
                minHeight: 118,
                minWidth: 64,
                px: theme.spacing(6),
                py: theme.spacing(3.5),
              })}
            >
              <Typography sx={{ fontSize: 18 }}>Status</Typography>
              <Box flex="1 1 auto" textAlign="center">
                <Typography fontWeight="medium" sx={{ fontSize: 24 }}>
                  {orderData?.repDisplay}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <InfoPanelBox mt={4}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableHeadCell>LINE #</StyledTableHeadCell>
                <StyledTableHeadCell>ITEM</StyledTableHeadCell>
                <StyledTableHeadCell>QTY</StyledTableHeadCell>
                <StyledTableHeadCell>UNIT PRICE</StyledTableHeadCell>
                <StyledTableHeadCell>UNIT LOADED COST</StyledTableHeadCell>
                <StyledTableHeadCell>TOTAL PRICE</StyledTableHeadCell>
                <StyledTableHeadCell>TOTAL LOADED COST</StyledTableHeadCell>
                <StyledTableHeadCell>GP</StyledTableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderData?.orderLines?.map((orderLine, index) => (
                <TableRow key={orderLine.orderLineId}>
                  <StyledTableCell data-testid="order-line-index">
                    <Typography>{index + 1}</Typography>
                  </StyledTableCell>
                  <StyledTableCell data-testid="order-line-item">
                    <Typography>{orderLine.mfgName}</Typography>
                    <Typography>{orderLine.mfgPart}</Typography>
                    <Typography>
                      {orderLine.suppCode} / {orderLine.suppPart}
                    </Typography>
                    <Typography
                      sx={(theme) => ({ color: theme.palette.blue["600"] })}
                    >
                      {orderLine.prodDesc}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell data-testid="order-line-qty">
                    <Box alignItems="center" display="inline-flex" gap={1}>
                      {orderLine.qtyOrdered}{" "}
                      {orderLine.dispatchDate ? (
                        <OrderLineShippingInfoPopoverButton
                          orderLine={orderLine}
                        />
                      ) : null}
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell data-testid="order-line-unit-price">
                    {formatCurrency(orderLine.memberPrice)}
                  </StyledTableCell>
                  <StyledTableCell data-testid="order-line-unit-loaded-cost">
                    <Box
                      alignItems="center"
                      display="inline-flex"
                      gap={0.5}
                      mt={-1.5}
                    >
                      {formatCurrency(orderLine.unitLoadedCost)}
                      {orderLine.deal ? (
                        <OrderLineDealDetailsPopoverButton
                          deal={orderLine.deal}
                          unitCost={orderLine.unitCost}
                        />
                      ) : null}
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell data-testid="order-line-total-price">
                    {formatCurrency(
                      (orderLine.qtyOrdered ?? 0) *
                        (orderLine.memberPrice ?? 0),
                    )}
                  </StyledTableCell>
                  <StyledTableCell data-testid="order-line-total-loaded-cost">
                    {formatCurrency(
                      (orderLine.qtyOrdered ?? 0) *
                        (orderLine.unitLoadedCost ?? 0),
                    )}
                  </StyledTableCell>
                  <StyledTableCell data-testid="order-line-gp">
                    <Typography>
                      {formatCurrency(orderLine.totalRepGp)}
                    </Typography>
                    <Typography>({orderLine.repGpPercentage}%)</Typography>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Box display="flex" mt={3}>
            <Box flexGrow={1} fontSize="sm" lineHeight={3} textAlign="right">
              <Typography sx={{ pr: 5 }}>Subtotal:</Typography>
              <Typography sx={{ pr: 5 }}>Freight:</Typography>
              <Typography sx={{ pr: 5 }}>Tax:</Typography>
              <Typography sx={{ pr: 5 }}>Fees:</Typography>
              <Typography
                sx={(theme) => ({
                  backgroundColor: theme.palette.grey["50"],
                  fontWeight: 600,
                  mt: 3,
                  pr: 5,
                  py: 1,
                })}
              >
                Total:
              </Typography>
            </Box>

            <Box
              sx={(theme) => ({
                fontSize: 14,
                lineHeight: theme.spacing(1.5),
                minWidth: theme.spacing(12),
              })}
            >
              <Typography data-testid="order-lines-subtotal">
                {formatCurrency(orderData?.orderHeader?.orderTotal)}&nbsp;
              </Typography>
              <Typography data-testid="order-lines-freight">
                {formatCurrency(
                  orderData?.orderHeader?.estimatedShippingHandling,
                )}
                &nbsp;
              </Typography>
              <Typography data-testid="order-lines-tax">
                {formatCurrency(orderData?.orderHeader?.estimatedTax)}&nbsp;
              </Typography>
              <Typography data-testid="order-lines-fees">
                {formatCurrency(orderData?.orderHeader?.estimatedFees)}&nbsp;
              </Typography>
              <Typography
                data-testid="order-lines-total"
                sx={(theme) => ({
                  backgroundColor: theme.palette.grey["50"],
                  fontWeight: 600,
                  mt: 3,
                  py: 1,
                })}
              >
                {formatCurrency(
                  (orderData?.orderHeader?.orderTotal ?? 0) +
                    (orderData?.orderHeader?.estimatedShippingHandling ?? 0) +
                    (orderData?.orderHeader?.estimatedTax ?? 0) +
                    (orderData?.orderHeader?.estimatedFees ?? 0),
                )}
                &nbsp;
              </Typography>
            </Box>
          </Box>
        </InfoPanelBox>

        <Box display="flex" gap={4} mt={5}>
          <InfoPanelBox
            data-testid="order-instructions"
            flexGrow={1}
            sx={(theme) => ({
              flexGrow: 1,
              minHeigth: theme.spacing(22),
            })}
          >
            <Typography fontWeight="medium" mb={1}>
              {orderData?._labels?.instructions}
            </Typography>
            <Typography>
              {orderData?.orderHeader?.instr1}
              {orderData?.orderHeader?.instr2}
            </Typography>
          </InfoPanelBox>
          <InfoPanelBox data-testid="order-internal-notes" flexGrow={1}>
            <Typography fontSize="md" fontWeight="medium" mb={1}>
              Internal Notes
            </Typography>
            <Typography>{orderData?.orderHeader?.internalNotes}</Typography>
          </InfoPanelBox>
          <InfoPanelBox
            data-testid="order-config-notes"
            sx={(theme) => ({
              backgroundColor: theme.palette.grey["50"],
              flexGrow: 1,
            })}
          >
            <Typography fontSize="md" fontWeight="medium" mb={1}>
              {orderData?._labels?.configNotes}
            </Typography>
            <Typography>
              {orderData?.orderHeader?.configNotes1}
              {orderData?.orderHeader?.configNotes2}
              {orderData?.orderHeader?.configNotes3}
            </Typography>
          </InfoPanelBox>
        </Box>
      </Box>

      {orderIdNum && (
        <EmailDetailModal
          accountCodes={[orderData?.orderHeader?.accountCode]}
          idsWithAccounts={[
            {
              accountCode: orderData?.orderHeader?.accountCode,
              id: orderIdNum,
            },
          ]}
          isOpen={isEmailModalOpened}
          typeLabel="Order"
          useSendEmailHook={useOrderEmail}
          onClose={() => setIsEmailModalOpened(false)}
        />
      )}
    </>
  );
};

export default memo(OrderDetail);
