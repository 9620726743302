import { createSvgIcon } from "@mui/material";

export const ExclamationTriangleIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 19 16" xmlns="http://www.w3.org/2000/svg">
    <title>exclamation-triangle</title>
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="warning-status"
        transform="translate(-59, -553)"
      >
        <g id="alert-warning-status" transform="translate(43, 531)">
          <g id="exclamation-triangle-fill" transform="translate(16, 22)">
            <path
              d="M10.2646724,0.648468009 C10.0344036,0.247348014 9.60720249,0 9.14468658,0 C8.68217066,0 8.25496952,0.247348014 8.02470072,0.648468009 L0.188228245,13.9820139 C-0.334050875,14.8711456 0.292226931,16.001417 1.3082141,16.001417 L16.9800162,16.001417 C17.9960034,16.001417 18.623424,14.8700027 18.1000021,13.9820139 L10.2646724,0.648468009 Z M9.14240089,4.57298988 C9.75382174,4.57298988 10.2326728,5.10098321 10.1709593,5.71011838 L9.77096438,9.71806777 C9.74310159,10.0444913 9.47001146,10.2952642 9.14240089,10.2952642 C8.81479032,10.2952642 8.54170019,10.0444913 8.5138374,9.71806777 L8.11384245,5.71011838 C8.08479382,5.41946677 8.1800015,5.1300841 8.37594651,4.913456 C8.57189153,4.6968279 8.85030133,4.57315569 9.14240089,4.57298988 Z M9.14468658,11.4300462 C9.77586118,11.4300462 10.2875293,11.9417143 10.2875293,12.5728889 C10.2875293,13.2040635 9.77586118,13.7157316 9.14468658,13.7157316 C8.51351198,13.7157316 8.00184387,13.2040635 8.00184387,12.5728889 C8.00184387,11.9417143 8.51351198,11.4300462 9.14468658,11.4300462 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "ExclamationTriangle",
);
