import { useQuery } from "@tanstack/react-query";
import {
  ListingUsersResourceService,
  RestCollection_ListingUser_,
} from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";
import { ListingMaxIdQueryHook } from "../../../types";
import { getMaxIdCriteria } from "../../../utils/criteria";

export const useUserMaxId: ListingMaxIdQueryHook<
  RestCollection_ListingUser_
> = (identifier: string) => {
  const maxIdCriteria = getMaxIdCriteria(identifier);

  return useQuery([QUERY_KEYS["user-max-id"], identifier], () => {
    return ListingUsersResourceService.listUsersUsingGet(
      JSON.stringify(maxIdCriteria),
      1,
      false,
      false,
      false,
      1,
    );
  });
};
