import { useEffect, useState } from "react";
import { useOrderEmail } from "../../hooks/api/mutations";
import EmailDetailModal from "../modals/EmailDetailModal";

type EmailOrderDto = {
  accountCode?: string;
  orderNumber?: number;
};

const EmailOrdersWrapper = () => {
  const [selectedOrders, setSelectedOrders] = useState<EmailOrderDto[]>([]);

  useEffect(() => {
    const handleShowEmailOrdersModalEvent = (event: Event) => {
      const rows = (event as CustomEvent).detail;
      setSelectedOrders(rows);
    };

    document.addEventListener(
      "showEmailOrdersModal",
      handleShowEmailOrdersModalEvent,
    );

    return () =>
      document.removeEventListener(
        "showEmailOrdersModal",
        handleShowEmailOrdersModalEvent,
      );
  }, []);

  return (
    <>
      {selectedOrders?.length ? (
        <EmailDetailModal
          isOpen
          accountCodes={selectedOrders
            .map((order) => order.accountCode)
            .filter((value, index, array) => {
              return array.indexOf(value) === index;
            })}
          idsWithAccounts={selectedOrders.map((order) => {
            return { accountCode: order.accountCode, id: order.orderNumber };
          })}
          typeLabel="Order"
          useSendEmailHook={useOrderEmail}
          onClose={() => {
            setSelectedOrders([]);
          }}
        />
      ) : null}
    </>
  );
};

export default EmailOrdersWrapper;
