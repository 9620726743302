import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { OrderLineDto } from "../../../api";

type Reason = {
  code?: string;
  reason?: string;
  reasonDetails?: string;
};

type Contact = {
  fullName?: string;
  email?: string;
  phone?: string;
};

type PartDetails = {
  supplierCode?: string;
  supplierPart?: string;
  category?: string;
};

type ContextState = {
  step?: string;
  setStep: Dispatch<SetStateAction<string>>;
  orderLine: OrderLineDto | undefined;
  setOrderLine: Dispatch<SetStateAction<OrderLineDto | undefined>>;
  orderId?: number;
  partDetails?: PartDetails;
  accountCode: string | undefined;
  onClose: () => void;
  orderLineId: number | undefined;
  numberToReturn: number | undefined;
  setNumberToReturn: Dispatch<SetStateAction<number | undefined>>;
  serialNumbers: string[] | undefined;
  setSerialNumbers: Dispatch<SetStateAction<string[] | undefined>>;
  reason: Reason | undefined;
  setReason: Dispatch<SetStateAction<Reason>>;
  sealed?: boolean | undefined;
  setSealed: Dispatch<SetStateAction<boolean | undefined>>;
  contact: Contact | undefined;
  setContact: Dispatch<SetStateAction<Contact>>;
};

const RmaContext = createContext<ContextState>({
  accountCode: undefined,
  contact: {},
  numberToReturn: undefined,
  onClose: () => {},
  orderId: undefined,
  orderLine: {},
  orderLineId: 0,
  partDetails: {},
  reason: {},
  sealed: undefined,
  serialNumbers: [],
  setContact: () => {},
  setNumberToReturn: () => {},
  setOrderLine: () => {},
  setReason: () => {},
  setSealed: () => {},
  setSerialNumbers: () => {},
  setStep: () => {},
  step: undefined,
});

interface RmaRequestContextProviderProps {
  children: ReactNode;
  onClose: () => void;
  defaultStep: string;
  orderLineId: number | undefined;
  orderId: number | undefined;
  partDetails: PartDetails | undefined;
  accountCode: string | undefined;
}

export const RmaContextProvider = ({
  children,
  onClose,
  defaultStep,
  orderLineId,
  orderId,
  partDetails,
  accountCode,
}: RmaRequestContextProviderProps) => {
  const [step, setStep] = useState<string>(defaultStep);
  const [orderLine, setOrderLine] = useState<OrderLineDto>();
  const [numberToReturn, setNumberToReturn] = useState<number>();
  const [serialNumbers, setSerialNumbers] = useState<string[]>();
  const [reason, setReason] = useState<Reason>({});
  const [contact, setContact] = useState<Contact>({});
  const [sealed, setSealed] = useState<boolean>();

  return (
    <RmaContext.Provider
      value={{
        accountCode,
        contact,
        numberToReturn,
        onClose,
        orderId,
        orderLine,
        orderLineId,
        partDetails,
        reason,
        sealed,
        serialNumbers,
        setContact,
        setNumberToReturn,
        setOrderLine,
        setReason,
        setSealed,
        setSerialNumbers,
        setStep,
        step,
      }}
    >
      {children}
    </RmaContext.Provider>
  );
};

export const useRmaContext = () => {
  return useContext<ContextState>(RmaContext);
};
