import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { isNumber } from "lodash";
import { memo, useCallback, useEffect, useRef } from "react";
import { AccountSalesSummaryDto } from "../../api";
import {
  SALES_SUMMARY_ACCOUNTS_PAGE_SIZE,
  TABLE_ROW_HEIGHT,
} from "../../constants";
import { useAccountSwitch } from "../../hooks/api/mutations";
import { useInfiniteTransactionsSalesSummary } from "../../hooks/api/queries/useInfiniteTransactionsSalesSummary";
import theme from "../../theme";
import { formatCurrency } from "../../utils/currency";
import { convertNumberToPercentFormat } from "../../utils/number";
import { TableCell, TableHeaderCell } from "../ui/Table";

const AccountTransactionsTable = memo(() => {
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteTransactionsSalesSummary();
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const { mutate: switchAccount } = useAccountSwitch();

  const accounts =
    data?.pages.flatMap((page) =>
      (page.accounts ?? []).slice(0, SALES_SUMMARY_ACCOUNTS_PAGE_SIZE),
    ) ?? [];

  useEffect(() => {
    if (!tableContainerRef.current) {
      return;
    }

    const handleScroll = () => {
      if (tableContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          tableContainerRef.current;
        const distanceToBottom = scrollHeight - (scrollTop + clientHeight);

        if (distanceToBottom < 100 && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      }
    };

    const container = tableContainerRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => container.removeEventListener("scroll", handleScroll);
  }, [hasNextPage, isFetchingNextPage, fetchNextPage, tableContainerRef]);

  const onAccountRowClick = useCallback(
    (account: AccountSalesSummaryDto) => {
      if (account.accountCode) {
        switchAccount(account.accountCode, {
          onSuccess: () => {
            window.location.reload();
          },
        });
      }
    },
    [switchAccount],
  );

  return (
    <Box
      ref={tableContainerRef}
      data-testid="att-container"
      sx={{ maxHeight: "600px", overflowY: `${isLoading ? "hidden" : "auto"}` }}
    >
      <Table data-testid="att-table">
        <TableHead data-testid="att-table-header">
          <TableRow>
            <TableHeaderCell></TableHeaderCell>
            <TableHeaderCell>QTD BOOKED</TableHeaderCell>
            <TableHeaderCell>QTD INVOICED</TableHeaderCell>
            <TableHeaderCell>QTD GP%</TableHeaderCell>
            <TableHeaderCell>OVERDUE AR</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody data-testid="att-table-body">
          {isLoading && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={5}
                data-testid="att-loading-spinner-cell"
                sx={{ borderBottomWidth: "0" }}
              >
                <CircularProgress className="homepage-table-loading-spinner" />
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            accounts.map((account) => (
              <TableRow
                key={account.accountCode}
                sx={{
                  "&:hover": {
                    backgroundColor: theme.palette.grey["50"],
                    cursor: "pointer",
                  },
                  height: TABLE_ROW_HEIGHT,
                }}
                onClick={() => onAccountRowClick(account)}
              >
                <TableCell>
                  <Typography
                    component="span"
                    sx={{
                      "&:hover": { textDecoration: "underline" },
                      color: theme.palette.blue["400"],
                      fontSize: "14px",
                    }}
                  >{`(${account.accountCode}) ${account.companyName}`}</Typography>
                </TableCell>
                <TableCell>
                  {formatCurrency(account.totalOrdersBooked, 0)}
                </TableCell>
                <TableCell>
                  {formatCurrency(account.totalInvoiced, 0)}
                </TableCell>
                <TableCell>
                  {isNumber(account.repGpPercent)
                    ? convertNumberToPercentFormat(account.repGpPercent)
                    : ""}
                </TableCell>
                <TableCell>
                  {formatCurrency(account.overdueArBalance, 0)}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
});

export default AccountTransactionsTable;
