/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InputStreamResource } from '../models/InputStreamResource';
import type { LogDto } from '../models/LogDto';
import type { RestCollection_StockingOrderSolrDto_ } from '../models/RestCollection_StockingOrderSolrDto_';
import type { StockingOrderDto } from '../models/StockingOrderDto';
import type { StockingOrderEmailRequestDto } from '../models/StockingOrderEmailRequestDto';
import type { StockingOrderPatchRequestDto } from '../models/StockingOrderPatchRequestDto';
import type { StockingOrderPostRequestDto } from '../models/StockingOrderPostRequestDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class StockingOrdersResourceService {
  /**
   * Get stocking order listing report
   * Get stocking order listing report
   * @param criteria criteria
   * @param page page
   * @param returnAllLabels returnAllLabels
   * @param returnPagination returnPagination
   * @param returnSavedViews returnSavedViews
   * @param size size
   * @param useDefaultView useDefaultView
   * @returns RestCollection_StockingOrderSolrDto_ Ok
   * @throws ApiError
   */
  public static listUsingGet27(
    criteria: string = '{}',
    page: number = 1,
    returnAllLabels: boolean = false,
    returnPagination: boolean = true,
    returnSavedViews: boolean = true,
    size: number = 50,
    useDefaultView: boolean = false,
  ): CancelablePromise<RestCollection_StockingOrderSolrDto_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/listing/stockingOrders',
      query: {
        'criteria': criteria,
        'page': page,
        'returnAllLabels': returnAllLabels,
        'returnPagination': returnPagination,
        'returnSavedViews': returnSavedViews,
        'size': size,
        'useDefaultView': useDefaultView,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Stocking order(s) Not Found`,
        500: `Internal Error fetching Stocking orders`,
      },
    });
  }
  /**
   * Send Stocking Order Email
   * Send Stocking Order Email
   * @param stockingOrderEmailRequestDto stockingOrderEmailRequestDto
   * @returns any Success
   * @throws ApiError
   */
  public static sendStockingOrderEmailUsingPost(
    stockingOrderEmailRequestDto: StockingOrderEmailRequestDto,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/stockingOrders/email',
      body: stockingOrderEmailRequestDto,
      errors: {
        400: `Stocking Order Email Bad Request`,
        401: `Unauthorized`,
        403: `You are not authorized to access this Stocking Order.`,
        404: `Stocking Order not found`,
        500: `Internal Error sending Stocking Order Email`,
      },
    });
  }
  /**
   * Validate stocking order inputs
   * Validate stocking order inputs
   * @param requestDto requestDto
   * @param accountId accountId
   * @returns any Success
   * @throws ApiError
   */
  public static validateStockingOrderUsingPost(
    requestDto: StockingOrderPostRequestDto,
    accountId?: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/stockingOrders/validation',
      query: {
        'accountId': accountId,
      },
      body: requestDto,
      errors: {
        400: `Stocking Order Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal Error Validating Stocking Order`,
      },
    });
  }
  /**
   * Get stocking order
   * Get stocking order
   * @param stockingOrderId stockingOrderId
   * @returns StockingOrderDto Ok
   * @throws ApiError
   */
  public static getUsingGet3(
    stockingOrderId: number,
  ): CancelablePromise<StockingOrderDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/stockingOrders/{stockingOrderId}',
      path: {
        'stockingOrderId': stockingOrderId,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Stocking order Not Found`,
        500: `Internal Error fetching Stocking order`,
      },
    });
  }
  /**
   * Delete Stocking Order
   * Delete Stocking Order
   * @param stockingOrderId stockingOrderId
   * @returns any Success
   * @throws ApiError
   */
  public static deleteUsingDelete10(
    stockingOrderId: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/service/rest/stockingOrders/{stockingOrderId}',
      path: {
        'stockingOrderId': stockingOrderId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Stocking Order Not Found`,
        500: `Internal Error Deleting Stocking Order`,
      },
    });
  }
  /**
   * Update Stocking Order
   * Update order
   * @param requestDto requestDto
   * @param stockingOrderId stockingOrderId
   * @returns StockingOrderDto Success
   * @throws ApiError
   */
  public static updateStockingOrderUsingPatch(
    requestDto: StockingOrderPatchRequestDto,
    stockingOrderId: number,
  ): CancelablePromise<StockingOrderDto> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/stockingOrders/{stockingOrderId}',
      path: {
        'stockingOrderId': stockingOrderId,
      },
      body: requestDto,
      errors: {
        400: `Bad request.`,
        401: `Unauthorized`,
        403: `You are not authorized for this request.`,
        404: `Order with orderNum {orderNum} not found.`,
        409: `Conflict`,
        500: `Internal Error updating stocking order`,
        503: `Service unavailable`,
      },
    });
  }
  /**
   * Export stocking order.
   * Export stocking order.
   * @param stockingOrderId stockingOrderId
   * @returns InputStreamResource Success
   * @throws ApiError
   */
  public static exportUsingGet8(
    stockingOrderId: number,
  ): CancelablePromise<InputStreamResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/stockingOrders/{stockingOrderId}/export',
      path: {
        'stockingOrderId': stockingOrderId,
      },
      errors: {
        400: `Bad request.`,
        401: `Unauthorized`,
        403: `You are not authorized to access this stocking order.`,
        404: `Stocking order not found.`,
        500: `Internal Error exporting stocking order`,
      },
    });
  }
  /**
   * Get Stocking Order Logs By Id
   * Get Stocking Order Logs By Id
   * @param stockingOrderId stockingOrderId
   * @returns LogDto Success
   * @throws ApiError
   */
  public static findOrderLogByOrderIdUsingGet1(
    stockingOrderId: number,
  ): CancelablePromise<Array<LogDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/stockingOrders/{stockingOrderId}/log',
      path: {
        'stockingOrderId': stockingOrderId,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Order Not Found`,
        500: `Internal Error Finding Stocking  Order Log`,
      },
    });
  }
}
