import { useEffect, useState } from "react";
import { ClientResourceService, ErpAccountRequestDto } from "../../api";
import { ImportWizardColumnDef } from "../../types";
import { ClientResourceDto } from "../../types/ClientResource";
import { generateClientResourceEntity } from "../../utils/clientResources";
import {
  ImportWizardContextProvider,
  useImportWizardContext,
} from "../ImportWizard/ImportWizardContext";
import { Import, MapFields, SelectFile } from "../ImportWizard/modals";
import ImportResult, { ImportClientDtoWithError } from "./ImportResult";

enum ImportClientStep {
  SelectFile = "SelectFile",
  MapFields = "MapFields",
  Import = "Import",
  ImportResult = "ImportResult",
}

const columnDefs: ImportWizardColumnDef[] = [
  {
    label: "CRM Client Code",
    name: "crmClientCode",
    required: true,
    suggestions: ["CRM Client Code"],
  },
  {
    label: "CRM",
    name: "crm",
    required: true,
    suggestions: ["CRM"],
  },
  {
    label: "ERP",
    name: "erp",
    required: true,
    suggestions: ["ERP"],
  },
  {
    label: "ERP Account Code(s)",
    name: "erpAccounts",
    required: true,
    suggestions: ["Account Number"],
  },
];

const insertClientResources = async (client: ClientResourceDto) => {
  try {
    await ClientResourceService.createClientUsingPost({
      crm: client.crm || "",
      crmClientCode: client.crmClientCode || "",
      erpAccounts:
        client?.erpAccounts?.map(
          (account) =>
            ({
              erp: client.erp,
              erpAccountCode: account,
            }) as ErpAccountRequestDto,
        ) || [],
    });
    return {
      success: true,
    };
  } catch (err) {
    return {
      error: (err as Error).message,
      success: false,
    };
  }
};

const ImportClientSteps = () => {
  const { step, setStep, onClose, progress } = useImportWizardContext();

  const [succeededClients, setSucceededClients] = useState<
    ImportClientDtoWithError[]
  >([]);
  const [failedClients, setFailedClients] = useState<
    ImportClientDtoWithError[]
  >([]);

  const [importFinished, setImportFinished] = useState(false);

  const handleImport = async (clients: ClientResourceDto[]) => {
    setStep(ImportClientStep.Import);

    const promises = clients.map((client) => insertClientResources(client));

    Promise.all(promises).then((results) => {
      const succeededClients = results
        .map((response, index) => (response?.success ? clients[index] : null))
        .filter((client) => client) as ImportClientDtoWithError[];

      const failedClients = results
        .map((response, index) =>
          response?.success
            ? null
            : { ...clients[index], error: response?.error },
        )
        .filter((client) => client) as ImportClientDtoWithError[];

      setSucceededClients(succeededClients);
      setFailedClients(failedClients);
      setImportFinished(true);
    });
  };

  useEffect(() => {
    if (importFinished && progress > 90) {
      setStep(ImportClientStep.ImportResult);
    }
  }, [importFinished, progress, setStep]);

  return (
    <>
      {step === ImportClientStep.SelectFile && (
        <SelectFile
          headline="Select an Excel or CSV file with Client Data"
          subHeadline="NOTE: Must include Salesforce ID, ERP & Account ID"
          title="Import CRM Clients"
          onNext={() => setStep(ImportClientStep.MapFields)}
        />
      )}
      {step === ImportClientStep.MapFields && (
        <MapFields
          columnDefs={columnDefs}
          generateEntity={generateClientResourceEntity}
          title="Import CRM Clients - Map Fields to Upload File"
          onNext={handleImport}
        />
      )}
      {step === ImportClientStep.Import && (
        <Import title="Importing Clients … do not close this window" />
      )}
      {step === ImportClientStep.ImportResult && (
        <ImportResult
          failedClients={failedClients}
          succeededClients={succeededClients}
          onClose={onClose}
        />
      )}
    </>
  );
};

const ImportClients = ({ onClose }: { onClose: () => void }) => {
  return (
    <ImportWizardContextProvider
      defaultStep={ImportClientStep.SelectFile}
      onClose={onClose}
    >
      <ImportClientSteps />
    </ImportWizardContextProvider>
  );
};

export default ImportClients;
