import { useEffect, useMemo, useRef } from "react";
import { Chart, ReactGoogleChartEvent } from "react-google-charts";
import { CHART_COLORS } from "../../constants/chart";
import { GOOGLE_CHART_VERSION } from "../../constants/chat";
import { usePurchaseAnalysisContext } from "../../contexts/PurchaseAnalysisContext";
import { useCookiesLive } from "../../hooks";
import { useMostPopularProducts } from "../../hooks/api/queries";
import { PurchaseAnalysisChartOption1 } from "../../types";
import { formatCurrency } from "../../utils/currency";
import { getAppLink } from "../../utils/link";
import ChartDateFilterButton from "./ChartDateFilterButton";
import ChartWidget from "./ChartWidget";

const MostPopularProducts = () => {
  const [accountCode] = useCookiesLive(["accountCode"]);
  const { config } = usePurchaseAnalysisContext();
  const chartConfig =
    config.popularProducts as PurchaseAnalysisChartOption1 | null;
  const { data, isFetching } = useMostPopularProducts(
    chartConfig?.startDate,
    chartConfig?.endDate,
    accountCode,
    chartConfig?.allTime,
  );
  const chartConfigRef = useRef<PurchaseAnalysisChartOption1 | null>(null);

  useEffect(() => {
    chartConfigRef.current = chartConfig;
  }, [chartConfig]);

  const oldChartData = useMemo(
    () => [
      ["Product", "Last Year"],
      ...(data?.map((item: any) => [
        `${item.manufacturer} - ${item.part}`,
        {
          f: formatCurrency(item.quantityLastYear, 0),
          v: item.quantityLastYear,
        },
      ]) ?? []),
    ],
    [data],
  );

  const newChartData = useMemo(
    () => [
      ["Product", "This Year"],
      ...(data?.map((item: any) => [
        `${item.manufacturer} - ${item.part}`,
        {
          f: formatCurrency(item.quantityThisYear, 0),
          v: item.quantityThisYear,
        },
      ]) ?? []),
    ],
    [data],
  );

  const chartEvents: ReactGoogleChartEvent[] = [
    {
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper?.getChart();
        const selection = chart?.getSelection();
        if (selection && selection.length === 1) {
          const [selectedItem] = selection;
          const dataTable = chartWrapper?.getDataTable();
          const { row } = selectedItem;
          if (row) {
            const value = dataTable?.getValue(Math.floor(row / 2), 0) as string;

            if (value) {
              const searchParams = new URLSearchParams();
              searchParams.set(
                "params",
                JSON.stringify({
                  d: 1,
                  fs: {
                    ...(chartConfigRef.current?.allTime
                      ? {}
                      : {
                          invoiceDate: {
                            end: chartConfigRef.current?.endDate,
                            start: chartConfigRef.current?.startDate,
                          },
                        }),
                    mfgName: [value.split(" - ")[0]],
                    mfgPart: [value.split(" - ")[1]],
                  },
                  fs_clear: false,
                  o: "orderNum",
                  p: "1",
                  r: "50",
                }),
              );
              window.location.href = getAppLink(
                `app/InvoiceLine?${searchParams.toString()}`,
              );
            }
          }
        }
      },
      eventName: "select",
    },
  ];

  return (
    <ChartWidget
      actions={<ChartDateFilterButton hasAllTime configKey="popularProducts" />}
      hasNoData={!isFetching && !data?.length}
      id="popularProducts"
      isLoading={isFetching}
      title="Most Popular Products"
    >
      <Chart
        chartEvents={chartEvents}
        chartType="BarChart"
        chartVersion={GOOGLE_CHART_VERSION}
        diffdata={{
          new: newChartData,
          old: oldChartData,
        }}
        graphID="popularProducts"
        height="100%"
        options={{
          colors: CHART_COLORS,
          diff: {
            newData: { widthFactor: 0.4 },
          },
          hAxis: { format: "###,###,###" },
          responsive: true,
        }}
        width="100%"
      />
    </ChartWidget>
  );
};
export default MostPopularProducts;
