import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import { styled } from "@mui/material";

export const LoadingButton = styled(MuiLoadingButton)(({
  theme,
  variant,
  color = "primary",
  size = "small",
}) => {
  const commonStyle = {
    ...(size === "small" && {
      fontSize: theme.spacing(1.75),
    }),
    ...(size === "medium" && {
      fontSize: theme.spacing(2),
    }),
    "&:hover, &:active": {
      boxShadow: "none",
    },
    borderRadius: theme.spacing(0.25),
    boxShadow: "none",
    fontWeight: 400,
    height: theme.spacing(4.5),
    minWidth: theme.spacing(4),
    padding: `0 ${theme.spacing(2)}`,
    textTransform: "none" as const,
  };

  if (variant === "contained") {
    if (color === "primary") {
      return {
        ...commonStyle,
        "&.Mui-disabled": {
          background: theme.palette[color].main,
          color: theme.palette.white,
          opacity: 0.6,
        },
        color: theme.palette.white,
      };
    }
  }

  if (variant === "outlined") {
    if (color === "primary") {
      return {
        ...commonStyle,
        "&.Mui-disabled": {
          background: "#F8F9FA",
          borderColor: theme.palette.grey["400"],
          color: theme.palette.grey["500"],
        },
        "&:hover": {
          background: "#F8F9FA",
          borderColor: theme.palette.grey["700"],
          color: theme.palette.grey["800"],
        },
        background: "#F8F9FA",
        border: `1px solid ${theme.palette.grey["400"]}`,
        color: theme.palette.grey["900"],
      };
    }

    if (color === "error") {
      return {
        ...commonStyle,
        "&.Mui-disabled": {
          background: theme.palette.red["400"],
          borderColor: theme.palette.grey["400"],
          color: theme.palette.white,
        },
        "&:hover": {
          background: theme.palette.red["400"],
          borderColor: theme.palette.grey["700"],
          color: theme.palette.white,
        },
        background: theme.palette.red["600"],
        border: `1px solid ${theme.palette.red["700"]}`,
        color: theme.palette.white,
      };
    }
  }

  return commonStyle;
});
