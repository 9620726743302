import { useEffect, useState } from "react";
import { GlobalInvoiceLinesResourceService } from "../../api";
import { useCookiesLive } from "../../hooks";
import { ImportWizardColumnDef } from "../../types";
import { GlobalInvoiceLineDto } from "../../types/GlobalInvoiceLine";
import { generateGlobalInvoiceLineResourceEntity } from "../../utils/globalInvoiceLines";
import {
  ImportWizardContextProvider,
  useImportWizardContext,
} from "../ImportWizard/ImportWizardContext";
import { Import, MapFields, SelectFile } from "../ImportWizard/modals";
import ImportResult, { GlobalInvoiceLineDtoWithError } from "./ImportResult";

enum ImportGlobalInvoiceLinesStep {
  SelectFile = "SelectFile",
  MapFields = "MapFields",
  Import = "Import",
  ImportResult = "ImportResult",
}

const columnDefs: ImportWizardColumnDef[] = [
  {
    label: "PO",
    name: "custPo",
    required: true,
    suggestions: ["PO Number"],
  },
  {
    label: "Order Number",
    name: "orderNum",
    required: true,
    suggestions: ["Order Number"],
  },
  {
    label: "Order Date",
    name: "orderDate",
    required: true,
    suggestions: ["Order Date"],
  },
  {
    label: "Line Number",
    name: "lineNumber",
    required: true,
    suggestions: ["Line Number"],
  },
  {
    label: "Supplier Name",
    name: "suppName",
    required: false,
    suggestions: ["Supplier Name"],
  },
  {
    label: "Invoice Date",
    name: "invoiceDate",
    required: false,
    suggestions: ["Invoice Date"],
  },
  {
    label: "Manufacturer",
    name: "manufacturerName",
    required: false,
    suggestions: ["Manufacturer", "Mfg Name"],
  },
  {
    label: "Manufacturer Part",
    name: "manufacturerPart",
    required: true,
    suggestions: ["Manufacturer Part", "Mfg Part"],
  },
  {
    label: "Category",
    name: "category",
    required: false,
    suggestions: ["Category"],
  },
  {
    label: "Description",
    name: "description",
    required: false,
    suggestions: ["Description"],
  },
  {
    label: "Serial Number",
    name: "serialNumber",
    required: false,
    suggestions: ["Serial Number"],
  },
  {
    label: "Address",
    name: "address",
    required: false,
    suggestions: ["Shipping Address 1"],
  },
  {
    label: "Address 2",
    name: "address2",
    required: false,
    suggestions: ["Shipping Address 2"],
  },
  {
    label: "City",
    name: "city",
    required: false,
    suggestions: ["Shipping City"],
  },
  {
    label: "State/Province/Region",
    name: "stateOrProvince",
    required: false,
    suggestions: ["Shipping State_Province"],
  },
  {
    label: "Zip Postal Code",
    name: "postalCode",
    required: false,
    suggestions: ["Postal Code"],
  },
  {
    label: "Country",
    name: "countryCode",
    required: true,
    suggestions: ["Shipping Country"],
  },
  {
    label: "Item Price (USD)",
    name: "unitPriceUsd",
    required: true,
    suggestions: ["Item Price"],
  },
];

const insertGlobalInvoiceLines = async (
  globalInvoiceLine: GlobalInvoiceLineDto,
  accountId: number | undefined,
) => {
  try {
    await GlobalInvoiceLinesResourceService.createGlobalInvoiceLineUsingPost(
      {
        accountId: accountId,
        category: globalInvoiceLine.category,
        custPo: globalInvoiceLine.custPo,
        description: globalInvoiceLine.description,
        invoiceDate: globalInvoiceLine.invoiceDate,
        lineNumber: globalInvoiceLine.lineNumber,
        mfgName: globalInvoiceLine.mfgName,
        mfgPart: globalInvoiceLine.manufacturerPart,
        orderDate: globalInvoiceLine.orderDate,
        orderNum: globalInvoiceLine.orderNum as number,
        serialNumber: globalInvoiceLine.serialNumber,
        shippingAddress: {
          address: globalInvoiceLine.shippingAddress?.address,
          address2: globalInvoiceLine.shippingAddress?.address2,
          city: globalInvoiceLine.shippingAddress?.city,
          countryCode: globalInvoiceLine.shippingAddress?.countryCode,
          postalCode: globalInvoiceLine.shippingAddress?.postalCode,
          stateOrProvince: globalInvoiceLine.shippingAddress?.stateOrProvince,
        },
        suppName: globalInvoiceLine.suppName,
        unitPriceUsd: globalInvoiceLine.unitPriceUsd as number,
      },
      accountId ? +accountId : undefined,
    );
    return {
      success: true,
    };
  } catch (err) {
    return {
      error: (err as Error).message,
      success: false,
    };
  }
};

const ImportGlobalInvoiceLinesSteps = () => {
  const { step, setStep, onClose, progress } = useImportWizardContext();

  const [succeededGlobalLines, setSucceededGlobalLines] = useState<
    GlobalInvoiceLineDtoWithError[]
  >([]);
  const [failedGlobalLines, setFailedGlobalLines] = useState<
    GlobalInvoiceLineDtoWithError[]
  >([]);
  const [importFinished, setImportFinished] = useState(false);
  const [accountId] = useCookiesLive(["accountId"]);

  const handleImport = (globalInvoiceLines: GlobalInvoiceLineDto[]) => {
    setStep(ImportGlobalInvoiceLinesStep.Import);

    const promises = globalInvoiceLines.map((globalInvoiceLine) =>
      insertGlobalInvoiceLines(
        globalInvoiceLine,
        accountId ? +accountId : undefined,
      ),
    );

    Promise.all(promises).then((results) => {
      const succeededGlobalInvoiceLines = results
        .map((response, index) =>
          response?.success ? globalInvoiceLines[index] : null,
        )
        .filter(
          (globalInvoiceLine) => globalInvoiceLine,
        ) as GlobalInvoiceLineDtoWithError[];
      const failedGlobalInvoiceLines = results
        .map((response, index) =>
          response?.success
            ? null
            : { ...globalInvoiceLines[index], error: response?.error },
        )
        .filter(
          (globalInvoiceLine) => globalInvoiceLine,
        ) as GlobalInvoiceLineDtoWithError[];
      setSucceededGlobalLines(succeededGlobalInvoiceLines);
      setFailedGlobalLines(failedGlobalInvoiceLines);
      setImportFinished(true);
    });
  };

  useEffect(() => {
    if (importFinished && progress > 90) {
      setStep(ImportGlobalInvoiceLinesStep.ImportResult);
    }
  }, [importFinished, progress, setStep]);

  return (
    <>
      {step === ImportGlobalInvoiceLinesStep.SelectFile && (
        <SelectFile
          headline="Select an Excel or CSV file with Global Invoice Data"
          subHeadline="NOTE: Must include PO, Order Number, Order Date, Line Number, Manufacturer Part & Country."
          title="Import Global Invoice Lines"
          onNext={() => setStep(ImportGlobalInvoiceLinesStep.MapFields)}
        />
      )}
      {step === ImportGlobalInvoiceLinesStep.MapFields && (
        <MapFields
          columnDefs={columnDefs}
          generateEntity={generateGlobalInvoiceLineResourceEntity}
          title="Map Fields to Upload File"
          onNext={handleImport}
        />
      )}
      {step === ImportGlobalInvoiceLinesStep.Import && (
        <Import title="Importing Global Invoice Lines - do not close this window" />
      )}
      {step === ImportGlobalInvoiceLinesStep.ImportResult && (
        <ImportResult
          failedGlobalLines={failedGlobalLines}
          succeededGlobalLines={succeededGlobalLines}
          onClose={onClose}
        />
      )}
    </>
  );
};

const ImportGlobalLines = ({ onClose }: { onClose: () => void }) => {
  return (
    <ImportWizardContextProvider
      defaultStep={ImportGlobalInvoiceLinesStep.SelectFile}
      onClose={onClose}
    >
      <ImportGlobalInvoiceLinesSteps />
    </ImportWizardContextProvider>
  );
};

export default ImportGlobalLines;
