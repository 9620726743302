import { useQuery } from "@tanstack/react-query";
import { UsersResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useUserRights = (userId?: number) => {
  return useQuery(
    [QUERY_KEYS["user-rights"], userId],
    () =>
      userId
        ? UsersResourceService.getUserRightsByIdUsingGet(userId)
        : UsersResourceService.getUserRightsUsingGet(),
    { staleTime: 5000 },
  );
};
