import { useEffect, useState } from "react";
import { ProductsResourceService } from "../../api";
import { ImportWizardColumnDef, TaxExemptDto } from "../../types";
import { generateTaxExemptsEntity } from "../../utils/taxExempts";
import {
  ImportWizardContextProvider,
  useImportWizardContext,
} from "../ImportWizard/ImportWizardContext";
import { Import, MapFields, SelectFile } from "../ImportWizard/modals";
import ImportResult, { TaxExemptDtoWithError } from "./ImportResult";

enum ImportTaxExemptionsStep {
  SelectFile = "SelectFile",
  MapFields = "MapFields",
  Import = "Import",
  ImportResult = "ImportResult",
}

const columnDefs: ImportWizardColumnDef[] = [
  {
    label: "Manufacturer Name",
    name: "manufacturerName",
    required: true,
    suggestions: ["Mfg Name"],
  },
  {
    label: "Manufacturer Part",
    name: "manufacturerPart",
    required: true,
    suggestions: ["Mfg Part"],
  },
  {
    label: "Exempt States",
    name: "exemptStates",
    required: true,
    suggestions: ["States"],
  },
];

const updateExemptStates = async (taxExempt: TaxExemptDto) => {
  try {
    const product =
      await ProductsResourceService.getProductDetailsByMfgPartAndMfgNameUsingGet(
        taxExempt.manufacturerName!,
        taxExempt.manufacturerPart!,
      );
    if (!product.id) {
      throw new Error("Product not found");
    }
    await ProductsResourceService.updateProductUsingPatch(product.id, {
      taxExemptStates: taxExempt.exemptStates,
    });

    return {
      success: true,
    };
  } catch (err) {
    return {
      error: (err as Error).message,
      success: false,
    };
  }
};

const ImportTaxExemptionsSteps = () => {
  const { step, setStep, onClose, progress } = useImportWizardContext();

  const [succeededTaxExempts, setSucceededTaxExempts] = useState<
    TaxExemptDtoWithError[]
  >([]);
  const [failedTaxExempts, setFailedTaxExempts] = useState<
    TaxExemptDtoWithError[]
  >([]);
  const [importFinished, setImportFinished] = useState(false);

  const handleImport = (taxExempts: TaxExemptDto[]) => {
    setStep(ImportTaxExemptionsStep.Import);

    const promises = taxExempts.map((taxExempt) =>
      updateExemptStates(taxExempt),
    );

    Promise.all(promises).then((results) => {
      const succeededTaxExempts = results
        .map((response, index) => (response.success ? taxExempts[index] : null))
        .filter((taxExempt) => taxExempt) as TaxExemptDtoWithError[];
      const failedTaxExempts = results
        .map((response, index) =>
          response.success
            ? null
            : { ...taxExempts[index], error: response.error },
        )
        .filter((taxExempt) => taxExempt) as TaxExemptDtoWithError[];
      setSucceededTaxExempts(succeededTaxExempts);
      setFailedTaxExempts(failedTaxExempts);
      setImportFinished(true);
    });
  };

  useEffect(() => {
    if (importFinished && progress > 90) {
      setStep(ImportTaxExemptionsStep.ImportResult);
    }
  }, [importFinished, progress, setStep]);

  return (
    <>
      {step === ImportTaxExemptionsStep.SelectFile && (
        <SelectFile
          headline="Select an Excel or CSV file with Warranty Data"
          subHeadline="NOTE: Must include manufacturer name, manufacturer part & exempt states."
          title="Manage Tax Exempt Parts"
          onNext={() => setStep(ImportTaxExemptionsStep.MapFields)}
        />
      )}
      {step === ImportTaxExemptionsStep.MapFields && (
        <MapFields
          columnDefs={columnDefs}
          generateEntity={generateTaxExemptsEntity}
          title="Map Fields to Upload File"
          onNext={handleImport}
        />
      )}
      {step === ImportTaxExemptionsStep.Import && (
        <Import title="Importing Tax Exempt Parts … do not close this window" />
      )}
      {step === ImportTaxExemptionsStep.ImportResult && (
        <ImportResult
          failedTaxExempts={failedTaxExempts}
          succeededTaxExempts={succeededTaxExempts}
          onClose={onClose}
        />
      )}
    </>
  );
};

const ImportTaxExemptions = ({ onClose }: { onClose: () => void }) => {
  return (
    <ImportWizardContextProvider
      defaultStep={ImportTaxExemptionsStep.SelectFile}
      onClose={onClose}
    >
      <ImportTaxExemptionsSteps />
    </ImportWizardContextProvider>
  );
};

export default ImportTaxExemptions;
