import { createSvgIcon } from "@mui/material";

export const ChevronUpIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 16 11" xmlns="http://www.w3.org/2000/svg">
    <title>chevron-up</title>
    <g
      fill="none"
      fillRule="evenodd"
      id="Order-Line-Report"
      opacity="0.672147529"
      stroke="none"
      strokeWidth="1"
    >
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="Back-to-Top"
        transform="translate(-1279.000000, -1184.000000)"
      >
        <g id="Group-13" transform="translate(1259.000000, 1168.000000)">
          <g id="Group-14" transform="translate(20.000000, 13.000000)">
            <g id="chevron-bar-up" transform="translate(0.000000, 3.000000)">
              <path
                d="M1.76510226,9.99280207 C1.89798679,10.1260229 2.07841888,10.200894 2.26658397,10.200894 C2.45474905,10.200894 2.63518115,10.1260229 2.76806567,9.99280207 L7.93304389,4.82640724 L13.0980221,9.99280207 C13.3749828,10.2697628 13.8240248,10.2697628 14.1009855,9.99280207 C14.3779462,9.71584137 14.3779462,9.26679936 14.1009855,8.98983866 L8.43452559,3.32337874 C8.30164106,3.19015786 8.12120897,3.11528681 7.93304389,3.11528681 C7.7448788,3.11528681 7.56444671,3.19015786 7.43156218,3.32337874 L1.76510226,8.98983866 C1.63188138,9.12272318 1.55701033,9.30315528 1.55701033,9.49132036 C1.55701033,9.67948545 1.63188138,9.85991754 1.76510226,9.99280207 L1.76510226,9.99280207 Z M0,0.566645992 C0,0.878301287 0.254990696,1.13329198 0.566645992,1.13329198 L15.2994418,1.13329198 C15.6123917,1.13329198 15.8660878,0.879595932 15.8660878,0.566645992 C15.8660878,0.253696052 15.6123917,-8.32667268e-17 15.2994418,-8.32667268e-17 L0.566645992,-8.32667268e-17 C0.253696052,1.16573418e-15 0,0.253696052 0,0.566645992 L0,0.566645992 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "ChevronUp",
);
