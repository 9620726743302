import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { OrderLineSolrDto } from "../../api";
import { useOrderLine } from "../../hooks/api/queries";
import { Button } from "../ui";

const ReturnTrackingCodesModal = ({
  isOpen,
  orderLine,
  onClose,
}: {
  isOpen: boolean;
  orderLine: OrderLineSolrDto;
  onClose: () => void;
}) => {
  const { data } = useOrderLine(orderLine.orderLineId);

  return (
    <Dialog
      fullWidth
      data-testid="rtc-modal"
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle
        data-testid="rtc-modal-title"
        sx={(theme) => ({
          fontSize: theme.spacing(2.25),
          pb: 0,
          pt: 3.5,
          px: 2,
        })}
      >
        Return Tracking Codes For {orderLine.orderNum} | {orderLine.suppPart}
      </DialogTitle>
      <IconButton
        data-testid="rtc-modal-close-icon"
        sx={{
          color: (theme) => theme.palette.grey[900],
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent data-testid="rtc-modal-body" sx={{ py: 6 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  component="th"
                  sx={(theme) => ({
                    background: theme.palette.grey["50"],
                    fontWeight: 600,
                    px: 4,
                    py: 1,
                  })}
                >
                  SHIPPER
                </TableCell>
                <TableCell
                  component="th"
                  sx={(theme) => ({
                    background: theme.palette.grey["50"],
                    fontWeight: 600,
                    px: 4,
                    py: 1,
                  })}
                >
                  TRACKING CODE
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.returnLabels?.map((returnLabel, index) => (
                <TableRow
                  key={`${returnLabel.shipper}-${returnLabel.trackingCode}-${index}`}
                  sx={(theme) => ({
                    "&:hover": {
                      "& td.MuiTableCell-root": {
                        background: theme.palette.grey["100"],
                      },
                      cursor: "pointer",
                    },
                  })}
                  onClick={() =>
                    window.open(returnLabel.trackingUrl, "_blank")?.focus()
                  }
                >
                  <TableCell
                    sx={{
                      border: "none",
                      px: 4,
                      py: 1,
                    }}
                  >
                    {returnLabel.shipper}
                  </TableCell>
                  <TableCell
                    sx={(theme) => ({
                      border: "none",
                      color: theme.palette.blue["700"],
                      px: 4,
                      py: 1,
                    })}
                  >
                    {returnLabel.trackingCode}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions sx={{ pb: 2, pt: 0, px: 2 }}>
        <Button
          data-testid="rtc-modal-close-btn"
          sx={(theme) => ({ width: theme.spacing(12.5) })}
          variant="outlined"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReturnTrackingCodesModal;
