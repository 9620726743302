import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CSVLink } from "react-csv";
import { ClientResourceDto } from "../../types/ClientResource";
import { Button, VCenterBox } from "../ui";

export type ImportClientDtoWithError = ClientResourceDto & {
  error?: string;
};

const ImportResult = ({
  succeededClients,
  failedClients,
  onClose,
}: {
  succeededClients: ImportClientDtoWithError[];
  failedClients: ImportClientDtoWithError[];
  onClose: () => void;
}) => {
  return (
    <Dialog fullWidth open maxWidth="md" onClose={onClose}>
      <DialogTitle sx={{ px: 2, py: 4 }}>Imported Clients</DialogTitle>
      <IconButton
        sx={{
          color: (theme) => theme.palette.grey[900],
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ px: 2, py: 0 }}>
        {failedClients.length > 0 && (
          <VCenterBox
            sx={(theme) => ({
              background: theme.palette.red["200"],
              justifyContent: "space-between",
              mb: 2,
              px: 3,
              py: 1,
            })}
          >
            <Typography variant="body1">
              There were clients that did not successfully import
            </Typography>

            <CSVLink
              data={failedClients}
              filename="failed-client-resources.csv"
              headers={[
                { key: "crmClientCode", label: "CRM Client Code" },
                { key: "crm", label: "CRM" },
                { key: "erp", label: "ERP" },
                { key: "erpAccounts", label: "ERP Account Number(s)" },
                { key: "error", label: "Error" },
              ]}
            >
              <Button color="error" variant="contained">
                Download Import Failures
              </Button>
            </CSVLink>
          </VCenterBox>
        )}

        {succeededClients.length > 0 && (
          <>
            <Alert severity="success" sx={{ mb: 2 }}>
              The following Clients were successfully imported:
            </Alert>
            <TableContainer>
              <Table sx={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow
                    sx={(theme) => ({
                      background: theme.palette.grey["50"],
                      fontSize: theme.spacing(1.75),
                    })}
                  >
                    <TableCell
                      component="th"
                      sx={{ textTransform: "uppercase" }}
                    >
                      CRM Client Code
                    </TableCell>
                    <TableCell
                      component="th"
                      sx={{ textTransform: "uppercase" }}
                    >
                      CRM
                    </TableCell>
                    <TableCell
                      component="th"
                      sx={{ textTransform: "uppercase" }}
                    >
                      ERP
                    </TableCell>
                    <TableCell
                      component="th"
                      sx={{ textTransform: "uppercase" }}
                    >
                      ERP Account Number(s)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {succeededClients.map((client) => (
                    <TableRow key={`${client.crmClientCode}`}>
                      <TableCell>{client.crmClientCode}</TableCell>
                      <TableCell>{client.crm}</TableCell>
                      <TableCell>{client.erp}</TableCell>
                      <TableCell>{client.erpAccounts}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: "flex-end", pb: 3, px: 2 }}>
        <Button
          sx={(theme) => ({ width: theme.spacing(12.5) })}
          variant="outlined"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportResult;
