import { createSvgIcon } from "@mui/material";

export const DropdownIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 13 7" xmlns="http://www.w3.org/2000/svg">
    <title>dropdown</title>
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="main-nav"
        transform="translate(-912.000000, -268.000000)"
      >
        <g id="Group-16" transform="translate(29.000000, 254.000000)">
          <g id="select" transform="translate(546.000000, 0.000000)">
            <g id="chevron-down" transform="translate(337.000000, 14.000000)">
              <path
                d="M0.146893784,0.146893784 C0.240698047,0.052852083 0.368066523,0 0.500893784,0 C0.633721045,0 0.761089521,0.052852083 0.854893784,0.146893784 L6.50089378,5.79389378 L12.1468938,0.146893784 C12.2733658,0.0204217649 12.4577028,-0.028971181 12.6304668,0.0173207907 C12.8032308,0.0636127625 12.9381748,0.1985568 12.9844668,0.371320791 C13.0307587,0.544084781 12.9813658,0.728421765 12.8548938,0.854893784 L6.85489378,6.85489378 C6.76108952,6.94893548 6.63372104,7.00178757 6.50089378,7.00178757 C6.36806652,7.00178757 6.24069805,6.94893548 6.14689378,6.85489378 L0.146893784,0.854893784 C0.052852083,0.761089521 -4.4408921e-16,0.633721045 -4.4408921e-16,0.500893784 C-4.4408921e-16,0.368066523 0.052852083,0.240698047 0.146893784,0.146893784 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "Dropdown",
);
