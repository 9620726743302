import { createSvgIcon } from "@mui/material";

export const EyeSlashIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 16 13" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g fillRule="nonzero" id="pg4" transform="translate(-363, -143)">
        <g id="Group-31" transform="translate(38, 132)">
          <g id="eye-slash" transform="translate(325, 11)">
            <path
              d="M13.359,9.592 C15.06,8.074 16,6.354 16,6.354 C16,6.354 13,0.854 8,0.854 C7.03959254,0.857307033 6.09005417,1.0574248 5.21,1.442 L5.98,2.213 C6.62743511,1.97715438 7.31094807,1.85567856 8,1.854 C10.12,1.854 11.879,3.022 13.168,4.311 C13.7883541,4.93469072 14.3444799,5.61912749 14.828,6.354 C14.77,6.441 14.706,6.537 14.633,6.642 C14.298,7.122 13.803,7.762 13.168,8.397 C13.003,8.562 12.831,8.725 12.651,8.883 L13.359,9.592 L13.359,9.592 Z"
              fill="#000000"
              id="Path"
            ></path>
            <path
              d="M11.297,7.53 C11.7524992,6.25598079 11.432895,4.83353363 10.4761807,3.87681933 C9.51946637,2.92010503 8.09701921,2.60050076 6.823,3.056 L7.646,3.879 C8.42518973,3.76747241 9.21140321,4.0294256 9.76798881,4.58601119 C10.3245744,5.14259679 10.5865276,5.92881027 10.475,6.708 L11.297,7.53 Z M8.354,8.829 L9.176,9.651 C7.90198079,10.1064992 6.47953363,9.78689497 5.52281933,8.83018067 C4.56610503,7.87346637 4.24650076,6.45101921 4.702,5.177 L5.525,6 C5.41347241,6.77918973 5.6754256,7.56540321 6.23201119,8.12198881 C6.78859679,8.6785744 7.57481027,8.94052759 8.354,8.829 L8.354,8.829 Z"
              fill="#000000"
              id="Shape"
            ></path>
            <path
              d="M3.35,3.824 C3.17,3.984 2.997,4.146 2.832,4.311 C2.21164592,4.93469072 1.65552011,5.61912749 1.172,6.354 L1.367,6.642 C1.702,7.122 2.197,7.762 2.832,8.397 C4.121,9.686 5.881,10.854 8,10.854 C8.716,10.854 9.39,10.721 10.02,10.494 L10.79,11.266 C9.90993715,11.6505481 8.96040447,11.8506647 8,11.854 C3,11.854 0,6.354 0,6.354 C0,6.354 0.939,4.633 2.641,3.116 L3.349,3.825 L3.35,3.824 Z M13.646,12.708 L1.646,0.708 L2.354,0 L14.354,12 L13.646,12.708 Z"
              fill="#231F20"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "EyeSlash",
);
