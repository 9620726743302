import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { CircularProgress, Link, Menu, MenuItem } from "@mui/material";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { memo } from "react";
import { useUserData } from "../../hooks/api/queries";
import { getAppLink } from "../../utils/link";
import { Button, VCenterBox } from "../ui";

const ProfileMenu = memo(() => {
  const { data, isLoading } = useUserData();
  const popupState = usePopupState({
    popupId: "profileMenu",
    variant: "popover",
  });

  return isLoading ? (
    <CircularProgress size="sm" />
  ) : (
    <>
      <Button
        variant="text"
        {...bindTrigger(popupState)}
        disableRipple
        data-testid="profile-btn"
        size="medium"
        sx={{ color: "inherit", padding: 0 }}
      >
        <VCenterBox>
          {`${data?.firstName} ${data?.lastName}`}
          {popupState.isOpen ? (
            <ArrowDropUpIcon
              sx={(theme) => ({
                color: theme.palette.cyan["400"],
              })}
            />
          ) : (
            <ArrowDropDownIcon
              sx={(theme) => ({
                color: theme.palette.cyan["400"],
              })}
            />
          )}
        </VCenterBox>
      </Button>

      <Menu {...bindMenu(popupState)}>
        <MenuItem data-testid="profile-change-password">
          <Link
            color="inherit"
            href={getAppLink("/app/Password/reset")}
            underline="none"
          >
            Change Password
          </Link>
        </MenuItem>
        <MenuItem data-testid="profile-sign-out">
          <Link
            color="inherit"
            href={getAppLink("/app/Auth/logout")}
            underline="none"
          >
            Sign Out
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
});

export default ProfileMenu;
