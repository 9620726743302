/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackorderNoteReq } from '../models/BackorderNoteReq';
import type { BackorderNoteRes } from '../models/BackorderNoteRes';
import type { CustomLineNotes } from '../models/CustomLineNotes';
import type { InputStreamResource } from '../models/InputStreamResource';
import type { OrderLineDto } from '../models/OrderLineDto';
import type { OrderLinePatchRequestDto } from '../models/OrderLinePatchRequestDto';
import type { OrderLineRequestDto } from '../models/OrderLineRequestDto';
import type { OrderLineSolrDto } from '../models/OrderLineSolrDto';
import type { RestCollection_OrderLineSolrDto_ } from '../models/RestCollection_OrderLineSolrDto_';
import type { SwLicenseDto } from '../models/SwLicenseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OrderLinesResourceService {
  /**
   * Get Order Line list
   * Get an order line list
   * @param criteria criteria
   * @param page page
   * @param returnAllLabels returnAllLabels
   * @param returnPagination returnPagination
   * @param returnSavedViews returnSavedViews
   * @param size size
   * @param useDefaultView useDefaultView
   * @returns RestCollection_OrderLineSolrDto_ Success
   * @throws ApiError
   */
  public static listUsingGet11(
    criteria: string = '{}',
    page: number = 1,
    returnAllLabels: boolean = false,
    returnPagination: boolean = true,
    returnSavedViews: boolean = true,
    size: number = 50,
    useDefaultView: boolean = false,
  ): CancelablePromise<RestCollection_OrderLineSolrDto_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/listing/order_lines',
      query: {
        'criteria': criteria,
        'page': page,
        'returnAllLabels': returnAllLabels,
        'returnPagination': returnPagination,
        'returnSavedViews': returnSavedViews,
        'size': size,
        'useDefaultView': useDefaultView,
      },
      errors: {
        401: `Unauthorized`,
        403: `You are not authorized to access this account.`,
        404: `Order Line list not found.`,
        500: `Internal Error retrieving order line list`,
      },
    });
  }
  /**
   * POST Order Line
   * POST Order Line
   * @param orderLineRequestDto orderLineRequestDto
   * @returns OrderLineDto Success
   * @throws ApiError
   */
  public static createOrderLineUsingPost(
    orderLineRequestDto: OrderLineRequestDto,
  ): CancelablePromise<Array<OrderLineDto>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/orderLines',
      body: orderLineRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Order Line Not Found`,
        409: `Product is already present`,
        500: `Internal Error Creating Order Line`,
      },
    });
  }
  /**
   * getOrderLine
   * @param orderLineId orderLineId
   * @returns OrderLineDto Success
   * @throws ApiError
   */
  public static getOrderLineUsingGet(
    orderLineId: number,
  ): CancelablePromise<OrderLineDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/orderLines/{orderLineId}',
      path: {
        'orderLineId': orderLineId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Order Line Not Found`,
        500: `Internal Error Getting Order line`,
      },
    });
  }
  /**
   * Delete Order Line
   * Delete Order Line
   * @param orderLineId orderLineId
   * @returns any Success
   * @throws ApiError
   */
  public static deleteOrderLineUsingDelete(
    orderLineId: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/service/rest/orderLines/{orderLineId}',
      path: {
        'orderLineId': orderLineId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Order Line Not Found`,
        500: `Internal Error Deleting Order Line`,
      },
    });
  }
  /**
   * Patch Order Line  by order line ID
   * Patch Order Line End User license
   * @param orderLineId orderLineId
   * @param requestDto requestDto
   * @returns OrderLineDto Success
   * @throws ApiError
   */
  public static updateOrderLineUsingPatch(
    orderLineId: number,
    requestDto: OrderLinePatchRequestDto,
  ): CancelablePromise<OrderLineDto> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/orderLines/{orderLineId}',
      path: {
        'orderLineId': orderLineId,
      },
      body: requestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Order Line Not Found`,
        500: `Internal Error Getting Order Line`,
      },
    });
  }
  /**
   * Export Order Line License
   * Export Order Line License
   * @param orderLineId orderLineId
   * @returns InputStreamResource Success
   * @throws ApiError
   */
  public static exportLicenseUsingGet(
    orderLineId: number,
  ): CancelablePromise<InputStreamResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/orderLines/{orderLineId}/license/export',
      path: {
        'orderLineId': orderLineId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `You are not authorized to access this Order Line License`,
        404: `Order line license {orderLineId} not found`,
        500: `Internal Error exporting order line license`,
      },
    });
  }
  /**
   * getOrderLines
   * @param accountCode accountCode
   * @param mfgName mfgName
   * @param mfgPart mfgPart
   * @param orderNum orderNum
   * @param suppCode suppCode
   * @param suppPart suppPart
   * @returns OrderLineSolrDto OK
   * @throws ApiError
   */
  public static getOrderLinesUsingGet(
    accountCode?: string,
    mfgName?: string,
    mfgPart?: string,
    orderNum: number = -1,
    suppCode?: string,
    suppPart?: string,
  ): CancelablePromise<Array<OrderLineSolrDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/order_lines',
      query: {
        'accountCode': accountCode,
        'mfgName': mfgName,
        'mfgPart': mfgPart,
        'orderNum': orderNum,
        'suppCode': suppCode,
        'suppPart': suppPart,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Het Order Line backorder notes
   * Get Order Line backorder notes
   * @param orderNum orderNum
   * @param suppCode suppCode
   * @param suppPart suppPart
   * @returns BackorderNoteRes Success
   * @throws ApiError
   */
  public static listBackorderNotesUsingGet(
    orderNum: number,
    suppCode: string,
    suppPart: string,
  ): CancelablePromise<Array<BackorderNoteRes>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/order_lines/backorder_notes',
      query: {
        'orderNum': orderNum,
        'suppCode': suppCode,
        'suppPart': suppPart,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Order Line Not Found`,
        500: `Internal Error Getting getting back order notes.`,
      },
    });
  }
  /**
   * @deprecated
   * Post Order Line backorder notes
   * DEPRECATED Post Order Line backorder notes. Please use PATCH /service/rest/orderLines/{orderLineId}
   * @param backorderNote backorderNote
   * @returns BackorderNoteRes Success
   * @throws ApiError
   */
  public static createBackorderNoteUsingPost(
    backorderNote: BackorderNoteReq,
  ): CancelablePromise<BackorderNoteRes> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/order_lines/backorder_notes',
      body: backorderNote,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Order Line Not Found`,
        500: `Internal Error Getting saving back order notes.`,
      },
    });
  }
  /**
   * readBackorderNote
   * @param id id
   * @returns BackorderNoteRes OK
   * @throws ApiError
   */
  public static readBackorderNoteUsingGet(
    id: number,
  ): CancelablePromise<BackorderNoteRes> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/order_lines/backorder_notes/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @deprecated
   * Patch Order Line backorder notes
   * DEPRECATED Patch Order Line backorder notes. Please use PATCH /service/rest/orderLines/{orderLineId}
   * @param backorderNote backorderNote
   * @param id id
   * @returns BackorderNoteRes Success
   * @throws ApiError
   */
  public static updateBackorderNoteUsingPatch(
    backorderNote: BackorderNoteReq,
    id: number,
  ): CancelablePromise<BackorderNoteRes> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/order_lines/backorder_notes/{id}',
      path: {
        'id': id,
      },
      body: backorderNote,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Order Line Not Found`,
        500: `Internal Error Getting Patching back order notes.`,
      },
    });
  }
  /**
   * getCustomNotesForOrderNumberSuppCodeAndSuppPart
   * @param orderNum orderNum
   * @param suppCode suppCode
   * @param suppPart suppPart
   * @returns CustomLineNotes OK
   * @throws ApiError
   */
  public static getCustomNotesForOrderNumberSuppCodeAndSuppPartUsingGet(
    orderNum: number,
    suppCode: string,
    suppPart: string,
  ): CancelablePromise<CustomLineNotes> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/order_lines/custom_notes',
      query: {
        'orderNum': orderNum,
        'suppCode': suppCode,
        'suppPart': suppPart,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * updateCustomNotes
   * @param customLineNotes customLineNotes
   * @param orderNum orderNum
   * @param suppCode suppCode
   * @param suppPart suppPart
   * @returns CustomLineNotes OK
   * @throws ApiError
   */
  public static updateCustomNotesUsingPatch(
    customLineNotes: CustomLineNotes,
    orderNum: number,
    suppCode: string,
    suppPart: string,
  ): CancelablePromise<CustomLineNotes> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/order_lines/custom_notes',
      query: {
        'orderNum': orderNum,
        'suppCode': suppCode,
        'suppPart': suppPart,
      },
      body: customLineNotes,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * Get Licenses
   * Get Licenses
   * @param orderNum orderNum
   * @param suppCode suppCode
   * @param suppPart suppPart
   * @returns SwLicenseDto Success
   * @throws ApiError
   */
  public static listSwLicensesUsingGet(
    orderNum: number,
    suppCode: string,
    suppPart: string,
  ): CancelablePromise<Array<SwLicenseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/order_lines/software_licenses',
      query: {
        'orderNum': orderNum,
        'suppCode': suppCode,
        'suppPart': suppPart,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
      },
    });
  }
}
