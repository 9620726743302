import { useEffect, useState } from "react";
import { StaffListing } from "../../api";
import StaffChangePasswordModal from "../modals/StaffChangePasswordModal";

const StaffChangePasswordWrapper = () => {
  const [selectedStaff, setSelectedStaff] = useState<StaffListing>();

  useEffect(() => {
    document.addEventListener(
      "selectStaffForChangeStaffPassword",
      (event: any) => {
        const entity = event.detail as StaffListing;
        setSelectedStaff(entity);
      },
    );
  }, []);

  return (
    <>
      {selectedStaff && (
        <StaffChangePasswordModal
          isOpen
          staff={selectedStaff}
          onClose={() => setSelectedStaff(undefined)}
        />
      )}
    </>
  );
};

export default StaffChangePasswordWrapper;
