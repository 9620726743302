/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExternalLicenseDto } from '../models/ExternalLicenseDto';
import type { ExternalLicenseResponseDto } from '../models/ExternalLicenseResponseDto';
import type { PatchExternalLicenseDto } from '../models/PatchExternalLicenseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ExternalLicenseResourceService {
  /**
   * GET External License List
   * GET External License List
   * @param accountId accountId
   * @param manufacturerProductId manufacturerProductId
   * @returns ExternalLicenseResponseDto Success
   * @throws ApiError
   */
  public static getExternalLicenseListUsingGet(
    accountId?: number,
    manufacturerProductId?: number,
  ): CancelablePromise<Array<ExternalLicenseResponseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/externalLicenses',
      query: {
        'accountId': accountId,
        'manufacturerProductId': manufacturerProductId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting External Licenses`,
      },
    });
  }
  /**
   * POST External License
   * POST External License
   * @param postRequestDto postRequestDto
   * @param accountId accountId
   * @returns ExternalLicenseResponseDto Success
   * @throws ApiError
   */
  public static createUsingPost3(
    postRequestDto: ExternalLicenseDto,
    accountId?: number,
  ): CancelablePromise<ExternalLicenseResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/externalLicenses',
      query: {
        'accountId': accountId,
      },
      body: postRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        409: `External License is already present`,
        500: `Internal Error Creating External License`,
      },
    });
  }
  /**
   * GET External License
   * GET External License
   * @param id id
   * @returns ExternalLicenseResponseDto OK
   * @throws ApiError
   */
  public static getUsingGet1(
    id: number,
  ): CancelablePromise<ExternalLicenseResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/externalLicenses/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error Getting External License`,
      },
    });
  }
  /**
   * DELETE External License
   * DELETE External License
   * @param id id
   * @returns any OK
   * @throws ApiError
   */
  public static deleteUsingDelete2(
    id: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/service/rest/externalLicenses/{id}',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error Deleting External License`,
      },
    });
  }
  /**
   * PATCH External License
   * PATCH External License
   * @param id id
   * @param patchRequestDto patchRequestDto
   * @returns ExternalLicenseResponseDto OK
   * @throws ApiError
   */
  public static updateUsingPatch2(
    id: number,
    patchRequestDto: PatchExternalLicenseDto,
  ): CancelablePromise<ExternalLicenseResponseDto> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/externalLicenses/{id}',
      path: {
        'id': id,
      },
      body: patchRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error Patching External License`,
      },
    });
  }
}
