import { useEffect, useMemo, useRef } from "react";
import { Chart, ReactGoogleChartEvent } from "react-google-charts";
import { GOOGLE_CHART_VERSION } from "../../constants/chat";
import { usePurchaseAnalysisContext } from "../../contexts/PurchaseAnalysisContext";
import { useCookiesLive } from "../../hooks";
import { useSalesByState } from "../../hooks/api/queries";
import { PurchaseAnalysisChartOption1 } from "../../types";
import { formatCurrency } from "../../utils/currency";
import { getAppLink } from "../../utils/link";
import ChartDateFilterButton from "./ChartDateFilterButton";
import ChartWidget from "./ChartWidget";

const SpendByState = () => {
  const [accountCode] = useCookiesLive(["accountCode"]);
  const { config } = usePurchaseAnalysisContext();
  const chartConfig =
    config.spendByState as PurchaseAnalysisChartOption1 | null;
  const { data, isFetching } = useSalesByState(
    chartConfig?.startDate,
    chartConfig?.endDate,
    accountCode,
  );
  const chartConfigRef = useRef<PurchaseAnalysisChartOption1 | null>(null);

  useEffect(() => {
    chartConfigRef.current = chartConfig;
  }, [chartConfig]);

  const chartData = useMemo(
    () => [
      ["State", "Spend"],
      ...(data?.map((value: any) => [
        { f: value.state, v: `US-${value.state}` },
        { f: formatCurrency(value.sales, 0), v: value.sales },
      ]) ?? []),
    ],
    [data],
  );

  const chartEvents: ReactGoogleChartEvent[] = [
    {
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper?.getChart();
        const selection = chart?.getSelection();

        if (selection && selection.length === 1) {
          const [selectedItem] = selection;
          const dataTable = chartWrapper?.getDataTable();
          const { row, column } = selectedItem;
          const value = dataTable?.getValue(row ?? 0, column ?? 0) as string;

          if (value) {
            const searchParams = new URLSearchParams();
            searchParams.set(
              "params",
              JSON.stringify({
                d: 1,
                disp: [
                  "reserved_select_row",
                  "invoiceNum",
                  "custPo",
                  "invoiceDate",
                  "orderNum",
                  "reference",
                  "subtotal",
                  "shipAndHand",
                  "salesTax",
                  "fees",
                  "total",
                  "balance",
                  "state",
                  "reserved_actions",
                ],
                fs: {
                  invoiceDate: {
                    end: chartConfigRef.current?.endDate,
                    start: chartConfigRef.current?.startDate,
                  },
                  state: [value.split("-")[1]],
                },
                fs_clear: false,
                o: "invoiceNum",
                p: "1",
                r: "50",
              }),
            );
            window.location.href = getAppLink(
              `app/Invoice?${searchParams.toString()}`,
            );
          }
        }
      },
      eventName: "select",
    },
  ];

  return (
    <ChartWidget
      actions={<ChartDateFilterButton configKey="spendByState" />}
      hasNoData={!isFetching && !data?.length}
      id="spendByState"
      isLoading={isFetching}
      title="Spend By State"
    >
      <Chart
        chartEvents={chartEvents}
        chartType="GeoChart"
        chartVersion={GOOGLE_CHART_VERSION}
        data={chartData}
        graphID="spendByState"
        height="100%"
        options={{
          colorAxis: { colors: ["#77C270", "#839AA2", "#B6B4DE"] },
          datalessRegionColor: "#DB7F7F",
          defaultColor: "#F0B848",
          displayMode: "regions",
          region: "US",
          resolution: "provinces",
        }}
        width="100%"
      />
    </ChartWidget>
  );
};
export default SpendByState;
