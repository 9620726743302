import download from "downloadjs";
import { useEffect } from "react";
import { useQuoteExport } from "../../hooks/api/mutations";

const ExportQuoteWrapper = () => {
  const { mutate: exportQuote } = useQuoteExport();

  useEffect(() => {
    const handleExportQuoteEvent = (event: Event) => {
      const quoteId = (event as CustomEvent).detail;
      if (quoteId) {
        exportQuote(quoteId, {
          onSuccess: ({ data }) => {
            download(data, `Quote#${quoteId}.pdf`, "application/pdf");
          },
        });
      }
    };

    document.addEventListener("exportQuote", handleExportQuoteEvent);

    return () =>
      document.removeEventListener("exportQuote", handleExportQuoteEvent);
  }, [exportQuote]);

  return null;
};

export default ExportQuoteWrapper;
