import { Box, Link, Popover, Stack, styled, Typography } from "@mui/material";
import { bindTrigger, bindPopover } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import { useSnackbar } from "notistack";
import { KeyboardEvent, memo, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDebounce } from "use-debounce";
import { AccountDetailsDto, AccountSuggestResponseDto } from "../../api";
import {
  ACCOUNT_CREDIT_CACHE_COOKIE_NAME,
  SALES_SUMMARY_SELECTED_ACCOUNT_CACHE_COOKIE_NAME,
} from "../../constants";
import { ACCOUNT_CHANGED_EVENT } from "../../constants/events";
import { useCookiesLive } from "../../hooks";
import { useAccountSwitch } from "../../hooks/api/mutations";
import { useAccount, useSuggestedAccounts } from "../../hooks/api/queries";
import { useGlobalState } from "../../hooks/store/base";
import { getAppLink } from "../../utils/link";
import { splitStringWithoutCase } from "../../utils/string";
import { SettingsIcon } from "../icons";
import {
  Button,
  CenterBox,
  IconButton,
  OverflowTooltip,
  TextField,
} from "../ui";

const AccountLogoImg = styled("img")(() => ({
  height: "100%",
  width: "auto",
}));
const SelectedAccountLogoImg = styled("img")(() => ({
  height: "auto",
  width: "100%",
}));

const SELECTOR_TRIGGER_WIDTH = 182;

const AccountSelector = memo(() => {
  const [, setAccount] = useGlobalState("account");
  const { enqueueSnackbar } = useSnackbar();
  const [, setCookie, removeCookie] = useCookies([
    "accountCode",
    "accountId",
    ACCOUNT_CREDIT_CACHE_COOKIE_NAME,
    SALES_SUMMARY_SELECTED_ACCOUNT_CACHE_COOKIE_NAME,
  ]);
  const [accountCode] = useCookiesLive(["accountCode"]);

  const accountSelectorPopupState = usePopupState({
    popupId: "accountSelectorPopover",
    variant: "popover",
  });
  const accountDetailsPopupState = usePopupState({
    popupId: "accountDetailsPopover",
    variant: "popover",
  });

  const [isSearchInputVisible, setIsSearchInputVisible] = useState(false);
  const [isAccountDetailsPopoverOpened, setIsAccountDetailsPopoverOpened] =
    useState(false);

  const [term, setTerm] = useState("");
  const [selectedAccount, setSelectedAccount] =
    useState<AccountSuggestResponseDto>();
  const [focusedAccount, setFocusedAccount] =
    useState<AccountSuggestResponseDto>();

  const [debouncedTerm] = useDebounce(term, 500);
  const { mutate: switchAccount } = useAccountSwitch();
  const { data: suggestedAccounts } = useSuggestedAccounts(debouncedTerm);
  const { data: accountDetails } = useAccount(
    selectedAccount?.accountCode || accountCode,
  );

  const handleClearAccount = useCallback(() => {
    setIsSearchInputVisible(false);
    switchAccount(null);
    setIsAccountDetailsPopoverOpened(false);
    setTerm("");
    setSelectedAccount(undefined);
    setFocusedAccount(undefined);
    removeCookie("accountCode");
    removeCookie("accountId");
    removeCookie(SALES_SUMMARY_SELECTED_ACCOUNT_CACHE_COOKIE_NAME);
    removeCookie(ACCOUNT_CREDIT_CACHE_COOKIE_NAME);
    window.dispatchEvent(
      new CustomEvent(ACCOUNT_CHANGED_EVENT, { detail: null }),
    );
  }, [removeCookie, switchAccount]);

  const handleAccountSelect = useCallback(
    (account: AccountSuggestResponseDto) => {
      setIsSearchInputVisible(false);

      if (account.accountCode) {
        switchAccount(account.accountCode, {
          onSettled(response, error) {
            if (error || response?.data?.errorMessage) {
              enqueueSnackbar({
                message: error
                  ? "Failed to change account."
                  : response?.data?.errorMessage,
                variant: "error",
              });
              return;
            }

            setSelectedAccount(account);
            setCookie("accountCode", account.accountCode);
            setCookie("accountId", account.accountId);
            window.dispatchEvent(
              new CustomEvent(ACCOUNT_CHANGED_EVENT, {
                detail: account.accountCode,
              }),
            );
          },
        });
      }
    },
    [setCookie, switchAccount, enqueueSnackbar],
  );

  const handleInputKeydown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (suggestedAccounts?.length) {
        if (e.key === "ArrowDown") {
          e.preventDefault();
          setFocusedAccount((prev) =>
            prev
              ? suggestedAccounts[
                  Math.min(
                    suggestedAccounts.findIndex(
                      (account) => account.accountCode === prev.accountCode,
                    ) + 1,
                    suggestedAccounts.length - 1,
                  )
                ]
              : suggestedAccounts[0],
          );
        }
        if (e.key === "ArrowUp") {
          e.preventDefault();
          setFocusedAccount((prev) =>
            prev
              ? suggestedAccounts[
                  Math.max(
                    suggestedAccounts.findIndex(
                      (account) => account.accountCode === prev.accountCode,
                    ) - 1,
                    0,
                  )
                ]
              : suggestedAccounts[suggestedAccounts.length - 1],
          );
        }

        if (e.key === "Escape") {
          setIsSearchInputVisible(false);
          setFocusedAccount(undefined);
          setTerm("");
        }

        if (e.key === "Enter" && focusedAccount) {
          handleAccountSelect(focusedAccount);
          setFocusedAccount(undefined);
        }
      }
    },
    [focusedAccount, handleAccountSelect, suggestedAccounts],
  );

  const handleInputBlur = useCallback(() => {
    if (!term) {
      setIsSearchInputVisible(false);
    }
  }, [term]);

  useEffect(() => {
    setAccount(accountDetails as AccountDetailsDto);
  }, [accountDetails, setAccount]);

  const accountDetailsName = accountDetails
    ? `(${(accountDetails as any)?.accountCode}) ${accountDetails?.companyName}`
    : "";

  return (
    <Box>
      {accountDetails ? (
        <>
          <Box
            data-testid="account-selector-selected-account-logo"
            sx={{ width: SELECTOR_TRIGGER_WIDTH }}
          >
            {accountDetails.logoUrl ? (
              <SelectedAccountLogoImg src={accountDetails.logoUrl} />
            ) : (
              <CenterBox
                sx={{
                  background: (theme) => theme.palette.grey["50"],
                  height: 48,
                }}
              >
                <Typography component="span" variant="body2">
                  No Logo
                </Typography>
              </CenterBox>
            )}
          </Box>
          <Box sx={{ alignItems: "center", display: "flex", gap: 1, mt: 1 }}>
            <OverflowTooltip title={accountDetailsName}>
              <Box
                sx={{
                  width: SELECTOR_TRIGGER_WIDTH,
                }}
              >
                <Typography
                  component="span"
                  data-testid="account-selector-selected-account-name"
                  variant="body2"
                >
                  {accountDetailsName}
                </Typography>
              </Box>
            </OverflowTooltip>

            <IconButton
              {...bindTrigger(accountDetailsPopupState)}
              data-testid="account-selector-selected-account-edit-btn"
              size="small"
              onClick={(e) => {
                bindTrigger(accountDetailsPopupState).onClick(e);
                setIsAccountDetailsPopoverOpened(true);
              }}
            >
              <SettingsIcon sx={{ height: 16, width: 16 }} />
            </IconButton>

            <Popover
              {...bindPopover(accountDetailsPopupState)}
              disableAutoFocus
              anchorOrigin={{
                horizontal: "left",
                vertical: "bottom",
              }}
              open={
                isAccountDetailsPopoverOpened &&
                bindPopover(accountDetailsPopupState).open
              }
              transformOrigin={{
                horizontal: "left",
                vertical: "top",
              }}
            >
              <Box sx={{ px: 2, py: 2.5 }}>
                <Box
                  sx={{ alignItems: "center", display: "flex", gap: 2, mb: 2 }}
                >
                  <Typography
                    component="div"
                    data-testid="account-selector-selected-account-name"
                    fontWeight={600}
                    sx={{ color: (theme) => theme.palette.grey["800"] }}
                    variant="body2"
                  >
                    {accountDetailsName}
                  </Typography>
                  <Button
                    data-testid="account-selector-selected-account-clear-btn"
                    sx={{
                      "&:hover span": { textDecoration: "underline" },
                    }}
                    variant="text"
                    onClick={handleClearAccount}
                  >
                    <Typography
                      component="span"
                      fontWeight={500}
                      sx={{ color: (theme) => theme.palette.grey["600"] }}
                      variant="caption"
                    >
                      CLEAR ACCOUNT
                    </Typography>
                  </Button>
                </Box>
                <Link
                  data-testid="account-selector-selected-account-customization"
                  href={getAppLink("/app/Customization")}
                  underline="none"
                >
                  <Typography
                    component="span"
                    sx={{ color: (theme) => theme.palette.grey["800"] }}
                    variant="body2"
                  >
                    Manage Customization Settings
                  </Typography>
                </Link>
              </Box>
            </Popover>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{ width: SELECTOR_TRIGGER_WIDTH }}
            {...bindTrigger(accountSelectorPopupState)}
          >
            {isSearchInputVisible ? (
              <TextField
                autoFocus
                fullWidth
                size="medium"
                onBlur={handleInputBlur}
                onChange={(e) => setTerm(e.target.value)}
                onKeyDown={handleInputKeydown}
              />
            ) : (
              <Button
                fullWidth
                size="medium"
                variant="outlined"
                onClick={() => setIsSearchInputVisible(true)}
              >
                Select Account
              </Button>
            )}
          </Box>

          <Popover
            {...bindPopover(accountSelectorPopupState)}
            disableAutoFocus
            anchorOrigin={{
              horizontal: "left",
              vertical: "bottom",
            }}
            open={Boolean(
              isSearchInputVisible &&
                suggestedAccounts?.length &&
                bindPopover(accountSelectorPopupState).open,
            )}
            transformOrigin={{
              horizontal: "left",
              vertical: "top",
            }}
          >
            {suggestedAccounts?.length ? (
              <Stack
                data-testid="account-selector-accounts-list"
                spacing={0.5}
                sx={{ py: 1.5 }}
              >
                {suggestedAccounts.map((account) => (
                  <Button
                    key={account.accountId}
                    sx={{
                      "&:hover": {
                        background: (theme) => theme.palette.grey["50"],
                      },
                      background:
                        account.accountId === focusedAccount?.accountId
                          ? (theme) => theme.palette.grey["50"]
                          : "transparent",
                    }}
                    variant="text"
                    onClick={() => handleAccountSelect(account)}
                    onMouseEnter={() => setFocusedAccount(account)}
                  >
                    <Box
                      data-testid={`account-selector-account-${account.accountCode?.toLowerCase()}`}
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        gap: (theme) => theme.spacing(1),
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <CenterBox
                        data-testid="account-selector-account-logo"
                        sx={{
                          height: 24,
                          width: 90,
                        }}
                      >
                        {account.logoUrl ? (
                          <AccountLogoImg src={account.logoUrl} />
                        ) : (
                          <Box
                            sx={{
                              background: (theme) => theme.palette.grey["50"],
                              py: 0.5,
                              width: "100%",
                            }}
                          >
                            <Typography component="span" variant="body2">
                              No Logo
                            </Typography>
                          </Box>
                        )}
                      </CenterBox>
                      <Box
                        data-testid="account-selector-account-text"
                        sx={{ alignItems: "center", display: "flex" }}
                      >
                        <Typography component="span" fontWeight={600} mr={0.5}>
                          {account.accountCode}:
                        </Typography>
                        {splitStringWithoutCase(
                          account.companyName ?? "",
                          term,
                        ).map((value, index) => (
                          <Typography
                            key={index}
                            component="span"
                            fontWeight={
                              value.toLowerCase() === term.toLowerCase()
                                ? 400
                                : 600
                            }
                          >
                            {value}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  </Button>
                ))}
              </Stack>
            ) : null}
          </Popover>
        </>
      )}
    </Box>
  );
});

export default AccountSelector;
