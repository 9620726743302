import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  RETURN_REASON_PROMPT_MAPPINGS,
  ReturnReasonPromptMapping,
} from "../../../constants/rmaRequest";
import { Button, TextField } from "../../ui";
import { useRmaContext } from "./RmaContext";

type FormValues = {
  reason?: string;
  reasonDetails?: string;
  isReasonDetailVisible?: boolean;
  sealed?: boolean;
};

const schema = yup.object({
  isReasonDetailVisible: yup.boolean(),
  reason: yup.string().required("Please select a reason for the return."),
  reasonDetails: yup
    .string()
    .when("isReasonDetailVisible", ([isReasonDetailVisible], schema) => {
      return isReasonDetailVisible
        ? schema.required("This field is required.")
        : schema;
    }),
  sealed: yup.boolean(),
});

const ReasonAndCondition = ({ onNext }: { onNext: () => void }) => {
  const { onClose, setReason, setSealed, orderId, partDetails } =
    useRmaContext();
  const [returnReason, setReturnReason] = useState<
    ReturnReasonPromptMapping | undefined
  >({});
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      isReasonDetailVisible: false,
    },
    resolver: yupResolver<FormValues>(schema),
  });

  const onSubmit = useCallback(
    (values: FormValues) => {
      const mappedReturnReason = RETURN_REASON_PROMPT_MAPPINGS.find(
        (returnReasonPromptMapping) =>
          returnReasonPromptMapping.reason === values.reason,
      );
      setReason({
        code: mappedReturnReason?.code,
        reason: values.reason,
        reasonDetails: values.reasonDetails ? values.reasonDetails : "",
      });
      setSealed(values.sealed);
      onNext();
    },
    [onNext, setReason, setSealed],
  );

  const handleReasonChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const mappedReturnReason = RETURN_REASON_PROMPT_MAPPINGS.find(
        (returnReasonPromptMapping) =>
          returnReasonPromptMapping.reason === e.target.value,
      );
      setReturnReason(mappedReturnReason);
      setValue(
        "isReasonDetailVisible",
        !!(mappedReturnReason && mappedReturnReason?.textboxPrompt),
      );
    },
    [setValue],
  );

  return (
    <Dialog
      fullWidth
      open
      data-testid="rma-request-reason-condition-modal"
      maxWidth="md"
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          data-testid="rma-request-reason-condition-modal-title"
          sx={(theme) => ({
            fontSize: theme.spacing(2.25),
            pb: 0,
            pt: 3.5,
            px: 2,
          })}
        >
          Request RMA for Order{" "}
          <Typography
            component="span"
            sx={(theme) => ({
              fontSize: theme.spacing(2.25),
              fontWeight: "bold",
            })}
          >
            {orderId}
          </Typography>{" "}
          Part{" "}
          <Typography
            component="span"
            sx={(theme) => ({
              fontSize: theme.spacing(2.25),
              fontWeight: "bold",
            })}
          >
            {partDetails?.supplierCode}|{partDetails?.supplierPart}
          </Typography>
        </DialogTitle>
        <IconButton
          data-testid="rma-request-reason-condition-modal-close-icon"
          sx={{
            color: (theme) => theme.palette.grey[900],
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent data-testid="rma-request-reason-condition-modal-body">
          <Box>
            <Typography
              sx={(theme) => ({
                fontSize: 16,
                mb: theme.spacing(1),
              })}
            >
              Please explain the reason for the return
            </Typography>
          </Box>
          <Box
            sx={(theme) => ({
              display: "flex",
              gap: theme.spacing(1),
              width: "100%",
            })}
          >
            <Box sx={() => ({ width: "50%" })}>
              <RadioGroup
                value={!!returnReason?.reason ? returnReason?.reason : ""}
                onChange={(e) => handleReasonChange(e)}
              >
                {RETURN_REASON_PROMPT_MAPPINGS.map(
                  (returnReasonPromptMapping, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Radio
                          key={index}
                          data-testid="rma-request-reason"
                          size="small"
                          {...register("reason")}
                        />
                      }
                      label={
                        <Typography
                          sx={() => ({
                            fontSize: 14,
                          })}
                        >
                          {returnReasonPromptMapping.reason}
                        </Typography>
                      }
                      value={returnReasonPromptMapping.reason}
                    />
                  ),
                )}
              </RadioGroup>
              {errors.reason && (
                <FormHelperText error>{errors.reason.message}</FormHelperText>
              )}
            </Box>
            <Box sx={() => ({ width: "50%" })}>
              {returnReason?.textboxPrompt && (
                <>
                  <Typography
                    sx={(theme) => ({
                      fontSize: 14,
                      mb: theme.spacing(1),
                    })}
                  >
                    {returnReason?.textboxPrompt}
                  </Typography>
                  <TextField
                    autoFocus
                    multiline
                    data-testid="rma-request-reason-details"
                    rows={6}
                    size="small"
                    sx={(theme) => ({
                      mb: theme.spacing(0.5),
                      width: "100%",
                    })}
                    {...register("reasonDetails")}
                  />
                  {errors.reasonDetails && (
                    <FormHelperText error>
                      {errors.reasonDetails.message}
                    </FormHelperText>
                  )}
                </>
              )}
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={watch("sealed")}
                      size="small"
                      {...register("sealed")}
                    />
                  }
                  data-testid="rma-request-reason-item-sealed-checkbox"
                  label={
                    <Typography
                      sx={() => ({
                        fontSize: 14,
                      })}
                    >
                      The item is sealed in the original manufacturer's box
                    </Typography>
                  }
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", pb: 3, px: 2 }}>
          <Button
            data-testid="rma-request-reason-condition-modal-close-btn"
            sx={(theme) => ({ width: theme.spacing(12.5) })}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            data-testid="rma-request-reason-condition-modal-next-btn"
            sx={(theme) => ({ width: theme.spacing(12.5) })}
            type="submit"
            variant="contained"
          >
            Next
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ReasonAndCondition;
