export const RETURN_REASON_PROMPT_MAPPINGS: ReturnReasonPromptMapping[] = [
  {
    code: "HIDDEN DAMAGE",
    reason: "Product damaged, but shipping box OK",
    textboxPrompt: "Please describe the damage and what the box looks like",
  },
  {
    code: "DAMAGED",
    reason: "Product and shipping box both damaged",
    textboxPrompt: "Please describe the damage and what the box looks like",
  },
  {
    code: "OTHER",
    reason: "Missing parts or accessories",
    textboxPrompt: "",
  },
  {
    code: "DOA",
    reason: "Item defective or doesn’t work",
    textboxPrompt: "Describe what the product is doing or not doing",
  },
  {
    code: "WRONG SHIP",
    reason: "Wrong item was sent",
    textboxPrompt:
      "Provide the manufacturer part number that was expected and the manufacturer part number that was sent in error",
  },
  {
    code: "OTHER",
    reason: "Bought by mistake",
    textboxPrompt: "",
  },
  {
    code: "OTHER",
    reason: "Item arrived too late",
    textboxPrompt: "Provide the date this was expected",
  },
  {
    code: "NOT WANTED",
    reason: "No longer needed",
    textboxPrompt: "",
  },
];

export type ReturnReasonPromptMapping = {
  code?: string;
  reason?: string;
  textboxPrompt?: string;
};
