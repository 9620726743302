import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ChangeEvent, useMemo, useState } from "react";
import { AbstractUserDto } from "../../api";
import { useUpdateUser } from "../../hooks/api/mutations/useUpdateUser";
import generateAuthHash from "../../utils/generateAuthHash";
import { getAppLink } from "../../utils/link";
import { Button, LoadingButton, TextField } from "../ui";

const isValidIpAddress = (value: string) =>
  value === "*" ||
  /^((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d|\*)\.){3}(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d|\*)$/.test(
    value,
  );

const ManageAuthenticatedLink = ({
  user,
  isOpen,
  onClose,
  onUserUpdated,
}: {
  user: AbstractUserDto;
  isOpen: boolean;
  onClose: () => void;
  onUserUpdated: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: updateUser, isLoading: isUpdatingUser } = useUpdateUser(
    user.userId,
  );

  const [isIpBoxExpanded, setIsIpBoxExpanded] = useState(false);
  const [isIpAddressValueInvalid, setIsIpAddressValueInvalid] = useState(false);
  const [ipAddressValue, setIpAddressValue] = useState("");

  const authLink = useMemo(
    // @ts-ignore
    () => getAppLink(`/app/Login/AuthLink?auth=${user.authenticatedLinkHash}`),
    [user],
  );

  const handleClickDisable = () => {
    updateUser(
      {
        authenticatedLinkHash: null as any,
      },
      {
        onError: () =>
          enqueueSnackbar({
            message: `Unable to disable authenticated link.`,
            variant: "error",
          }),
        onSuccess: () => {
          enqueueSnackbar({
            message: `Successfully disabled authenticated link.`,
            variant: "success",
          });
          onClose();
          onUserUpdated();
        },
      },
    );
  };
  const handleClickRegenerate = () => {
    updateUser(
      {
        authenticatedLinkHash: generateAuthHash(),
      },
      {
        onError: () =>
          enqueueSnackbar({
            message: `Unable to regenerate authenticated link.`,
            variant: "error",
          }),
        onSettled: () => onUserUpdated(),
        onSuccess: () =>
          enqueueSnackbar({
            message: `Authenticated link for user BUS019 | Brand has expired and regenerated.`,
            variant: "success",
          }),
      },
    );
  };
  const handleClickToggleIpBox = () => setIsIpBoxExpanded((prev) => !prev);
  const handleChangeIpAddressValue = (event: ChangeEvent<HTMLInputElement>) => {
    setIpAddressValue(event.target.value.replace(/[^0-9*.]/g, ""));
    setIsIpAddressValueInvalid(false);
  };

  const handleClickAddIpAddress = () => {
    if (!isValidIpAddress(ipAddressValue)) {
      setIsIpAddressValueInvalid(true);
      return;
    }

    setIsIpAddressValueInvalid(false);

    updateUser(
      {
        authenticatedLinkAllowedIPs: [
          ...(user.authenticatedLinkAllowedIPs ?? []),
          ipAddressValue,
        ],
      },
      {
        onError: () =>
          enqueueSnackbar({
            message: `Unable to add IP address.`,
            variant: "error",
          }),
        onSuccess: () => {
          onUserUpdated();
          setIpAddressValue("");
        },
      },
    );
  };
  const handleClickRemoveIpAddress = (ipAddress: string) => {
    updateUser(
      {
        authenticatedLinkAllowedIPs: (
          user.authenticatedLinkAllowedIPs ?? []
        ).filter((value) => ipAddress !== value),
      },
      {
        onError: () =>
          enqueueSnackbar({
            message: `Unable to remove IP address.`,
            variant: "error",
          }),
        onSuccess: () => onUserUpdated(),
      },
    );
  };

  return (
    <Dialog
      fullWidth
      data-testid="manage-auth-link-modal"
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle
        data-testid="manage-auth-link-modal-title"
        sx={(theme) => ({
          fontSize: theme.spacing(2.25),
          m: 0,
          pb: 0,
          pt: 3.5,
          px: 2,
        })}
      >
        Manage Authenticated Link For User {user.accountCode} | {user.userCode}
      </DialogTitle>
      <IconButton
        sx={{
          color: (theme) => theme.palette.grey[900],
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent data-testid="manage-auth-link-modal-body">
        <Typography
          sx={(theme) => ({
            fontSize: "18",
            fontWeight: 600,
            mt: theme.spacing(1.5),
          })}
        >
          Authenticated Link for Brand
        </Typography>
        <TextField
          disabled
          size="small"
          sx={(theme) => ({ mt: theme.spacing(1.5), width: "100%" })}
          value={authLink}
        />
        <Box
          sx={(theme) => ({
            border: "1px solid #2FA3DE",
            borderRadius: 0.5,
            mt: theme.spacing(2.5),
            px: theme.spacing(1),
            width: 256,
          })}
        >
          <Box
            sx={(theme) => ({
              alignItems: "center",
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
            })}
            onClick={handleClickToggleIpBox}
          >
            <Typography
              sx={{
                color: "#2481C8",
                fontSize: 14,
              }}
            >
              Add an IP Address
            </Typography>
            <Typography
              sx={{
                color: "#2481C8",
                fontSize: 20,
              }}
            >
              {isIpBoxExpanded ? "-" : "+"}
            </Typography>
          </Box>
          {isIpBoxExpanded && (
            <Box
              sx={(theme) => ({
                alignItems: "center",
                display: "flex",
                mb: theme.spacing(isIpAddressValueInvalid ? 3 : 1),
                mt: theme.spacing(1),
              })}
            >
              <TextField
                error={isIpAddressValueInvalid}
                helperText={
                  isIpAddressValueInvalid ? "Invalid IP address." : null
                }
                size="small"
                sx={{
                  "& fieldset": {
                    "& legend": {
                      display: "none",
                    },
                    height: 25,
                    inset: "0px 0px 0px",
                  },
                  "& input.MuiInputBase-input": {
                    height: 25,
                  },
                  flexGrow: 1,
                  height: 25,
                }}
                value={ipAddressValue}
                onChange={handleChangeIpAddressValue}
              />
              <LoadingButton
                color="success"
                data-testid="manage-auth-link-modal-add-ip-btn"
                loading={isUpdatingUser}
                sx={(theme) => ({
                  height: 25,
                  ml: theme.spacing(1.5),
                })}
                variant="contained"
                onClick={handleClickAddIpAddress}
              >
                Add
              </LoadingButton>
            </Box>
          )}
        </Box>
        {(user.authenticatedLinkAllowedIPs ?? []).length === 0 && (
          <Typography
            sx={(theme) => ({
              color: theme.palette.red["500"],
              fontSize: 12,
              mt: theme.spacing(0.5),
            })}
          >
            NOTE: You must authorize at least one IP address to use this link.
          </Typography>
        )}
        {(user.authenticatedLinkAllowedIPs ?? []).length > 0 && (
          <Box
            sx={(theme) => ({
              mt: theme.spacing(1.5),
              width: 256,
            })}
          >
            {(user.authenticatedLinkAllowedIPs ?? []).map((ipAddress) => (
              <Box
                key={ipAddress}
                sx={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <CloseIcon
                  sx={(theme) => ({
                    color: theme.palette.red["500"],
                    cursor: "pointer",
                    fontSize: 18,
                    mr: 0.3,
                  })}
                  onClick={() => handleClickRemoveIpAddress(ipAddress)}
                />

                <Typography sx={{ fontSize: 14 }}>{ipAddress}</Typography>
              </Box>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pb: 2,
          px: 3,
        }}
      >
        <Button
          data-testid="manage-auth-link-modal-close-btn"
          sx={(theme) => ({
            height: theme.spacing(4.5),
            width: theme.spacing(12),
          })}
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Box>
          <LoadingButton
            data-testid="manage-auth-link-modal-disable-btn"
            loading={isUpdatingUser}
            sx={(theme) => ({
              height: theme.spacing(4.5),
              width: theme.spacing(12),
            })}
            variant="outlined"
            onClick={handleClickDisable}
          >
            Disable
          </LoadingButton>
          <LoadingButton
            color="primary"
            data-testid="manage-auth-link-modal-regenerate-btn"
            loading={isUpdatingUser}
            sx={(theme) => ({
              height: theme.spacing(4.5),
              ml: 1,
            })}
            variant="contained"
            onClick={handleClickRegenerate}
          >
            Expire and Regenerate
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ManageAuthenticatedLink;
