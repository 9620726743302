import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import CloseIcon from "@mui/icons-material/Close";
import {
  alpha,
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableContainer,
  Typography,
} from "@mui/material";
import {
  ColumnDef,
  ColumnOrderState,
  getCoreRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { isArray, isObject, keys } from "lodash";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { useSnackbar } from "notistack";
import {
  memo,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ViewRes } from "../../api";
import {
  CHECKBOX_COLUMN_ID,
  SCROLL_TO_TOP_AVAILABLE_AT,
  TABLE_ACTIONS_HEIGHT,
  TABLE_FILTER_HEIGHT,
  TABLE_OVERSCAN,
  TABLE_ROW_HEIGHT,
} from "../../constants";
import useScrollbarWidth from "../../hooks/layout/useScrollbarWidth";
import { ActionRowClickItem, ActionRowItem } from "../../types";
import { reorderColumn } from "../../utils/datatable";
import {
  DropdownIcon,
  PencilIcon,
  ScheduleIcon,
  ChevronUpIcon,
  InfoCircleIcon,
} from "../icons";
import SelectColumns from "../modals/SelectColumns";
import { Button, CenterBox, VCenterBox } from "../ui";
import DataTableBody from "./DataTableBody";
import { useDataTableContext } from "./DataTableContext";
import DataTableHeader from "./DataTableHeader";
import EmailExportButton from "./EmailExportButton";
import ExportButton from "./ExportButton";
import PrintButton from "./PrintButton";

const DataTable = <TData extends Record<string, unknown>>({
  actionRowClickItem,
  actionRowItems,
  activeView,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  isSelectable,
  isViewsLoading,
  newRows,
  onChangeView,
  onFetchNextPage,
  onResetNewRows,
  onRowsSelect,
  selectedRows,
  totalElements,
  views,
}: {
  actionRowClickItem?: ActionRowClickItem<TData>;
  actionRowItems?: ActionRowItem[];
  activeView?: ViewRes;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  isSelectable: boolean;
  isViewsLoading: boolean;
  newRows: TData[];
  onChangeView: (view?: ViewRes) => void;
  onFetchNextPage: () => void;
  onResetNewRows: () => void;
  onRowsSelect?: (rows: TData[]) => void;
  selectedRows?: TData[];
  totalElements: number;
  views: ViewRes[];
}) => {
  const {
    additionalColumnId,
    criteria,
    identifier,
    rawColumns,
    setTable,
    tableColumns,
    tableData,
    updateColumns,
    updateSort,
  } = useDataTableContext();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const [columnsSelectorOpened, setColumnsSelectorOpened] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isScrolled, setIsScrolled] = useState(false);

  useScrollbarWidth(tableContainerRef);

  const tableRowsIndexMap = useMemo(
    () =>
      identifier
        ? tableData.reduce(
            (prev, curr, index) => ({
              ...prev,
              [curr[identifier]]: index,
            }),
            {},
          )
        : {},
    [identifier, tableData],
  );

  const rowSelection = useMemo(
    () =>
      identifier
        ? (selectedRows?.reduce(
            (prev, curr) => ({
              ...prev,
              [tableRowsIndexMap[curr[identifier as any] as any]]: true,
            }),
            {},
          ) ?? {})
        : {},
    [identifier, tableRowsIndexMap, selectedRows],
  );

  const table = useReactTable<TData>({
    columns: tableColumns as ColumnDef<TData>[],
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    onColumnOrderChange: (orderState) => {
      if (isArray(orderState)) {
        setColumnOrder(orderState);
        updateColumns(orderState);
      }
    },
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: (updaterOrValue) => {
      const newSelection =
        typeof updaterOrValue === "function"
          ? updaterOrValue(rowSelection)
          : updaterOrValue;
      if (isObject(newSelection)) {
        if (onRowsSelect) {
          onRowsSelect(
            keys(newSelection)
              .filter((rowIndex) => (newSelection as any)[rowIndex])
              .map((rowIndex) => tableData[+rowIndex]),
          );
        }
      }
    },
    onSortingChange: (updaterOrValue) => {
      const value =
        typeof updaterOrValue === "function"
          ? updaterOrValue(sorting)
          : updaterOrValue;
      setSorting(value);
      if (value.length) {
        updateSort(value[0].id, value[0].desc ? 1 : 0);
      } else {
        updateSort(); // clear sort
      }
    },
    state: {
      columnOrder: [
        ...(isSelectable ? [CHECKBOX_COLUMN_ID] : []),
        ...columnOrder.filter((column) => column !== CHECKBOX_COLUMN_ID),
      ],
      columnVisibility: {
        [CHECKBOX_COLUMN_ID]: isSelectable,
        ...columnVisibility,
      },
      rowSelection,
      sorting,
    },
  });

  useEffect(() => {
    setTable(table);
  }, [setTable, table]);

  const { rows } = table.getRowModel();
  const isAdditionalColumnVisible = useMemo(
    () => !!(additionalColumnId && columnVisibility[additionalColumnId]),
    [additionalColumnId, columnVisibility],
  );

  const rowVirtualizer = useVirtualizer<any, any>({
    count: hasNextPage ? rows.length + 1 : rows.length,
    estimateSize: () =>
      isAdditionalColumnVisible ? TABLE_ROW_HEIGHT * 2 : TABLE_ROW_HEIGHT,
    getScrollElement: () => tableContainerRef.current,
    overscan: TABLE_OVERSCAN,
    paddingStart: TABLE_ROW_HEIGHT + TABLE_FILTER_HEIGHT,
    scrollPaddingStart:
      TABLE_ROW_HEIGHT + TABLE_FILTER_HEIGHT + TABLE_ACTIONS_HEIGHT,
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  const handleColumnMove = useCallback(
    (event: DragEndEvent) => {
      const columnOrder = table?.getState().columnOrder ?? [];
      const newColumnOrder = reorderColumn(
        event.active.id as string,
        event.over?.id as string,
        columnOrder,
      );
      if (table) {
        table.setColumnOrder(newColumnOrder);
      }
    },
    [table],
  );

  useEffect(() => {
    rowVirtualizer.measure();
  }, [isAdditionalColumnVisible, rowVirtualizer]);

  const { startIndex } = (rowVirtualizer as any).range ?? 0;

  const virtualRows = rowVirtualizer.getVirtualItems();
  const allLeafColumns = table.getAllLeafColumns();

  const manageableColumns = useMemo(
    () => allLeafColumns.filter((column) => column.id !== CHECKBOX_COLUMN_ID),
    [allLeafColumns],
  );

  useEffect(() => {
    const [lastItem] = [...virtualRows].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= rows.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      onFetchNextPage();
    }
  }, [
    hasNextPage,
    onFetchNextPage,
    isFetchingNextPage,
    rows.length,
    virtualRows,
  ]);

  const onTableScroll = useCallback((e: SyntheticEvent) => {
    if (e.target !== tableContainerRef.current) {
      return;
    }

    const scrollTop = (e.target as HTMLDivElement).scrollTop;

    setIsScrolled(scrollTop > TABLE_ACTIONS_HEIGHT);
  }, []);

  useEffect(
    () =>
      setColumnOrder(
        criteria.fields ?? tableColumns.map((column) => column.id as string),
      ),
    [criteria.fields, tableColumns],
  );

  useEffect(() => {
    setColumnVisibility(
      rawColumns
        .filter((column) => column.element !== CHECKBOX_COLUMN_ID)
        .reduce<VisibilityState>(
          (prev, curr) => ({
            ...prev,
            [curr.element ?? ""]:
              criteria.fields?.includes(curr.element ?? "") ?? false,
          }),
          {},
        ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria.fields, JSON.stringify(rawColumns)]);

  useEffect(() => {
    setSorting(
      criteria.sort?.map((s) => ({
        desc: s.desc === 1 || s.desc === "1",
        id: s.field,
      })) ?? [],
    );
  }, [criteria.sort]);

  const hasNewRows = newRows.length > 0;

  useEffect(() => {
    if (hasNewRows) {
      enqueueSnackbar(
        <VCenterBox
          sx={(theme) => ({
            background: theme.palette.purple["100"],
            borderRadius: theme.spacing(0.5),
            gap: theme.spacing(1),
            p: theme.spacing(2),
            position: "relative",
            pr: theme.spacing(5),
            width: "100%",
          })}
        >
          <InfoCircleIcon
            sx={(theme) => ({ color: theme.palette.purple["400"], width: 16 })}
          />
          <Typography color="purple.400" fontWeight="600" variant="body1">
            <Typography color="purple.500" component="span" variant="body1">
              NEW
            </Typography>{" "}
            results update.
          </Typography>

          {startIndex > 0 && (
            <Button
              sx={(theme) => ({
                background: theme.palette.purple["300"],
                border: "none",
                color: theme.palette.white,
                fontSize: theme.spacing(1.75),
                fontWeight: 600,
                height: theme.spacing(3.5),
                px: 0.75,
                py: 0.25,
              })}
              variant="contained"
              onClick={() =>
                rowVirtualizer.scrollToIndex(0, { behavior: "smooth" })
              }
            >
              Scroll to Top
            </Button>
          )}
          <IconButton
            aria-label="Close"
            size="small"
            sx={(theme) => ({
              color: theme.palette.purple["300"],
              position: "absolute",
              right: theme.spacing(0.25),
              top: theme.spacing(0.25),
            })}
            onClick={() => closeSnackbar("new-results")}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </VCenterBox>,
        {
          anchorOrigin: {
            horizontal: "center",
            vertical: startIndex > 0 ? "bottom" : "top",
          },
          autoHideDuration: 5000,
          key: "new-results",
          onClose: onResetNewRows,
          preventDuplicate: true,
        },
      );
    }
  }, [
    hasNewRows,
    rowVirtualizer,
    startIndex,
    onResetNewRows,
    closeSnackbar,
    enqueueSnackbar,
  ]);

  return isViewsLoading ? (
    <CenterBox
      sx={{ color: (theme) => theme.palette.grey["500"], height: "100%" }}
    >
      <CircularProgress color="inherit" />
    </CenterBox>
  ) : (
    <>
      <Box
        data-testid="dt"
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Box
          ref={tableContainerRef}
          data-testid="dt-scrollable"
          sx={{
            flex: "1 1 0",
            minHeight: "500px",
            overflow: isLoading ? "hidden" : "auto",
          }}
          onScroll={onTableScroll}
        >
          <Box
            data-testid="dt-features"
            sx={(theme) => ({
              display: "flex",
              justifyContent: "space-between",
              left: 0,
              position: "sticky",
              px: theme.spacing(4),
              py: theme.spacing(2),
              zIndex: theme.zIndex.appBar,
            })}
          >
            <Box
              data-testid="dt-actions"
              sx={(theme) => ({ display: "flex", gap: theme.spacing(0.5) })}
            >
              {actionRowItems
                ?.filter((item) => item.isVisible)
                ?.map((item) => (
                  <Button
                    key={item.label}
                    variant="outlined"
                    onClick={item.onClick}
                  >
                    {item.label}
                  </Button>
                ))}
            </Box>
            <Box
              data-testid="dt-controls"
              sx={(theme) => ({
                display: "flex",
                gap: theme.spacing(0.5),
                justifyContent: "flex-end",
              })}
            >
              <PopupState popupId="views-list" variant="popover">
                {(popupState) => (
                  <Box>
                    <Button
                      {...bindTrigger(popupState)}
                      data-testid="dt-controls-view"
                      endIcon={<DropdownIcon sx={{ width: 13 }} />}
                      sx={(theme) => ({
                        "&.MuiButtonBase-root": {
                          justifyContent: "space-between",
                        },
                        width: theme.spacing(30),
                      })}
                      variant="outlined"
                    >
                      {activeView ? activeView.name : "Select View"}
                    </Button>
                    {views.length > 0 && (
                      <Menu {...bindMenu(popupState)}>
                        {views.map((view) => (
                          <MenuItem
                            key={view.viewId}
                            onClick={() => {
                              popupState.close();
                              onChangeView(view);
                            }}
                          >
                            {view.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  </Box>
                )}
              </PopupState>

              <Button
                disabled
                data-testid="dt-controls-edit-view"
                variant="outlined"
              >
                <PencilIcon sx={{ width: 16 }} />
              </Button>

              <Button
                disabled
                data-testid="dt-controls-schedule"
                variant="outlined"
              >
                <ScheduleIcon sx={{ width: 16 }} />
              </Button>

              <Button
                data-testid="dt-controls-select-columns"
                variant="outlined"
                onClick={() => setColumnsSelectorOpened(true)}
              >
                Select Columns
              </Button>
              <EmailExportButton />
              <PrintButton />
              <ExportButton
                activeView={activeView}
                totalElements={totalElements}
              />
            </Box>
          </Box>

          <Box data-testid="dt-content" position="relative">
            <DndContext
              collisionDetection={closestCenter}
              sensors={sensors}
              onDragEnd={handleColumnMove}
            >
              <TableContainer
                sx={{
                  height: `${rowVirtualizer.getTotalSize()}px`,
                  overflowX: "unset",
                  overflowY: "unset",
                  position: "relative",
                }}
              >
                <Table sx={{ height: "100%" }}>
                  <DataTableHeader isScrolled={isScrolled} />
                  <DataTableBody
                    actionRowClickItem={actionRowClickItem}
                    hasNextPage={hasNextPage}
                    isAdditionalColumnVisible={isAdditionalColumnVisible}
                    newRows={newRows}
                    rows={rows}
                    virtualRows={virtualRows}
                  />
                </Table>
              </TableContainer>
            </DndContext>

            {startIndex > SCROLL_TO_TOP_AVAILABLE_AT && (
              <Button
                data-testid="dt-back-to-top"
                startIcon={<ChevronUpIcon sx={{ width: 16 }} />}
                sx={(theme) => ({
                  bottom: theme.spacing(6),
                  opacity: 0.67,
                  position: "fixed",
                  right: theme.spacing(6),
                })}
                variant="outlined"
                onClick={() =>
                  rowVirtualizer.scrollToIndex(0, { behavior: "smooth" })
                }
              >
                Back to Top
              </Button>
            )}
          </Box>

          {columnsSelectorOpened && (
            <SelectColumns<TData>
              isOpen
              columns={manageableColumns}
              onClose={() => setColumnsSelectorOpened(false)}
            />
          )}

          {isLoading && (
            <CenterBox
              sx={(theme) => ({
                background: alpha(theme.palette.grey["200"], 0.5),
                color: theme.palette.grey["500"],
                inset: 0,
                position: "absolute",
                zIndex: theme.zIndex.appBar,
              })}
            >
              <CircularProgress color="inherit" variant="indeterminate" />
            </CenterBox>
          )}
        </Box>
      </Box>
    </>
  );
};

export default memo(DataTable) as typeof DataTable;
