import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import { ListingExportEmailRequestDto, OpenAPI } from "../../../api";
import { EXPORT_LISTING_CONTENT_TYPES } from "../../../constants/exportListings";
import { ExportListingType } from "../../../types";

interface ExportListingEmailParams {
  exportListingType: ExportListingType;
  listingName: string;
  criteria?: string;
  page?: number;
  size?: number;
  listingExportEmailRequestDto: ListingExportEmailRequestDto;
}

const exportListingEmailUsingPost = async ({
  exportListingType,
  listingName,
  criteria = "{}",
  page = 1,
  size = 20000,
  listingExportEmailRequestDto,
}: ExportListingEmailParams) => {
  try {
    return axios.post(
      `/service/rest/listing/${listingName}/email?criteria=${encodeURIComponent(
        criteria,
      )}`,
      listingExportEmailRequestDto,
      {
        baseURL: OpenAPI.BASE,
        headers: {
          Accept: EXPORT_LISTING_CONTENT_TYPES[exportListingType],
          Authorization: `Bearer ${OpenAPI.TOKEN}`,
        },
        params: {
          page,
          size,
        },
        responseType: "blob",
      },
    );
  } catch (error) {
    throw error;
  }
};

// Hook that provides the mutation
export const useExportListingEmail = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (params: ExportListingEmailParams) => exportListingEmailUsingPost(params),
    {
      onError: (error, variables) => {
        const emailList = variables.listingExportEmailRequestDto.to.join(", ");
        enqueueSnackbar({
          message: `Unable to send report email to: ${emailList}.`,
          variant: "error",
        });
      },
      onSuccess: (data, variables) => {
        const emailList = variables.listingExportEmailRequestDto.to.join(", ");
        enqueueSnackbar({
          message: `Report email sent to: ${emailList}.`,
          variant: "success",
        });
      },
    },
  );
};
