import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { ViewResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";
import { ListingName } from "../../../types";
import { useCookiesLive } from "../../useCookiesLive";

export const useListingViews = (listingName: ListingName) => {
  const { data, ...rest } = useQuery(
    [QUERY_KEYS["listing-views"], listingName],
    () => ViewResourceService.listGridViewsUsingGet(listingName),
  );
  const [accountCode] = useCookiesLive(["accountCode"]);
  const [searchParams] = useSearchParams();
  const viewName = decodeURIComponent(searchParams.get("view") ?? "");

  const initialView = useMemo(() => {
    const views = data?._embedded?.entities;
    let defaultView = views?.find((view) =>
      viewName ? view.name === viewName : view.isDefault,
    );
    if (!defaultView) {
      defaultView = views?.find((view) =>
        viewName ? view.name === viewName : view.defaultView,
      );
    }

    return defaultView;
  }, [data?._embedded?.entities, viewName]);

  const views = useMemo(
    () =>
      data?._embedded?.entities?.filter(
        (view) => !accountCode || view.crossAccount === 0,
      ) ?? [],
    [accountCode, data],
  );

  return {
    data: views,
    ...rest,
    initialView,
  };
};
