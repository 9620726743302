/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GlobalInvoiceLinePatchRequestDto } from '../models/GlobalInvoiceLinePatchRequestDto';
import type { GlobalInvoiceLineRequestDto } from '../models/GlobalInvoiceLineRequestDto';
import type { GlobalInvoiceLineResponseDto } from '../models/GlobalInvoiceLineResponseDto';
import type { RestCollection_GlobalInvoiceLineListing_ } from '../models/RestCollection_GlobalInvoiceLineListing_';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class GlobalInvoiceLinesResourceService {
  /**
   * Create a Global Invoice Line
   * Create a Global Invoice Line
   * @param globalInvoiceLineRequestDto globalInvoiceLineRequestDto
   * @param accountId accountId
   * @param customerId customerId
   * @param oneSourceId oneSourceId
   * @returns GlobalInvoiceLineResponseDto Created
   * @throws ApiError
   */
  public static createGlobalInvoiceLineUsingPost(
    globalInvoiceLineRequestDto: GlobalInvoiceLineRequestDto,
    accountId?: number,
    customerId?: number,
    oneSourceId?: number,
  ): CancelablePromise<GlobalInvoiceLineResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/globalInvoiceLines',
      query: {
        'accountId': accountId,
        'customerId': customerId,
        'oneSourceId': oneSourceId,
      },
      body: globalInvoiceLineRequestDto,
      errors: {
        400: `Global Invoice Line Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        409: `The Global Invoice Line already exists`,
        500: `Internal Error Creating Global Invoice Line`,
      },
    });
  }
  /**
   * Get a Global Invoice Line by id
   * Get a Global Invoice Line by id
   * @param globalInvoiceLineId globalInvoiceLineId
   * @returns GlobalInvoiceLineResponseDto Ok
   * @throws ApiError
   */
  public static getGlobalInvoiceLineUsingGet(
    globalInvoiceLineId: number,
  ): CancelablePromise<GlobalInvoiceLineResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/globalInvoiceLines/{globalInvoiceLineId}',
      path: {
        'globalInvoiceLineId': globalInvoiceLineId,
      },
      errors: {
        400: `Global Invoice Line Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `The Global Invoice Line was not found`,
        500: `Internal Error Getting Global Invoice Line`,
      },
    });
  }
  /**
   * Delete Global Invoice Line by id
   * Delete Global Invoice Line by id
   * @param globalInvoiceLineId globalInvoiceLineId
   * @returns any Success
   * @throws ApiError
   */
  public static deleteGlobalInvoiceLineUsingDelete(
    globalInvoiceLineId: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/service/rest/globalInvoiceLines/{globalInvoiceLineId}',
      path: {
        'globalInvoiceLineId': globalInvoiceLineId,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Global Invoice Line with id {globalInvoiceLineId} not found.`,
        500: `Internal Error deleting Global Invoice Line`,
      },
    });
  }
  /**
   * Patch an Global Invoice Line
   * Patch Global Invoice Line
   * @param globalInvoiceLineId globalInvoiceLineId
   * @param globalInvoiceLinePatchRequestDto globalInvoiceLinePatchRequestDto
   * @returns GlobalInvoiceLineResponseDto Success
   * @throws ApiError
   */
  public static updateGlobalInvoiceLineUsingPatch(
    globalInvoiceLineId: number,
    globalInvoiceLinePatchRequestDto: GlobalInvoiceLinePatchRequestDto,
  ): CancelablePromise<GlobalInvoiceLineResponseDto> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/globalInvoiceLines/{globalInvoiceLineId}',
      path: {
        'globalInvoiceLineId': globalInvoiceLineId,
      },
      body: globalInvoiceLinePatchRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Global Invoice Line Not Found`,
        500: `Internal Error Patching Global Invoice Line`,
      },
    });
  }
  /**
   * Get Global Invoice Line List
   * Get Global Invoice Line List
   * @param criteria criteria
   * @param page page
   * @param returnAllLabels returnAllLabels
   * @param returnPagination returnPagination
   * @param returnSavedViews returnSavedViews
   * @param size size
   * @param useDefaultView useDefaultView
   * @returns RestCollection_GlobalInvoiceLineListing_ Success
   * @throws ApiError
   */
  public static listGlobalInvoiceLinesUsingGet(
    criteria: string = '{}',
    page: number = 1,
    returnAllLabels: boolean = false,
    returnPagination: boolean = true,
    returnSavedViews: boolean = true,
    size: number = 50,
    useDefaultView: boolean = false,
  ): CancelablePromise<RestCollection_GlobalInvoiceLineListing_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/listing/globalInvoiceLines',
      query: {
        'criteria': criteria,
        'page': page,
        'returnAllLabels': returnAllLabels,
        'returnPagination': returnPagination,
        'returnSavedViews': returnSavedViews,
        'size': size,
        'useDefaultView': useDefaultView,
      },
      errors: {
        401: `Unauthorized`,
        403: `You are not authorized to access this resource.`,
        404: `Global Invoice Lines  not found.`,
        500: `Internal Error retrieving global invoice lines`,
      },
    });
  }
}
