/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientPatchRequestDto } from '../models/ClientPatchRequestDto';
import type { ClientRequestDto } from '../models/ClientRequestDto';
import type { ClientResponseDto } from '../models/ClientResponseDto';
import type { RestCollection_ClientListing_ } from '../models/RestCollection_ClientListing_';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ClientResourceService {
  /**
   * Create a Client
   * Create a Client
   * @param clientRequestDto clientRequestDto
   * @returns ClientResponseDto Created
   * @throws ApiError
   */
  public static createClientUsingPost(
    clientRequestDto: ClientRequestDto,
  ): CancelablePromise<ClientResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/client',
      body: clientRequestDto,
      errors: {
        400: `Client Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        409: `The client already exists`,
        500: `Internal Error Creating Client`,
        503: `The client creation service is unavailable`,
      },
    });
  }
  /**
   * Get a Client by id
   * Get a Client by Id
   * @param clientId clientId
   * @returns ClientResponseDto Ok
   * @throws ApiError
   */
  public static getClientUsingGet(
    clientId: number,
  ): CancelablePromise<ClientResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/client/{clientId}',
      path: {
        'clientId': clientId,
      },
      errors: {
        400: `Client Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `The client was not found`,
        500: `Internal Error Getting Client`,
      },
    });
  }
  /**
   * Delete a Client
   * Delete a Client
   * @param clientId clientId
   * @returns any Success
   * @throws ApiError
   */
  public static deleteClientUsingDelete(
    clientId: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/service/rest/client/{clientId}',
      path: {
        'clientId': clientId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Client Not Found`,
        500: `Internal Error Deleting Client`,
      },
    });
  }
  /**
   * Patch a Client
   * Patch a Client
   * @param clientId clientId
   * @param clientPatchRequestDto clientPatchRequestDto
   * @returns ClientResponseDto Success
   * @throws ApiError
   */
  public static updateClientUsingPatch(
    clientId: number,
    clientPatchRequestDto: ClientPatchRequestDto,
  ): CancelablePromise<ClientResponseDto> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/client/{clientId}',
      path: {
        'clientId': clientId,
      },
      body: clientPatchRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Client Not Found`,
        409: `The client already exists`,
        500: `Internal Error Patching Client`,
      },
    });
  }
  /**
   * Get Client List
   * Get Client List
   * @param criteria criteria
   * @param page page
   * @param returnAllLabels returnAllLabels
   * @param returnPagination returnPagination
   * @param returnSavedViews returnSavedViews
   * @param size size
   * @param useDefaultView useDefaultView
   * @returns RestCollection_ClientListing_ Success
   * @throws ApiError
   */
  public static listingUsingGet(
    criteria: string = '{}',
    page: number = 1,
    returnAllLabels: boolean = false,
    returnPagination: boolean = true,
    returnSavedViews: boolean = true,
    size: number = 50,
    useDefaultView: boolean = false,
  ): CancelablePromise<RestCollection_ClientListing_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/listing/clients',
      query: {
        'criteria': criteria,
        'page': page,
        'returnAllLabels': returnAllLabels,
        'returnPagination': returnPagination,
        'returnSavedViews': returnSavedViews,
        'size': size,
        'useDefaultView': useDefaultView,
      },
      errors: {
        401: `Unauthorized`,
        403: `You are not authorized to access this resource.`,
        404: `Clients not found.`,
        500: `Internal Error retrieving clients`,
      },
    });
  }
}
