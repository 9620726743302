import { createSvgIcon } from "@mui/material";

export const ThreeDotsIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(24) rotate(90)">
      <rect fill="none" height="24" width="24" />
      <circle
        cx="1"
        cy="1"
        r="1"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        transform="translate(5 11)"
      />
      <circle
        cx="1"
        cy="1"
        r="1"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        transform="translate(11 11)"
      />
      <circle
        cx="1"
        cy="1"
        r="1"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        transform="translate(17 11)"
      />
    </g>
  </svg>,
  "ThreeDots",
);
