import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
} from "@mui/material";
import { kebabCase, keys, sortBy } from "lodash";
import { useSnackbar } from "notistack";
import { ChangeEvent, Fragment, useCallback, useEffect, useState } from "react";
import { StaffListing, UserPermissionsDto } from "../../api";
import { useUserRightsUpdate } from "../../hooks/api/mutations";
import { useUserRights } from "../../hooks/api/queries";
import { Button } from "../ui";

type RightsState = { [id: string]: { name: string; isActivated: boolean } };

const EditStaffRights = ({
  isOpen,
  staff,
  onClose,
}: {
  isOpen: boolean;
  staff: StaffListing;
  onClose: () => void;
}) => {
  const { data, isFetching } = useUserRights(staff.userId);
  const [rightsState, setRightsState] = useState<RightsState>({});
  const { mutate: updateRights } = useUserRightsUpdate(staff.userId!);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data && !isFetching) {
      setRightsState(
        sortBy(
          data.filter((right) => (right as UserPermissionsDto).type === "Rep"),
          (right) => !(right as UserPermissionsDto).active,
        ).reduce(
          (prev, curr) => ({
            ...prev,
            [curr.rightCode]: {
              isActivated: (curr as UserPermissionsDto).active,
              name: curr.rightName,
            },
          }),
          {},
        ),
      );
    }
  }, [data, isFetching]);

  const handleRightsStateUpdate = useCallback(
    (e: ChangeEvent<HTMLInputElement>, rightCode: string) => {
      setRightsState((prev) => ({
        ...prev,
        [rightCode]: {
          ...prev[rightCode],
          isActivated: e.target.checked,
        },
      }));
    },
    [],
  );

  const handleSubmit = useCallback(() => {
    updateRights(
      Object.keys(rightsState).map((rightCode) => ({
        active: rightsState[rightCode].isActivated,
        rightCode,
      })),
      {
        onError: () => {
          enqueueSnackbar({
            message: `Unable to update rights for ${staff.firstName} ${staff.lastName} (${staff.email})`,
            variant: "error",
          });
        },
        onSettled: () => {
          onClose();
        },
        onSuccess: () => {
          enqueueSnackbar({
            message: `Rights updated for ${staff.firstName} ${staff.lastName} (${staff.email})`,
            variant: "success",
          });
        },
      },
    );
  }, [onClose, rightsState, staff, enqueueSnackbar, updateRights]);

  return (
    <Dialog
      fullWidth
      data-testid="select-columns-modal"
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle
        data-testid="edit-staff-rights-modal-title"
        sx={(theme) => ({
          fontSize: theme.spacing(2.25),
          m: 0,
          pb: 0,
          pt: 3.5,
          px: 2,
        })}
      >
        Edit Rights for {staff.firstName} {staff.lastName} ({staff.username})
      </DialogTitle>
      <IconButton
        sx={{
          color: (theme) => theme.palette.grey[900],
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack
          sx={{
            alignItems: "stretch",
            maxHeight: "60vh",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          {keys(rightsState).map((rightCode) => (
            <Fragment key={rightCode}>
              {
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rightsState[rightCode].isActivated}
                      data-testid={`select-columns-modal-column-${kebabCase(
                        rightCode,
                      )}`}
                      size="small"
                      sx={{
                        py: 0.8,
                      }}
                      onChange={(e) => handleRightsStateUpdate(e, rightCode)}
                    />
                  }
                  data-testid="sr-result-entity-filter-item-facet-checkbox"
                  label={rightsState[rightCode].name}
                />
              }
            </Fragment>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pb: 2,
          px: 3,
        }}
      >
        <Button
          data-testid="select-columns-modal-close-btn"
          sx={(theme) => ({
            height: theme.spacing(4.5),
            width: theme.spacing(12),
          })}
          variant="outlined"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          color="primary"
          data-testid="select-columns-modal-save-btn"
          sx={(theme) => ({
            height: theme.spacing(4.5),
            width: theme.spacing(12),
          })}
          variant="contained"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default EditStaffRights;
