import { createSvgIcon } from "@mui/material";

export const FileRemove = createSvgIcon(
  <svg version="1.1" viewBox="0 0 23 31" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="main-nav"
        transform="translate(-1345, -383)"
      >
        <g
          id="file-earmark-minus"
          transform="translate(1356.5, 398.5) rotate(-360) translate(-1356.5, -398.5)translate(1345, 383)"
        >
          <path
            d="M6,18.5 C6,17.6715729 6.44771525,17 7,17 L15,17 C15.5522847,17 16,17.6715729 16,18.5 C16,19.3284271 15.5522847,20 15,20 L7,20 C6.44771525,20 6,19.3284271 6,18.5 Z"
            id="Path"
          ></path>
          <path
            d="M23,8.71875 L23,27.125 C23,29.2651034 21.2837582,31 19.1666667,31 L3.83333333,31 C1.71624179,31 0,29.2651034 0,27.125 L0,3.875 C0,1.73489659 1.71624179,0 3.83333333,0 L14.375,0 L23,8.71875 Z M17.25,8.71875 C15.6621813,8.71875 14.375,7.41757755 14.375,5.8125 L14.375,1.9375 L3.83333333,1.9375 C2.77478756,1.9375 1.91666667,2.8049483 1.91666667,3.875 L1.91666667,27.125 C1.91666667,28.1950517 2.77478756,29.0625 3.83333333,29.0625 L19.1666667,29.0625 C20.2252124,29.0625 21.0833333,28.1950517 21.0833333,27.125 L21.0833333,8.71875 L17.25,8.71875 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>,
  "FileRemove",
);
