import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { memo, useEffect, useRef } from "react";
import { OrderListing } from "../../api";
import { TABLE_ROW_HEIGHT } from "../../constants";
import { useCookiesLive } from "../../hooks";
import { useInfiniteOrders } from "../../hooks/api/queries/useInfiniteOrders";
import theme from "../../theme";
import { getFormattedDate } from "../../utils/datetime";
import { getAppLink } from "../../utils/link";
import { TableCell, TableHeaderCell } from "../ui/Table";

const RecentOrdersTable = memo(() => {
  const [accountCode] = useCookiesLive(["accountCode"]);
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteOrders({
      sort: [
        {
          desc: 1,
          field: "orderNum",
        },
      ],
      ...(accountCode ? { eq: [{ accountCode: [accountCode] }] } : {}),
    });
  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  const orders =
    data?.pages.flatMap((page) => page._embedded?.entities ?? []) ?? [];

  useEffect(() => {
    if (!tableContainerRef.current) {
      return;
    }

    const handleScroll = () => {
      if (tableContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          tableContainerRef.current;
        const distanceToBottom = scrollHeight - (scrollTop + clientHeight);

        if (distanceToBottom < 100 && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      }
    };

    const container = tableContainerRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => container.removeEventListener("scroll", handleScroll);
  }, [hasNextPage, isFetchingNextPage, fetchNextPage, tableContainerRef]);

  const handleOrderRowClick = (order: OrderListing) => {
    window.location.href = getAppLink(
      `CBSDirectRep/OrderDetailsX.php?ordernum=${encodeURIComponent(
        order?.orderNum ?? "",
      )}`,
    );
  };

  return (
    <Box
      ref={tableContainerRef}
      data-testid="rot-container"
      sx={{ maxHeight: "600px", overflowY: `${isLoading ? "hidden" : "auto"}` }}
    >
      <Table data-testid="rot-table">
        <TableHead data-testid="rot-table-header">
          <TableRow>
            <TableHeaderCell>Order Number</TableHeaderCell>
            <TableHeaderCell>PO Number</TableHeaderCell>
            <TableHeaderCell>Date</TableHeaderCell>
            <TableHeaderCell>Status</TableHeaderCell>
            <TableHeaderCell>Subtotal</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody data-testid="rot-table-body">
          {isLoading && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={5}
                data-testid="rot-loading-spinner-cell"
                sx={{ borderBottomWidth: "0" }}
              >
                <CircularProgress className="homepage-table-loading-spinner" />
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            orders.map((order) => (
              <TableRow
                key={order.orderNum}
                sx={{
                  "&:hover": {
                    backgroundColor: theme.palette.grey["100"],
                    cursor: "pointer",
                  },
                  height: TABLE_ROW_HEIGHT,
                }}
                onClick={() => handleOrderRowClick(order)}
              >
                <TableCell>
                  <Typography
                    className="text-link"
                    component="span"
                    sx={{
                      "&:hover": { textDecoration: "underline" },
                      color: theme.palette.blue["400"],
                      fontSize: "14px",
                    }}
                  >
                    {order.orderNum}
                  </Typography>
                </TableCell>
                <TableCell>{order.custPo}</TableCell>
                <TableCell>{getFormattedDate(order.orderDate)}</TableCell>
                <TableCell>{order.repDisplay}</TableCell>
                <TableCell>${order.subtotal?.toLocaleString()}</TableCell>
              </TableRow>
            ))}
          {isFetchingNextPage && (
            <TableRow>
              <TableCell colSpan={5} data-testid="rot-loading-spinner-cell">
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
});

export default RecentOrdersTable;
