import { createSvgIcon } from "@mui/material";

export const CalendarIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g
      fill="none"
      fillRule="evenodd"
      id="Order-Line-Report"
      stroke="none"
      strokeWidth="1"
    >
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="main-nav"
        transform="translate(-342.000000, -371.000000)"
      >
        <g id="Group-16" transform="translate(29.000000, 254.000000)">
          <g id="date-filter" transform="translate(298.000000, 107.000000)">
            <g id="calendar2-week" transform="translate(15.000000, 10.000000)">
              <path
                d="M3.5,0 C3.77614237,0 4,0.223857625 4,0.5 L4,1 L12,1 L12,0.5 C12,0.223857625 12.2238576,0 12.5,0 C12.7761424,0 13,0.223857625 13,0.5 L13,1 L14,1 C15.1045695,1 16,1.8954305 16,3 L16,14 C16,15.1045695 15.1045695,16 14,16 L2,16 C0.8954305,16 0,15.1045695 0,14 L0,3 C0,1.8954305 0.8954305,1 2,1 L3,1 L3,0.5 C3,0.223857625 3.22385763,0 3.5,0 Z M2,2 C1.44771525,2 1,2.44771525 1,3 L1,14 C1,14.5522847 1.44771525,15 2,15 L14,15 C14.5522847,15 15,14.5522847 15,14 L15,3 C15,2.44771525 14.5522847,2 14,2 L2,2 Z"
                id="Shape"
              ></path>
              <path
                d="M2.5,4 C2.5,3.72385763 2.72385763,3.5 3,3.5 L13,3.5 C13.2761424,3.5 13.5,3.72385763 13.5,4 L13.5,5 C13.5,5.27614237 13.2761424,5.5 13,5.5 L3,5.5 C2.72385763,5.5 2.5,5.27614237 2.5,5 L2.5,4 Z M11,7.5 C11,7.22385763 11.2238576,7 11.5,7 L12.5,7 C12.7761424,7 13,7.22385763 13,7.5 L13,8.5 C13,8.77614237 12.7761424,9 12.5,9 L11.5,9 C11.2238576,9 11,8.77614237 11,8.5 L11,7.5 Z M8,7.5 C8,7.22385763 8.22385763,7 8.5,7 L9.5,7 C9.77614237,7 10,7.22385763 10,7.5 L10,8.5 C10,8.77614237 9.77614237,9 9.5,9 L8.5,9 C8.22385763,9 8,8.77614237 8,8.5 L8,7.5 Z M3,10.5 C3,10.2238576 3.22385763,10 3.5,10 L4.5,10 C4.77614237,10 5,10.2238576 5,10.5 L5,11.5 C5,11.7761424 4.77614237,12 4.5,12 L3.5,12 C3.22385763,12 3,11.7761424 3,11.5 L3,10.5 Z M6,10.5 C6,10.2238576 6.22385763,10 6.5,10 L7.5,10 C7.77614237,10 8,10.2238576 8,10.5 L8,11.5 C8,11.7761424 7.77614237,12 7.5,12 L6.5,12 C6.22385763,12 6,11.7761424 6,11.5 L6,10.5 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "Calendar",
);
