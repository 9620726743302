import { DetailPageEmailDto, FormValues } from "../types/DetailPage";

export const getEmailDetailPayload = (
  emailType: string,
  values: FormValues,
  ids: (number | undefined)[],
) => {
  const emailList = values.email.split(",").map((email) => email.trim());

  const payload: DetailPageEmailDto = {
    to: emailList,
  };

  if (values.message) {
    payload.body = values.message;
  }

  if (values.cc) {
    payload.cc = values.cc.split(",").map((email) => email.trim());
  }

  if (values.subject) {
    payload.subject = values.subject;
  }

  if (emailType === "Order") {
    payload.orderNums = ids as number[];
  }

  if (emailType === "Quote") {
    payload.quoteIds = ids as number[];
  }

  if (emailType === "Invoice") {
    payload.invoiceNums = ids as number[];
  }

  return payload;
};
