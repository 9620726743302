/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BundleDto } from '../models/BundleDto';
import type { BundlePatchRequestDto } from '../models/BundlePatchRequestDto';
import type { BundlePostRequestDto } from '../models/BundlePostRequestDto';
import type { BundleResponseDto } from '../models/BundleResponseDto';
import type { CategoryGroupPatchRequestDto } from '../models/CategoryGroupPatchRequestDto';
import type { CategoryGroupPostRequestDto } from '../models/CategoryGroupPostRequestDto';
import type { PrimaryPackageDto } from '../models/PrimaryPackageDto';
import type { RestCollection_Standard_ } from '../models/RestCollection_Standard_';
import type { StandardCatalogCategoryDto } from '../models/StandardCatalogCategoryDto';
import type { StandardCatalogCategoryPatchDto } from '../models/StandardCatalogCategoryPatchDto';
import type { StandardCatalogCategoryRequestDto } from '../models/StandardCatalogCategoryRequestDto';
import type { StandardCatalogDto } from '../models/StandardCatalogDto';
import type { StandardCatalogGroupDto } from '../models/StandardCatalogGroupDto';
import type { StandardResponseDto } from '../models/StandardResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class StandardsResourceService {
  /**
   * Get standards list
   * Get an standards list
   * @param criteria criteria
   * @param page page
   * @param returnAllLabels returnAllLabels
   * @param returnPagination returnPagination
   * @param returnSavedViews returnSavedViews
   * @param size size
   * @param useDefaultView useDefaultView
   * @returns RestCollection_Standard_ Success
   * @throws ApiError
   */
  public static listUsingGet25(
    criteria: string = '{}',
    page: number = 1,
    returnAllLabels: boolean = false,
    returnPagination: boolean = true,
    returnSavedViews: boolean = true,
    size: number = 50,
    useDefaultView: boolean = false,
  ): CancelablePromise<RestCollection_Standard_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/listing/standards',
      query: {
        'criteria': criteria,
        'page': page,
        'returnAllLabels': returnAllLabels,
        'returnPagination': returnPagination,
        'returnSavedViews': returnSavedViews,
        'size': size,
        'useDefaultView': useDefaultView,
      },
      errors: {
        401: `Unauthorized`,
        403: `You are not authorized to access this account.`,
        404: `Standards list not found.`,
        500: `Internal Error retrieving standards list`,
      },
    });
  }
  /**
   * POST Bundle
   * POST Bundle
   * @param bundlePostRequestDto bundlePostRequestDto
   * @param accountId accountId
   * @returns BundleResponseDto Success
   * @throws ApiError
   */
  public static createBundleV1UsingPost(
    bundlePostRequestDto: BundlePostRequestDto,
    accountId?: number,
  ): CancelablePromise<BundleResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/standards',
      query: {
        'accountId': accountId,
      },
      body: bundlePostRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Find Standard Catalog by Account
   * Find Standard Catalog by Account
   * @param accountCode accountCode
   * @returns StandardCatalogDto Success
   * @throws ApiError
   */
  public static getStandardCatalogUsingGet(
    accountCode?: string,
  ): CancelablePromise<StandardCatalogDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/standards/catalog',
      query: {
        'accountCode': accountCode,
      },
      errors: {
        401: `Unauthorized`,
        403: `You do not have the required permissions to access this resource`,
        404: `Standard Catalog Not Found`,
        500: `Internal Error Finding Standard Catalog By Account`,
      },
    });
  }
  /**
   * Create Standard Category
   * create Standard Category
   * @param request request
   * @param accountId accountId
   * @returns any Success
   * @returns StandardCatalogCategoryDto Created
   * @throws ApiError
   */
  public static createStandardCategoryUsingPost(
    request: StandardCatalogCategoryRequestDto,
    accountId?: number,
  ): CancelablePromise<any | StandardCatalogCategoryDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/standards/catalog',
      query: {
        'accountId': accountId,
      },
      body: request,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `You do not have the required permissions to access this resource`,
        404: `Not Found`,
        500: `Internal Error Finding Standard Category By standard category id`,
      },
    });
  }
  /**
   * Find Standard Catalog Groups by Account
   * Find Standard Catalog Groups by Account
   * @param accountId accountId
   * @returns StandardCatalogGroupDto Success
   * @throws ApiError
   */
  public static getStandardCatalogGroupsUsingGet(
    accountId?: number,
  ): CancelablePromise<Array<StandardCatalogGroupDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/standards/catalog/groups',
      query: {
        'accountId': accountId,
      },
      errors: {
        401: `Unauthorized`,
        403: `You do not have the required permissions to access this resource`,
        404: `Standard Catalog Groups Not Found`,
        500: `Internal Error Finding Standard Catalog Groups By Account`,
      },
    });
  }
  /**
   * POST Category Group
   * POST Category Group
   * @param categoryGroupPostRequest categoryGroupPostRequest
   * @param accountId accountId
   * @returns any Success
   * @returns StandardCatalogGroupDto Created
   * @throws ApiError
   */
  public static createCategoryGroupUsingPost(
    categoryGroupPostRequest: CategoryGroupPostRequestDto,
    accountId?: number,
  ): CancelablePromise<any | StandardCatalogGroupDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/standards/catalog/groups',
      query: {
        'accountId': accountId,
      },
      body: categoryGroupPostRequest,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Find Standard Catalog Groups by Account
   * Find Standard Catalog Groups by Account
   * @param categoryGroupId categoryGroupId
   * @returns StandardCatalogGroupDto Success
   * @throws ApiError
   */
  public static getStandardCatalogGroupUsingGet(
    categoryGroupId: number,
  ): CancelablePromise<StandardCatalogGroupDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/standards/catalog/groups/{categoryGroupId}',
      path: {
        'categoryGroupId': categoryGroupId,
      },
      errors: {
        401: `Unauthorized`,
        403: `You do not have the required permissions to access this resource`,
        404: `Standard Catalog Groups Not Found`,
        500: `Internal Error Finding Standard Catalog Groups By Account`,
      },
    });
  }
  /**
   * DELETE Category Group
   * DELETE Category Group
   * @param categoryGroupId categoryGroupId
   * @returns any Success
   * @throws ApiError
   */
  public static deleteCategoryGroupUsingDelete(
    categoryGroupId: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/service/rest/standards/catalog/groups/{categoryGroupId}',
      path: {
        'categoryGroupId': categoryGroupId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * PATCH Category Group
   * PATCH Category Group
   * @param categoryGroupId categoryGroupId
   * @param categoryGroupPatchRequestDto categoryGroupPatchRequestDto
   * @returns StandardCatalogGroupDto Success
   * @throws ApiError
   */
  public static patchCategoryGroupUsingPatch(
    categoryGroupId: number,
    categoryGroupPatchRequestDto: CategoryGroupPatchRequestDto,
  ): CancelablePromise<StandardCatalogGroupDto> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/standards/catalog/groups/{categoryGroupId}',
      path: {
        'categoryGroupId': categoryGroupId,
      },
      body: categoryGroupPatchRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Find Standard Category
   * Find Standard Category
   * @param standardCategoryId standardCategoryId
   * @returns StandardCatalogCategoryDto Success
   * @throws ApiError
   */
  public static getStandardCategoryUsingGet(
    standardCategoryId: number,
  ): CancelablePromise<StandardCatalogCategoryDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/standards/catalog/{standardCategoryId}',
      path: {
        'standardCategoryId': standardCategoryId,
      },
      errors: {
        401: `Unauthorized`,
        403: `You do not have the required permissions to access this resource`,
        404: `Standard Category Not Found`,
        500: `Internal Error Finding Standard Category By standard category id`,
      },
    });
  }
  /**
   * Delete Standard Category
   * Delete Standard Category
   * @param standardCategoryId standardCategoryId
   * @returns any Success
   * @throws ApiError
   */
  public static deleteStandardCategoryUsingDelete(
    standardCategoryId: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/service/rest/standards/catalog/{standardCategoryId}',
      path: {
        'standardCategoryId': standardCategoryId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `You do not have the required permissions to access this resource`,
        404: `Not Found`,
        500: `Internal Error Deleting Standard Category By standard category id`,
      },
    });
  }
  /**
   * Delete Standard Category
   * Delete Standard Category
   * @param request request
   * @param standardCategoryId standardCategoryId
   * @returns StandardCatalogCategoryDto Success
   * @throws ApiError
   */
  public static updateStandardCategoryUsingPatch(
    request: StandardCatalogCategoryPatchDto,
    standardCategoryId: number,
  ): CancelablePromise<StandardCatalogCategoryDto> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/standards/catalog/{standardCategoryId}',
      path: {
        'standardCategoryId': standardCategoryId,
      },
      body: request,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `You do not have the required permissions to access this resource`,
        404: `Not Found`,
        500: `Internal Error Patching Standard Category By standard category id`,
      },
    });
  }
  /**
   * Find Standards by Standard Category Id
   * Find Standards by Standard Category Id
   * @param standardCategoryId standardCategoryId
   * @param accountCode accountCode
   * @param page page
   * @param size size
   * @returns StandardResponseDto Success
   * @throws ApiError
   */
  public static getOrderByStandardCategoryIdUsingGet(
    standardCategoryId: number,
    accountCode?: string,
    page?: number,
    size?: number,
  ): CancelablePromise<StandardResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/standards/catalog/{standardCategoryId}/standards',
      path: {
        'standardCategoryId': standardCategoryId,
      },
      query: {
        'accountCode': accountCode,
        'page': page,
        'size': size,
      },
      errors: {
        401: `Unauthorized`,
        403: `You do not have the required permissions to access this resource`,
        404: `Standard Catalog Category Not Found`,
        500: `Internal Error Finding Standard Catalog Category but Category Id`,
      },
    });
  }
  /**
   * Validate bundle
   * Validate bundle
   * @param bundlePostRequestDto bundlePostRequestDto
   * @param accountId accountId
   * @returns any Success
   * @throws ApiError
   */
  public static validateBundleUsingPost(
    bundlePostRequestDto: BundlePostRequestDto,
    accountId?: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/standards/validation',
      query: {
        'accountId': accountId,
      },
      body: bundlePostRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Find Bundle By Id
   * Find Bundle By Id
   * @param standardId standardId
   * @param expand expand
   * @returns BundleDto Success
   * @throws ApiError
   */
  public static getBundleByIdUsingGet(
    standardId: number,
    expand?: string,
  ): CancelablePromise<BundleDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/standards/{standardId}',
      path: {
        'standardId': standardId,
      },
      query: {
        'expand': expand,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal Error Finding Bundle By Id`,
      },
    });
  }
  /**
   * Delete Bundle
   * Delete Bundle
   * @param standardId standardId
   * @returns any Success
   * @throws ApiError
   */
  public static deleteUsingDelete9(
    standardId: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/service/rest/standards/{standardId}',
      path: {
        'standardId': standardId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Bundle Not Found`,
        500: `Internal Error Deleting Bundle`,
      },
    });
  }
  /**
   * PATCH Bundle
   * PATCH Bundle
   * @param bundlePatchRequest bundlePatchRequest
   * @param standardId standardId
   * @returns any Success
   * @throws ApiError
   */
  public static updateBundleUsingPatch(
    bundlePatchRequest: BundlePatchRequestDto,
    standardId: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/standards/{standardId}',
      path: {
        'standardId': standardId,
      },
      body: bundlePatchRequest,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Find Primary Package By Bundle Id
   * Find Primary Package By Bundle Id
   * @param standardId standardId
   * @returns PrimaryPackageDto Success
   * @throws ApiError
   */
  public static getPrimaryPackageByIdUsingGet(
    standardId: number,
  ): CancelablePromise<Array<PrimaryPackageDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/standards/{standardId}/primaryPackage',
      path: {
        'standardId': standardId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Internal Error Finding Primary Package By Bundle Id`,
      },
    });
  }
}
