import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { OpenAPI } from "../../api";
import { ErpAccountRequestDto } from "../../api/models/ErpAccountRequestDto";
import { useClientResourceCreate } from "../../hooks/api/mutations/useClientResourceCreate";
import { useClientResourceUpdate } from "../../hooks/api/mutations/useClientResourceUpdate";
import { useClientResource } from "../../hooks/api/queries/useClientResource";
import { splitStringOnCommas } from "../../utils/string";
import { Button, LoadingButton, TextField } from "../ui";

type FormValues = {
  crmClientCode?: string;
  erpAccounts?: ErpAccountRequestDto[];
  erp?: string;
  erpAccountCode: string;
};

enum ERP {
  E1 = "E1",
  TRAXX = "TRAXX",
}

const DEFAULT_CRM = "Salesforce";

const getTraxxAccount = async (accountCode: string) => {
  return axios.get(`/service/rest/account/${accountCode}`, {
    baseURL: OpenAPI.BASE,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${OpenAPI.TOKEN}`,
    },
    responseType: "json",
  });
};

const schema = yup.object({
  crmClientCode: yup
    .string()
    .required("Please enter a valid Salesforce ID.")
    .length(18, "Must be 18 alphanumeric characters."),
  erp: yup.string().required("Please select the ERP."),
  erpAccountCode: yup
    .string()
    .required("Please enter the ERP Account(s).")
    .test({
      name: "erpAccountCode",
      test: async (value, context) => {
        const testValue = splitStringOnCommas(value);
        if (!value) {
          return context.createError({
            message: "Please enter the ERP Account(s).",
          });
        }
        if (context.parent.erp === ERP.TRAXX && testValue.length > 1) {
          return context.createError({
            message: "Must be a single TRAXX Account.",
          });
        } else if (
          context.parent.erp === ERP.TRAXX &&
          testValue[0].length !== 6
        ) {
          return context.createError({
            message: "Must be a single, valid TRAXX Account.",
          });
        } else if (context.parent.erp === ERP.TRAXX) {
          try {
            await getTraxxAccount(testValue[0]);
          } catch (err) {
            return context.createError({
              message: "Must be a single, valid TRAXX Account.",
            });
          }
        }

        const invalidAccounts = testValue.filter(
          (checkValue) =>
            checkValue.length > 10 || !Number.isInteger(+checkValue),
        );
        if (
          context.parent.erp === ERP.E1 &&
          testValue.length > 0 &&
          invalidAccounts.length
        ) {
          return context.createError({
            message:
              "Must be one or more integers up to 10 digits separated by commas.",
          });
        }

        return true;
      },
    }),
  erpAccounts: yup.array(),
});

const FormFieldLabel = styled(Typography)(({ theme }) => ({
  flexShrink: 0,
  fontSize: 14,
  fontWeight: "medium",
  lineHeight: theme.spacing(4.5),
  width: theme.spacing(14),
}));

const ClientModal = ({
  clientId,
  isOpen,
  onClose,
}: {
  clientId?: number;
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={onClose}>
      {true ? (
        <ClientForm clientId={clientId || undefined} onClose={onClose} />
      ) : (
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "center",
            m: theme.spacing(1.5),
          })}
        >
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};

const ClientForm = ({
  clientId,
  onClose,
}: {
  clientId?: number;
  onClose: () => void;
}) => {
  const { data: client } = useClientResource(clientId);
  const { mutate: createClient, isLoading: isCreateLoading } =
    useClientResourceCreate();
  const { mutate: updateClient, isLoading: isUpdateLoading } =
    useClientResourceUpdate();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    crmClientCode: client?.crmClientCode,
    erp: client?.erpAccounts ? client?.erpAccounts[0].erp : "",
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver<FormValues>(schema),
  });
  const erpAccountType = watch("erp");
  const getErpAccountsPayload = (erp: string, erpAccountCodes: string) => {
    return splitStringOnCommas(erpAccountCodes).map((erpAccount) => {
      return {
        erp: erp,
        erpAccountCode: erpAccount,
      };
    });
  };

  useEffect(() => {
    reset(client);
  }, [reset, client]);

  useEffect(() => {
    if (client && client.erpAccounts && client.erpAccounts.length) {
      const erpAccountCodes = client.erpAccounts.map(
        (value) => value.erpAccountCode,
      );
      setValue("erp", client?.erpAccounts[0].erp);
      setValue(
        "erpAccountCode",
        erpAccountCodes ? erpAccountCodes.join(", ") : "",
      );
    }
  }, [client, setValue]);

  const onSubmit = useCallback(
    (values: FormValues) => {
      if (client) {
        updateClient(
          {
            clientId: client?.clientId ?? 0,
            request: {
              crm: DEFAULT_CRM,
              crmClientCode: values.crmClientCode ?? "",
              erpAccounts: getErpAccountsPayload(
                values.erp ?? "",
                values.erpAccountCode,
              ),
            },
          },
          {
            onError: (error) => {
              enqueueSnackbar({
                message: error.message
                  ? error.message
                  : `Unable to update CRM Client ${values.crmClientCode}.`,
                variant: "error",
              });
            },
            onSuccess: () => {
              enqueueSnackbar({
                message: `CRM Client ${values.crmClientCode} successfully updated`,
                variant: "success",
              });
              onClose();
            },
          },
        );
      } else {
        createClient(
          {
            request: {
              crm: DEFAULT_CRM,
              crmClientCode: values.crmClientCode ?? "",
              erpAccounts: getErpAccountsPayload(
                values.erp ?? "",
                values.erpAccountCode,
              ),
            },
          },
          {
            onError: (error) => {
              enqueueSnackbar({
                message: error.message
                  ? error.message
                  : `Unable to update CRM Client ${values.crmClientCode}.`,
                variant: "error",
              });
            },
            onSuccess: () => {
              enqueueSnackbar({
                message: `CRM Client ${values.crmClientCode} successfully added`,
                variant: "success",
              });
              onClose();
            },
          },
        );
      }
    },
    [client, createClient, enqueueSnackbar, onClose, updateClient],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle
        sx={(theme) => ({
          fontSize: theme.spacing(2.25),
          m: 0,
          pb: 0,
          pt: 3.5,
          px: 2,
        })}
      >
        {client ? "Edit CRM Client" : "Add CRM Client"}
      </DialogTitle>
      <IconButton
        sx={{
          color: (theme) => theme.palette.grey[900],
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box display="flex" gap={3}>
          <FormFieldLabel>Salesforce ID</FormFieldLabel>
          <TextField
            fullWidth
            InputProps={{
              endAdornment: !!errors.crmClientCode ? (
                <InputAdornment position="end">
                  <ErrorOutlineIcon
                    sx={(theme) => ({
                      color: theme.palette.red["500"],
                      mr: theme.spacing(1),
                    })}
                  />
                </InputAdornment>
              ) : null,
            }}
            data-testid="manage-crm-client-salesforce-id"
            error={!!errors.crmClientCode}
            helperText={errors.crmClientCode?.message ?? ""}
            size="small"
            {...register("crmClientCode")}
          />
        </Box>
        <Box display="flex" gap={3} mt={2}>
          <FormFieldLabel>ERP</FormFieldLabel>
          <Box>
            <RadioGroup
              data-testid="manage-crm-client-erp"
              value={
                client?.erpAccounts && !erpAccountType
                  ? client?.erpAccounts[0].erp
                  : erpAccountType
              }
            >
              <FormControlLabel
                control={
                  <Radio
                    size="small"
                    sx={(theme) => ({
                      pb: theme.spacing(0.5),
                      pt: theme.spacing(0.5),
                    })}
                    {...register("erp")}
                    value={ERP.E1}
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: 14,
                    }}
                  >
                    {ERP.E1}
                  </Typography>
                }
                value={ERP.E1}
              />
              <FormControlLabel
                control={
                  <Radio
                    size="small"
                    sx={(theme) => ({
                      pb: theme.spacing(0.5),
                      pt: theme.spacing(0.5),
                    })}
                    {...register("erp")}
                    value={ERP.TRAXX}
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: 14,
                    }}
                  >
                    {ERP.TRAXX}
                  </Typography>
                }
                value={ERP.TRAXX}
              />
            </RadioGroup>
            {errors["erp"]?.message && (
              <Box sx={{ pl: "14px" }}>
                <FormHelperText error>
                  {String(errors["erp"]?.message ?? "")}
                </FormHelperText>
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex" gap={3} mt={2}>
          <FormFieldLabel>ERP Account(s)</FormFieldLabel>
          <TextField
            data-testid="manage-crm-client-erp-accounts"
            {...register("erpAccountCode")}
            fullWidth
            multiline
            InputProps={{
              endAdornment: !!errors.erpAccountCode ? (
                <InputAdornment position="end">
                  <ErrorOutlineIcon
                    sx={(theme) => ({
                      color: theme.palette.red["500"],
                      mr: theme.spacing(1),
                    })}
                  />
                </InputAdornment>
              ) : null,
            }}
            error={!!errors.erpAccountCode}
            helperText={errors.erpAccountCode?.message ?? ""}
            rows={5}
            size="small"
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pb: 2,
          px: 3,
        }}
      >
        <Button
          data-testid="manage-crm-client-close-btn"
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          data-testid="manage-crm-client-save-btn"
          loading={isCreateLoading || isUpdateLoading}
          type="submit"
          variant="contained"
        >
          {client ? "Save" : "Add"}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default ClientModal;
