import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSearchResultsContext } from "../SearchResult";
import { TextField } from "../ui";

const ProductSearchBox = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<string>("");
  const { onClearFacets } = useSearchResultsContext();

  const updateSearchParams = useRef(
    debounce((terms: string) => {
      onClearFacets();
      setSearchParams((prev) => {
        prev.set("terms", terms);
        return prev;
      });
      if (!window.location.pathname.includes("/results")) {
        navigate(`/mui/results?terms=${terms}`);
      }
    }, 500),
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    updateSearchParams.current(event.target.value);
  };

  useEffect(() => {
    setValue(searchParams.get("terms") ?? "");
  }, [searchParams]);

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <SearchIcon sx={{ color: (theme) => theme.palette.grey["800"] }} />
        ),
      }}
      placeholder="Search products, assets, quotes, orders, invoices and more"
      size="medium"
      sx={(theme) => ({
        "& .MuiInputBase-input": {
          padding: `0 ${theme.spacing(1.5)}`,
        },
        "& .MuiInputBase-root": {
          pl: 2,
        },
        width: theme.spacing(82.5),
      })}
      value={value}
      onChange={handleChange}
    />
  );
};

export default ProductSearchBox;
