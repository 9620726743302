import { useQuery } from "@tanstack/react-query";
import { ProductsResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useProductAvailabilityPricing = (
  accountCode?: string,
  productId?: number,
) => {
  return useQuery(
    [QUERY_KEYS["product-availability-pricing"], accountCode, productId],
    () =>
      ProductsResourceService.getProductAvailabilityAndPricingByProductIdUsingGet(
        accountCode!,
        productId!,
      ),
    { enabled: !!accountCode && !!productId },
  );
};
