import { createSvgIcon } from "@mui/material";

export const SortDescIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 15 11" xmlns="http://www.w3.org/2000/svg">
    <g
      fill="none"
      fillRule="evenodd"
      id="Order-Line-Report"
      stroke="none"
      strokeWidth="1"
    >
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="main-nav"
        transform="translate(-143.000000, -317.000000)"
      >
        <g id="Group-16" transform="translate(29.000000, 254.000000)">
          <g id="sort-down" transform="translate(114.000000, 63.000000)">
            <path
              d="M3.00063158,0.5 C3.00063158,0.223857625 2.77677396,0 2.50063158,0 C2.22448921,0 2.00063158,0.223857625 2.00063158,0.5 L2.00063158,9.293 L0.854631584,8.146 C0.65912278,7.95049121 0.342140396,7.95049122 0.146631601,8.14600002 C-0.0488771938,8.34150881 -0.0488772013,8.6584912 0.146631584,8.854 L2.14663158,10.853 L2.15363158,10.86 C2.34903334,11.0505381 2.66152466,11.0478596 2.85363158,10.854 L4.85363158,8.854 C4.9801036,8.72770661 5.02959185,8.54353469 4.98345458,8.37086002 C4.93731731,8.19818535 4.80256387,8.06324131 4.62995458,8.01686002 C4.45734529,7.97047873 4.2731036,8.01970661 4.14663158,8.146 L3.00063158,9.293 L3.00063158,0.5 Z M6.50063158,1.5 C6.50063158,1.22385763 6.72448921,1 7.00063158,1 L14.0006316,1 C14.276774,1 14.5006316,1.22385763 14.5006316,1.5 C14.5006316,1.77614237 14.276774,2 14.0006316,2 L7.00063158,2 C6.72448921,2 6.50063158,1.77614237 6.50063158,1.5 Z M7.00063158,4 C6.72448921,4 6.50063158,4.22385763 6.50063158,4.5 C6.50063158,4.77614237 6.72448921,5 7.00063158,5 L12.0006316,5 C12.276774,5 12.5006316,4.77614237 12.5006316,4.5 C12.5006316,4.22385763 12.276774,4 12.0006316,4 L7.00063158,4 Z M7.00063158,7 C6.72448921,7 6.50063158,7.22385763 6.50063158,7.5 C6.50063158,7.77614237 6.72448921,8 7.00063158,8 L10.0006316,8 C10.276774,8 10.5006316,7.77614237 10.5006316,7.5 C10.5006316,7.22385763 10.276774,7 10.0006316,7 L7.00063158,7 Z M7.00063158,10 C6.72448921,10 6.50063158,10.2238576 6.50063158,10.5 C6.50063158,10.7761424 6.72448921,11 7.00063158,11 L8.00063158,11 C8.27677396,11 8.50063158,10.7761424 8.50063158,10.5 C8.50063158,10.2238576 8.27677396,10 8.00063158,10 L7.00063158,10 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "SortDesc",
);
