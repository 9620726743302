import { Box, Popover, Typography } from "@mui/material";
import { useState } from "react";
import { usePurchaseAnalysisContext } from "../../contexts/PurchaseAnalysisContext";
import {
  PurchaseAnalysisChartConfigKey,
  PurchaseAnalysisChartOption1,
} from "../../types";
import { CalendarIcon, FilterIcon } from "../icons";
import { Button } from "../ui";
import ChartDateFilterContent from "./ChartDateFilterContent";

const ChartDateFilterButton = ({
  configKey,
  hasAllTime,
}: {
  configKey: PurchaseAnalysisChartConfigKey;
  hasAllTime?: boolean;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { config } = usePurchaseAnalysisContext();
  const chartConfig = config[configKey] as PurchaseAnalysisChartOption1 | null;

  const isOpen = Boolean(anchorEl);
  const onClose = () => setAnchorEl(null);

  return (
    <>
      <Button
        data-testid="chart-date-filter-btn"
        size="small"
        sx={(theme) => ({
          "&:active": {
            backgroundColor: theme.palette.grey["50"],
          },
          "&:hover": {
            backgroundColor: theme.palette.grey["50"],
            borderColor: isOpen
              ? theme.palette.blue["700"]
              : theme.palette.grey["500"],
          },
          backgroundColor: theme.palette.grey["50"],
          borderColor: isOpen
            ? theme.palette.blue["300"]
            : theme.palette.grey["200"],
          borderStyle: "solid",
          borderWidth: 1,
          color: isOpen ? theme.palette.blue["600"] : "inherit",
          gap: theme.spacing(1),
          height: theme.spacing(4.5),
          px: theme.spacing(1.5),
        })}
        onClick={(e) =>
          isOpen ? setAnchorEl(null) : setAnchorEl(e.currentTarget)
        }
      >
        <Typography sx={(theme) => ({ fontSize: 14, mr: theme.spacing(1) })}>
          {chartConfig?.period}
        </Typography>
        <CalendarIcon sx={{ fontSize: 14 }} />
        <FilterIcon sx={{ fontSize: 14 }} />
      </Button>
      <Popover
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        open={isOpen}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        onClose={onClose}
      >
        <Box
          sx={(theme) => ({
            "&:focus-visible": {
              boxShadow: "0px 8px 16px rgba(224,226,228, 1)",
              outline: "none",
            },
            border: "none",
            boxShadow: "0px 8px 16px rgba(224,226,228, 1)",
            p: theme.spacing(0.5),
            width: "50rem",
          })}
        >
          <ChartDateFilterContent
            configKey={configKey}
            hasAllTime={hasAllTime}
            onClose={onClose}
          />
        </Box>
      </Popover>
    </>
  );
};

export default ChartDateFilterButton;
