import { yupResolver } from "@hookform/resolvers/yup";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Box,
  FormHelperText,
  InputAdornment,
  MenuItem,
  styled,
  TableRow,
} from "@mui/material";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { BlanketPoResponseDto } from "../../../api";
import {
  STORED_IN_ALLOWWED_DEFAULT_HEADERS,
  STORED_IN_HEADER_MAPPING,
} from "../../../constants";
import { useCookiesLive } from "../../../hooks";
import { useBlanketPoCreate } from "../../../hooks/api/mutations/useBlanketPoCreate";
import { useBlanketPoUpdate } from "../../../hooks/api/mutations/useBlanketPoUpdate";
import { useAccount } from "../../../hooks/api/queries";
import theme from "../../../theme";
import { formatCurrency } from "../../../utils/currency";
import { FileEdit, FileRemove } from "../../icons";
import { Button, IconButton, TextField } from "../../ui";
import { LoadingButton } from "../../ui/LoadingButton/LoadingButton";
import { TableCell } from "../../ui/Table";
import BlanketPOField from "./BlanketPOField";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 0,
  h: 12,
  paddingBottom: 0,
  paddingTop: 0,
  position: "sticky",
  px: 16,
  top: 0,
  zIndex: 1,
}));

const StyledInputTableCell = styled(StyledTableCell)(({ theme }) => ({
  border: 0,
  paddingBottom: "12px",
  paddingTop: "12px",
  verticalAlign: "top",
}));

type FormValues = {
  description?: string;
  po: string;
  storedInHeaderField: string;
  value: number;
};

const schema = yup
  .object({
    description: yup.string(),
    po: yup
      .string()
      .required("Please enter a valid Blanket PO.")
      .max(40, "Only 40 characters are allowed."),
    storedInHeaderField: yup
      .string()
      .required("Please select a Stored In Field."),
    value: yup
      .number()
      .typeError("Please enter a valid value.")
      .required("Please enter a valid value.")
      .min(0.01, "Must be greater than $0.")
      .max(99999999.99, "Must be less than $100,000,000."),
  })
  .required();

const BlanketPOsTableRow = ({
  blanketPo,
  onClickEdit,
  onClickCancel,
  onClickDelete,
  isEditing = false,
}: {
  blanketPo?: BlanketPoResponseDto;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  onClickCancel: () => void;
  isEditing?: boolean;
}) => {
  const [accountId, accountCode] = useCookiesLive(["accountId", "accountCode"]);
  const { enqueueSnackbar } = useSnackbar();
  const { data: accountData } = useAccount(accountCode);
  const { mutate: createBlanketPo, isLoading: isCreatingBlanketPo } =
    useBlanketPoCreate();
  const { mutate: updateBlanketPo, isLoading: isUpdatingBlanketPo } =
    useBlanketPoUpdate(accountId ? +accountId : undefined);
  const isLoading = isCreatingBlanketPo || isUpdatingBlanketPo;

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormValues>({
    defaultValues: {
      description: blanketPo?.description ?? "",
      po: blanketPo?.po ?? "",
      storedInHeaderField: blanketPo?.storedInHeaderField ?? "",
      value: blanketPo?.value ?? undefined,
    },
    resolver: yupResolver<FormValues>(schema),
  });

  const getStoredInHeaderItemName = (
    storedInHeaderField?: string,
    po?: string,
  ) => {
    if (accountData && storedInHeaderField && po) {
      const headerItem = accountData.metaDataFields?.standardHeader?.find(
        (header) => header.label === storedInHeaderField,
      );
      if (headerItem?.name && STORED_IN_HEADER_MAPPING[headerItem.name]) {
        return {
          [STORED_IN_HEADER_MAPPING[headerItem.name]]: po,
        };
      } else {
        const additionalHeaderItem =
          accountData.metaDataFields?.additionalHeaders?.find(
            (header) => header.label === storedInHeaderField,
          );
        if (additionalHeaderItem?.id) {
          return {
            [`customField${additionalHeaderItem.id}`]: po,
          };
        }
      }
    }
    return {};
  };

  const handleClickCancel = useCallback(() => {
    onClickCancel();
  }, [onClickCancel]);

  const onSubmit = useCallback(
    (values: FormValues) => {
      if (blanketPo) {
        updateBlanketPo(
          {
            blanketPoId: blanketPo.blanketPoId!,
            request: {
              ...values,
              description: (values.description
                ? values.description
                : null) as any,
            },
          },
          {
            onError: (error) => {
              enqueueSnackbar({
                message: error.message
                  ? error.message
                  : "Unable to update Blanket PO.",
                variant: "error",
              });
            },
            onSettled: () => {
              handleClickCancel();
            },
            onSuccess: () => {
              enqueueSnackbar({
                message: "Blanket PO has been updated.",
                variant: "success",
              });
            },
          },
        );
      } else {
        createBlanketPo(
          {
            accountId: accountId ? +accountId : undefined,
            request: {
              ...values,
              description: (values.description
                ? values.description
                : null) as any,
            },
          },
          {
            onError: (error) => {
              enqueueSnackbar({
                message: error.message
                  ? error.message
                  : "Unable to create Blanket PO.",
                variant: "error",
              });
            },
            onSettled: () => {
              handleClickCancel();
            },
            onSuccess: () => {
              enqueueSnackbar({
                message: "Blanket PO has been created.",
                variant: "success",
              });
            },
          },
        );
      }
    },
    [
      accountId,
      blanketPo,
      createBlanketPo,
      enqueueSnackbar,
      handleClickCancel,
      updateBlanketPo,
    ],
  );

  const storedInHeaderValues = useMemo(
    () => [
      ...STORED_IN_ALLOWWED_DEFAULT_HEADERS,
      ...(accountData?.metaDataFields?.additionalHeaders ?? [])
        .filter((header) => header.enabled)
        .map((header) => header.label),
    ],

    [accountData],
  );

  return (
    <>
      {blanketPo && !isEditing ? (
        <TableRow
          sx={{ "&:hover": { backgroundColor: theme.palette.grey["50"] } }}
        >
          <StyledTableCell>{blanketPo.po}</StyledTableCell>
          <StyledTableCell>{blanketPo.description}</StyledTableCell>
          <StyledTableCell>
            {blanketPo.storedInHeaderField?.toUpperCase()}
          </StyledTableCell>
          <StyledTableCell>{formatCurrency(blanketPo.value)}</StyledTableCell>
          <StyledTableCell>
            <BlanketPOField
              fieldKey="invoiceTotal"
              fs={getStoredInHeaderItemName(
                blanketPo.storedInHeaderField,
                blanketPo.po,
              )}
              id={blanketPo.blanketPoId}
            />
          </StyledTableCell>
          <StyledTableCell>
            <BlanketPOField
              fieldKey="openOrderValue"
              fs={getStoredInHeaderItemName(
                blanketPo.storedInHeaderField,
                blanketPo.po,
              )}
              id={blanketPo.blanketPoId}
            />
          </StyledTableCell>
          <StyledTableCell>
            <BlanketPOField
              fieldKey="availableValue"
              id={blanketPo.blanketPoId}
            />
          </StyledTableCell>
          <StyledTableCell>
            <IconButton
              aria-label="Edit Blanket PO"
              data-testid="blanket-po-edit-icon"
              sx={{
                backgroundColor: "transparent",
                color: theme.palette.blue["800"],
              }}
              onClick={onClickEdit}
            >
              <FileEdit sx={{ height: 31, width: 23 }} />
            </IconButton>
            <IconButton
              aria-label="Remove Blanket PO"
              data-testid="blanket-po-edit-remove-icon"
              sx={(theme) => ({
                "&:hover svg": {
                  color: theme.palette.red["500"],
                },
                backgroundColor: "transparent",
                color: theme.palette.red["500"],
              })}
              onClick={onClickDelete}
            >
              <FileRemove sx={{ height: 31, width: 23 }} />
            </IconButton>
          </StyledTableCell>
        </TableRow>
      ) : (
        <TableRow>
          <StyledInputTableCell>
            <TextField
              {...register("po")}
              InputProps={{
                endAdornment: !!errors.po ? (
                  <InputAdornment position="end">
                    <ErrorOutlineIcon
                      sx={(theme) => ({ color: theme.palette.red["500"] })}
                    />
                  </InputAdornment>
                ) : null,
                readOnly: blanketPo !== undefined,
              }}
              data-testid="blanket-po-po-field"
              disabled={isLoading}
              error={!!errors.po}
              helperText={errors.po?.message ?? ""}
              sx={{
                backgroundColor: `${
                  blanketPo !== undefined
                    ? theme.palette.grey["100"]
                    : "inherit"
                }`,
              }}
            />
          </StyledInputTableCell>
          <StyledInputTableCell>
            <TextField
              {...register("description")}
              data-testid="blanket-po-description-field"
              disabled={isLoading}
              error={!!errors.description}
            />
          </StyledInputTableCell>
          <StyledInputTableCell>
            <Controller
              control={control}
              name="storedInHeaderField"
              render={({ field: { name, value } }) => (
                <Select
                  {...register(name)}
                  data-testid="blanket-po-stored-in-header-field"
                  defaultValue=""
                  disabled={isLoading}
                  error={!!errors.storedInHeaderField}
                  size="small"
                  sx={{ fontSize: "14px" }}
                  value={value}
                >
                  {storedInHeaderValues.map((label) => (
                    <MenuItem
                      key={label}
                      sx={{ fontSize: "14px" }}
                      value={label}
                    >
                      {label?.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.storedInHeaderField?.message && (
              <FormHelperText
                sx={(theme) => ({ color: theme.palette.red["500"] })}
              >
                {errors.storedInHeaderField?.message}
              </FormHelperText>
            )}
          </StyledInputTableCell>
          <StyledInputTableCell>
            <TextField
              {...register("value")}
              InputProps={{
                endAdornment: !!errors.value ? (
                  <InputAdornment position="end">
                    <ErrorOutlineIcon
                      sx={(theme) => ({ color: theme.palette.red["500"] })}
                    />
                  </InputAdornment>
                ) : null,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              data-testid="blanket-po-value-field"
              disabled={isLoading}
              error={!!errors.value}
              helperText={errors.value?.message ?? ""}
              type="number"
            />
          </StyledInputTableCell>
          <StyledInputTableCell colSpan={4}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                data-testid="blanket-po-edit-cancel-button"
                disabled={isLoading}
                sx={{ mr: 3, px: "40px" }}
                variant="outlined"
                onClick={handleClickCancel}
              >
                Cancel
              </Button>
              <LoadingButton
                data-testid="blanket-po-edit-save-button"
                loading={isLoading}
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </LoadingButton>
            </Box>
          </StyledInputTableCell>
        </TableRow>
      )}
    </>
  );
};

export default BlanketPOsTableRow;
