import { Box, Link, Popover, Typography } from "@mui/material";
import { isNumber, kebabCase } from "lodash";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useMemo } from "react";
import { useCookiesLive } from "../../hooks";
import { useAccountCredit, useSalesSummary } from "../../hooks/api/queries";
import { ListCriteriaV1 } from "../../types";
import { getAppLink } from "../../utils/link";
import {
  convertNumberToCurrencyFormat,
  convertNumberToPercentFormat,
} from "../../utils/number";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ExclamationTriangleIcon,
  GraphUpIcon,
} from "../icons";
import { CenterBox, VCenterBox } from "../ui";

const buildLegacyCriteriaUrl = (fs: Record<string, any>) => {
  const criteria: ListCriteriaV1 = { fs };
  const searchParams = new URLSearchParams();
  searchParams.set("params", JSON.stringify(criteria));
  return searchParams.toString();
};

const SalesSummary = () => {
  const salesSummaryPopupState = usePopupState({
    popupId: "salesSummaryPopover",
    variant: "popover",
  });
  const [accountCode, accountId] = useCookiesLive(["accountCode", "accountId"]);
  const {
    data: salesSummary,
    startDate: salesSummaryStartDate,
    endDate: salesSummaryEndDate,
    isFetching: isFetchingSalesSummary,
  } = useSalesSummary(accountId ? +accountId : undefined);
  const { data: accountCredit, isFetching: isFetchingAccountCredit } =
    useAccountCredit(accountCode);
  const qtdStats = [
    {
      link: `${getAppLink("/app/OrderManagement")}?${buildLegacyCriteriaUrl({
        orderDate: {
          end: salesSummaryEndDate,
          start: salesSummaryStartDate,
        },
        repDisplay: ["Over shipped", "Partially Shipped", "Pending", "Shipped"],
      })}`,
      title: "QTD Booked",
      value: convertNumberToCurrencyFormat(salesSummary?.totalOrdersBooked),
    },
    {
      link: `${getAppLink("/app/Invoice")}?${buildLegacyCriteriaUrl({
        invoiceDate: {
          end: salesSummaryEndDate,
          start: salesSummaryStartDate,
        },
      })}`,
      title: "QTD Invoiced",
      value: convertNumberToCurrencyFormat(salesSummary?.totalInvoiced),
    },
    ...(isNumber(salesSummary?.repGpPercent)
      ? [
          {
            link: `${getAppLink("/app/Invoice")}?${buildLegacyCriteriaUrl({
              invoiceDate: {
                end: salesSummaryEndDate,
                start: salesSummaryStartDate,
              },
            })}`,
            title: "QTD GP%",
            value: convertNumberToPercentFormat(salesSummary?.repGpPercent),
          },
        ]
      : []),
    {
      link: `${getAppLink("/app/Invoice")}?${buildLegacyCriteriaUrl({
        balance: { end: "", start: "0.01" },
        dueDate: { end: "yesterday", start: "" },
      })}`,
      title: "Overdue AR",
      value: convertNumberToCurrencyFormat(salesSummary?.overdueArBalance),
    },
  ];

  const creditStats = [
    {
      title: "Payment Terms",
      value: `${accountCredit?.paymentTerms}`,
    },
    {
      title: "Credit Limit",
      value: `$${Math.round(accountCredit?.creditLimit ?? 0).toLocaleString()}`,
    },
    {
      title: "AR Balance",
      value: `$${Math.round(accountCredit?.arBalance ?? 0).toLocaleString()}`,
    },
  ];

  const isCreditLessThanTwoAvgDaysOfOrders = useMemo(() => {
    if (salesSummary && "avgDailySales" in salesSummary && accountCredit) {
      const avgDailySales = salesSummary.avgDailySales;
      const availableCredit = accountCredit.availableCredit;
      if (isNumber(avgDailySales) && isNumber(availableCredit)) {
        return availableCredit < 5 * avgDailySales;
      }
    }
    return false;
  }, [accountCredit, salesSummary]);

  const isCreditLessThanTotalOfRepHoldOrders = useMemo(() => {
    if (salesSummary && "repHoldOrderTotal" in salesSummary && accountCredit) {
      const repHoldOrderTotal = salesSummary.repHoldOrderTotal;
      const availableCredit = accountCredit.availableCredit;
      if (isNumber(repHoldOrderTotal) && isNumber(availableCredit)) {
        return availableCredit < repHoldOrderTotal;
      }
    }
    return false;
  }, [accountCredit, salesSummary]);

  const isLowCredit =
    isCreditLessThanTwoAvgDaysOfOrders || isCreditLessThanTotalOfRepHoldOrders;

  return salesSummary && !isFetchingSalesSummary ? (
    <>
      <Box
        data-testid="sales-summary-btn"
        sx={(theme) => ({
          "&:hover": {
            background: salesSummaryPopupState.isOpen
              ? isLowCredit
                ? theme.palette.yellow["300"]
                : theme.palette.blue["700"]
              : isLowCredit
                ? theme.palette.yellow["100"]
                : theme.palette.blue["500"],
          },
          background: salesSummaryPopupState.isOpen
            ? isLowCredit
              ? theme.palette.yellow["300"]
              : theme.palette.blue["700"]
            : isLowCredit
              ? theme.palette.yellow["100"]
              : theme.palette.blue["800"],
          border: `1px solid ${
            isLowCredit
              ? theme.palette.yellow["100"]
              : theme.palette.blue["900"]
          }`,
          borderRadius: 0.5,
          color: theme.palette.white,
          cursor: "pointer",
          display: "flex",
          fontSize: theme.spacing(1.75),
          gap: theme.spacing(1.25),
          position: "relative",
        })}
        {...bindTrigger(salesSummaryPopupState)}
      >
        <VCenterBox
          justifyContent="space-between"
          sx={({ spacing, palette }) => ({
            gap: spacing(1.25),
            left: 0,
            opacity: salesSummaryPopupState.isOpen ? 1 : 0,
            position: "absolute",
            px: spacing(1),
            py: spacing(0.75),
            top: 0,
            width: "100%",
          })}
        >
          <GraphUpIcon sx={{ height: 14, width: 14 }} />
          <Typography
            color="inherit"
            component="span"
            fontWeight={500}
            lineHeight={1}
            variant="body2"
          >
            {isLowCredit ? (
              "Warning: Low Credit"
            ) : accountCode ? (
              <>{accountCode.toUpperCase()}&nbsp; Sales & Credit Info</>
            ) : (
              <>Sales Info</>
            )}
          </Typography>
          <ArrowUpIcon sx={{ height: 14, width: 14 }} />
        </VCenterBox>

        <VCenterBox
          sx={({ spacing }) => ({
            display: "flex",
            gap: spacing(1.25),
            opacity: salesSummaryPopupState.isOpen ? 0 : 1,
            px: spacing(1),
            py: spacing(0.75),
          })}
        >
          <GraphUpIcon sx={{ height: 14, width: 14 }} />
          <Typography
            color="inherit"
            component="span"
            fontWeight={500}
            lineHeight={1}
            variant="body2"
          >
            {isLowCredit ? (
              "Warning: Low Credit"
            ) : (
              <>
                QTD Invoiced:&nbsp;
                {convertNumberToCurrencyFormat(salesSummary.totalInvoiced)}
                &nbsp;&nbsp;|&nbsp;&nbsp;Overdue&nbsp;AR:&nbsp;
                {convertNumberToCurrencyFormat(salesSummary.overdueArBalance)}
              </>
            )}
          </Typography>
          <ArrowDownIcon sx={{ height: 14, width: 14 }} />
        </VCenterBox>
      </Box>
      <Popover
        {...bindPopover(salesSummaryPopupState)}
        disableAutoFocus
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiPaper-root": {
            borderBottomLeftRadius: 2,
            borderBottomRightRadius: 2,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Box
          sx={({ spacing }) => ({
            display: "flex",
            flexDirection: "column",
            gap: spacing(1.75),
            p: spacing(1.5),
          })}
        >
          <Box
            data-testid="sales-summary-data"
            sx={(theme) => ({
              background: theme.palette.grey["50"],
              borderRadius: theme.spacing(1.25),
              display: "flex",
              py: theme.spacing(3),
            })}
          >
            {qtdStats.map((stat) => (
              <Link
                key={`${stat.value}-${stat.title}`}
                data-testid={`sales-summary-${kebabCase(stat.title)}`}
                href={stat.link}
                underline="none"
              >
                <Box
                  sx={({ spacing, palette }) => ({
                    color: palette.grey["900"],
                    px: spacing(1.75),
                    textAlign: "center",
                  })}
                >
                  <Typography
                    component="div"
                    fontWeight="500"
                    mb={0.75}
                    variant="h6"
                  >
                    {stat.value}
                  </Typography>
                  <Typography
                    component="div"
                    variant="caption"
                    whiteSpace="nowrap"
                  >
                    {stat.title}
                  </Typography>
                </Box>
              </Link>
            ))}
          </Box>

          {accountCredit && !isFetchingAccountCredit && (
            <Box
              data-testid="sales-summary-credit-info"
              sx={(theme) => ({
                background: theme.palette.grey["50"],
                borderRadius: theme.spacing(1.25),
                display: "flex",
                gap: theme.spacing(2),
                px: theme.spacing(2),
                py: theme.spacing(3),
              })}
            >
              {creditStats.map((stat) => (
                <Box
                  key={`${stat.value}-${stat.title}`}
                  sx={{ display: "flex", flex: 1, justifyContent: "center" }}
                >
                  <Box sx={{ display: "inline-block" }}>
                    <Typography
                      component="div"
                      mb={1.25}
                      variant="body2"
                      whiteSpace="nowrap"
                    >
                      {stat.title}
                    </Typography>
                    <Typography component="div" variant="h6">
                      {stat.value}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          {accountCredit && !isFetchingAccountCredit && (
            <CenterBox
              data-testid="sales-summary-available-credit"
              sx={(theme) => ({
                background: isLowCredit
                  ? theme.palette.yellow["50"]
                  : theme.palette.green["600"],
                borderRadius: theme.spacing(1.25),
                gap: theme.spacing(5),
                px: theme.spacing(2),
                py: theme.spacing(3),
              })}
            >
              <Typography
                fontWeight={400}
                textAlign="center"
                variant="h6"
                whiteSpace="nowrap"
              >
                Available Credit
              </Typography>
              <Typography fontWeight={500} variant="h5">
                {`$${Math.round(
                  accountCredit?.availableCredit ?? 0,
                ).toLocaleString()}`}
              </Typography>
            </CenterBox>
          )}

          {isLowCredit && (
            <CenterBox
              data-testid="sales-summary-low-credit"
              sx={(theme) => ({
                background: theme.palette.yellow["50"],
                borderLeft: `2px solid ${theme.palette.yellow["100"]}`,
                borderRadius: theme.spacing(0.25),
                gap: theme.spacing(0.75),
                px: theme.spacing(2),
                py: theme.spacing(2.75),
              })}
            >
              <ExclamationTriangleIcon
                sx={{
                  color: (theme) => theme.palette.yellow["100"],
                  height: 16,
                  width: 16,
                }}
              />
              <Typography variant="caption">
                {isCreditLessThanTwoAvgDaysOfOrders
                  ? "Available credit is less than five average days of orders"
                  : "Available credit is less than the total of rep-hold orders"}
              </Typography>
            </CenterBox>
          )}
        </Box>
      </Popover>
    </>
  ) : null;
};

export default SalesSummary;
