/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LicenseProgramResponseDto } from '../models/LicenseProgramResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LicenseProgramResourceService {
  /**
   * Get license programs.
   * Get license programs.
   * @returns LicenseProgramResponseDto OK
   * @throws ApiError
   */
  public static getLicenseProgramsUsingGet(): CancelablePromise<Array<LicenseProgramResponseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/licensePrograms',
      errors: {
        401: `Unauthorized`,
        403: `Unauthorized`,
        404: `Not Found`,
        500: `Internal Error getting license programs`,
      },
    });
  }
}
