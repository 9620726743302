import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { DealResponseDto } from "../../api";

export const OrderLineDealDetailsPopoverButton = ({
  unitCost,
  deal,
}: {
  deal: DealResponseDto;
  unitCost?: number;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <IconButton
        aria-label="Order Info"
        data-testid="order-line-deal-btn"
        size="small"
        sx={(theme) => ({
          color: theme.palette.yellow["500"],
        })}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MonetizationOnIcon
          sx={(theme) => ({ color: theme.palette.yellow["500"], fontSize: 18 })}
        />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "right",
          vertical: "center",
        }}
        open={Boolean(anchorEl)}
        transformOrigin={{
          horizontal: "left",
          vertical: "center",
        }}
        onClose={() => setAnchorEl(null)}
      >
        <Box sx={{ px: 2.5, py: 3 }}>
          <Typography
            sx={(theme) => ({
              fontWeight: "medium",
            })}
          >
            {deal.program}
          </Typography>
          <Box
            data-testid="order-line-deal-info"
            sx={(theme) => ({
              color: theme.palette.grey["700"],
              display: "flex",
              fontSize: 14,
              gap: 2,
              lineHeight: theme.spacing(3),
              mt: theme.spacing(1.25),
            })}
          >
            <Box
              sx={(theme) => ({
                "& .MuiTypography-root": {
                  fontSize: 14,
                },
                backgroundColor: theme.palette.grey["100"],
                fontWeight: "medium",
                px: theme.spacing(2),
                py: theme.spacing(0.5),
              })}
            >
              <Typography>Deal Number</Typography>
              <Typography>Deal Version</Typography>
              <Typography>Expiration Date</Typography>
              <Typography>Deal Cost</Typography>
              <Typography>Supplier Uplift/ Downlift</Typography>
              <Typography>Raw Cost</Typography>
            </Box>
            <Box py={0.5}>
              <Typography fontSize={14}>
                {deal.number || <>&nbsp;</>}
              </Typography>
              <Typography fontSize={14}>
                {deal.version || <>&nbsp;</>}
              </Typography>
              <Typography fontSize={14}>
                {deal.expirationDate ? (
                  DateTime.fromISO(deal.expirationDate).toFormat("M/d/yyyy")
                ) : (
                  <>&nbsp;</>
                )}
              </Typography>
              <Typography fontSize={14}>
                ${(unitCost ?? 0) / ((deal.uplift ?? 0) + 1)}
              </Typography>
              <Typography fontSize={14}>{deal.uplift}%</Typography>
              <Typography fontSize={14}>${unitCost}</Typography>
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
