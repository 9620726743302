import ListingPage from "../../components/ListingPage";
import { withPageTitle } from "../../hocs";

const ShippingAddressesList = () => {
  return (
    <>
      <ListingPage identifier="shipno" listingName="ship_address_list" />
    </>
  );
};

export default withPageTitle("Shipping Addresses List", ShippingAddressesList);
