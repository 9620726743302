import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { AbstractUserDto } from "../../api";
import { useUpdateUser } from "../../hooks/api/mutations/useUpdateUser";
import generateAuthHash from "../../utils/generateAuthHash";
import { Button, LoadingButton } from "../ui";

const GenerateAuthenticatedLink = ({
  user,
  isOpen,
  onClose,
  onAuthLinkGenerated,
}: {
  user: AbstractUserDto;
  isOpen: boolean;
  onClose: () => void;
  onAuthLinkGenerated: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: updateUser, isLoading: isUpdatingUser } = useUpdateUser(
    user.userId,
  );

  const handleClickConfirm = () => {
    updateUser(
      {
        authenticatedLinkHash: generateAuthHash(),
      },
      {
        onError: () =>
          enqueueSnackbar({
            message: `Unable to generate authenticated link.`,
            variant: "error",
          }),
        onSuccess: () => {
          enqueueSnackbar({
            message: `Successfully generated authenticated link.`,
            variant: "success",
          });
          onAuthLinkGenerated();
        },
      },
    );
  };

  return (
    <Dialog
      fullWidth
      data-testid="generate-auth-link-modal"
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle
        data-testid="generate-auth-link-modal-title"
        sx={(theme) => ({
          fontSize: theme.spacing(2.25),
          m: 0,
          pb: 0,
          pt: 3.5,
          px: 2,
        })}
      >
        Generate Authenticated Link For User {user.accountCode} |{" "}
        {user.userCode}
      </DialogTitle>
      <IconButton
        sx={{
          color: (theme) => theme.palette.grey[900],
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent data-testid="generate-auth-link-modal-body">
        <Box
          sx={(theme) => ({
            backgroundColor: "#FFF6DE",
            borderLeft: "2px solid #F0B849",
            display: "flex",
            gap: theme.spacing(1.5),
            p: theme.spacing(1.5),
          })}
        >
          <WarningAmberIcon
            sx={(theme) => ({
              color: "#F0B849",
              marginTop: "5px",
            })}
          />
          <Typography component="span">
            This feature will generate a <b>link</b> which will provide direct
            access to Connection’s Site without requiring this user to enter a
            username or password. You can change or disable this link any time
            and limit the IP address from which this link may be accessed.
          </Typography>
        </Box>
        <Typography
          mt={3}
          sx={(theme) => ({
            mt: theme.spacing(1.5),
          })}
        >
          Are you sure you want to continue?
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pb: 2,
          px: 3,
        }}
      >
        <Button
          data-testid="generate-auth-link-modal-close-btn"
          sx={(theme) => ({
            height: theme.spacing(4.5),
            width: theme.spacing(12),
          })}
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          data-testid="generate-auth-link-modal-save-btn"
          loading={isUpdatingUser}
          sx={(theme) => ({
            height: theme.spacing(4.5),
            width: theme.spacing(12),
          })}
          variant="contained"
          onClick={handleClickConfirm}
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateAuthenticatedLink;
