import { useQuery } from "@tanstack/react-query";
import { StockingOrdersResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useStockingOrder = (stockingOrderNum?: number) => {
  return useQuery(
    [QUERY_KEYS["stocking-order"], stockingOrderNum],
    () => StockingOrdersResourceService.getUsingGet3(stockingOrderNum!),
    { enabled: !!stockingOrderNum },
  );
};
