import { useQuery } from "@tanstack/react-query";
import { UsersResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useUserData = () => {
  return useQuery(
    [QUERY_KEYS["user-data"]],
    UsersResourceService.getUserUsingGet,
  );
};
