export const QUERY_KEYS = {
  account: "account",
  "account-credit": "account-credit",
  "blanket-po": "blanket-po",
  "blanket-pos": "blanket-pos",
  bundle: "bundle",
  "bundle-line": "bundle-line",
  "client-resource": "client-resource",
  "client-resource-max-id": "client-resource-max-id",
  "client-resource-report": "client-resource-report",
  "deal-max-id": "deal-max-id",
  "deals-list": "deals-list",
  "global-invoice-line": "global-invoice-line",
  "global-invoice-line-max-id": "global-invoice-line-max-id",
  invoice: "invoice",
  "license-programs": "license-programs",
  "listing-column-resource": "listing-column-resource",
  "listing-facets": "listing-facets",
  "listing-views": "listing-views",
  "most-popular-products": "most-popular-products",
  order: "order",
  "order-line": "order-line",
  "order-line-max-id": "order-line-max-id",
  "order-line-report": "order-line-report",
  "order-list": "order-list",
  "order-list-max-id": "order-list-max-id",
  "order-log": "order-log",
  "product-availability-pricing": "product-availability-pricing",
  "product-details": "product-details",
  "product-report": "product-report",
  "product-report-max-id": "product-report-max-id",
  "product-search-suggestions": "product-search-suggestions",
  quote: "quote",
  "quote-list": "quote-list",
  "quote-list-max-id": "quote-list-max-id",
  "recent-electronic-pos": "recent-electronic-pos",
  "recent-quotes": "recent-quotes",
  "return-notes": "return-notes",
  returns: "returns",
  "sales-by-category": "sales-by-category",
  "sales-by-location": "sales-by-location",
  "sales-by-manufacturer": "sales-by-manufacturer",
  "sales-by-state": "sales-by-state",
  "sales-by-year-and-month": "sales-by-year-and-month",
  "sales-summary-account": "sales-summary-account",
  "sales-summary-no-account": "sales-summary",
  "sales-summary-transactions": "sales-summary-transactions",
  "savings-by-category": "savings-by-category",
  "savings-by-manufacturer": "savings-by-manufacturer",
  "search-categories": "search-categories",
  "search-facets": "search-facets",
  "search-results": "search-results",
  "shipping-address-max-id": "shipping-address-max-id",
  "shipping-addresses-list": "shipping-addresses-list",
  "staff-list": "staff-list",
  "staff-max-id": "staff-max-id",
  "standard-line-max-id": "standard-line-max-id",
  "standard-lines": "standard-lines",
  "stocking-order": "stocking-order",
  "suggested-accounts": "suggested-accounts",
  user: "user",
  "user-data": "user-data",
  "user-max-id": "user-max-id",
  "user-rights": "user-rights",
  users: "users",
};
