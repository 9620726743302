import { createSvgIcon } from "@mui/material";

export const FilterIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 13 14" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="Sort-view"
        transform="translate(-135.000000, -295.000000)"
      >
        <g id="Group-13" transform="translate(21.000000, 169.000000)">
          <g id="sm-filter" transform="translate(53.000000, 115.000000)">
            <g id="funnel" transform="translate(61.000000, 11.000000)">
              <path
                d="M0,0.5 C0,0.223857625 0.223857625,0 0.5,0 L12.5,0 C12.7761424,0 13,0.223857625 13,0.5 L13,2.5 C13,2.62330794 12.9543919,2.7422589 12.872,2.834 L8.5,7.692 L8.5,12.5 C8.49983666,12.7151196 8.36209682,12.9060222 8.158,12.974 L5.158,13.974 C5.00559736,14.0247603 4.83808723,13.9992078 4.70775005,13.9053174 C4.57741287,13.8114271 4.50012194,13.6606336 4.5,13.5 L4.5,7.692 L0.128,2.834 C0.0456081477,2.7422589 0,2.62330794 0,2.5 L0,0.5 Z M1,1 L1,2.308 L5.372,7.166 C5.45439185,7.2577411 5.49997785,7.37669206 5.5,7.5 L5.5,12.806 L7.5,12.14 L7.5,7.5 C7.50002215,7.37669206 7.54560815,7.2577411 7.628,7.166 L12,2.308 L12,1 L1,1 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "Filter",
);
