import { useCallback, useMemo, useState } from "react";
import ImportGlobalInvoiceLines from "../../components/ImportGlobalInvoiceLines/ImportGlobalInvoiceLines";
import ListingPage from "../../components/ListingPage";
import { withPageTitle } from "../../hocs";
import { useCookiesLive } from "../../hooks";
import { useUserRights } from "../../hooks/api/queries";
import { ActionRowItem, UserPermission } from "../../types";
import { hasPermission } from "../../utils/user";

enum ActionModalType {
  IMPORT_GLOBAL_INVOICE_LINES,
}

const GlobalInvoiceLineList = () => {
  const { data: userRights } = useUserRights();
  const [selectedActionModal, setSelectedActionModal] =
    useState<ActionModalType>();
  const canUserManageGlobalUsers = hasPermission(
    userRights,
    UserPermission.MANAGE_GLOBAL_USERS,
  );
  const [accountId] = useCookiesLive(["accountId"]);

  const actionRowItems: ActionRowItem[] = useMemo(
    () => [
      {
        isVisible: (accountId ?? 0) > 0 && canUserManageGlobalUsers,
        label: "Import",
        onClick: () =>
          setSelectedActionModal(ActionModalType.IMPORT_GLOBAL_INVOICE_LINES),
      },
    ],
    [accountId, canUserManageGlobalUsers],
  );
  const handleCloseModal = useCallback(() => {
    setSelectedActionModal(undefined);
  }, []);

  return (
    <>
      <ListingPage
        actionRowItems={actionRowItems}
        identifier="id"
        listingName="global_invoice_line"
      />

      {selectedActionModal === ActionModalType.IMPORT_GLOBAL_INVOICE_LINES && (
        <ImportGlobalInvoiceLines onClose={handleCloseModal} />
      )}
    </>
  );
};

export default withPageTitle("Global Invoice Lines", GlobalInvoiceLineList);
