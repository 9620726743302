import { useCallback, useMemo, useState } from "react";
import { StaffListing } from "../../api";
import ListingPage from "../../components/ListingPage";
import EditStaffRights from "../../components/modals/EditStaffRights";
import StaffChangePasswordModal from "../../components/modals/StaffChangePasswordModal";
import { withPageTitle } from "../../hocs";
import { useUserRights } from "../../hooks/api/queries";
import { ActionColumnItem, UserPermission } from "../../types";
import { getAppLink } from "../../utils/link";
import { hasPermission } from "../../utils/user";

enum ActionModalType {
  CHANGE_PASSWORD,
  EDIT_STAFF_RIGHTS,
}

const StaffList = () => {
  const { data: userRights } = useUserRights();
  const [selectedStaff, setSelectedStaff] = useState<StaffListing>();
  const [selectedActionModal, setSelectedActionModal] =
    useState<ActionModalType>();

  const handleCloseModal = useCallback(() => {
    setSelectedStaff(undefined);
    setSelectedActionModal(undefined);
  }, []);

  const canUserManageApplication = hasPermission(
    userRights,
    UserPermission.MANAGE_APPLICATION_USERS,
  );

  const actionItems = useMemo<ActionColumnItem<StaffListing>[]>(
    () => [
      {
        isVisible: (staff: StaffListing) =>
          (staff?.assignedAccountCount ?? 0) > 0 && canUserManageApplication,
        label: "View Assigned Accounts",
        onClick: (staff: StaffListing) => {
          const filter = {
            d: "1",
            fs: {
              active: ["Yes"],
              repId: [staff.repId],
            },
            o: "creditLimit",
          };
          window.open(
            `/app/Account?params=${encodeURIComponent(JSON.stringify(filter))}`,
            "_blank",
          );
        },
      },
      {
        isVisible: (staff) =>
          staff?.active === true && canUserManageApplication,
        label: "Assume Role",
        onClick: (staff) => {
          window.location.href = getAppLink(
            `/app/Auth/assumeRole?userId=${staff.userId}`,
          );
        },
      },
      {
        isVisible: (staff) =>
          staff?.active === true && canUserManageApplication,
        label: "Change Password",
        onClick: (staff) => {
          setSelectedStaff(staff);
          setSelectedActionModal(ActionModalType.CHANGE_PASSWORD);
        },
      },
      {
        isVisible: () => canUserManageApplication,
        label: "View/Edit Rights",
        onClick: (staff) => {
          setSelectedStaff(staff);
          setSelectedActionModal(ActionModalType.EDIT_STAFF_RIGHTS);
        },
      },
    ],
    [canUserManageApplication],
  );

  return (
    <>
      <ListingPage
        actionColumnItems={actionItems}
        identifier="id"
        listingName="staff"
      />
      {selectedStaff &&
        selectedActionModal === ActionModalType.CHANGE_PASSWORD && (
          <StaffChangePasswordModal
            isOpen
            staff={selectedStaff}
            onClose={handleCloseModal}
          />
        )}
      {selectedStaff &&
        selectedActionModal === ActionModalType.EDIT_STAFF_RIGHTS && (
          <EditStaffRights
            isOpen
            staff={selectedStaff}
            onClose={handleCloseModal}
          />
        )}
    </>
  );
};

export default withPageTitle("Staff List", StaffList);
