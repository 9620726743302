import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EventNoteIcon from "@mui/icons-material/EventNote";
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  styled,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { BundleLinePatchRequestDto, DealDto, DealSolrDto } from "../../api";
import { QUERY_KEYS } from "../../constants/query";
import { useBundleLineUpdate } from "../../hooks/api/mutations";
import { useBundleLines } from "../../hooks/api/queries";
import { getFormattedDate } from "../../utils/datetime";
import { Button, TextField } from "../ui";

const schema = yup
  .object({
    dealNumber: yup
      .string()
      .when("isDealNumberDisabled", ([isDealNumberDisabled], schema) => {
        return isDealNumberDisabled
          ? schema
          : schema.required("Please enter a valid deal number.");
      }),
    dealVersion: yup
      .string()
      .when("isDealVersionDisabled", ([isDealVersionDisabled], schema) => {
        return isDealVersionDisabled
          ? schema
          : schema.required("Please enter a valid deal version.");
      }),
    expirationDate: yup
      .date()
      .when(
        "isExpirationDateDisabled",
        ([isExpirationDateDisabled], schema) => {
          return isExpirationDateDisabled
            ? schema
            : schema.required("Please enter a valid expiration date.");
        },
      ),
    isDealNumberDisabled: yup.boolean().required(),
    isDealVersionDisabled: yup.boolean().required(),
    isExpirationDateDisabled: yup.boolean().required(),
    isSupplierUpliftDisabled: yup.boolean().required(),
    supplierUplift: yup
      .number()
      .when(
        "isSupplierUpliftDisabled",
        ([isSupplierUpliftDisabled], schema) => {
          return isSupplierUpliftDisabled
            ? schema
            : schema.required("Please enter a valid supplier uplift.");
        },
      ),
  })
  .required();

type FormValues = {
  dealNumber?: string;
  isDealNumberDisabled: boolean;
  dealVersion?: string;
  isDealVersionDisabled: boolean;
  expirationDate?: Date | null;
  isExpirationDateDisabled: boolean;
  supplierUplift?: number;
  isSupplierUpliftDisabled: boolean;
};

const FormFieldLabel = styled(Typography)(({ theme }) => ({
  flexShrink: 0,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: theme.spacing(4.5),
  width: theme.spacing(14),
}));

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    overflow: "visible",
  },
}));

const DealsEditSelected = ({
  deals,
  isOpen,
  onClose,
  refreshAfterSuccess,
}: {
  deals: DealSolrDto[];
  isOpen: boolean;
  refreshAfterSuccess?: boolean;
  onClose: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const bundleLineIds: number[] = deals
    .filter((deal) => deal.bundleLineId)
    .map((deal) => deal.bundleLineId!);
  const bundleLines = useBundleLines(bundleLineIds);
  const { mutateAsync: updateBundleLine } = useBundleLineUpdate();
  const [submitting, setSubmitting] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    control,
  } = useForm<FormValues>({
    defaultValues: {
      isDealNumberDisabled: true,
      isDealVersionDisabled: true,
      isExpirationDateDisabled: true,
      isSupplierUpliftDisabled: true,
    },
    resolver: yupResolver<FormValues>(schema),
  });
  const queryClient = useQueryClient();

  const onSubmit = useCallback(
    (values: FormValues) => {
      setSubmitting(true);
      const deal: Partial<DealDto> = {};
      if (!values.isDealNumberDisabled) {
        deal.number = values.dealNumber;
      }
      if (!values.isDealVersionDisabled) {
        deal.version = values.dealVersion;
      }
      if (!values.isExpirationDateDisabled) {
        deal.expirationDate = getFormattedDate(
          values.expirationDate,
          "yyyy-MM-dd",
        );
      }
      if (!values.isSupplierUpliftDisabled) {
        deal.uplift = values.supplierUplift;
      }
      const promises = bundleLineIds.map((bundleLineId, index) =>
        updateBundleLine({
          bundleLineId,
          request: {
            deal: {
              ...bundleLines[index].data?.deal,
              ...deal,
            } as DealDto,
            unitCost: bundleLines[index].data?.unitCost,
          } as BundleLinePatchRequestDto,
        }),
      );

      Promise.allSettled(promises).then((values) => {
        const failedBundleIndexes = values
          .map((v, index) => (v.status === "rejected" ? index : -1))
          .filter((v) => v >= 0);
        const successfulBundleIndexes = values
          .map((v, index) => (v.status === "fulfilled" ? index : -1))
          .filter((v) => v >= 0);
        if (failedBundleIndexes.length) {
          enqueueSnackbar({
            message: (
              <Box>
                <Typography sx={{ color: (theme) => theme.palette.white }}>
                  Unable to update the following Deals:
                </Typography>
                {failedBundleIndexes.map((index) => (
                  <Typography
                    key={bundleLines[index]?.data?.bundleLineId}
                    sx={{ color: (theme) => theme.palette.white }}
                  >
                    * {deals[index].standardNumber} |{" "}
                    {bundleLines[index]?.data?.supplierCode} |{" "}
                    {bundleLines[index]?.data?.supplierPart}
                  </Typography>
                ))}
              </Box>
            ),
            variant: "error",
          });
        } else {
          enqueueSnackbar({
            message: `${bundleLineIds.length} deals updated successfully`,
            variant: "success",
          });
        }

        if (successfulBundleIndexes.length) {
          if (refreshAfterSuccess) {
            window.location.reload();
          } else {
            queryClient.invalidateQueries([QUERY_KEYS["deals-list"]]);
          }
        }

        setSubmitting(false);
        onClose();
      });
    },
    [
      deals,
      refreshAfterSuccess,
      bundleLines,
      bundleLineIds,
      queryClient,
      updateBundleLine,
      onClose,
      enqueueSnackbar,
    ],
  );

  const isFetchingBundles = bundleLines.every(
    (bundleLine) => bundleLine.isFetching,
  );

  useEffect(() => {
    if (bundleLines.filter((bundleLine) => bundleLine.isError).length) {
      enqueueSnackbar({
        message: "Unable to update selected deals.",
        variant: "error",
      });
      onClose();
    }
  }, [bundleLines, enqueueSnackbar, onClose]);

  return (
    <StyledDialog maxWidth="sm" open={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={(theme) => ({
            fontSize: theme.spacing(2.25),
            m: 0,
            pb: 0,
            pt: 3.5,
            px: 2,
          })}
        >
          Edit {deals.length} Selected Deals
        </DialogTitle>
        <IconButton
          sx={{
            color: (theme) => theme.palette.grey[900],
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {isFetchingBundles ? (
            <Box
              sx={(theme) => ({
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                py: theme.spacing(2.5),
              })}
            >
              <CircularProgress />
            </Box>
          ) : submitting ? (
            <Box
              sx={(theme) => ({
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                py: theme.spacing(2.5),
              })}
            >
              <Typography sx={{ fontSize: 18, fontWeight: "medium" }}>
                Processing... do not close this window
              </Typography>
              <CircularProgress sx={(theme) => ({ mt: theme.spacing(2.5) })} />
            </Box>
          ) : (
            <>
              <Box
                sx={(theme) => ({
                  alignItems: "flex-start",
                  display: "flex",
                  gap: theme.spacing(3),
                  my: 1,
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: "flex",
                  })}
                >
                  <FormFieldLabel>Deal Number</FormFieldLabel>
                  <TextField
                    error={!!errors.dealNumber}
                    helperText={errors.dealNumber?.message ?? ""}
                    size="small"
                    {...register("dealNumber")}
                    InputProps={{
                      endAdornment: !!errors.dealNumber ? (
                        <InputAdornment position="end">
                          <ErrorOutlineIcon
                            sx={(theme) => ({
                              color: theme.palette.red["500"],
                            })}
                          />
                        </InputAdornment>
                      ) : null,
                    }}
                    disabled={watch("isDealNumberDisabled")}
                    sx={{ flex: 1 }}
                  />
                </Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...register("isDealNumberDisabled")}
                      checked={watch("isDealNumberDisabled")}
                      size="small"
                    />
                  }
                  label="Don't change"
                  slotProps={{
                    typography: {
                      sx: { fontSize: 14 },
                    },
                  }}
                />
              </Box>

              <Box
                sx={(theme) => ({
                  alignItems: "flex-start",
                  display: "flex",
                  gap: theme.spacing(3),
                  my: 1,
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: "flex",
                  })}
                >
                  <FormFieldLabel>Deal Version</FormFieldLabel>
                  <TextField
                    error={!!errors.dealVersion}
                    helperText={errors.dealVersion?.message ?? ""}
                    size="small"
                    {...register("dealVersion")}
                    InputProps={{
                      endAdornment: !!errors.dealVersion ? (
                        <InputAdornment position="end">
                          <ErrorOutlineIcon
                            sx={(theme) => ({
                              color: theme.palette.red["500"],
                            })}
                          />
                        </InputAdornment>
                      ) : null,
                    }}
                    disabled={watch("isDealVersionDisabled")}
                    sx={{ flex: 1 }}
                  />
                </Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...register("isDealVersionDisabled")}
                      checked={watch("isDealVersionDisabled")}
                      size="small"
                    />
                  }
                  label="Don't change"
                  slotProps={{
                    typography: {
                      sx: { fontSize: 14 },
                    },
                  }}
                />
              </Box>

              <Box
                sx={(theme) => ({
                  alignItems: "flex-start",
                  display: "flex",
                  gap: theme.spacing(3),
                  my: 1,
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: "flex",
                  })}
                >
                  <FormFieldLabel>Expiration Date</FormFieldLabel>
                  <Box flex="1">
                    <Controller
                      control={control}
                      name="expirationDate"
                      render={({ field: { value, onChange } }) => (
                        <>
                          <DatePicker
                            shouldCloseOnSelect
                            showIcon
                            customInput={
                              <TextField
                                placeholder="99/99/9999"
                                sx={{ boxSizing: "border-box" }}
                              />
                            }
                            dateFormat="MM/dd/yyyy"
                            disabled={watch("isExpirationDateDisabled")}
                            icon={
                              <EventNoteIcon
                                sx={(theme) => ({
                                  color: watch("isExpirationDateDisabled")
                                    ? theme.palette.grey["300"]
                                    : theme.palette.grey["500"],
                                })}
                              />
                            }
                            placeholderText="mm/dd/yyyy"
                            selected={value}
                            onChange={onChange}
                          />
                        </>
                      )}
                    />
                    {!!errors.expirationDate && (
                      <FormHelperText error>
                        {errors.expirationDate.message}
                      </FormHelperText>
                    )}
                  </Box>
                </Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...register("isExpirationDateDisabled")}
                      checked={watch("isExpirationDateDisabled")}
                      size="small"
                    />
                  }
                  label="Don't change"
                  slotProps={{
                    typography: {
                      sx: { fontSize: 14 },
                    },
                  }}
                />
              </Box>

              <Box
                sx={(theme) => ({
                  alignItems: "flex-start",
                  display: "flex",
                  gap: theme.spacing(3),
                  my: 1,
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: "flex",
                  })}
                >
                  <FormFieldLabel>Supplier Uplift</FormFieldLabel>
                  <TextField
                    error={!!errors.supplierUplift}
                    helperText={errors.supplierUplift?.message ?? ""}
                    size="small"
                    {...register("supplierUplift")}
                    InputProps={{
                      endAdornment: !!errors.supplierUplift ? (
                        <InputAdornment position="end">
                          <ErrorOutlineIcon
                            sx={(theme) => ({
                              color: theme.palette.red["500"],
                            })}
                          />
                        </InputAdornment>
                      ) : null,
                    }}
                    disabled={watch("isSupplierUpliftDisabled")}
                    sx={{ flex: 1 }}
                  />
                </Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...register("isSupplierUpliftDisabled")}
                      checked={watch("isSupplierUpliftDisabled")}
                      size="small"
                    />
                  }
                  label="Don't change"
                  slotProps={{
                    typography: {
                      sx: { fontSize: 14 },
                    },
                  }}
                />
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pb: 2,
            px: 3,
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
          <Button
            color="primary"
            sx={(theme) => ({ mr: theme.spacing(1.5) })}
            type="submit"
            variant="contained"
          >
            Update
          </Button>
        </DialogActions>
      </form>
    </StyledDialog>
  );
};

export default DealsEditSelected;
