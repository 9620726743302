import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  ImportWizardColumnDef,
  ImportWizardDto,
  ImportWizardFileRecord,
} from "../../../types";
import { guessColumnIndex } from "../../../utils/importWizard";
import { ExclamationCircleIcon } from "../../icons";
import { Button } from "../../ui";
import { useImportWizardContext } from "../ImportWizardContext";

const indexSchema = yup.number().integer().min(0);

type FormValues = {
  columnMapping: Record<string, number | null>;
};

export const MapFields = ({
  columnDefs,
  generateEntity,
  onNext,
  title,
}: {
  columnDefs: ImportWizardColumnDef[];
  generateEntity: (
    columnMapping: Record<string, number | null>,
    fileRow: ImportWizardFileRecord,
  ) => ImportWizardDto;
  onNext: (records: ImportWizardDto[]) => void;
  title: string;
}) => {
  const { fileContents, setRecords, onClose } = useImportWizardContext();
  const [hasHeaderRow, setHasHeaderRow] = useState(true);
  const fileColumnNames = fileContents[0] as string[];
  const fileRows = hasHeaderRow ? fileContents.slice(1) : fileContents;

  const schema = useMemo(
    () =>
      yup.object({
        columnMapping: yup
          .object(
            columnDefs.reduce<Record<string, any>>(
              (schema, columnDef) => ({
                ...schema,
                [columnDef.name]: columnDef.required
                  ? indexSchema.required()
                  : indexSchema.nullable(),
              }),
              {},
            ),
          )
          .required(),
      }),
    [columnDefs],
  );

  const columnsCount = useMemo(
    () =>
      hasHeaderRow
        ? (fileRows?.[0].length ?? 0)
        : fileRows.reduce((prev, record) => Math.max(prev, record.length), 0),
    [hasHeaderRow, fileRows],
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      columnMapping: columnDefs.reduce<Record<string, number | null>>(
        (mapping, columnDef) => ({
          ...mapping,
          [columnDef.name]: guessColumnIndex(columnDef, fileColumnNames),
        }),
        {},
      ),
    },
    resolver: yupResolver<FormValues>(schema),
  });

  const onSubmit = ({ columnMapping }: FormValues) => {
    const records = fileRows.map((row) => generateEntity(columnMapping, row));
    setRecords(records);
    onNext(records);
  };

  const getPreview = useCallback(
    (columnIndex: number) => {
      const foundValue =
        fileRows.find(
          (row) =>
            row[columnIndex] !== undefined &&
            row[columnIndex] !== null &&
            row[columnIndex] !== "",
        )?.[columnIndex] ?? null;

      if (foundValue === null) {
        return "";
      }
      if (typeof foundValue === "object") {
        return new Date(foundValue).toLocaleDateString();
      }

      return String(foundValue);
    },
    [fileRows],
  );

  /**
   * @todo check DEV-5512
   */
  const numberOfFields = columnDefs.length;
  const splitIntoChuncks = numberOfFields > 10;
  const sizeOfColumns = splitIntoChuncks
    ? Math.ceil(numberOfFields / 2)
    : numberOfFields;
  const chunkedFields = columnDefs.reduce<Record<string, any>>(
    (acc, _, index) => {
      if (index % sizeOfColumns === 0) {
        acc.push(columnDefs.slice(index, index + sizeOfColumns));
      }
      return acc;
    },
    [],
  );

  return (
    <Dialog
      fullWidth
      open
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: `${splitIntoChuncks ? "1120px" : "540px"}`,
          },
        },
      }}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ m: 0, px: 2, py: 4 }}>{title}</DialogTitle>

        <IconButton
          sx={{
            color: (theme) => theme.palette.grey[900],
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent sx={{ px: 2, py: 0 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={hasHeaderRow}
                size="small"
                onChange={(e) => setHasHeaderRow(e.target.checked)}
              />
            }
            label="First row has column names"
            sx={{
              mb: 2.5,
            }}
          />
          <Box
            sx={{
              display: "flex",
              gap: "80px",
              justifyContent: "space-between",
            }}
          >
            {chunkedFields.map(
              (columnDefsFields: ImportWizardColumnDef[], index: number) => (
                <Box
                  key={`${index}-box`}
                  sx={{
                    width: `${splitIntoChuncks ? "500px" : "100%"}`,
                  }}
                >
                  {columnDefsFields.map((columnDef: ImportWizardColumnDef) => (
                    <FormControl
                      key={columnDef.name}
                      error={!!errors.columnMapping?.[columnDef.name]}
                      sx={(theme) => ({
                        display: "flex",
                        flexDirection: "row",
                        gap: theme.spacing(1),
                        mb: 2,
                      })}
                    >
                      <FormLabel
                        sx={(theme) => ({
                          "&.Mui-focused": {
                            color: theme.palette.grey["900"],
                          },
                          flexShrink: 0,
                          m: 0,
                          width: theme.spacing(24),
                        })}
                      >
                        {columnDef.required && (
                          <Typography
                            color="red.500"
                            component="span"
                            fontWeight={600}
                            variant="body1"
                          >
                            *{" "}
                          </Typography>
                        )}
                        {columnDef.label}
                      </FormLabel>
                      <Box flex={1}>
                        <Controller
                          control={control}
                          name={`columnMapping.${columnDef.name}`}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                          }) => (
                            <>
                              <Box sx={{ position: "relative", width: "100%" }}>
                                <Select
                                  sx={(theme) => ({
                                    "& .MuiSelect-select": {
                                      fontSize: theme.spacing(2),
                                      py: 0.75,
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "rgba(0, 0, 0, 0.23)",
                                        borderWidth: 1,
                                      },
                                    width: "100%",
                                  })}
                                  value={value ?? ""}
                                  onBlur={onBlur}
                                  onChange={(e) =>
                                    onChange(
                                      (e.target.value + "").length > 0
                                        ? parseInt(e.target.value + "", 10)
                                        : null,
                                    )
                                  }
                                >
                                  <MenuItem value=""></MenuItem>
                                  {[...Array(columnsCount).keys()].map(
                                    (columnIndex) =>
                                      (!Object.values(
                                        watch("columnMapping"),
                                      ).includes(columnIndex) ||
                                        watch("columnMapping")[
                                          columnDef.name
                                        ] === columnIndex) && (
                                        <MenuItem
                                          key={columnIndex}
                                          value={columnIndex}
                                        >
                                          {hasHeaderRow
                                            ? `${
                                                fileColumnNames[columnIndex]
                                              } (Column ${String.fromCharCode(
                                                65 + columnIndex,
                                              )})`
                                            : `Column ${String.fromCharCode(
                                                65 + columnIndex,
                                              )}`}
                                        </MenuItem>
                                      ),
                                  )}
                                </Select>
                                {!!errors.columnMapping?.[columnDef.name] && (
                                  <ExclamationCircleIcon
                                    sx={(theme) => ({
                                      color: theme.palette.red["500"],
                                      height: 16,
                                      position: "absolute",
                                      right: 30,
                                      top: 10,
                                      width: 16,
                                    })}
                                  />
                                )}
                              </Box>
                              {typeof value === "number" && value >= 0 && (
                                <FormHelperText
                                  sx={(theme) => ({
                                    color: theme.palette.grey["500"],
                                    ml: 0,
                                  })}
                                >
                                  {`Preview: ${getPreview(value)}`}
                                </FormHelperText>
                              )}
                            </>
                          )}
                        />
                      </Box>
                    </FormControl>
                  ))}
                </Box>
              ),
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", p: 2 }}>
          <Button
            sx={(theme) => ({ width: theme.spacing(12.5) })}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            sx={(theme) => ({ width: theme.spacing(12.5) })}
            type="submit"
            variant="contained"
          >
            Next
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
