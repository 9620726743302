import { useMutation } from "@tanstack/react-query";
import {
  NoteRequestDto,
  ReturnedItemsResourceService,
  RmaResponseDto,
} from "../../../api";

export const useReturnNoteCreate = () => {
  return useMutation<
    RmaResponseDto,
    Error,
    { note: NoteRequestDto; returnId: number }
  >(({ returnId, note }) =>
    ReturnedItemsResourceService.createNoteUsingPost(note, returnId),
  );
};
