import { useCallback, useMemo, useState } from "react";
import { ClientListing } from "../../api";
import ImportClients from "../../components/ImportClients/ImportClients";
import ListingPage from "../../components/ListingPage";
import ClientModal from "../../components/modals/ClientModal";
import DeleteCrmClientConfirmModal from "../../components/modals/DeleteCrmClientConfirmModal";
import { withPageTitle } from "../../hocs";
import { useUserRights } from "../../hooks/api/queries";
import {
  ActionColumnItem,
  ActionRowClickItem,
  ActionRowItem,
  UserPermission,
} from "../../types";
import { hasPermission } from "../../utils/user";

type ClientListingExt = ClientListing & { disabled?: boolean };

enum ActionModalType {
  IMPORT_CLIENTS,
  MANAGE_CLIENT,
  DELETE_CLIENT,
}

const ClientResourceReport = () => {
  const [selectedClientLine, setSelectedClientLine] =
    useState<ClientListingExt>();
  const [selectedActionModal, setSelectedActionModal] =
    useState<ActionModalType>();
  const [selectedCrmClient, setSelectedCrmClient] = useState<ClientListing>();
  const { data: userRights } = useUserRights();
  const canManageClient = hasPermission(
    userRights,
    UserPermission.MANAGE_CLIENT_USERS,
  );
  const actionRowItems: ActionRowItem[] = useMemo(
    () => [
      {
        isVisible: canManageClient,
        label: "Add",
        onClick: () => setSelectedActionModal(ActionModalType.MANAGE_CLIENT),
      },
      {
        isVisible: canManageClient,
        label: "Import",
        onClick: () => setSelectedActionModal(ActionModalType.IMPORT_CLIENTS),
      },
    ],
    [canManageClient],
  );

  const actionRowClickItem: ActionRowClickItem<ClientListingExt> =
    useMemo(() => {
      return {
        onClick: (client) => {
          setSelectedClientLine(client);
          setSelectedActionModal(ActionModalType.MANAGE_CLIENT);
        },
      };
    }, []);

  const actionItems: ActionColumnItem<ClientListing>[] = useMemo(
    () => [
      {
        isVisible: () => canManageClient,
        label: "Delete",
        onClick: (client) => {
          setSelectedCrmClient(client);
          setSelectedActionModal(ActionModalType.DELETE_CLIENT);
        },
      },
    ],
    [canManageClient],
  );

  const handleCloseModal = useCallback(() => {
    setSelectedActionModal(undefined);
    setSelectedClientLine(undefined);
    setSelectedCrmClient(undefined);
  }, []);

  return (
    <>
      <ListingPage<ClientListingExt>
        actionColumnItems={actionItems}
        actionRowClickItem={actionRowClickItem}
        actionRowItems={actionRowItems}
        additionalColumnId="description"
        disableRowActionOnRow={(row) => {
          if (Array.isArray(row.erp)) {
            row.disabled = [...new Set(row.erp)].length > 1;
          }
        }}
        identifier="clientId"
        listingName="crm_client"
      />
      {selectedActionModal === ActionModalType.MANAGE_CLIENT && (
        <ClientModal
          isOpen
          clientId={selectedClientLine?.clientId}
          onClose={handleCloseModal}
        />
      )}
      {selectedActionModal === ActionModalType.IMPORT_CLIENTS && (
        <ImportClients onClose={handleCloseModal} />
      )}
      {selectedCrmClient &&
        selectedActionModal === ActionModalType.DELETE_CLIENT && (
          <DeleteCrmClientConfirmModal
            crmClient={selectedCrmClient}
            onClose={handleCloseModal}
          />
        )}
    </>
  );
};

export default withPageTitle("CRM Clients", ClientResourceReport);
