/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ElectronicPoDto } from '../models/ElectronicPoDto';
import type { ElectronicPoPatchRequestDto } from '../models/ElectronicPoPatchRequestDto';
import type { ElectronicPoRequestDto } from '../models/ElectronicPoRequestDto';
import type { RecentElectronicPoResponseDto } from '../models/RecentElectronicPoResponseDto';
import type { RestCollection_ElectronicPoSolrDto_ } from '../models/RestCollection_ElectronicPoSolrDto_';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ElectronicPoResourceService {
  /**
   * Get electronic po listing report
   * Get electronic po listing report
   * @param criteria criteria
   * @param page page
   * @param returnAllLabels returnAllLabels
   * @param returnPagination returnPagination
   * @param returnSavedViews returnSavedViews
   * @param size size
   * @param useDefaultView useDefaultView
   * @returns RestCollection_ElectronicPoSolrDto_ Ok
   * @throws ApiError
   */
  public static listUsingGet4(
    criteria: string = '{}',
    page: number = 1,
    returnAllLabels: boolean = false,
    returnPagination: boolean = true,
    returnSavedViews: boolean = true,
    size: number = 50,
    useDefaultView: boolean = false,
  ): CancelablePromise<RestCollection_ElectronicPoSolrDto_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/listing/electronic_pos',
      query: {
        'criteria': criteria,
        'page': page,
        'returnAllLabels': returnAllLabels,
        'returnPagination': returnPagination,
        'returnSavedViews': returnSavedViews,
        'size': size,
        'useDefaultView': useDefaultView,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Electronic Po(s) Not Found`,
        500: `Internal Error fetching Electronic Po`,
      },
    });
  }
  /**
   * Find Recent Pos
   * Find Recent Po
   * @param accountId accountId
   * @param onlyPending onlyPending
   * @param page page
   * @param size size
   * @returns RecentElectronicPoResponseDto Success
   * @throws ApiError
   */
  public static getRecentPosUsingGet(
    accountId: number,
    onlyPending?: boolean,
    page: number = 1,
    size: number = 10,
  ): CancelablePromise<Array<RecentElectronicPoResponseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/po/recent',
      query: {
        'accountId': accountId,
        'onlyPending': onlyPending,
        'page': page,
        'size': size,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error Finding Recent Quotes`,
      },
    });
  }
  /**
   * Get a Electronic Po
   * Get a Electronic Po
   * @param electronicPoId electronicPoId
   * @returns ElectronicPoDto Ok
   * @throws ApiError
   */
  public static getElectronicPoUsingGet(
    electronicPoId: number,
  ): CancelablePromise<ElectronicPoDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/po/{electronicPoId}',
      path: {
        'electronicPoId': electronicPoId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Electronic Po Not Found`,
        500: `Internal Error Getting Electronic Po`,
      },
    });
  }
  /**
   * Patch an Electronic Po
   * Patch an Electronic Po
   * @param electronicPoId electronicPoId
   * @param electronicPoPatchRequestDto electronicPoPatchRequestDto
   * @returns ElectronicPoDto Patched
   * @throws ApiError
   */
  public static patchUsingPatch(
    electronicPoId: number,
    electronicPoPatchRequestDto: ElectronicPoPatchRequestDto,
  ): CancelablePromise<ElectronicPoDto> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/po/{electronicPoId}',
      path: {
        'electronicPoId': electronicPoId,
      },
      body: electronicPoPatchRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Electronic Po Not Found`,
        500: `Internal Error Patching Electronic Po`,
      },
    });
  }
  /**
   * Create a Electronic Po
   * Create a Electronic Po
   * @param accountId accountId
   * @param request request
   * @returns ElectronicPoDto Success
   * @throws ApiError
   */
  public static postElectronicPoUsingPost(
    accountId: number,
    request: ElectronicPoRequestDto,
  ): CancelablePromise<ElectronicPoDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/pos',
      query: {
        'accountId': accountId,
      },
      body: request,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error Creating Electronic Po`,
      },
    });
  }
}
