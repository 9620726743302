import { createSvgIcon } from "@mui/material";

export const ExclamationCircleIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <title>exclamation-circle</title>
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="front-end-validation"
        transform="translate(-401, -303)"
      >
        <g id="email" transform="translate(48, 294)">
          <g id="Group" transform="translate(85, 0)">
            <g id="exclamation-circle" transform="translate(268, 9)">
              <path
                d="M8,15 C4.13400675,15 1,11.8659932 1,8 C1,4.13400675 4.13400675,1 8,1 C11.8659932,1 15,4.13400675 15,8 C15,11.8659932 11.8659932,15 8,15 Z M8,16 C12.418278,16 16,12.418278 16,8 C16,3.581722 12.418278,0 8,0 C3.581722,0 0,3.581722 0,8 C0,12.418278 3.581722,16 8,16 Z"
                id="Shape"
              ></path>
              <path
                d="M7.002,11 C7.002,10.6427344 7.19259891,10.3126074 7.502,10.1339746 C7.81140107,9.95534177 8.19259893,9.95534177 8.502,10.1339746 C8.81140109,10.3126074 9.002,10.6427344 9.002,11 C9.002,11.5522847 8.55428475,12 8.002,12 C7.44971525,12 7.002,11.5522847 7.002,11 L7.002,11 Z M7.1,4.995 C7.06366872,4.65080892 7.22682397,4.31603829 7.52030888,4.13258784 C7.81379379,3.94913739 8.18620621,3.94913739 8.47969112,4.13258784 C8.77317603,4.31603829 8.93633128,4.65080892 8.9,4.995 L8.55,8.502 C8.52561975,8.78762423 8.28666287,9.00705322 8,9.00705322 C7.71333713,9.00705322 7.47438025,8.78762423 7.45,8.502 L7.1,4.995 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "ExclamationCircle",
);
