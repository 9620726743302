import {
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ExternalLicenseDto } from "../../api";
import { useCookiesLive } from "../../hooks";
import { useProductSearchSuggestions } from "../../hooks/api/queries";

export const MapCatalogRow = ({
  manufacturer,
  manufacturerPart,
  onChange,
}: {
  onChange: (manufacturerProductId: number | undefined) => void;
  manufacturerPart: ExternalLicenseDto["manufacturerPart"];
  manufacturer: ExternalLicenseDto["manufacturer"];
}) => {
  const [accountCode] = useCookiesLive(["accountCode"]);
  const { data: suggestions = [], isLoading } = useProductSearchSuggestions(
    accountCode,
    manufacturerPart,
  );
  const [manufacturerProductId, setManufacturerProductId] = useState<string>();

  const handleChange = (event: SelectChangeEvent<string>) =>
    setManufacturerProductId(event.target.value);

  useEffect(() => {
    const exactMatchSuggestion = suggestions.find(
      (suggestion) => (suggestion as any).manufacturerPart === manufacturerPart,
    );
    if (exactMatchSuggestion) {
      setManufacturerProductId(
        String((exactMatchSuggestion as any).manufacturerProductId),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestions]);
  useEffect(() => {
    onChange(
      manufacturerProductId ? parseInt(manufacturerProductId) : undefined,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturerProductId]);

  return (
    <TableRow key={`${manufacturer}-${manufacturerPart}`}>
      <TableCell>{manufacturer}</TableCell>
      <TableCell>{manufacturerPart}</TableCell>
      <TableCell>
        {isLoading && <CircularProgress size={7} variant="indeterminate" />}
        {!isLoading && suggestions.length === 0 && (
          <Typography fontSize={14} textTransform="uppercase">
            no likely matches found
          </Typography>
        )}
        {!isLoading && suggestions.length > 0 && (
          <Select
            sx={(theme) => ({
              "& .MuiSelect-select": {
                fontSize: theme.spacing(2),
                py: 0.75,
              },
              width: "100%",
            })}
            value={manufacturerProductId ?? ""}
            onChange={handleChange}
          >
            <MenuItem value="">(SELECT ONE)</MenuItem>
            {suggestions.map((suggestion) => (
              <MenuItem
                key={(suggestion as any).manufacturerProductId}
                value={(suggestion as any).manufacturerProductId}
              >
                {`${(suggestion as any).manufacturerName} ${
                  (suggestion as any).manufacturerPart
                } (${(suggestion as any).description})`}
              </MenuItem>
            ))}
          </Select>
        )}
      </TableCell>
    </TableRow>
  );
};
