import { useQuery } from "@tanstack/react-query";
import { format, startOfQuarter } from "date-fns";
import {
  AccountSalesSummaryResponseDto,
  AccountsResourceService,
  SalesSummaryResponseDto,
  TransactionStatisticsResourceService,
} from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";
import {
  SALES_SUMMARY_CACHE_COOKIE_NAME,
  SALES_SUMMARY_SELECTED_ACCOUNT_CACHE_COOKIE_NAME,
} from "../../../constants/salesSummary";
import { useSavedApiResult } from "../../useSavedApiResult";

export const useSalesSummary = (accountId?: number) => {
  const queryKey = accountId
    ? QUERY_KEYS["sales-summary-account"]
    : QUERY_KEYS["sales-summary-no-account"];
  const [savedData, setSavedData] = useSavedApiResult<
    AccountSalesSummaryResponseDto | SalesSummaryResponseDto
  >(
    accountId
      ? SALES_SUMMARY_SELECTED_ACCOUNT_CACHE_COOKIE_NAME
      : SALES_SUMMARY_CACHE_COOKIE_NAME,
  );

  const endDate = format(new Date(), "yyyy-MM-dd");
  const startDate = format(startOfQuarter(new Date()), "yyyy-MM-dd");
  const response = useQuery([queryKey], async () => {
    if (savedData !== null) {
      return savedData;
    }
    const data = accountId
      ? await AccountsResourceService.getAccountSalesSummaryUsingGet(
          accountId!,
          endDate,
          startDate,
        )
      : await TransactionStatisticsResourceService.getSalesSummaryUsingGet(
          endDate,
          startDate,
        );
    setSavedData(data);
    return data;
  });
  return { ...response, endDate, startDate };
};
