import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  styled,
  Typography,
} from "@mui/material";
import { UseMutationResult } from "@tanstack/react-query/src/types";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  DetailPageTypeLabels,
  FormValues,
  IdsWithAccounts,
} from "../../types/DetailPage";
import { getEmailDetailPayload } from "../../utils/getEmailDetailPayload";
import { isValidEmail } from "../../utils/string";
import { ExclamationCircleIcon } from "../icons";
import { Button, TextField } from "../ui";

const subjectLimit = 256;
const bodyLimit = 65535;

const yupEmailTest: yup.TestFunction<string | undefined> = (value, context) => {
  if (value && !value?.split(",").every((email) => isValidEmail(email))) {
    return context.createError({
      message: "Please enter a valid email.",
    });
  }
  return true;
};

const schema = yup
  .object({
    cc: yup.string().test({ name: "cc", test: yupEmailTest }),
    email: yup
      .string()
      .test({
        name: "email",
        test: yupEmailTest,
      })
      .required("Please enter a valid email."),
    message: yup.string().max(bodyLimit, "0 characters remaining"),
    subject: yup.string().max(subjectLimit, "0 characters remaining"),
  })
  .required();

const FormFieldLabel = styled(Typography)(({ theme }) => ({
  flexShrink: 0,
  fontSize: 14,
  fontWeight: 600,
  m: 0,
  width: theme.spacing(8),
}));

const EmailDetailModal = ({
  isOpen,
  typeLabel,
  idsWithAccounts,
  accountCodes,
  onClose,
  useSendEmailHook,
}: {
  isOpen: boolean;
  typeLabel: DetailPageTypeLabels;
  idsWithAccounts: IdsWithAccounts[];
  accountCodes: (string | undefined)[];
  onClose: () => void;
  useSendEmailHook: () => UseMutationResult<any, unknown, any, unknown>;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver<FormValues>(schema),
  });
  const { mutateAsync: sendEmail, isLoading } = useSendEmailHook();
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = useCallback(
    (values: FormValues) => {
      const promises = accountCodes.map((accountCode) => {
        const ids = idsWithAccounts
          .filter(
            (idWithAccount) =>
              accountCode?.toUpperCase() ===
              idWithAccount.accountCode?.toUpperCase(),
          )
          .map((idWithAccount) => idWithAccount.id);
        const payload = getEmailDetailPayload(typeLabel, values, ids);
        return sendEmail(payload);
      });

      Promise.allSettled(promises).then((values) => {
        const failedIndexes = values
          .map((v, index) => (v.status === "rejected" ? index : -1))
          .filter((v) => v >= 0);
        if (failedIndexes.length) {
          enqueueSnackbar({
            message: `Unable to send ${typeLabel} emails.`,
            variant: "error",
          });
        } else {
          enqueueSnackbar({
            message: `${typeLabel} emails sent.`,
            variant: "success",
          });
          onClose();
        }
        reset();
      });
    },
    [
      accountCodes,
      enqueueSnackbar,
      onClose,
      reset,
      sendEmail,
      typeLabel,
      idsWithAccounts,
    ],
  );
  const [subject, message] = watch(["subject", "message"]);

  return (
    <Dialog
      fullWidth
      data-testid="detail-page-email-modal"
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={(theme) => ({
            fontSize: theme.spacing(2.25),
            m: 0,
            pb: 0,
            pt: 3.5,
            px: 2,
          })}
        >
          {`Email ${typeLabel}${idsWithAccounts.length > 1 ? "s" : ""}`}
        </DialogTitle>
        <IconButton
          sx={{
            color: (theme) => theme.palette.grey[900],
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent data-testid="detail-page-email-modal-body">
          <Box sx={{ display: "flex", gap: 3 }}>
            <FormFieldLabel>
              Email{" "}
              <Typography
                component="span"
                sx={(theme) => ({ color: theme.palette.red["500"] })}
              >
                *
              </Typography>
            </FormFieldLabel>

            <TextField
              data-testid="detail-page-email-field"
              error={!!errors.email}
              helperText={
                errors.email?.message ??
                "Enter multiple emails separated by commas."
              }
              placeholder="repname@moredirect.com"
              size="small"
              {...register("email")}
              InputProps={{
                endAdornment: !!errors.email ? (
                  <InputAdornment position="end">
                    <ExclamationCircleIcon
                      sx={(theme) => ({
                        color: theme.palette.red["500"],
                        fontSize: 16,
                        mr: theme.spacing(1),
                      })}
                    />
                  </InputAdornment>
                ) : null,
              }}
              sx={{ flex: 1 }}
            />
          </Box>

          <Box display="flex" gap={3} mt={2}>
            <FormFieldLabel />
            <Box sx={{ flex: 1 }}>
              <FormFieldLabel sx={{ mb: 0.5 }}>Cc</FormFieldLabel>
              <TextField
                data-testid="detail-page-email-cc-field"
                error={!!errors.cc}
                helperText={
                  errors.cc?.message ??
                  "Enter multiple emails separated by commas."
                }
                placeholder="repname@moredirect.com"
                size="small"
                {...register("cc")}
                fullWidth
                InputProps={{
                  endAdornment: !!errors.cc ? (
                    <InputAdornment position="end">
                      <ExclamationCircleIcon
                        sx={(theme) => ({
                          color: theme.palette.red["500"],
                          fontSize: 16,
                          mr: theme.spacing(1),
                        })}
                      />
                    </InputAdornment>
                  ) : null,
                }}
              />
            </Box>
          </Box>

          <Box display="flex" gap={3} mt={3}>
            <FormFieldLabel>Subject</FormFieldLabel>
            <TextField
              data-testid="detail-page-email-subject-field"
              error={!!errors.subject}
              helperText={
                errors.subject?.message ??
                (subject?.length
                  ? `${(
                      subjectLimit - subject.length
                    ).toLocaleString()} characters remaining`
                  : "")
              }
              maxLength={subjectLimit}
              placeholder={
                accountCodes[0] && accountCodes.length === 1
                  ? `Connection ${typeLabel} Number(s) ${idsWithAccounts
                      .map((idWithAccount) => idWithAccount.id)
                      .join(
                        ", ",
                      )} Details  (Account # ${accountCodes[0].toUpperCase()})`
                  : ""
              }
              size="small"
              {...register("subject")}
              multiline
              rows={4}
              sx={{ flex: 1 }}
            />
          </Box>

          <Box display="flex" gap={3} mt={3}>
            <FormFieldLabel>Message</FormFieldLabel>
            <TextField
              data-testid="detail-page-email-message-field"
              error={!!errors.message}
              helperText={
                errors.message?.message ??
                (message?.length
                  ? `${(
                      bodyLimit - message.length
                    ).toLocaleString()} characters remaining`
                  : "")
              }
              maxLength={bodyLimit}
              placeholder={`Attached please find the ${typeLabel} details you requested.`}
              size="small"
              {...register("message")}
              multiline
              rows={4}
              sx={{ flex: 1 }}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pb: 2,
            px: 3,
          }}
        >
          <Button
            data-testid="detail-page-email-modal-close-btn"
            sx={(theme) => ({
              height: theme.spacing(4.5),
              width: theme.spacing(12),
            })}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            data-testid="detail-page-email-modal-save-btn"
            disabled={isLoading}
            sx={(theme) => ({
              height: theme.spacing(4.5),
              width: theme.spacing(12),
            })}
            type="submit"
            variant="contained"
          >
            Send
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EmailDetailModal;
