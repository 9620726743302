/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DealResponseDto } from './DealResponseDto';
import type { ProductFeeDto } from './ProductFeeDto';
export type QuoteDetailDto = {
  /**
   * Response Only
   */
  _labels?: Record<string, string>;
  /**
   * Response Only
   */
  _links?: Record<string, Array<string>>;
  buildLinks?: Record<string, Array<string>>;
  category?: string;
  componentType?: QuoteDetailDto.componentType;
  connectionSku?: number;
  custom1?: string;
  custom2?: string;
  custom3?: string;
  custom4?: string;
  deal?: DealResponseDto;
  description?: string;
  discontinued?: boolean;
  endUserLicenseEligible?: boolean;
  endUserLicenses?: any;
  fee?: ProductFeeDto;
  gpPercentage?: number;
  internalOnly?: boolean;
  isVirtual?: boolean;
  itemClassification?: string;
  largeImageUrl?: string;
  leadTime?: number;
  lineNumber: number;
  manufacturerId?: number;
  mfgName?: string;
  mfgPart?: string;
  msrp?: number;
  previousUnitPrice?: number;
  primaryImageUrl?: string;
  productId?: number;
  qty?: number;
  qtyAvailable?: number;
  quantityBundle1?: number;
  quantityBundle10?: number;
  quantityBundle2?: number;
  quantityBundle3?: number;
  quantityBundle4?: number;
  quantityBundle5?: number;
  quantityBundle6?: number;
  quantityBundle7?: number;
  quantityBundle8?: number;
  quantityBundle9?: number;
  quoteLineId?: number;
  repGpPercentage?: number;
  screenSize?: number;
  sequence?: number;
  specialPrice?: string;
  subtotalWeight?: number;
  supplierCode?: string;
  supplierName?: string;
  supplierPart?: string;
  supplierPartAuxiliaryId?: string;
  supplierProductId?: number;
  totalCost?: number;
  totalGp?: number;
  totalLoadedCost?: number;
  totalPrice?: number;
  totalRepGp?: number;
  unitCost?: number;
  unitGp?: number;
  unitLoadedCost?: number;
  unitPrice?: number;
  unitRepGp?: number;
  unitWeight?: number;
  unspsc?: string;
  width400ImageUrl?: string;
};
export namespace QuoteDetailDto {
  export enum componentType {
    COMPONENT = 'Component',
    FEE = 'Fee',
    MASTER = 'Master',
    PICK_PACK = 'Pick-Pack',
  }
}

