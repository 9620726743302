import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  InputAdornment,
  styled,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { OpenAPI, RmaRequestDto } from "../../../api";
import {
  isValidEmail,
  isValidFullName,
  isValidPhone,
} from "../../../utils/string";
import { Button, LoadingButton, TextField } from "../../ui";
import { useRmaContext } from "./RmaContext";

const FormFieldLabel = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  mb: theme.spacing(1),
}));

const FormFieldTextField = styled(TextField)(() => ({
  fontSize: 14,
  width: 261,
}));

type FormValues = {
  name: string;
  email: string;
  phone: string;
};

const yupNameTest: yup.TestFunction<string | undefined> = (value, context) => {
  if (!isValidFullName(value)) {
    return context.createError({
      message: "Please enter a valid full name.",
    });
  }
  return true;
};

const yupEmailTest: yup.TestFunction<string | undefined> = (value, context) => {
  if (!isValidEmail(value)) {
    return context.createError({
      message: "Please enter a valid email.",
    });
  }
  return true;
};

const yupPhoneTest: yup.TestFunction<string | undefined> = async (
  value,
  context,
) => {
  if (!isValidPhone(value)) {
    return context.createError({
      message: "Please enter a valid phone.",
    });
  }
  return true;
};

const schema = yup.object({
  email: yup
    .string()
    .test({
      name: "email",
      test: yupEmailTest,
    })
    .required("Please enter an email."),
  name: yup
    .string()
    .test({
      name: "name",
      test: yupNameTest,
    })
    .required("Please enter a full name."),
  phone: yup
    .string()
    .test({
      name: "phone",
      test: yupPhoneTest,
    })
    .required("Please enter a phone number."),
});

const ContactDetails = ({
  onSubmitRequest,
}: {
  onSubmitRequest: (returnPayload: RmaRequestDto, notes: string[]) => void;
}) => {
  const {
    handleSubmit,
    setError,
    formState: { errors },
    register,
  } = useForm<FormValues>({
    resolver: yupResolver<FormValues>(schema),
  });
  const {
    orderId,
    orderLine,
    numberToReturn,
    serialNumbers,
    reason,
    sealed,
    setContact,
    partDetails,
    onClose,
    accountCode,
  } = useRmaContext();
  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false);

  const onSubmit = useCallback(
    (values: FormValues) => {
      setSubmitIsLoading(true);
      axios
        .post(
          `/service/rest/address/shipping/validation?accountCode=${accountCode}`,
          {
            telephone: values.phone,
          },
          {
            baseURL: OpenAPI.BASE,
            headers: {
              Accept: "/",
              Authorization: `Bearer ${OpenAPI.TOKEN}`,
            },
          },
        )
        .catch(() => {
          setSubmitIsLoading(false);
          setError("phone", {
            message: "Please enter a valid phone number",
            type: "manual",
          });
        })
        .then((response) => {
          if (!response) {
            setSubmitIsLoading(false);
            setError("phone", {
              message: "Please enter a valid phone number",
              type: "manual",
            });
            return;
          }
          setContact({
            email: values.email,
            fullName: values.name,
            phone: values.phone,
          });
          let trackingNumberNotes: string[] = [];
          serialNumbers?.forEach((serialNumber) => {
            orderLine?.shipments?.some((shipment) =>
              shipment?.devices?.some((device) => {
                if (device.serialNumber === serialNumber) {
                  trackingNumberNotes.push(
                    `${shipment.trackingCode} (Serial # ${serialNumber})`,
                  );
                  return true;
                }
                return false;
              }),
            );
          });

          onSubmitRequest(
            {
              email: values.email,
              lines: [
                {
                  qtyReturned: numberToReturn
                    ? numberToReturn
                    : serialNumbers
                      ? serialNumbers?.length
                      : 0,
                  returnReason: reason?.code ?? "",
                  sealed: sealed,
                  serialNumbers: serialNumbers,
                  suppCode: partDetails?.supplierCode ?? "",
                  suppPart: partDetails?.supplierPart ?? "",
                },
              ],
              name: values.name,
              orderId: orderId ?? 0,
              phone: values.phone,
            },
            [
              `Invoice Numbers(s): ${orderLine?.invoiceNumbers?.join(", ")}`,
              `Tracking #(s): ${trackingNumberNotes.join(", ")}`,
              reason?.reasonDetails as string,
            ],
          );
        });
    },
    [
      onSubmitRequest,
      setSubmitIsLoading,
      serialNumbers,
      reason,
      numberToReturn,
      partDetails,
      orderId,
      orderLine,
      sealed,
      setContact,
      setError,
      accountCode,
    ],
  );

  return (
    <Dialog
      fullWidth
      open
      data-testid="rma-request-contact-details-modal"
      maxWidth="sm"
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          data-testid="rma-request-contact-details-modal-title"
          sx={(theme) => ({
            fontSize: theme.spacing(2.25),
            pb: 0,
            pt: 3.5,
            px: 2,
          })}
        >
          Request RMA for Order{" "}
          <Typography
            component="span"
            sx={(theme) => ({
              fontSize: theme.spacing(2.25),
              fontWeight: "bold",
            })}
          >
            {orderId}
          </Typography>{" "}
          Part{" "}
          <Typography
            component="span"
            sx={(theme) => ({
              fontSize: theme.spacing(2.25),
              fontWeight: "bold",
            })}
          >
            {partDetails?.supplierCode}|{partDetails?.supplierPart}
          </Typography>
        </DialogTitle>
        <IconButton
          data-testid="rma-request-contact-details-modal-close-icon"
          sx={{
            color: (theme) => theme.palette.grey[900],
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent data-testid="rma-request-contact-details-modal-body">
          <Box>
            <Typography
              sx={(theme) => ({
                fontSize: 16,
                mb: theme.spacing(1),
              })}
            >
              Please tell us who has the item now
            </Typography>
          </Box>
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(2),
            })}
          >
            <Box>
              <FormFieldLabel>Name</FormFieldLabel>
              <FormFieldTextField
                autoFocus
                InputProps={{
                  endAdornment: !!errors.name ? (
                    <InputAdornment position="end">
                      <ErrorOutlineIcon
                        sx={(theme) => ({ color: theme.palette.red["500"] })}
                      />
                    </InputAdornment>
                  ) : null,
                }}
                data-testid="rma-request-contact-details-name"
                error={!!errors.name}
                placeholder="First and last name"
                size="small"
                {...register("name")}
              />
              {errors.name && (
                <FormHelperText error>{errors.name.message}</FormHelperText>
              )}
            </Box>
            <Box>
              <FormFieldLabel>Email</FormFieldLabel>
              <FormFieldTextField
                InputProps={{
                  endAdornment: !!errors.email ? (
                    <InputAdornment position="end">
                      <ErrorOutlineIcon
                        sx={(theme) => ({ color: theme.palette.red["500"] })}
                      />
                    </InputAdornment>
                  ) : null,
                }}
                data-testid="rma-request-contact-details-email"
                error={!!errors.email}
                placeholder="example@mail.com"
                size="small"
                {...register("email")}
              />
              {errors.email && (
                <FormHelperText error>{errors.email.message}</FormHelperText>
              )}
            </Box>
            <Box>
              <FormFieldLabel>Phone number</FormFieldLabel>
              <FormFieldTextField
                InputProps={{
                  endAdornment: !!errors.phone ? (
                    <InputAdornment position="end">
                      <ErrorOutlineIcon
                        sx={(theme) => ({ color: theme.palette.red["500"] })}
                      />
                    </InputAdornment>
                  ) : null,
                }}
                data-testid="rma-request-contact-details-phone"
                error={!!errors.phone}
                placeholder="xxx-xxx-xxxx"
                size="small"
                type="tel"
                {...register("phone")}
              />
              {errors.phone && (
                <FormHelperText error>{errors.phone.message}</FormHelperText>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", pb: 3, px: 2 }}>
          <Button
            data-testid="rma-request-contact-details-modal-close-btn"
            sx={(theme) => ({ width: theme.spacing(12.5) })}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            data-testid="rma-request-contact-details-modal-submit-btn"
            loading={submitIsLoading}
            sx={(theme) => ({ width: theme.spacing(16.5) })}
            type="submit"
            variant="contained"
          >
            Submit Request
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ContactDetails;
