import { createSvgIcon } from "@mui/material";

export const ClockIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="clock"
        transform="translate(-119.000000, -193.000000)"
      >
        <g id="date-filter" transform="translate(104.000000, 183.000000)">
          <g id="clock" transform="translate(15.000000, 10.000000)">
            <path
              d="M8,3.5 C8,3.22385763 7.77614237,3 7.5,3 C7.22385763,3 7,3.22385763 7,3.5 L7,9 C7.00005787,9.17939937 7.09622347,9.34501792 7.252,9.434 L10.752,11.434 C10.9908782,11.5631011 11.2891306,11.4779553 11.4238484,11.2421991 C11.5585662,11.0064429 11.4805022,10.7062591 11.248,10.566 L8,8.71 L8,3.5 Z"
              id="Path"
            ></path>
            <path
              d="M8,16 C12.418278,16 16,12.418278 16,8 C16,3.581722 12.418278,0 8,0 C3.581722,0 0,3.581722 0,8 C0,12.418278 3.581722,16 8,16 Z M15,8 C15,11.8659932 11.8659932,15 8,15 C4.13400675,15 1,11.8659932 1,8 C1,4.13400675 4.13400675,1 8,1 C11.8659932,1 15,4.13400675 15,8 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "Clock",
);
