/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListingColumnsDto } from '../models/ListingColumnsDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ListingColumnResourceService {
  /**
   * Get Listing Columns
   * Get Listing Columns
   * @param listingName listingName
   * @returns ListingColumnsDto Success
   * @throws ApiError
   */
  public static listingsColumnsUsingGet(
    listingName: 'account' | 'accounts' | 'asset_list' | 'assets' | 'bill_address_list' | 'billing_addresses' | 'crm_client' | 'data_map' | 'datamap' | 'datamaps' | 'deal_list' | 'deals' | 'electronic_pos' | 'electronicpo' | 'email_log' | 'expiration_list' | 'expirations' | 'global_invoice_line' | 'inventory' | 'inventory_list' | 'invoice_line_list' | 'invoice_list' | 'invoicelines' | 'invoices' | 'lcds' | 'lowest_cost_list' | 'order_line_list' | 'order_lines' | 'order_list' | 'orderlines' | 'orders' | 'par_list' | 'pars' | 'product' | 'products' | 'quote_line_list' | 'quote_list' | 'quotelines' | 'quotes' | 'returns' | 'scheduled_reports' | 'scheduledreports' | 'service_order_list' | 'service_orders' | 'serviceorders' | 'ship_address_list' | 'shipment_list' | 'shipments' | 'shippingaddresses' | 'staff' | 'staff_listing' | 'standard_line_list' | 'standard_list' | 'standardlines' | 'standards' | 'stocking_order' | 'stocking_order_line' | 'stocking_order_lines' | 'stocking_order_list' | 'stocking_orders' | 'stockingorder' | 'stockingorderline' | 'stockingorderlines' | 'stockingorders' | 'user_list' | 'users',
  ): CancelablePromise<Array<ListingColumnsDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/listing/{listingName}/columns',
      path: {
        'listingName': listingName,
      },
      errors: {
        401: `Unauthorized`,
        403: `You are not authorized to access this resource.`,
        404: `Facets not found.`,
        500: `Internal Error retrieving columns`,
      },
    });
  }
}
