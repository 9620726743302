import { useEffect, useMemo, useRef } from "react";
import { Chart, ReactGoogleChartEvent } from "react-google-charts";
import { CHART_COLORS } from "../../constants/chart";
import { GOOGLE_CHART_VERSION } from "../../constants/chat";
import { usePurchaseAnalysisContext } from "../../contexts/PurchaseAnalysisContext";
import { useCookiesLive } from "../../hooks";
import { useSalesByCategory } from "../../hooks/api/queries";
import { PurchaseAnalysisChartOption1 } from "../../types";
import { formatCurrency } from "../../utils/currency";
import { getAppLink } from "../../utils/link";
import ChartDateFilterButton from "./ChartDateFilterButton";
import ChartWidget from "./ChartWidget";

const SpendByCategory = () => {
  const [accountCode] = useCookiesLive(["accountCode"]);
  const { config } = usePurchaseAnalysisContext();
  const chartConfig =
    config.spendByCategory as PurchaseAnalysisChartOption1 | null;
  const { data, isFetching } = useSalesByCategory(
    chartConfig?.startDate,
    chartConfig?.endDate,
    accountCode,
    chartConfig?.allTime,
  );
  const chartConfigRef = useRef<PurchaseAnalysisChartOption1 | null>(null);

  useEffect(() => {
    chartConfigRef.current = chartConfig;
  }, [chartConfig]);

  const chartData = useMemo(
    () => [
      ["Category", "Sales"],
      ...(data?.map((value: any) => [
        value.categoryName,
        { f: formatCurrency(value.thisYear, 0), v: value.thisYear },
      ]) ?? []),
    ],
    [data],
  );

  const chartEvents: ReactGoogleChartEvent[] = [
    {
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper?.getChart();
        const selection = chart?.getSelection();
        if (selection && selection.length === 1) {
          const [selectedItem] = selection;
          const dataTable = chartWrapper?.getDataTable();
          const { row, column } = selectedItem;
          const value = dataTable?.getValue(row ?? 0, column ?? 0);

          if (value) {
            const searchParams = new URLSearchParams();
            searchParams.set(
              "params",
              JSON.stringify({
                d: 1,
                disp: [
                  "invoiceNum",
                  "custPo",
                  "invoiceDate",
                  "mfgName",
                  "mfgPart",
                  "catName",
                  "qty",
                  "unitPrice",
                  "totalPrice",
                  "description",
                  "reserved_actions",
                ],
                fs: {
                  catName: [value],
                  ...(chartConfigRef.current?.allTime
                    ? {}
                    : {
                        invoiceDate: {
                          end: chartConfigRef.current?.endDate,
                          start: chartConfigRef.current?.startDate,
                        },
                      }),
                },
                fs_clear: false,
                o: "orderNum",
                p: "1",
                r: "50",
              }),
            );
            searchParams.set("view_label", "Default+View");
            window.location.href = getAppLink(
              `app/InvoiceLine?${searchParams.toString()}`,
            );
          }
        }
      },
      eventName: "select",
    },
  ];

  return (
    <ChartWidget
      actions={<ChartDateFilterButton hasAllTime configKey="spendByCategory" />}
      hasNoData={!isFetching && !data?.length}
      id="spendByCategory"
      isLoading={isFetching}
      title="Spend By Category"
    >
      <Chart
        chartEvents={chartEvents}
        chartType="PieChart"
        chartVersion={GOOGLE_CHART_VERSION}
        data={chartData}
        graphID="spendByCategory"
        height="100%"
        options={{
          legend: {
            pagingTextStyle: {
              color: "#2481C8",
            },
            scrollArrows: {
              activeColor: "#28A2E1",
              cursor: "pointer",
              inactiveColor: "#CCE3E3",
            },
          },
          slices: CHART_COLORS.reduce(
            (result, color, index) => ({
              ...result,
              [index]: { color },
            }),
            {},
          ),
        }}
        width="100%"
      />
    </ChartWidget>
  );
};
export default SpendByCategory;
