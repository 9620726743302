import { useEffect, useState } from "react";
import ImportTaxExemptions from "../ImportTaxExemptions";

const ImportTaxExemptionsWrapper = () => {
  const [importing, setImporting] = useState(false);

  useEffect(() => {
    document.addEventListener("startImportTaxExemptions", () =>
      setImporting(true),
    );
  }, []);

  const handleCancel = () => setImporting(false);
  return <>{importing && <ImportTaxExemptions onClose={handleCancel} />}</>;
};

export default ImportTaxExemptionsWrapper;
