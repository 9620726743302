import { createSvgIcon } from "@mui/material";

export const PencilIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <title>pencil</title>
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="main-nav"
        transform="translate(-971.000000, -263.000000)"
      >
        <g id="Group-16" transform="translate(29.000000, 254.000000)">
          <g id="edit" transform="translate(921.000000, 0.000000)">
            <g id="pencil" transform="translate(21.000000, 9.000000)">
              <path
                d="M12.1458205,0.146893784 C12.2396247,0.052852083 12.3669932,-3.33066907e-16 12.4998205,-3.33066907e-16 C12.6326477,-3.33066907e-16 12.7600162,0.052852083 12.8538205,0.146893784 L15.8538205,3.14689378 C15.9478622,3.24069805 16.0007142,3.36806652 16.0007142,3.50089378 C16.0007142,3.63372104 15.9478622,3.76108952 15.8538205,3.85489378 L5.85382046,13.8548938 C5.80583541,13.9025441 5.74868298,13.9399654 5.68582046,13.9648938 L0.685820461,15.9648938 C0.500089603,16.0392374 0.287923067,15.995715 0.146461179,15.8542531 C0.00499929228,15.7127912 -0.038523196,15.5006246 0.0358204609,15.3148938 L2.03582046,10.3148938 C2.06074887,10.2520313 2.0981701,10.1948788 2.14582046,10.1468938 L12.1458205,0.146893784 Z M11.2068205,2.50089378 L13.4998205,4.79389378 L14.7928205,3.50089378 L12.4998205,1.20789378 L11.2068205,2.50089378 Z M12.7928205,5.50089378 L10.4998205,3.20789378 L3.99982046,9.70789378 L3.99982046,10.0008938 L4.49982046,10.0008938 C4.77596284,10.0008938 4.99982046,10.2247514 4.99982046,10.5008938 L4.99982046,11.0008938 L5.49982046,11.0008938 C5.77596284,11.0008938 5.99982046,11.2247514 5.99982046,11.5008938 L5.99982046,12.0008938 L6.29282046,12.0008938 L12.7928205,5.50089378 L12.7928205,5.50089378 Z M3.03182046,10.6758938 L2.92582046,10.7818938 L1.39782046,14.6028938 L5.21882046,13.0748938 L5.32482046,12.9688938 C5.12950379,12.8959172 4.9999768,12.7093984 4.99982046,12.5008938 L4.99982046,12.0008938 L4.49982046,12.0008938 C4.22367809,12.0008938 3.99982046,11.7770362 3.99982046,11.5008938 L3.99982046,11.0008938 L3.49982046,11.0008938 C3.29131589,11.0007374 3.10479702,10.8712105 3.03182046,10.6758938 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "Pencil",
);
