/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CostDto = {
  cost?: number;
  costType?: CostDto.costType;
  supplierProductId?: number;
};
export namespace CostDto {
  export enum costType {
    COST_TYPE_DEAL = 'COST_TYPE_DEAL',
    COST_TYPE_GOV_EDU = 'COST_TYPE_GOV_EDU',
    COST_TYPE_SPECIAL_BID = 'COST_TYPE_SPECIAL_BID',
    COST_TYPE_TOSS = 'COST_TYPE_TOSS',
    COST_TYPE_VENDOR_PRICE = 'COST_TYPE_VENDOR_PRICE',
  }
}

