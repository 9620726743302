import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box } from "@mui/material";
import { flexRender, Header } from "@tanstack/react-table";
import {
  CHECKBOX_COLUMN_ID,
  TABLE_ROW_HEIGHT,
} from "../../constants/dataTable";
import theme from "../../theme";
import { SortAscIcon, SortDescIcon } from "../icons";
import { IconButton, TableHeaderCell, VCenterBox } from "../ui";

const DataTableColumn = <TData,>({
  header,
}: {
  header: Header<TData, unknown>;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
  } = useSortable({ id: header.id });

  const sortDirection = header.column.getIsSorted();

  return (
    <TableHeaderCell
      ref={setNodeRef}
      component="th"
      data-testid={`dt-column-${header.column.columnDef.id}`}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
      sx={{
        alignItems: "center",
        background: theme.palette.white,
        boxShadow: isDragging ? 3 : 0,
        color: theme.palette.grey["900"],
        columnSpan: header.colSpan,
        cursor: isDragging ? "grabbing" : "pointer",
        display: "inline-flex",
        height: TABLE_ROW_HEIGHT,
        opacity: isDragging ? 0.5 : 1,
        overflow: "hidden",
        p: 0,
        position: "relative",
        textTransform: "none",
        width: `${header.getSize()}px`,
        zIndex: isDragging ? 1 : "unset",
      }}
      {...attributes}
    >
      <Box
        data-testid="dt-column-drag-preview"
        sx={{
          height: "100%",
          width: "100%",
        }}
        onClick={
          header.column.columnDef.id !== CHECKBOX_COLUMN_ID
            ? header.column.getToggleSortingHandler()
            : undefined
        }
      >
        <VCenterBox
          ref={setActivatorNodeRef}
          data-testid="dt-column-drag-handler"
          sx={{
            height: "100%",
            justifyContent: "space-between",
            px: header.id === CHECKBOX_COLUMN_ID ? 0 : 2,
          }}
          {...listeners}
        >
          <Box
            component="span"
            sx={{
              alignItems: "center",
              display: "inline-flex",
              gap: 1,
            }}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
            {sortDirection ? (
              <IconButton
                data-testid={`dt-column-sort-${sortDirection}`}
                sx={(theme) => ({
                  background: theme.palette.grey["100"],
                  borderRadius: "2px",
                  color: theme.palette.grey["800"],
                  height: theme.spacing(2),
                  p: 0,
                  width: theme.spacing(3),
                })}
              >
                {sortDirection === "asc" ? (
                  <SortAscIcon color="inherit" sx={{ width: 15 }} />
                ) : (
                  <SortDescIcon color="inherit" sx={{ width: 15 }} />
                )}
              </IconButton>
            ) : null}
          </Box>
        </VCenterBox>
      </Box>
    </TableHeaderCell>
  );
};

export default DataTableColumn;
