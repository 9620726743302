import { useMemo, useState } from "react";
import { ListingUser } from "../../api";
import ListingPage from "../../components/ListingPage";
import AuthenticatedLink from "../../components/UserList/AuthenticatedLink";
import { withPageTitle } from "../../hocs";
import { ActionColumnItem } from "../../types";

const UserList = () => {
  const [selectedUserId, setSelectedUserId] = useState<number>();

  const actionItems = useMemo<ActionColumnItem<ListingUser>[]>(
    () => [
      {
        isVisible: (user: ListingUser) => user?.userStatus === "Active",
        label: "Manage Authenticated Link",
        onClick: (user: ListingUser) => setSelectedUserId(user.userId!),
      },
    ],
    [],
  );

  return (
    <>
      <ListingPage
        actionColumnItems={actionItems}
        identifier="userId"
        listingName="users"
      />
      {selectedUserId && (
        <AuthenticatedLink
          userId={selectedUserId}
          onClose={() => setSelectedUserId(undefined)}
        />
      )}
    </>
  );
};

export default withPageTitle("User List", UserList);
