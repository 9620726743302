import { useUser } from "../../hooks/api/queries";
import GenerateAuthenticatedLink from "../modals/GenerateAuthenticatedLink";
import ManageAuthenticatedLink from "../modals/ManageAuthenticatedLink";

const AuthenticatedLink = ({
  userId,
  onClose,
}: {
  userId: number;
  onClose: () => void;
}) => {
  const { data: user, isLoading, refetch } = useUser(userId);

  if (isLoading || !user) {
    return null;
  }

  return (
    <>
      {/* @ts-ignore */}
      {user?.authenticatedLinkHash ? (
        <ManageAuthenticatedLink
          isOpen
          user={user}
          onClose={onClose}
          onUserUpdated={() => refetch()}
        />
      ) : (
        <GenerateAuthenticatedLink
          isOpen
          user={user}
          onAuthLinkGenerated={() => refetch()}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default AuthenticatedLink;
