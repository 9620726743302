import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CSVLink } from "react-csv";
import { GlobalInvoiceLineDto } from "../../types/GlobalInvoiceLine";
import { Button, VCenterBox } from "../ui";

export type GlobalInvoiceLineDtoWithError = GlobalInvoiceLineDto & {
  error?: string;
};

const ImportResult = ({
  succeededGlobalLines,
  failedGlobalLines,
  onClose,
}: {
  succeededGlobalLines: GlobalInvoiceLineDtoWithError[];
  failedGlobalLines: GlobalInvoiceLineDtoWithError[];
  onClose: () => void;
}) => {
  return (
    <Dialog fullWidth open maxWidth="md" onClose={onClose}>
      <DialogTitle sx={{ px: 2, py: 4 }}>
        Imported Global Invoice Lines
      </DialogTitle>
      <IconButton
        sx={{
          color: (theme) => theme.palette.grey[900],
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ px: 2, py: 0 }}>
        {failedGlobalLines.length > 0 && (
          <VCenterBox
            sx={(theme) => ({
              background: theme.palette.red["200"],
              justifyContent: "space-between",
              mb: 2,
              px: 3,
              py: 1,
            })}
          >
            <Typography variant="body1">
              There were Invoice Lines that did not successfully import
            </Typography>

            <CSVLink
              data={failedGlobalLines}
              filename="failed-global-invoice-lines.csv"
              headers={[
                { key: "custPo", label: "PO" },
                { key: "orderNum", label: "Order Number" },
                { key: "lineNumber", label: "Line Number" },
                { key: "countryCode", label: "Country" },
                { key: "error", label: "Error" },
              ]}
            >
              <Button color="error" variant="contained">
                Download Import Failures
              </Button>
            </CSVLink>
          </VCenterBox>
        )}

        {succeededGlobalLines.length > 0 && (
          <>
            <Alert severity="success" sx={{ mb: 2 }}>
              The following Invoice lines were successfully imported:
            </Alert>
            <TableContainer>
              <Table sx={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow
                    sx={(theme) => ({
                      background: theme.palette.grey["50"],
                      fontSize: theme.spacing(1.75),
                    })}
                  >
                    <TableCell
                      component="th"
                      sx={{ textTransform: "uppercase" }}
                    >
                      PO
                    </TableCell>
                    <TableCell
                      component="th"
                      sx={{ textTransform: "uppercase" }}
                    >
                      Order Number
                    </TableCell>
                    <TableCell
                      component="th"
                      sx={{ textTransform: "uppercase" }}
                    >
                      Line Number
                    </TableCell>
                    <TableCell
                      component="th"
                      sx={{ textTransform: "uppercase" }}
                    >
                      Country
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {succeededGlobalLines.map((globalLine) => (
                    <TableRow
                      key={`${globalLine.custPo}-${globalLine.lineNumber}`}
                    >
                      <TableCell>{globalLine.custPo}</TableCell>
                      <TableCell>{globalLine.orderNum}</TableCell>
                      <TableCell>{globalLine.lineNumber}</TableCell>
                      <TableCell>
                        {globalLine.shippingAddress?.countryCode}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: "flex-end", pb: 3, px: 2 }}>
        <Button
          sx={(theme) => ({ width: theme.spacing(12.5) })}
          variant="outlined"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportResult;
