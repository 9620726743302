import { createSvgIcon } from "@mui/material";

export const EmailIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
    <title>email-icon</title>
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="order-details"
        transform="translate(-1198, -265)"
      >
        <g id="action-btns" transform="translate(1177, 254)">
          <g id="envelope" transform="translate(21, 11)">
            <path
              d="M0,2 C0,0.8954305 0.8954305,0 2,0 L14,0 C15.1045695,0 16,0.8954305 16,2 L16,10 C16,11.1045695 15.1045695,12 14,12 L2,12 C0.8954305,12 0,11.1045695 0,10 L0,2 Z M2,1 C1.44771525,1 1,1.44771525 1,2 L1,2.217 L8,6.417 L15,2.217 L15,2 C15,1.44771525 14.5522847,1 14,1 L2,1 Z M15,3.383 L10.292,6.208 L15,9.105 L15,3.383 Z M14.966,10.259 L9.326,6.788 L8,7.583 L6.674,6.788 L1.034,10.258 C1.15083968,10.6955352 1.547133,10.9999344 2,11 L14,11 C14.4525816,11.0000555 14.8487814,10.6961383 14.966,10.259 L14.966,10.259 Z M1,9.105 L5.708,6.208 L1,3.383 L1,9.105 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "EmailIcon",
);
