import { Box, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";

const LinearProgressBar = ({
  onChange,
}: {
  onChange?: (progress: number) => void;
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        return Math.min(oldProgress + 5, 95);
      });
    }, 200);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(progress);
    }
  }, [onChange, progress]);

  return (
    <Box sx={{ borderRadius: 8, overflow: "hidden", width: "100%" }}>
      <LinearProgress
        sx={{ height: 8 }}
        value={progress}
        variant="determinate"
      />
    </Box>
  );
};

export default LinearProgressBar;
