/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GridViewReportScheduleDto } from '../models/GridViewReportScheduleDto';
import type { RestCollection_View_ } from '../models/RestCollection_View_';
import type { RestMessage } from '../models/RestMessage';
import type { ViewReq } from '../models/ViewReq';
import type { ViewRequest } from '../models/ViewRequest';
import type { ViewRes } from '../models/ViewRes';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ViewResourceService {
  /**
   * Get grid view reports
   * Get grid view reports
   * @param accountCode accountCode
   * @param viewId viewId
   * @returns GridViewReportScheduleDto Ok
   * @throws ApiError
   */
  public static listByGridViewIdUsingGet1(
    accountCode: string,
    viewId: number,
  ): CancelablePromise<Array<GridViewReportScheduleDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/account/{accountCode}/views/{viewId}/schedules',
      path: {
        'accountCode': accountCode,
        'viewId': viewId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `View Not Found`,
        500: `Internal Error fetching view`,
      },
    });
  }
  /**
   * Get views
   * Get views
   * @param listingName listingName
   * @returns RestCollection_View_ Ok
   * @throws ApiError
   */
  public static listGridViewsUsingGet(
    listingName: 'account' | 'accounts' | 'ami_list' | 'amis' | 'asset_list' | 'assets' | 'crm_client' | 'data_map' | 'datamap' | 'datamaps' | 'deal_list' | 'deals' | 'electronic_pos' | 'electronicpo' | 'email_log' | 'expiration_list' | 'expirations' | 'global_invoice_line' | 'inventory' | 'inventory_list' | 'invoice_line_list' | 'invoice_list' | 'invoicelines' | 'invoices' | 'lcds' | 'lowest_cost_list' | 'order_line_list' | 'order_lines' | 'order_list' | 'orderlines' | 'orders' | 'par_list' | 'pars' | 'product' | 'products' | 'quote_line_list' | 'quote_list' | 'quotelines' | 'quotes' | 'reg_deal_list' | 'registereddeals' | 'returns' | 'search_banners' | 'search_list' | 'searches' | 'service_order_list' | 'serviceorders' | 'ship_address_list' | 'shipment_list' | 'shipments' | 'shippingaddresses' | 'staff' | 'staff_listing' | 'standard_line_list' | 'standard_list' | 'standardlines' | 'standards' | 'stocking_order' | 'stocking_order_line' | 'stocking_order_lines' | 'stocking_order_list' | 'stocking_orders' | 'stockingorder' | 'stockingorderline' | 'stockingorderlines' | 'stockingorders' | 'user_list' | 'users',
  ): CancelablePromise<RestCollection_View_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/listing/{listingName}/views',
      path: {
        'listingName': listingName,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `View Not Found`,
        500: `Internal Error fetching views`,
      },
    });
  }
  /**
   * Create listing view
   * Create listing view
   * @param listingName listingName
   * @param view view
   * @returns ViewRes Ok
   * @throws ApiError
   */
  public static createListingViewUsingPost(
    listingName: string,
    view: ViewReq,
  ): CancelablePromise<ViewRes> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/listing/{listingName}/views',
      path: {
        'listingName': listingName,
      },
      body: view,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `View Not Found`,
        500: `Internal Error fetching view`,
      },
    });
  }
  /**
   * Get grid view
   * Get grid view
   * @param listingName listingName
   * @param viewId viewId
   * @returns ViewRes Ok
   * @throws ApiError
   */
  public static getGridViewUsingGet(
    listingName: string,
    viewId: number,
  ): CancelablePromise<ViewRes> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/listing/{listingName}/views/{viewId}',
      path: {
        'listingName': listingName,
        'viewId': viewId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `View Not Found`,
        500: `Internal Error fetching view`,
      },
    });
  }
  /**
   * Delete listing view
   * Delete Listing view
   * @param listingName listingName
   * @param viewId viewId
   * @returns RestMessage Ok
   * @throws ApiError
   */
  public static deleteListingViewUsingDelete(
    listingName: string,
    viewId: number,
  ): CancelablePromise<RestMessage> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/service/rest/listing/{listingName}/views/{viewId}',
      path: {
        'listingName': listingName,
        'viewId': viewId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `View Not Found`,
        500: `Internal Error fetching view`,
      },
    });
  }
  /**
   * Update listing view
   * Update listing view
   * @param listingName listingName
   * @param view view
   * @param viewId viewId
   * @returns ViewRes Ok
   * @throws ApiError
   */
  public static updateListingViewUsingPatch(
    listingName: string,
    view: ViewRequest,
    viewId: number,
  ): CancelablePromise<ViewRes> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/listing/{listingName}/views/{viewId}',
      path: {
        'listingName': listingName,
        'viewId': viewId,
      },
      body: view,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `View Not Found`,
        500: `Internal Error fetching view`,
      },
    });
  }
  /**
   * Get view listing
   * Get views listing
   * @param criteria criteria
   * @param page page
   * @param returnAllLabels returnAllLabels
   * @param returnPagination returnPagination
   * @param returnSavedViews returnSavedViews
   * @param size size
   * @param useDefaultView useDefaultView
   * @returns RestCollection_View_ Ok
   * @throws ApiError
   */
  public static listUsingGet28(
    criteria: string = '{}',
    page: number = 1,
    returnAllLabels: boolean = false,
    returnPagination: boolean = true,
    returnSavedViews: boolean = true,
    size: number = 50,
    useDefaultView: boolean = false,
  ): CancelablePromise<RestCollection_View_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/views',
      query: {
        'criteria': criteria,
        'page': page,
        'returnAllLabels': returnAllLabels,
        'returnPagination': returnPagination,
        'returnSavedViews': returnSavedViews,
        'size': size,
        'useDefaultView': useDefaultView,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `View(s) Not Found`,
        500: `Internal Error fetching views`,
      },
    });
  }
  /**
   * Create view
   * Create view
   * @param view view
   * @returns ViewRes Created
   * @throws ApiError
   */
  public static createUsingPost8(
    view: ViewRequest,
  ): CancelablePromise<ViewRes> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/views',
      body: view,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `View Not Found`,
        500: `Internal Error creating view`,
      },
    });
  }
  /**
   * Set default view
   * Set default view
   * @param viewId viewId
   * @returns ViewRes Ok
   * @returns any Created
   * @throws ApiError
   */
  public static setDefaultUsingPost(
    viewId: number,
  ): CancelablePromise<ViewRes | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/views/setdefault/{viewId}',
      path: {
        'viewId': viewId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `View Not Found`,
        500: `Internal Error setting default view`,
      },
    });
  }
  /**
   * Remove deprecated view
   * Remove deprecated view
   * @param viewId viewId
   * @returns ViewRes Ok
   * @returns any Created
   * @throws ApiError
   */
  public static removeDefaultUsingPost(
    viewId: number,
  ): CancelablePromise<ViewRes | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/views/unsetdefault/{viewId}',
      path: {
        'viewId': viewId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `View Not Found`,
        500: `Internal Error removing deprecated view`,
      },
    });
  }
  /**
   * @deprecated
   * removeDefaultByGridIdDeprecated
   * @param gridId gridId
   * @returns RestMessage OK
   * @returns any Created
   * @throws ApiError
   */
  public static removeDefaultByGridIdDeprecatedUsingPost(
    gridId: number,
  ): CancelablePromise<RestMessage | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/views/unsetgriddefault/{gridId}',
      path: {
        'gridId': gridId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get view
   * Get view
   * @param viewId viewId
   * @returns ViewRes Ok
   * @throws ApiError
   */
  public static readUsingGet2(
    viewId: number,
  ): CancelablePromise<ViewRes> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/views/{viewId}',
      path: {
        'viewId': viewId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `View Not Found`,
        500: `Internal Error fetching view`,
      },
    });
  }
  /**
   * Delete view
   * Delete view
   * @param viewId viewId
   * @returns RestMessage Ok
   * @throws ApiError
   */
  public static deleteUsingDelete11(
    viewId: number,
  ): CancelablePromise<RestMessage> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/service/rest/views/{viewId}',
      path: {
        'viewId': viewId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `View Not Found`,
        500: `Internal Error deleting view`,
      },
    });
  }
  /**
   * update view
   * Update view
   * @param viewId viewId
   * @returns ViewRes Ok
   * @throws ApiError
   */
  public static patchUsingPatch5(
    viewId: number,
  ): CancelablePromise<ViewRes> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/views/{viewId}',
      path: {
        'viewId': viewId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `View Not Found`,
        500: `Internal Error updating view`,
      },
    });
  }
  /**
   * Get view schedule
   * Get view schedule
   * @param viewId viewId
   * @returns GridViewReportScheduleDto Ok
   * @throws ApiError
   */
  public static listByGridViewIdUsingGet(
    viewId: number,
  ): CancelablePromise<Array<GridViewReportScheduleDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/views/{viewId}/schedules',
      path: {
        'viewId': viewId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `View Not Found`,
        500: `Internal Error fetching view schedule`,
      },
    });
  }
}
