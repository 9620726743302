import { useQuery } from "@tanstack/react-query";
import { SearchSuggestionsResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useProductSearchSuggestions = (
  accountCode?: string,
  terms?: string,
) => {
  return useQuery(
    [QUERY_KEYS["product-search-suggestions"], accountCode, terms],
    () =>
      SearchSuggestionsResourceService.listUsingGet20(
        accountCode,
        100,
        terms,
        "products",
      ),
  );
};
