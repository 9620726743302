import { ImportWizardFileRecord, TaxExemptDto } from "../types";
import { getArrayValue, getStringValue } from "./importWizard";

export const generateTaxExemptsEntity = (
  columnMapping: Record<string, number | null>,
  record: ImportWizardFileRecord,
): TaxExemptDto => ({
  exemptStates: getArrayValue(record, columnMapping.exemptStates),
  manufacturerName: getStringValue(record, columnMapping.manufacturerName),
  manufacturerPart: getStringValue(record, columnMapping.manufacturerPart),
});
