/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FavoriteProductRequestDto = {
  productId: number;
  type: FavoriteProductRequestDto.type;
};
export namespace FavoriteProductRequestDto {
  export enum type {
    BUNDLE = 'BUNDLE',
    PRODUCT = 'PRODUCT',
  }
}

