import {
  Box,
  styled,
  Typography,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { format } from "date-fns";
import { memo, useEffect } from "react";
import { useParams } from "react-router";
import { DownloadIcon, EmailIcon } from "../../components/icons/";
import { Button, LoadingButton } from "../../components/ui";
import { useStockingOrder } from "../../hooks/api/queries";
import usePageTitle from "../../hooks/store/usePageTitle";
import { formatCurrency } from "../../utils/currency";
import { parseDate } from "../../utils/datetime";

const InfoPanelBox = styled(Box)(({ theme }) => ({
  borderColor: theme.palette.grey["400"],
  borderRadius: 3,
  borderWidth: 1,
  boxShadow: "0px 2px 4px rgba(227,227,227, 0.5)",
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(2),
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey["50"],
  fontSize: 14,
  fontWeight: "medium",
  py: theme.spacing(2),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  lineHeight: theme.spacing(1.5),
  verticalAlign: "top",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  lineHeight: theme.spacing(4),
}));

const StockingOrderDetail = () => {
  const [, setPageTitle] = usePageTitle();
  const { stockingOrderId } = useParams();
  const stockingOrderIdNum = stockingOrderId ? +stockingOrderId : undefined;
  const { data } = useStockingOrder(stockingOrderIdNum);

  useEffect(() => {
    if (data?.orderDate) {
      const orderDate = parseDate(data.orderDate);
      setPageTitle(
        <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
          Stocking Order #{stockingOrderId} created by{" "}
          {data?.stockingOrderCreatedBy?.name} on{" "}
          {format(orderDate as Date, "MMM d, yyyy")}
        </Box>,
      );
    }
  }, [data, stockingOrderId, setPageTitle]);

  return (
    <>
      <Box
        data-testid="sodp-wrapper"
        sx={(theme) => ({ color: theme.palette.grey["700"] })}
      >
        <Box sx={{ display: "flex", gap: 2, justifyContent: "end", pr: 2 }}>
          <Button
            aria-label="Email"
            data-testid="sodp-email-button"
            sx={{ minWidth: 58 }}
            variant="outlined"
          >
            <EmailIcon sx={{ fontSize: 14 }} />
          </Button>
          <LoadingButton
            aria-label="Download"
            data-testid="sodp-download-button"
            sx={{ minWidth: 58 }}
            variant="outlined"
          >
            <DownloadIcon sx={{ fontSize: 14 }} />
          </LoadingButton>
        </Box>
        <Box sx={{ display: "flex", gap: 4, mt: 4 }}>
          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto",
              flexDirection: "column",
              gap: 4,
            }}
          >
            <Box sx={{ display: "flex", gap: 4 }}>
              <InfoPanelBox
                sx={{ display: "flex", flex: 1, gap: 3, lineHeight: 5 }}
              >
                <Box textAlign="right">
                  <StyledTypography data-testid="sodp-label-csp">
                    CSP:
                  </StyledTypography>
                  <StyledTypography data-testid="sodp-labal-hold-days">
                    Hold Days:
                  </StyledTypography>
                  <StyledTypography data-testid="sodp-label-hold-notes">
                    Hold Notes:
                  </StyledTypography>
                </Box>
                <Box>
                  <StyledTypography data-testid="sodp-value-csp">
                    {data?.csp}
                  </StyledTypography>
                  <StyledTypography data-testid="sodp-value-hold-days">
                    {data?.holdDays}
                  </StyledTypography>
                  <StyledTypography data-testid="sodp-value-hold-notes">
                    {data?.holdNotes}
                  </StyledTypography>
                </Box>
              </InfoPanelBox>

              <InfoPanelBox sx={{ flex: 1, width: "full" }}>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Typography
                    data-testid="sodp-warehouse-title"
                    fontWeight="medium"
                    sx={{
                      fontSize: 18,
                    }}
                  >
                    Warehouse
                  </Typography>
                  <Typography
                    data-testid="sodp-warehouse-ship-to-id"
                    sx={(theme) => ({
                      backgroundColor: theme.palette.grey["50"],
                      fontSize: 18,
                      px: theme.spacing(1),
                      py: theme.spacing(0.5),
                    })}
                  >
                    ID: {data?.warehouseShipToId}
                  </Typography>
                </Box>
                <Box sx={{ lineHeight: 2 }}>
                  <Typography data-testid="sodp-value-attention">
                    Attention: {data?.attention}
                  </Typography>
                  <Typography data-testid="sodp-value-company-name">
                    {data?.warehouseAddress?.companyName}
                  </Typography>
                  <Typography data-testid="sodp-value-address">
                    {data?.warehouseAddress?.address}
                  </Typography>
                  {data?.warehouseAddress?.address2 && (
                    <Typography data-testid="sodp-value-address-2">
                      {data?.warehouseAddress.address2}
                    </Typography>
                  )}
                  <Typography data-testid="sodp-value-address-details">
                    {data?.warehouseAddress?.city},{" "}
                    {data?.warehouseAddress?.state}{" "}
                    {data?.warehouseAddress?.zip}
                  </Typography>
                </Box>
              </InfoPanelBox>
            </Box>

            <InfoPanelBox>
              <Grid container gap={4}>
                <Grid item sx={{ display: "flex", gap: 3 }} xs={6}>
                  <Box textAlign="right">
                    <StyledTypography data-testid="sodp-label-po">
                      PO:
                    </StyledTypography>
                    <StyledTypography data-testid="sodp-label-ship-method">
                      Ship Methods:
                    </StyledTypography>
                  </Box>
                  <Box>
                    <StyledTypography data-testid="sodp-value-po">
                      {data?.po}
                    </StyledTypography>
                    <StyledTypography data-testid="sodp-value-ship-method">
                      {data?.shipMethod}
                    </StyledTypography>
                  </Box>
                </Grid>

                <Grid item display="flex" gap={6} xs={5}>
                  <Box textAlign="right">
                    <StyledTypography data-testid="sodp-label-reference">
                      Reference:
                    </StyledTypography>
                    <StyledTypography data-testid="sodp-label-project">
                      Project:
                    </StyledTypography>
                    <StyledTypography data-testid="sodp-label-cost-center">
                      Cost Center:
                    </StyledTypography>
                  </Box>
                  <Box>
                    <StyledTypography data-testid="sodp-value-reference">
                      {data?.reference}
                    </StyledTypography>
                    <StyledTypography data-testid="sodp-value-project">
                      {data?.project}
                    </StyledTypography>
                    <StyledTypography data-testid="sodp-value-cost-center">
                      {data?.costCenter}
                    </StyledTypography>
                  </Box>
                </Grid>
              </Grid>
            </InfoPanelBox>
          </Box>

          <Box display="flex" flexDirection="column" gap={8}>
            <Box
              data-testid="sodp-total-gp-wrapper"
              sx={(theme) => ({
                alignItems: "center",
                backgroundColor: theme.palette.yellow["200"],
                borderRadius: 2,
                display: "flex",
                gap: 2,
                minHeight: 118,
                minWidth: 64,
                px: 3,
                py: 4,
              })}
            >
              <Typography
                data-testid="sodp-label-total-gp"
                sx={{ fontSize: 18 }}
              >
                Total GP
              </Typography>
              <Box flex="1 1 auto" textAlign="center">
                <StyledTypography
                  data-testid="sodp-value-total-gp"
                  fontWeight="medium"
                  sx={{ fontSize: 24 }}
                >
                  {formatCurrency(data?.totalRepGp)}
                </StyledTypography>
                <StyledTypography
                  data-testid="sodp-value-total-gp-percent"
                  fontSize="medium"
                >
                  ({data?.repGpPercentage}%)
                </StyledTypography>
              </Box>
            </Box>

            <Box
              data-testid="sodp-status-wrapper"
              sx={(theme) => ({
                alignItems: "center",
                backgroundColor: theme.palette.cyan["100"],
                borderRadius: 2,
                display: "flex",
                gap: 2,
                minHeight: 118,
                minWidth: 64,
                px: 3,
                py: 4,
              })}
            >
              <StyledTypography data-testid="sodp-label-status" fontSize="lg">
                Status
              </StyledTypography>
              <Box flex="1 1 auto" textAlign="center">
                <StyledTypography
                  data-testid="sodp-value-status"
                  fontWeight="medium"
                  sx={{ fontSize: 24 }}
                >
                  {data?.status}
                </StyledTypography>
              </Box>
            </Box>
          </Box>
        </Box>

        <InfoPanelBox data-testid="sodp-table-wrapper" sx={{ mt: 4 }}>
          <Table data-testid="sodp-table">
            <TableHead data-testid="sodp-table-header">
              <TableRow>
                <StyledTableHeadCell data-testid="sodp-table-header-line">
                  LINE #
                </StyledTableHeadCell>
                <StyledTableHeadCell data-testid="sodp-table-header-item">
                  ITEM
                </StyledTableHeadCell>
                <StyledTableHeadCell data-testid="sodp-table-header-qty">
                  QTY
                </StyledTableHeadCell>
                <StyledTableHeadCell data-testid="sodp-table-header-unit-sell-price">
                  UNIT SELL PRICE
                </StyledTableHeadCell>
                <StyledTableHeadCell data-testid="sodp-table-header-unit-loaded-cost">
                  UNIT LOADED COST
                </StyledTableHeadCell>
                <StyledTableHeadCell data-testid="sodp-table-header-total-sell-price">
                  TOTAL SELL PRICE
                </StyledTableHeadCell>
                <StyledTableHeadCell data-testid="sodp-table-header-total-loaded-cost">
                  TOTAL LOADED COST
                </StyledTableHeadCell>
                <StyledTableHeadCell data-testid="sodp-table-header-gp">
                  GP
                </StyledTableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody data-testid="sodp-table-body">
              {data?.stockingLines?.map((stockingLine, index) => (
                <TableRow key={stockingLine.stockingOrderLineId}>
                  <StyledTableCell data-testid="sodp-table-cell-line">
                    <Typography>{index + 1}</Typography>
                  </StyledTableCell>
                  <StyledTableCell data-testid="sodp-table-cell-item">
                    <Typography>{stockingLine.mfgName}</Typography>
                    <Typography>{stockingLine.mfgPart}</Typography>
                    <Typography>
                      {stockingLine.supplierCode} / {stockingLine.supplierPart}
                    </Typography>
                    <Typography
                      sx={(theme) => ({ color: theme.palette.blue["600"] })}
                    >
                      {stockingLine.description}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell data-testid="sodp-table-cell-qty">
                    <Typography>{stockingLine.quantity}</Typography>
                  </StyledTableCell>
                  <StyledTableCell data-testid="sodp-table-cell-unit-sell-price">
                    <Typography>
                      {formatCurrency(stockingLine.sellPrice)}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell data-testid="sodp-table-cell-unit-loaded-cost">
                    <Typography>
                      {formatCurrency(stockingLine.unitLoadedCost)}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell data-testid="sodp-table-cell-total-sell-price">
                    <Typography>
                      {formatCurrency(stockingLine.totalPrice)}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell data-testid="sodp-table-cell-total-loaded-cost">
                    <Typography>
                      {formatCurrency(stockingLine.totalLoadedCost)}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell data-testid="sodp-table-cell-gp">
                    <Typography>
                      {formatCurrency(stockingLine.totalRepGp)}
                    </Typography>
                    {!!stockingLine.repGpPercentage && (
                      <Typography>({stockingLine.repGpPercentage}%)</Typography>
                    )}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.grey["50"],
              display: "flex",
              gap: 4,
              py: 2,
            })}
          >
            <StyledTypography
              data-testid="sodp-table-footer-value-total-price"
              flexGrow="1"
              sx={{
                fontSize: "md",
                fontWeight: 600,
              }}
              textAlign="right"
            >
              Price: {formatCurrency(data?.totalPrice)}
            </StyledTypography>
            <StyledTypography
              data-testid="sodp-table-footer-value-total-loaded-cost"
              flexGrow="1"
              sx={{
                fontSize: "md",
                fontWeight: 600,
              }}
              textAlign="center"
            >
              Loaded Cost: {formatCurrency(data?.totalLoadedCost)}
            </StyledTypography>
          </Box>
        </InfoPanelBox>

        <Box display="flex" gap={8} mt={5}>
          <InfoPanelBox
            data-testid="sodp-instructions-wrapper"
            flexGrow={1}
            sx={{
              flowGrow: 1,
              minHeight: 22,
            }}
          >
            <Typography
              data-testid="sodp-instructions-label"
              fontSize="md"
              fontWeight="medium"
              mb={2}
            >
              Instructions
            </Typography>
            <Typography data-testid="sodp-instructions-value">
              {data?.instructions}
            </Typography>
          </InfoPanelBox>
          <InfoPanelBox data-testid="sodp-notes-wrapper" flexGrow={1}>
            <Typography
              data-testid="sodp-notes-label"
              fontSize="md"
              fontWeight="medium"
              mb={2}
            >
              Notes
            </Typography>
            <Typography data-testid="sodp-notes-value">
              {data?.notes}
            </Typography>
          </InfoPanelBox>
          <InfoPanelBox data-testid="sodp-internal-notes-wrapper" flexGrow={1}>
            <Typography
              data-testid="sodp-internal-notes-label"
              fontSize="md"
              fontWeight="medium"
              mb={2}
            >
              Internal Notes
            </Typography>
            <Typography data-testid="sodp-internal-notes-value">
              {data?.internalNotes}
            </Typography>
          </InfoPanelBox>
        </Box>
      </Box>
    </>
  );
};

export default memo(StockingOrderDetail);
