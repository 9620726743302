/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InputStreamResource } from '../models/InputStreamResource';
import type { LogDto } from '../models/LogDto';
import type { QuoteDetailDto } from '../models/QuoteDetailDto';
import type { QuoteLabeledResponse } from '../models/QuoteLabeledResponse';
import type { QuoteOrderResponseDto } from '../models/QuoteOrderResponseDto';
import type { QuotePatchRequestDto } from '../models/QuotePatchRequestDto';
import type { QuotePostRequestDto } from '../models/QuotePostRequestDto';
import type { QuoteResponseDto } from '../models/QuoteResponseDto';
import type { QuotesDetailEmailRequestDto } from '../models/QuotesDetailEmailRequestDto';
import type { QuoteSuggestResponseDto } from '../models/QuoteSuggestResponseDto';
import type { RecentQuoteResponseDto } from '../models/RecentQuoteResponseDto';
import type { RestCollection_QuoteSolrDto_ } from '../models/RestCollection_QuoteSolrDto_';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class QuotesResourceService {
  /**
   * Get Quotes list
   * Get an quote list
   * @param criteria criteria
   * @param page page
   * @param returnAllLabels returnAllLabels
   * @param returnPagination returnPagination
   * @param returnSavedViews returnSavedViews
   * @param size size
   * @param useDefaultView useDefaultView
   * @returns RestCollection_QuoteSolrDto_ Success
   * @throws ApiError
   */
  public static listUsingGet16(
    criteria: string = '{}',
    page: number = 1,
    returnAllLabels: boolean = false,
    returnPagination: boolean = true,
    returnSavedViews: boolean = true,
    size: number = 50,
    useDefaultView: boolean = false,
  ): CancelablePromise<RestCollection_QuoteSolrDto_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/listing/quotes',
      query: {
        'criteria': criteria,
        'page': page,
        'returnAllLabels': returnAllLabels,
        'returnPagination': returnPagination,
        'returnSavedViews': returnSavedViews,
        'size': size,
        'useDefaultView': useDefaultView,
      },
      errors: {
        401: `Unauthorized`,
        403: `You are not authorized to access this account.`,
        404: `Quote list not found.`,
        500: `Internal Error retrieving quotes list`,
      },
    });
  }
  /**
   * POST Quote
   * POST Quote
   * @param quoteRequestDto quoteRequestDto
   * @param accountId accountId
   * @returns QuoteResponseDto Success
   * @throws ApiError
   */
  public static createQuoteV1UsingPost(
    quoteRequestDto: QuotePostRequestDto,
    accountId?: number,
  ): CancelablePromise<QuoteResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/quotes',
      query: {
        'accountId': accountId,
      },
      body: quoteRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error Creating Quote Line`,
      },
    });
  }
  /**
   * Send Quote Details Email
   * Send Quote Details Email
   * @param quotesDetailEmailRequestDto quotesDetailEmailRequestDto
   * @returns any Success
   * @throws ApiError
   */
  public static sendQuoteDetailsEmailUsingPost(
    quotesDetailEmailRequestDto: QuotesDetailEmailRequestDto,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/quotes/email',
      body: quotesDetailEmailRequestDto,
      errors: {
        400: `Quote Detail Email Bad Request`,
        401: `Unauthorized`,
        403: `You are not authorized to access this Quote.`,
        404: `Quote not found`,
        500: `Internal Error Quote Detail Email`,
      },
    });
  }
  /**
   * Find Quote By Id
   * Find Quote By Id
   * @param quoteId quoteId
   * @param expand expand
   * @returns QuoteLabeledResponse Success
   * @throws ApiError
   */
  public static getQuoteUsingGet(
    quoteId: number,
    expand?: 'NULL' | 'quote_details',
  ): CancelablePromise<QuoteLabeledResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/quotes/quoteId/{quoteId}',
      path: {
        'quoteId': quoteId,
      },
      query: {
        'expand': expand,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error Finding Quote`,
      },
    });
  }
  /**
   * Patch Quote
   * Patch Quote
   * @param quoteId quoteId
   * @param quoteRequestDto quoteRequestDto
   * @returns any Success
   * @throws ApiError
   */
  public static updateQuoteUsingPatch(
    quoteId: number,
    quoteRequestDto: QuotePatchRequestDto,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/quotes/quoteId/{quoteId}',
      path: {
        'quoteId': quoteId,
      },
      body: quoteRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Quote Not Found`,
        500: `Internal Error While Updating The Quote`,
      },
    });
  }
  /**
   * Find All Quote Details By Quote Id
   * Find All Quote Details By Quote Id
   * @param quoteId quoteId
   * @returns QuoteDetailDto Success
   * @throws ApiError
   */
  public static getQuoteDetailsUsingGet(
    quoteId: number,
  ): CancelablePromise<Array<QuoteDetailDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/quotes/quoteId/{quoteId}/details',
      path: {
        'quoteId': quoteId,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error Finding Quote Details`,
      },
    });
  }
  /**
   * Export Quote.
   * Export Quote.
   * @param quoteId quoteId
   * @returns InputStreamResource Success
   * @throws ApiError
   */
  public static exportUsingGet5(
    quoteId: number,
  ): CancelablePromise<InputStreamResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/quotes/quoteId/{quoteId}/export',
      path: {
        'quoteId': quoteId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `You are not authorized to access this Quote.`,
        404: `Not Found`,
        500: `Internal Error exporting quote`,
      },
    });
  }
  /**
   * Find Recent Quotes
   * Find Recent Quotes
   * @param accountId accountId
   * @param page page
   * @param size size
   * @returns RecentQuoteResponseDto Success
   * @throws ApiError
   */
  public static getRecentQuotesUsingGet(
    accountId: number,
    page: number = 1,
    size: number = 10,
  ): CancelablePromise<Array<RecentQuoteResponseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/quotes/recent',
      query: {
        'accountId': accountId,
        'page': page,
        'size': size,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error Finding Recent Quotes`,
      },
    });
  }
  /**
   * Quote type-ahead suggest
   * Quote type-ahead suggest
   * @param terms terms
   * @param accountId accountId
   * @param limit limit
   * @param type type
   * @returns QuoteSuggestResponseDto Success
   * @throws ApiError
   */
  public static getSuggestsUsingGet2(
    terms: string,
    accountId?: number,
    limit: number = 10,
    type: 'active' | 'all' = 'all',
  ): CancelablePromise<Array<QuoteSuggestResponseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/quotes/suggest',
      query: {
        'accountId': accountId,
        'limit': limit,
        'terms': terms,
        'type': type,
      },
      errors: {
        400: `Invalid request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `There was an error getting the quote suggestions`,
      },
    });
  }
  /**
   * Validate Quote
   * Validate Quote
   * @param quoteRequestDto quoteRequestDto
   * @param accountId accountId
   * @returns any Ok
   * @throws ApiError
   */
  public static validateQuoteUsingPost(
    quoteRequestDto: QuotePostRequestDto,
    accountId?: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/quotes/validation',
      query: {
        'accountId': accountId,
      },
      body: quoteRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error Validating Quote`,
      },
    });
  }
  /**
   * Delete Quote
   * Delete Quote
   * @param quoteId quoteId
   * @returns any Success
   * @throws ApiError
   */
  public static deleteUsingDelete8(
    quoteId: number,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/service/rest/quotes/{quoteId}',
      path: {
        'quoteId': quoteId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Quote Not Found`,
        500: `Internal Error Deleting Quote`,
      },
    });
  }
  /**
   * Get quote logs by quoteId
   * Get quote logs by quoteId
   * @param quoteId quoteId
   * @returns LogDto Success
   * @throws ApiError
   */
  public static findQuoteLogByQuoteIdUsingGet(
    quoteId: number,
  ): CancelablePromise<Array<LogDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/quotes/{quoteId}/log',
      path: {
        'quoteId': quoteId,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `No quote found`,
        500: `Internal error finding quote logs`,
      },
    });
  }
  /**
   * Get Quote orders
   * Get Quote orders
   * @param quoteId quoteId
   * @returns QuoteOrderResponseDto Success
   * @throws ApiError
   */
  public static getQuoteOrdersUsingGet(
    quoteId: number,
  ): CancelablePromise<Array<QuoteOrderResponseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/quotes/{quoteId}/orders',
      path: {
        'quoteId': quoteId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Quotes Orders Not Found`,
        500: `Internal Error Getting Quote orders`,
      },
    });
  }
}
