import {
  Box,
  styled,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { BlanketPoResponseDto } from "../../../api";
import { useCookiesLive } from "../../../hooks";
import { useBlanketPOs } from "../../../hooks/api/queries";
import { Button } from "../../ui";
import { TableHeaderCell } from "../../ui/Table";
import BlanketPOsTableRow from "./BlanketPOsTableRow";
import DeleteBlanketPOConfirmModal from "./DeleteBlanketPOConfirmModal";

const StyledTableHeaderCell = styled(TableHeaderCell)(({ theme }) => ({
  h: 12,
  position: "sticky",
  px: 4,
  py: 0,
  top: 0,
  zIndex: 2,
}));

const BlanketPOsTable = () => {
  const [accountId] = useCookiesLive(["accountId"]);
  const { data: blanketPOsData } = useBlanketPOs(
    accountId ? +accountId : undefined,
  );

  const [isCreating, setIsCreating] = useState(false);
  const [deletingBlanketPo, setDeletingBlanketPo] =
    useState<BlanketPoResponseDto>();
  const [editingBlanketPo, setEditingBlanketPo] =
    useState<BlanketPoResponseDto>();

  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  const handleClickCreate = () => {
    setIsCreating(true);

    setTimeout(() => {
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop =
          tableContainerRef.current.scrollHeight;
      }
    }, 500);
  };

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setEditingBlanketPo(undefined);
        setIsCreating(false);
      }
    };

    document.addEventListener("keydown", handleEscKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEscKeyPress);
    };
  }, []);

  return (
    <>
      <Box
        ref={tableContainerRef}
        data-testid="blanket-pos-container"
        sx={{ maxHeight: "375px", overflowY: "auto" }}
      >
        <Table data-testid="blanket-pos-table" sx={{ position: "relative" }}>
          <TableHead data-testid="blanket-pos-table-header">
            <TableRow>
              <StyledTableHeaderCell>BLANKET PO</StyledTableHeaderCell>
              <StyledTableHeaderCell>DESCRIPTION</StyledTableHeaderCell>
              <StyledTableHeaderCell>STORED IN FIELD</StyledTableHeaderCell>
              <StyledTableHeaderCell>VALUE</StyledTableHeaderCell>
              <StyledTableHeaderCell>INVOICES</StyledTableHeaderCell>
              <StyledTableHeaderCell>OPEN ORDER VALUE</StyledTableHeaderCell>
              <StyledTableHeaderCell>AVAILABLE</StyledTableHeaderCell>
              <StyledTableHeaderCell></StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blanketPOsData?.map((row, index) => (
              <BlanketPOsTableRow
                key={row.blanketPoId + "" + index}
                blanketPo={row}
                isEditing={
                  editingBlanketPo !== undefined &&
                  editingBlanketPo.blanketPoId === row.blanketPoId
                }
                onClickCancel={() => setEditingBlanketPo(undefined)}
                onClickDelete={() => setDeletingBlanketPo(row)}
                onClickEdit={() => setEditingBlanketPo(row)}
              />
            ))}
            {isCreating && (
              <BlanketPOsTableRow onClickCancel={() => setIsCreating(false)} />
            )}
          </TableBody>
        </Table>
      </Box>
      {!isCreating && (
        <Button
          color="primary"
          data-testid="add-blanket-po-button"
          sx={{ m: 2 }}
          variant="contained"
          onClick={handleClickCreate}
        >
          Add Blanket PO
        </Button>
      )}

      {deletingBlanketPo && (
        <DeleteBlanketPOConfirmModal
          blanketPo={deletingBlanketPo}
          onClose={() => setDeletingBlanketPo(undefined)}
        />
      )}
    </>
  );
};

export default BlanketPOsTable;
