import AddIcon from "@mui/icons-material/Add";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  FormControlLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Checkbox,
} from "@mui/material";
import { useMemo, useState } from "react";
import {
  FacetsResultsResponseDto,
  FacetValueDto,
  SearchFieldStatsDto,
} from "../../api";
import { SEARCH_RESULT_FACETS_TRIMMED_COUNT } from "../../constants/searchResults";
import { getSearchFacetText, isFacetsEqual } from "../../utils/searchFacet";
import { Button, TextField } from "../ui";
import { useSearchResultsContext } from "./SearchResultsContext";

export const SearchFacets = ({
  facetType,
  facets = [],
  title,
}: {
  facetType: keyof FacetsResultsResponseDto;
  facets?: Array<FacetValueDto | SearchFieldStatsDto>;
  title: string;
}) => {
  const { selectedFacets, toggleSearchFacet } = useSearchResultsContext();
  const [expanded, setExpanded] = useState(false);
  const [trimmed, setTrimmed] = useState(true);
  const [searchText, setSearchText] = useState("");

  const filteredFacets = useMemo(
    () =>
      (facets ?? []).filter((facet) =>
        getSearchFacetText(facetType, facet)
          .toLowerCase()
          .includes(searchText.toLocaleLowerCase()),
      ),
    [facets, searchText, facetType],
  );

  const handleFacetChange = (
    facet: FacetValueDto | SearchFieldStatsDto,
    checked: boolean,
  ) => toggleSearchFacet(facet, checked);
  const handleToggleTrimmed = () => setTrimmed((prev) => !prev);

  return (
    <Box
      data-testid="sr-result-entity-filter-item"
      sx={(theme) => ({
        backgroundColor: theme.palette.grey["50"],
        overflow: expanded && !trimmed ? "auto" : "hidden",
        p: theme.spacing(1),
      })}
    >
      <Box
        sx={(theme) => ({
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        })}
      >
        <Typography
          data-testid="sr-result-entity-filter-item-facet-type-text"
          sx={{
            fontSize: 16,
            textTransform: "uppercase",
          }}
        >
          {title}
        </Typography>
        <IconButton
          aria-label="toggle-facets"
          data-testid="sr-result-entity-filter-item-facet-toggle-button"
          sx={{
            color: "black",
          }}
          onClick={() => setExpanded((prev) => !prev)}
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Box>
      {expanded && facets.length > 1 && (
        <TextField
          placeholder="Search filters..."
          size="small"
          sx={{
            width: "100%",
          }}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      )}
      {expanded && (
        <Stack
          data-testid="sr-result-entity-filter-item-facets-list"
          sx={(theme) => ({
            align: "stretch",
            maxHeight: theme.spacing(48),
            mt: theme.spacing(0.5),
            overflow: "auto",
            paddingLeft: "1.5rem",
            px: theme.spacing(1),
          })}
        >
          {filteredFacets
            .slice(
              0,
              trimmed
                ? SEARCH_RESULT_FACETS_TRIMMED_COUNT
                : filteredFacets.length,
            )
            .map((facet: FacetValueDto | SearchFieldStatsDto) => {
              const facetText = getSearchFacetText(facetType, facet);
              const trimmedFacetText =
                facetText.length > 26
                  ? facetText.substring(0, 26) + "..."
                  : facetText;
              const isChecked = selectedFacets.some((facetEntity) =>
                isFacetsEqual(facetEntity, facet),
              );

              return (
                <Tooltip
                  key={`facet-entity-${facetType}-${
                    (facet as FacetValueDto).value
                  }-${(facet as SearchFieldStatsDto).min}-${
                    (facet as SearchFieldStatsDto).max
                  }`}
                  placement="right"
                  title={facetText}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        size="small"
                        onChange={(e) =>
                          handleFacetChange(facet, e.target.checked)
                        }
                      />
                    }
                    data-testid="sr-result-entity-filter-item-facet-checkbox"
                    label={`${trimmedFacetText} ${
                      facet.count ? "(" + facet.count + ")" : ""
                    }`}
                    slotProps={{
                      typography: {
                        sx: { fontSize: 14 },
                      },
                    }}
                  />
                </Tooltip>
              );
            })}
        </Stack>
      )}
      {expanded &&
        filteredFacets.length > SEARCH_RESULT_FACETS_TRIMMED_COUNT && (
          <Box display="flex" justifyContent="end">
            <Button
              data-testid="sr-result-entity-filter-item-trim-button"
              endIcon={trimmed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              size="small"
              sx={(theme) => ({
                color: theme.palette.blue["600"],
              })}
              onClick={handleToggleTrimmed}
            >
              {trimmed ? "Show More" : "Show Less"}
            </Button>
          </Box>
        )}
    </Box>
  );
};
