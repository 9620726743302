import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { TableRow } from "@mui/material";
import { formatCurrency } from "../../utils/currency";
import { GripVerticalIcon } from "../icons/GripVertical";
import { IconButton } from "../ui";
import { TableCell } from "../ui/Table";

const MovableLine = ({
  lineId,
  manufacturerName,
  manufacturerPart,
  supplierName,
  supplierPart,
  unitPrice,
  index,
}: {
  lineId: number;
  manufacturerName: string | undefined;
  manufacturerPart: string | undefined;
  supplierName: string | undefined;
  supplierPart: string | undefined;
  unitPrice: number | undefined;
  index: number;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
  } = useSortable({ id: lineId });

  return (
    <>
      <TableRow
        key={lineId}
        ref={setNodeRef}
        sx={{
          opacity: isDragging ? 0.5 : 1,
          transform: CSS.Transform.toString(transform),
          transition,
        }}
        {...attributes}
      >
        <TableCell
          data-testid="line-index"
          sx={{ paddingLeft: "32px", position: "relative" }}
        >
          <IconButton
            ref={setActivatorNodeRef}
            aria-label="Move"
            sx={(theme) => ({
              color: theme.palette.grey["400"],
              cursor: isDragging ? "grabbing" : "grab",
              display: "inline-flex",
              height: "4",
              left: "8px",
              minW: "0",
              opacity: "1",
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
            })}
            {...listeners}
          >
            <GripVerticalIcon />
          </IconButton>
          {index + 1}
        </TableCell>
        <TableCell data-testid="line-manufacturer">
          {manufacturerName}
        </TableCell>
        <TableCell data-testid="line-manufacturer-part">
          {manufacturerPart}
        </TableCell>
        <TableCell data-testid="line-supplier-name">{supplierName}</TableCell>
        <TableCell data-testid="line-supplier-part">{supplierPart}</TableCell>
        <TableCell data-testid="line-unit-price">
          {formatCurrency(unitPrice)}
        </TableCell>
      </TableRow>
    </>
  );
};

export default MovableLine;
