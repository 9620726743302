import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import download from "downloadjs";

import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { ListingExportEmailRequestDto } from "../../api";
import FileCsvSvg from "../../assets/svgs/file-csv.svg";
import FileExcelSvg from "../../assets/svgs/file-excel.svg";
import FilePdfSvg from "../../assets/svgs/file-pdf.svg";
import FileTxtSvg from "../../assets/svgs/file-txt.svg";
import { LISTING_DISPLAY_NAMES } from "../../constants";
import {
  EXPORT_LISTING_CONTENT_TYPES,
  EXPORT_LISTING_FILE_EXTENSIONS,
} from "../../constants/exportListings";
import { useExportListingEmail } from "../../hooks/api/mutations/useExportListingEmail";
import { ExportListingType } from "../../types";
import { isValidEmail } from "../../utils/string";
import { useDataTableContext } from "../DataTable/DataTableContext";
import { ExclamationCircleIcon } from "../icons";
import { Button, TextField } from "../ui";

const subjectLimit = 256;
const bodyLimit = 65535;

const yupEmailTest: yup.TestFunction<string | undefined> = (value, context) => {
  if (value && !value?.split(",").every((email) => isValidEmail(email))) {
    return context.createError({
      message: "Please enter a valid email.",
    });
  }
  return true;
};

const schema = yup
  .object({
    cc: yup.string().test({ name: "cc", test: yupEmailTest }),
    email: yup
      .string()
      .test({
        name: "email",
        test: yupEmailTest,
      })
      .required("Please enter a valid email."),
    exportListingType: yup
      .mixed<ExportListingType>()
      .required("Export listing type is required"),
    message: yup.string().max(bodyLimit, "0 characters remaining"),
    subject: yup.string().max(subjectLimit, "0 characters remaining"),
  })
  .required();

const FormFieldLabel = styled(Typography)(({ theme }) => ({
  flexShrink: 0,
  fontSize: 14,
  fontWeight: 600,
  m: 0,
  width: theme.spacing(8),
}));

type FormValues = {
  email: string;
  cc?: string;
  subject?: string;
  message?: string;
  exportListingType: ExportListingType;
};

const ExportListingEmail = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { listingName, criteria } = useDataTableContext();
  const { mutate: exportListingEmail, isLoading } = useExportListingEmail();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      exportListingType: "excel",
    },
    resolver: yupResolver<FormValues>(schema),
  });

  const onSubmit = useCallback(
    (values: FormValues) => {
      const { email, message, cc, subject, exportListingType } = values;
      const payload: ListingExportEmailRequestDto = {
        to: email.split(",").map((emailString) => emailString.trim()),
        ...(message ? { body: message } : {}),
        ...(cc ? { cc: cc.split(",").map((email) => email.trim()) } : {}),
        ...(subject ? { subject: subject } : {}),
      };

      exportListingEmail(
        {
          criteria: JSON.stringify(criteria),
          exportListingType,
          listingExportEmailRequestDto: payload,
          listingName: listingName ?? "",
        },
        {
          onSuccess: ({ data }) => {
            download(
              data,
              `EmailReport-${listingName}.${EXPORT_LISTING_FILE_EXTENSIONS[exportListingType]}`,
              EXPORT_LISTING_CONTENT_TYPES[exportListingType],
            );
          },
        },
      );

      reset();
      onClose();
    },
    [onClose, reset, criteria, exportListingEmail, listingName],
  );

  const [subject, message] = watch(["subject", "message"]);

  return (
    <Dialog
      fullWidth
      data-testid="export-report-email-modal"
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
    >
      <form style={{ width: 444 }} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={(theme) => ({
            fontSize: theme.spacing(2.25),
            m: 0,
            pb: 0,
            pt: 3.5,
            px: 2.5,
          })}
        >
          Email Report
        </DialogTitle>
        <IconButton
          sx={{
            color: (theme) => theme.palette.grey[900],
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box sx={{ display: "flex", gap: 3 }}>
            <FormFieldLabel>
              Email{" "}
              <Typography
                component="span"
                sx={(theme) => ({ color: theme.palette.red["500"] })}
              >
                *
              </Typography>
            </FormFieldLabel>

            <TextField
              data-testid="export-report-mail-email-field"
              error={!!errors.email}
              helperText={
                errors.email?.message ??
                "Enter multiple emails separated by commas."
              }
              placeholder="repname@moredirect.com"
              size="small"
              {...register("email")}
              InputProps={{
                endAdornment: !!errors.email ? (
                  <InputAdornment position="end">
                    <ExclamationCircleIcon
                      sx={(theme) => ({
                        color: theme.palette.red["500"],
                        fontSize: 16,
                        mr: theme.spacing(1),
                      })}
                    />
                  </InputAdornment>
                ) : null,
              }}
              sx={{ flex: 1 }}
            />
          </Box>

          <Box display="flex" gap={3} mt={1}>
            <FormFieldLabel />
            <Box sx={{ flex: 1 }}>
              <FormFieldLabel sx={{ mb: 1 }}>Cc</FormFieldLabel>
              <TextField
                data-testid="export-report-mail-cc-field"
                error={!!errors.cc}
                helperText={
                  errors.cc?.message ??
                  "Enter multiple emails separated by commas."
                }
                placeholder="repname@moredirect.com"
                size="small"
                {...register("cc")}
                fullWidth
                InputProps={{
                  endAdornment: !!errors.cc ? (
                    <InputAdornment position="end">
                      <ExclamationCircleIcon
                        sx={(theme) => ({
                          color: theme.palette.red["500"],
                          fontSize: 16,
                          mr: theme.spacing(1),
                        })}
                      />
                    </InputAdornment>
                  ) : null,
                }}
              />
            </Box>
          </Box>

          <Box display="flex" gap={3} mt={3}>
            <FormFieldLabel>Subject</FormFieldLabel>
            <TextField
              data-testid="export-report-mail-subject-field"
              error={!!errors.subject}
              helperText={
                errors.subject?.message ??
                (subject?.length
                  ? `${(
                      subjectLimit - subject.length
                    ).toLocaleString()} characters remaining`
                  : "")
              }
              maxLength={subjectLimit}
              size="small"
              {...register("subject")}
              multiline
              placeholder={
                listingName
                  ? `Connection ${LISTING_DISPLAY_NAMES[listingName]} Report`
                  : ""
              }
              rows={4}
              sx={{ flex: 1 }}
            />
          </Box>

          <Box display="flex" gap={3} mt={3}>
            <FormFieldLabel>Message</FormFieldLabel>
            <TextField
              data-testid="export-report-mail-message-field"
              error={!!errors.message}
              helperText={
                errors.message?.message ??
                (message?.length
                  ? `${(
                      bodyLimit - message.length
                    ).toLocaleString()} characters remaining`
                  : "")
              }
              maxLength={bodyLimit}
              size="small"
              {...register("message")}
              multiline
              placeholder={
                listingName
                  ? `Here is your ${LISTING_DISPLAY_NAMES[listingName]} Report.`
                  : ""
              }
              rows={4}
              sx={{ flex: 1 }}
            />
          </Box>

          <Box display="flex" gap={3} mt={3}>
            <FormFieldLabel>Format</FormFieldLabel>
            <Controller
              control={control}
              name="exportListingType"
              render={({ field: { onChange, value } }) => (
                <>
                  <RadioGroup
                    value={value}
                    onChange={(event) =>
                      onChange(
                        (event.target as HTMLInputElement)
                          .value as ExportListingType,
                      )
                    }
                  >
                    <FormControlLabel
                      control={<Radio size="small" />}
                      label={
                        <Box sx={{ display: "flex" }}>
                          <img
                            alt="Download Excel"
                            src={FileExcelSvg}
                            style={{ marginRight: 12 }}
                          />
                          <span>Excel</span>
                        </Box>
                      }
                      value="excel"
                    />
                    <FormControlLabel
                      control={<Radio size="small" />}
                      label={
                        <Box sx={{ display: "flex" }}>
                          <img
                            alt="Comma-separated"
                            src={FileCsvSvg}
                            style={{ marginRight: 12 }}
                          />
                          <span>Comma-separated</span>
                        </Box>
                      }
                      value="comma-separated"
                    />
                    <FormControlLabel
                      control={<Radio size="small" />}
                      label={
                        <Box sx={{ display: "flex" }}>
                          <img
                            alt="Tab-separated"
                            src={FileTxtSvg}
                            style={{ marginRight: 12 }}
                          />
                          <span>Tab-separated</span>
                        </Box>
                      }
                      value="tab-separated"
                    />
                    <FormControlLabel
                      control={<Radio size="small" />}
                      label={
                        <Box sx={{ display: "flex" }}>
                          <img
                            alt="PDF"
                            src={FilePdfSvg}
                            style={{ marginRight: 12 }}
                          />
                          <span>PDF</span>
                        </Box>
                      }
                      value="pdf"
                    />
                  </RadioGroup>
                </>
              )}
            />
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pb: 2,
            px: 3,
          }}
        >
          <Button
            data-testid="export-report-modal-close-btn"
            sx={(theme) => ({
              height: theme.spacing(4.5),
              width: theme.spacing(12),
            })}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            data-testid="export-report-modal-save-btn"
            disabled={isLoading}
            sx={(theme) => ({
              height: theme.spacing(4.5),
              width: theme.spacing(12),
            })}
            type="submit"
            variant="contained"
          >
            Send
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ExportListingEmail;
