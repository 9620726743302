import { useQuery } from "@tanstack/react-query";
import {
  ClientResourceService,
  RestCollection_ClientListing_,
} from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";
import { ListingMaxIdQueryHook } from "../../../types";
import { getMaxIdCriteria } from "../../../utils/criteria";

export const useClientResourceMaxId: ListingMaxIdQueryHook<
  RestCollection_ClientListing_
> = (identifier: string) => {
  const maxIdCriteria = getMaxIdCriteria(identifier);

  return useQuery([QUERY_KEYS["client-resource-max-id"], identifier], () => {
    return ClientResourceService.listingUsingGet(
      JSON.stringify(maxIdCriteria),
      1,
      false,
      true,
      false,
      1,
    );
  });
};
