import { TableCell, Tooltip, Typography } from "@mui/material";
import { Cell, flexRender } from "@tanstack/react-table";
import { memo, ReactNode } from "react";
import { CHECKBOX_COLUMN_ID } from "../../constants/dataTable";

const DataTableCell = <TData extends Record<string, any>>({
  cell,
}: {
  cell: Cell<TData, unknown>;
}) => {
  const cellContent = flexRender(cell.column.columnDef.cell, cell.getContext());

  return cell.column.columnDef.id === CHECKBOX_COLUMN_ID ? (
    <TableCell
      data-testid="dt-cell-checkbox"
      sx={(theme) => ({
        alignItems: "center",
        border: "none",
        color: theme.palette.grey["900"],
        display: "inline-flex",
        fontSize: theme.spacing(1.5),
        justifyContent: "center",
        overflow: "hidden",
        px: 0,
        py: 1.5,
        width: `${cell.column.getSize()}px`,
      })}
    >
      {cellContent}
    </TableCell>
  ) : (
    <TableCell
      data-testid={`dt-cell-${cell.column.columnDef.id}`}
      sx={(theme) => ({
        alignItems: "center",
        border: "none",
        color: theme.palette.grey["900"],
        display: "inline-flex",
        fontSize: theme.spacing(1.5),
        overflow: "hidden",
        py: 1.5,
        width: `${cell.column.getSize()}px`,
      })}
    >
      <Tooltip
        enterDelay={1000}
        placement="bottom"
        title={cellContent as ReactNode}
      >
        <Typography noWrap sx={{ overflow: "hidden" }} variant="caption">
          {cellContent}
        </Typography>
      </Tooltip>
    </TableCell>
  );
};

export default memo(DataTableCell) as typeof DataTableCell;
