import {
  CircularProgress,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { Row } from "@tanstack/react-table";
import { VirtualItem } from "@tanstack/react-virtual";
import classNames from "classnames";
import { Fragment, useState } from "react";
import {
  CHECKBOX_COLUMN_ID,
  TABLE_ACTION_COLUMN_WIDTH,
  TABLE_ROW_HEIGHT,
} from "../../constants";
import { ActionRowClickItem } from "../../types";
import DataTableActionCell from "./DataTableActionCell";
import DataTableCell from "./DataTableCell";

import { useDataTableContext } from "./DataTableContext";

const getRowWidth = <TData,>(
  row: Row<TData>,
  additionalColumnId?: string,
  hasActionColumn?: boolean,
) =>
  row
    .getVisibleCells()
    .filter((cell) => cell.column.id !== additionalColumnId)
    .reduce(
      (prev, curr) =>
        prev +
        ((curr.column.columnDef.id ?? "") === CHECKBOX_COLUMN_ID
          ? 36
          : curr.column.getSize()),
      0,
    ) + (hasActionColumn ? TABLE_ACTION_COLUMN_WIDTH : 0);

const DataTableBody = <TData extends Record<string, any>>({
  actionRowClickItem,
  hasNextPage,
  isAdditionalColumnVisible,
  newRows,
  rows,
  virtualRows,
}: {
  actionRowClickItem?: ActionRowClickItem<TData>;
  hasNextPage: boolean;
  isAdditionalColumnVisible: boolean;
  newRows: TData[];
  rows: Row<TData>[];
  virtualRows: VirtualItem[];
}) => {
  const { additionalColumnId, hasActionColumn, identifier } =
    useDataTableContext();
  const [activeRow, setActiveRow] = useState<Row<TData>>();

  return (
    <TableBody data-testid="dt-body">
      {virtualRows.map((virtualRow) => {
        const isLoaderRow = virtualRow.index > rows.length - 1;
        const row = rows[virtualRow.index];
        const isActiveRow =
          activeRow !== undefined &&
          row.original[identifier as string] ===
            activeRow?.original?.[identifier as string];

        const isNewRow = !!(
          identifier &&
          newRows.find(
            (newRow) =>
              row.original[identifier as string] ===
              newRow[identifier as string],
          )
        );

        return (
          <Fragment key={virtualRow.index}>
            <TableRow
              className={classNames({
                "active-row": isActiveRow,
                "has-border": !isAdditionalColumnVisible,
                "new-row": isNewRow,
                "row-clickable": !!actionRowClickItem && !row.original.disabled,
              })}
              sx={(theme) => ({
                "&.active-row": {
                  td: {
                    background: theme.palette.blue["100"],
                  },
                },
                "&.has-border": {
                  borderBottom: `1px solid ${theme.palette.grey["200"]}`,
                },
                "&.new-row": {
                  td: {
                    background: theme.palette.purple["200"],
                  },
                },
                "&.row-clickable:hover": {
                  backgroundColor: theme.palette.blue["100"],
                  cursor: "pointer",
                },
                border: "none",
                display: "flex",
                height: `${
                  isLoaderRow || !isAdditionalColumnVisible
                    ? virtualRow.size
                    : virtualRow.size / 2
                }px`,
                left: 0,
                position: "absolute",
                right: isLoaderRow ? 0 : "unset",
                top: 0,
                transform: `translateY(${virtualRow.start}px)`,
              })}
              onClick={() => {
                if (
                  actionRowClickItem &&
                  actionRowClickItem.onClick &&
                  !row.original.disabled
                ) {
                  actionRowClickItem.onClick(row.original);
                }
              }}
            >
              {isLoaderRow ? (
                <TableCell
                  sx={(theme) => ({
                    alignItems: "center",
                    color: theme.palette.grey["900"],
                    display: "inline-flex",
                    fontSize: theme.spacing(1.5),
                    justifyContent: "center",
                    overflow: "hidden",
                    py: 3,
                    width: "full",
                  })}
                >
                  {hasNextPage ? (
                    <CircularProgress
                      color="inherit"
                      size="16px"
                      variant="indeterminate"
                    />
                  ) : (
                    "Nothing more to load"
                  )}
                </TableCell>
              ) : (
                <>
                  {row
                    .getVisibleCells()
                    .filter((cell) => cell.column.id !== additionalColumnId)
                    .map((cell) => (
                      <DataTableCell key={cell.id} cell={cell} />
                    ))}
                  {hasActionColumn && (
                    <DataTableActionCell<TData>
                      entity={row.original}
                      isActiveRow={isActiveRow}
                      onActionMenuClosed={() => setActiveRow(undefined)}
                      onActionMenuOpened={() => setActiveRow(row)}
                    />
                  )}
                </>
              )}
            </TableRow>
            {!isLoaderRow &&
              additionalColumnId &&
              isAdditionalColumnVisible && (
                <TableRow
                  className={classNames("striped-row", {
                    "new-striped-row": isNewRow,
                  })}
                  sx={(theme) => ({
                    "&.new-striped-row": {
                      td: {
                        background: theme.palette.purple["200"],
                      },
                    },
                    "&.striped-row:not(.new-striped-row)": {
                      borderBottom: `1px solid ${theme.palette.grey["200"]}`,
                      td: {
                        background: theme.palette.grey["10"],
                      },
                    },
                    display: "flex",
                    height: `${virtualRow.size / 2}px`,
                    left: 0,
                    position: "absolute",
                    top: 0,
                    transform: `translateY(${
                      virtualRow.start + TABLE_ROW_HEIGHT
                    }px)`,
                    width: `${getRowWidth(
                      row,
                      additionalColumnId,
                      hasActionColumn,
                    )}px`,
                  })}
                >
                  <TableCell
                    data-testid={`dt-cell-${additionalColumnId}`}
                    sx={(theme) => ({
                      alignItems: "center",
                      border: "none",
                      color: theme.palette.grey["900"],
                      display: "inline-flex",
                      fontSize: theme.spacing(1.5),
                      overflow: "hidden",
                      py: 1.5,
                      width: "100%",
                    })}
                  >
                    {row.original[additionalColumnId]}
                  </TableCell>
                </TableRow>
              )}
          </Fragment>
        );
      })}
    </TableBody>
  );
};

export default DataTableBody;
