import { useCallback, useEffect, useState } from "react";
import {
  ORDER_TYPE_QUOTE,
  ORDER_TYPE_STANDARD,
} from "../../constants/orderTypes";
import MoveBundleLines from "../modals/MoveBundleLines";
import MoveQuoteLines from "../modals/MoveQuoteLines";

const MoveLinesWrapper = () => {
  const [orderNumber, setOrderNumber] = useState<number>();
  const [orderType, setOrderType] = useState<string>();

  useEffect(() => {
    const handleLinesEvent = (event: any) => {
      setOrderNumber(event.detail.orderNumber);
      setOrderType(event.detail.orderType);
    };

    document.addEventListener("moveLinesEvent", handleLinesEvent);

    return () =>
      document.removeEventListener("moveLinesEvent", handleLinesEvent);
  }, []);

  const onModalClose = useCallback((isUpdated: boolean) => {
    setOrderNumber(undefined);
    setOrderType(undefined);

    if (isUpdated) {
      setTimeout(() => {
        window.location.reload();
      });
    }
  }, []);

  return (
    <>
      {orderNumber && orderType === ORDER_TYPE_QUOTE ? (
        <MoveQuoteLines isOpen orderNum={orderNumber} onClose={onModalClose} />
      ) : null}
      {orderNumber && orderType === ORDER_TYPE_STANDARD ? (
        <MoveBundleLines isOpen orderNum={orderNumber} onClose={onModalClose} />
      ) : null}
    </>
  );
};

export default MoveLinesWrapper;
