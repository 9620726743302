import csvToJson from "csvtojson";
import { format, isValid } from "date-fns";
import { ExpirationDto } from "../api";
import { ImportWizardColumnDef, ImportWizardFileRecord } from "../types";

export const getArrayValue = (
  record: ImportWizardFileRecord,
  index: number | undefined | null,
) => {
  const stringValue = getStringValue(record, index);
  return stringValue ? stringValue.split(",").map((value) => value.trim()) : [];
};

export const getDateValue = (
  record: ImportWizardFileRecord,
  index: number | undefined | null,
): string | undefined => {
  const value = record?.[index ?? -2];
  if (!value) {
    return undefined;
  }
  const jsDate = new Date(value);
  if (!isValid(jsDate)) {
    return undefined;
  }
  const dateWithoutTimezone = new Date(
    jsDate.getUTCFullYear(),
    jsDate.getUTCMonth(),
    jsDate.getUTCDate(),
  );

  return format(dateWithoutTimezone, "yyyy-MM-dd");
};

export const getExpirations = (
  columnMapping: Record<string, number | null>,
  record: ImportWizardFileRecord,
): Array<ExpirationDto> | undefined => {
  const type = getStringValue(record, columnMapping.expirationType);
  const date = getDateValue(record, columnMapping.expirationDate);

  if (type && date) {
    return [
      {
        date,
        type,
      },
    ];
  }

  return undefined;
};
export const getNumberValue = (
  record: ImportWizardFileRecord,
  index: number | undefined | null,
): number | undefined => {
  const stringValue = getStringValue(record, index);

  return stringValue !== undefined
    ? parseFloat(stringValue as string)
    : undefined;
};

export const getStringValue = (
  record: ImportWizardFileRecord,
  index: number | undefined | null,
): string | undefined => {
  const value = record?.[index ?? -2];
  if (value === undefined || value === null) {
    return undefined;
  }

  return String(value);
};

export const guessColumnIndex = (
  columnDef: ImportWizardColumnDef,
  fileColumnNames: string[],
): number | null => {
  const suggestions = [
    columnDef.name,
    columnDef.label,
    ...columnDef.suggestions,
  ].map((name) => name.toLowerCase());

  const columnIndex = fileColumnNames.findIndex((fileColumName) =>
    suggestions.includes(fileColumName.toLowerCase()),
  );

  return columnIndex < 0 ? null : columnIndex;
};

export const readCsvFile = async (file: File) =>
  new Promise((resolve, reject) => {
    if (!file) {
      reject("No file provided");
    }

    const reader = new FileReader();

    reader.onload = async (event) => {
      try {
        const records = await csvToJson({ noheader: true }).fromString(
          (event.target?.result ?? "") as string,
        );
        resolve(records.map((record) => Object.values(record)));
      } catch (error) {
        reject("Error parsing CSV: " + error);
      }
    };

    reader.onerror = () => {
      reject("Error reading file");
    };

    reader.readAsText(file);
  });
