import { sortBy, uniq, uniqBy, without } from "lodash";
import { useEffect, useMemo, useRef } from "react";
import { Chart, ReactGoogleChartEvent } from "react-google-charts";
import { AREA_CHART_COLORS } from "../../constants/chart";
import { GOOGLE_CHART_VERSION } from "../../constants/chat";
import { usePurchaseAnalysisContext } from "../../contexts/PurchaseAnalysisContext";
import { useCookiesLive } from "../../hooks";
import { useSavingsByManufacturer } from "../../hooks/api/queries";
import { PurchaseAnalysisChartOption1 } from "../../types";
import { getAppLink } from "../../utils/link";
import ChartDateFilterButton from "./ChartDateFilterButton";
import ChartWidget from "./ChartWidget";

const SavingsByManufacturer = () => {
  const [accountCode] = useCookiesLive(["accountCode"]);
  const { config } = usePurchaseAnalysisContext();
  const chartConfig =
    config.savingsByManufacturer as PurchaseAnalysisChartOption1 | null;
  const { data, isFetching } = useSavingsByManufacturer(
    chartConfig?.startDate,
    chartConfig?.endDate,
    accountCode,
  );
  const chartConfigRef = useRef<PurchaseAnalysisChartOption1 | null>(null);

  useEffect(() => {
    chartConfigRef.current = chartConfig;
  }, [chartConfig]);

  const chartData = useMemo(() => {
    let manufacturers = uniq(data?.map((row: any) => row.manufacturerName));
    if (manufacturers.includes("All Others")) {
      manufacturers = [...without(manufacturers, "All Others"), "All Others"];
    }
    const sortedData = sortBy(data, ["cyear", "cmonth"]);
    const periods = uniqBy(sortedData, (row: any) => row.cyear + row.cmonth);

    return [
      ["Year/Month", ...manufacturers],
      ...periods.map((period: any) => [
        `${period.monthName} ${period.cyear}`,
        ...manufacturers.map((manufacturer) => {
          const value = (
            data?.find(
              (row: any) =>
                row.cyear === period.cyear &&
                row.cmonth === period.cmonth &&
                row.manufacturerName === manufacturer,
            ) as any
          )?.savings;
          return value;
        }),
      ]),
    ];
  }, [data]);

  const chartEvents: ReactGoogleChartEvent[] = [
    {
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper?.getChart();
        const selection = chart?.getSelection();
        if (selection && selection.length === 1) {
          const [selectedItem] = selection;
          const dataTable = chartWrapper?.getDataTable();
          const { column } = selectedItem;
          const value = dataTable?.getColumnLabel(column);

          if (value) {
            const searchParams = new URLSearchParams();
            searchParams.set(
              "params",
              JSON.stringify({
                d: 1,
                disp: [
                  "reserved_select_row",
                  "orderNum",
                  "custPo",
                  "orderDate",
                  "mfgName",
                  "mfgPart",
                  "catName",
                  "qtyOrdered",
                  "unitPrice",
                  "totalPrice",
                  "avgMdPrice",
                  "unitSavings",
                  "totalSavings",
                  "reserved_actions",
                ],
                fs: {
                  mfgName: [value],
                  orderDate: {
                    end: chartConfigRef.current?.endDate,
                    start: chartConfigRef.current?.startDate,
                  },
                  totalSavings: { end: "", start: "0" },
                },
                fs_clear: false,
                o: "orderNum",
                p: "1",
                r: "50",
              }),
            );
            window.location.href = getAppLink(
              `app/OrderLine?${searchParams.toString()}`,
            );
          }
        }
      },
      eventName: "select",
    },
  ];

  return (
    <ChartWidget
      actions={<ChartDateFilterButton configKey="savingsByManufacturer" />}
      hasNoData={!isFetching && !data?.length}
      id="savingsByManufacturer"
      isLoading={isFetching}
      title="Savings By Manufacturer"
    >
      <Chart
        chartEvents={chartEvents}
        chartType="AreaChart"
        chartVersion={GOOGLE_CHART_VERSION}
        data={chartData}
        graphID="savingsByManufacturer"
        height="100%"
        options={{
          colors: AREA_CHART_COLORS,
          vAxis: {
            format: "$###,###,###",
          },
        }}
        width="100%"
      />
    </ChartWidget>
  );
};
export default SavingsByManufacturer;
