import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { OpenAPI } from "../../../api";

const exportUsingGet = async (quoteId: number) => {
  return axios.get(`/service/rest/quotes/quoteId/${quoteId}/export`, {
    baseURL: OpenAPI.BASE,
    headers: {
      ACCEPT: "application/pdf",
      Authorization: `Bearer ${OpenAPI.TOKEN}`,
    },
    responseType: "blob",
  });
};

export const useQuoteExport = () => {
  return useMutation((quoteId: number) => exportUsingGet(quoteId));
};
