import { createContext, ReactElement, useCallback, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import {
  PurchaseAnalysisChartConfigKey,
  PurchaseAnalysisChartOption1,
  PurchaseAnalysisChartOption2,
} from "../types";
import { parseJsonString } from "../utils/string";

type PurchaseAnalysisContextState = {
  config: {
    [key in PurchaseAnalysisChartConfigKey]?:
      | PurchaseAnalysisChartOption1
      | PurchaseAnalysisChartOption2;
  };
  updateConfig: (
    key: PurchaseAnalysisChartConfigKey,
    value: PurchaseAnalysisChartOption1 | PurchaseAnalysisChartOption2,
  ) => void;
};

const PurchaseAnalysisContext = createContext<PurchaseAnalysisContextState>({
  config: {},
  updateConfig: () => {},
});

export const PurchaseAnalysisContextProvider = ({
  children,
}: {
  children?: ReactElement | ReactElement[];
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const spendByCategory: PurchaseAnalysisChartOption1 = parseJsonString(
    searchParams.get("spendByCategory"),
  );
  const spendByManufacturer: PurchaseAnalysisChartOption1 = parseJsonString(
    searchParams.get("spendByManufacturer"),
  );
  const spendByState: PurchaseAnalysisChartOption1 = parseJsonString(
    searchParams.get("spendByState"),
  );
  const yearOverYearSpend: PurchaseAnalysisChartOption2 = parseJsonString(
    searchParams.get("yearOverYearSpend"),
  );
  const spendByLocation: PurchaseAnalysisChartOption1 = parseJsonString(
    searchParams.get("spendByLocation"),
  );
  const popularProducts: PurchaseAnalysisChartOption1 = parseJsonString(
    searchParams.get("popularProducts"),
  );
  const savingsByCategory: PurchaseAnalysisChartOption1 = parseJsonString(
    searchParams.get("savingsByCategory"),
  );
  const savingsByManufacturer: PurchaseAnalysisChartOption1 = parseJsonString(
    searchParams.get("savingsByManufacturer"),
  );

  const updateConfig = useCallback(
    (
      configKey: PurchaseAnalysisChartConfigKey,
      configValue: PurchaseAnalysisChartOption1 | PurchaseAnalysisChartOption2,
    ) => {
      setSearchParams((prev) => {
        prev.set(configKey, JSON.stringify(configValue));
        return prev;
      });
    },
    [setSearchParams],
  );

  return (
    <PurchaseAnalysisContext.Provider
      value={{
        config: {
          popularProducts,
          savingsByCategory,
          savingsByManufacturer,
          spendByCategory,
          spendByLocation,
          spendByManufacturer,
          spendByState,
          yearOverYearSpend,
        },
        updateConfig,
      }}
    >
      {children}
    </PurchaseAnalysisContext.Provider>
  );
};

export const usePurchaseAnalysisContext = () =>
  useContext(PurchaseAnalysisContext);
