import { useQuery } from "@tanstack/react-query";
import {
  GlobalInvoiceLinesResourceService,
  type RestCollection_InvoiceSolrDto_,
} from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";
import { ListingMaxIdQueryHook } from "../../../types";
import { getMaxIdCriteria } from "../../../utils/criteria";

export const useGlobalInvoiceLineMaxId: ListingMaxIdQueryHook<
  RestCollection_InvoiceSolrDto_
> = (identifier: string) => {
  const maxIdCriteria = getMaxIdCriteria(identifier);

  return useQuery(
    [QUERY_KEYS["global-invoice-line-max-id"], identifier],
    () => {
      return GlobalInvoiceLinesResourceService.listGlobalInvoiceLinesUsingGet(
        JSON.stringify(maxIdCriteria),
        1,
        false,
        true,
        false,
        1,
      );
    },
  );
};
