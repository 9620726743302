import { createSvgIcon } from "@mui/material";

export const ImportIcon = createSvgIcon(
  <svg viewBox="0 0 12 15" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="#242021"
        fillRule="nonzero"
        id="pg1"
        transform="translate(-37, -269)"
      >
        <g id="import-btn" transform="translate(21, 259)">
          <g id="box-arrow-in-down" transform="translate(16, 10)">
            <path
              d="M1.5,5 C1.22385763,5 1,5.22385763 1,5.5 L1,13.5 C1,13.7761424 1.22385763,14 1.5,14 L10.5,14 C10.7761424,14 11,13.7761424 11,13.5 L11,5.5 C11,5.22385763 10.7761424,5 10.5,5 L8.5,5 C8.22385763,5 8,4.77614237 8,4.5 C8,4.22385763 8.22385763,4 8.5,4 L10.5,4 C11.3284271,4 12,4.67157288 12,5.5 L12,13.5 C12,14.3284271 11.3284271,15 10.5,15 L1.5,15 C0.671572875,15 0,14.3284271 0,13.5 L0,5.5 C0,4.67157288 0.671572875,4 1.5,4 L3.5,4 C3.77614237,4 4,4.22385763 4,4.5 C4,4.77614237 3.77614237,5 3.5,5 L1.5,5 Z"
              id="Path"
            ></path>
            <path
              d="M5.646,10.854 C5.73980426,10.9480417 5.86717274,11.0008938 6,11.0008938 C6.13282726,11.0008938 6.26019574,10.9480417 6.354,10.854 L9.354,7.854 C9.5495088,7.6584912 9.5495088,7.3415088 9.354,7.146 C9.1584912,6.9504912 8.8415088,6.9504912 8.646,7.146 L6.5,9.293 L6.5,0.5 C6.5,0.223857625 6.27614237,0 6,0 C5.72385763,0 5.5,0.223857625 5.5,0.5 L5.5,9.293 L3.354,7.146 C3.1584912,6.9504912 2.8415088,6.9504912 2.646,7.146 C2.4504912,7.3415088 2.4504912,7.6584912 2.646,7.854 L5.646,10.854 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "ImportIcon",
);
