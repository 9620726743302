import { useMemo, useState } from "react";
import { QuoteSolrDto } from "../../api";
import ListingPage from "../../components/ListingPage";
import EmailDetailModal from "../../components/modals/EmailDetailModal";
import { withPageTitle } from "../../hocs";
import { useQuoteEmail } from "../../hooks/api/mutations";
import { ActionRowItem } from "../../types";

const QuotesList = () => {
  const [selectedQuotes, setSelectedQuotes] = useState<QuoteSolrDto[]>([]);
  const [
    isEmailSelectedQuotesModalOpened,
    setIsEmailSelectedQuotesModalOpened,
  ] = useState(false);
  const actionRowItems: ActionRowItem[] = useMemo(
    () => [
      {
        isVisible: !!selectedQuotes.length,
        label: "Email Selected",
        onClick: () => setIsEmailSelectedQuotesModalOpened(true),
      },
    ],
    [selectedQuotes],
  );

  return (
    <>
      <ListingPage
        isSelectable
        actionRowItems={actionRowItems}
        identifier="orderNum"
        listingName="quote_list"
        selectedRows={selectedQuotes}
        onRowsSelect={setSelectedQuotes}
      />

      {isEmailSelectedQuotesModalOpened && (
        <EmailDetailModal
          isOpen
          accountCodes={selectedQuotes
            .map((quote) => quote.accountCode?.toUpperCase())
            .filter((value, index, array) => {
              return array.indexOf(value) === index;
            })}
          idsWithAccounts={selectedQuotes.map((quote) => {
            return { accountCode: quote.accountCode, id: quote.orderNum };
          })}
          typeLabel="Quote"
          useSendEmailHook={useQuoteEmail}
          onClose={() => {
            setIsEmailSelectedQuotesModalOpened(false);
          }}
        />
      )}
    </>
  );
};

export default withPageTitle("Quotes", QuotesList);
