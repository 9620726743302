import { StandardLineSolrDto } from "../../api";
import ListingPage from "../../components/ListingPage";
import { withPageTitle } from "../../hocs";

const StandardItemsList = () => {
  return (
    <ListingPage<StandardLineSolrDto>
      additionalColumnId="description"
      identifier="bundleLineId"
      listingName="standardlines"
    />
  );
};

export default withPageTitle("Standard Items", StandardItemsList);
