import { Box, Grid, SxProps, Theme, Typography } from "@mui/material";
import { Header } from "@tanstack/react-table";
import { endOfToday, startOfToday } from "date-fns";
import { kebabCase } from "lodash";

import { memo, useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm, UseFormSetValue } from "react-hook-form";
import { SelectElement } from "react-hook-form-mui";
import { PatternFormat } from "react-number-format";
import { FILTER_BLANK_OPTIONS } from "../../../constants/dataTable";
import {
  DATE_FILTER_FROM_OPTIONS,
  DATE_FILTER_OPTIONS,
  DATE_FILTER_PERIOD_DIRECTIONS,
  DATE_FILTER_PERIOD_OPTIONS,
  DATE_FILTER_START_END_OF_PERIODS,
  DATE_FILTER_START_END_OPTIONS,
  DATE_FILTER_START_END_PERIOD_POINTERS,
  DATE_FILTER_TO_OPTIONS,
} from "../../../constants/datefilter";
import { DateFilterFormValues } from "../../../types";
import {
  convertDateOptionsIntoTextualRepresentation,
  convertTextualRepresentationIntoDateOptions,
  getFormattedDate,
  isValidDateString,
  parseDate,
} from "../../../utils/datetime";
import { Button, TextField } from "../../ui";
import { useDataTableContext } from "../DataTableContext";

const selectProps = {
  MenuProps: {
    PaperProps: {
      sx: (theme: Theme) => ({
        "& .MuiList-root .MuiMenuItem-root": {
          fontSize: theme.spacing(1.5),
        },
      }),
    },
  },
};
const selectStyle = (theme: Theme) => ({
  "& .MuiSelect-select": {
    fontSize: theme.spacing(1.5),
    py: 0.75,
  },
  flexGrow: 1,
});

const filterButtonProps =
  (isActive: boolean): SxProps<Theme> =>
  (theme) => ({
    "&:hover": {
      background: isActive ? "transparent" : theme.palette.grey["100"],
    },
    background: isActive ? "transparent" : theme.palette.grey["100"],
    borderColor: isActive
      ? theme.palette.blue["800"]
      : theme.palette.grey["500"],
    color: isActive ? theme.palette.blue["800"] : theme.palette.grey["900"],
    justifyContent: "flex-start",
    textWrap: "nowrap",
  });

const defaultValues: DateFilterFormValues = {
  dates: [
    {
      date: startOfToday(),
      duration: "7",
      durationPointer: "before",
      durationUnit: "days",
      option: "",
      startOrEnd: "start",
      startOrEndPointer: "this",
      startOrEndUnit: "month",
    },
    {
      date: endOfToday(),
      duration: "1",
      durationPointer: "after",
      durationUnit: "days",
      option: "",
      startOrEnd: "end",
      startOrEndPointer: "this",
      startOrEndUnit: "month",
    },
  ],
};

const DateFilter = <TData, TValue>({
  header,
  onClose,
}: {
  header: Header<TData, TValue>;
  onClose: () => void;
}) => {
  const { criteria, updateFilter } = useDataTableContext();
  const { id: element } = header.column.columnDef;
  const defaultStartDate = element
    ? (criteria.ge?.[0]?.[element]?.[0] as string)
    : undefined;
  const defaultEndDate = element
    ? (criteria.le?.[0]?.[element]?.[0] as string)
    : undefined;

  const { control, register, handleSubmit, watch, setValue } =
    useForm<DateFilterFormValues>({
      defaultValues: {
        dates: [
          defaultStartDate
            ? convertTextualRepresentationIntoDateOptions(defaultStartDate)
            : defaultValues.dates[0],
          defaultEndDate
            ? convertTextualRepresentationIntoDateOptions(defaultEndDate)
            : defaultValues.dates[1],
        ],
      },
    });

  const allFields = watch();

  const [selectedOption, setSelectedOption] = useState(
    DATE_FILTER_OPTIONS[0].label,
  );
  const [blankOrNonBlank, setBlankOrNonBlank] = useState<string | undefined>(); // true: blank, false: non-blank, undefined: other

  const isBlankOrNonBlank = !!blankOrNonBlank;

  const handleFilterOptionClick = useCallback(
    (option: {
      label: string;
      onClick?: (setValue: UseFormSetValue<DateFilterFormValues>) => () => void;
    }) => {
      if (option.onClick) {
        option.onClick(setValue)();
      }

      setBlankOrNonBlank(undefined);
      setTimeout(() => setSelectedOption(option.label), 10);
    },
    [setValue],
  );

  const handleBlankOptions = useCallback(
    (blankOrNonBlank: string) => {
      setSelectedOption(DATE_FILTER_OPTIONS[0].label);
      setValue("dates.0.option", "");
      setValue("dates.1.option", "");
      setBlankOrNonBlank(blankOrNonBlank);
      if (element) {
        updateFilter([
          {
            field: element,
            key: "eq",
            values: [blankOrNonBlank],
          },
          {
            field: element,
            key: "ge",
            values: [],
          },
          {
            field: element,
            key: "le",
            values: [],
          },
        ]);
      }
      onClose();
    },
    [element, onClose, setValue, updateFilter],
  );

  const handleSave = useCallback(
    (values: DateFilterFormValues) => {
      if (element) {
        if (blankOrNonBlank) {
          updateFilter([
            {
              field: element,
              key: "eq",
              values: [blankOrNonBlank],
            },
            {
              field: element,
              key: "ge",
              values: [],
            },
            {
              field: element,
              key: "le",
              values: [],
            },
          ]);
        } else {
          const startDateValue = convertDateOptionsIntoTextualRepresentation(
            values.dates[0],
          );
          const endDateValue = convertDateOptionsIntoTextualRepresentation(
            values.dates[1],
          );
          updateFilter([
            {
              field: element,
              key: "eq",
              values: [],
            },
            {
              field: element,
              key: "ge",
              values: startDateValue ? [startDateValue] : [],
            },
            {
              field: element,
              key: "le",
              values: endDateValue ? [endDateValue] : [],
            },
          ]);
        }
      }

      onClose();
    },
    [blankOrNonBlank, element, onClose, updateFilter],
  );

  const handleClear = useCallback(() => {
    if (element) {
      updateFilter([
        {
          field: element,
          key: "eq",
          values: [],
        },
        {
          field: element,
          key: "ge",
          values: [],
        },
        {
          field: element,
          key: "le",
          values: [],
        },
      ]);
    }
    setValue("dates", defaultValues.dates);
    onClose();
  }, [element, onClose, updateFilter, setValue]);

  useEffect(() => {
    setSelectedOption(DATE_FILTER_OPTIONS[0].label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(allFields)]);

  return (
    <Box sx={{ px: 2, py: 2.5 }}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Typography fontWeight="500" sx={{ mb: 1.25 }} variant="body2">
          Filter By:
        </Typography>

        <Box
          sx={(theme) => ({
            alignItems: "flex-start",
            display: "inline-flex",
            gap: theme.spacing(3),
            width: "100%",
          })}
        >
          <Box
            sx={(theme) => ({
              alignItems: "flex-start",
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(0.5),
            })}
          >
            <Button
              fullWidth
              data-testid={`dt-date-filter-option-${kebabCase(
                DATE_FILTER_OPTIONS[0].label,
              )}`}
              sx={filterButtonProps(!isBlankOrNonBlank)}
              variant="outlined"
              onClick={() => handleFilterOptionClick(DATE_FILTER_OPTIONS[0])}
            >
              {DATE_FILTER_OPTIONS[0].label}
            </Button>
            {DATE_FILTER_OPTIONS.slice(1).map((option) => (
              <Button
                key={option.label}
                fullWidth
                data-testid={`dt-date-filter-option-${kebabCase(option.label)}`}
                sx={(theme) => ({
                  "&:hover": {
                    border: "none",
                  },
                  border: "none",
                  justifyContent: "flex-start",
                  textWrap: "nowrap",
                  ...(selectedOption === option.label
                    ? {
                        background: theme.palette.grey["100"],
                        color: theme.palette.blue["700"],
                      }
                    : {
                        background: "transparent",
                        color: theme.palette.grey["900"],
                      }),
                })}
                variant="outlined"
                onClick={() => handleFilterOptionClick(option)}
              >
                {option.label}
              </Button>
            ))}
            <Button
              fullWidth
              data-testid={`dt-date-filter-option-${FILTER_BLANK_OPTIONS[0]}`}
              sx={filterButtonProps(
                blankOrNonBlank === FILTER_BLANK_OPTIONS[0],
              )}
              variant="outlined"
              onClick={() => handleBlankOptions(FILTER_BLANK_OPTIONS[0])}
            >
              All Blank Dates
            </Button>
            <Button
              fullWidth
              data-testid={`dt-date-filter-option-${FILTER_BLANK_OPTIONS[1]}`}
              sx={filterButtonProps(
                blankOrNonBlank === FILTER_BLANK_OPTIONS[1],
              )}
              variant="outlined"
              onClick={() => handleBlankOptions(FILTER_BLANK_OPTIONS[1])}
            >
              All Non-Blank Dates
            </Button>
          </Box>
          <Grid container spacing={3} sx={{ width: "100%" }}>
            <Grid item xs={6}>
              <Typography
                fontWeight="500"
                lineHeight={1}
                sx={{ mb: 1.5 }}
                variant="body2"
              >
                From
              </Typography>
              <Box
                sx={(theme) => ({ display: "flex", gap: theme.spacing(1.5) })}
              >
                <SelectElement
                  SelectProps={selectProps}
                  control={control}
                  data-testid="dt-date-filter-from-date-options"
                  disabled={isBlankOrNonBlank}
                  labelKey="label"
                  name="dates.0.option"
                  options={DATE_FILTER_FROM_OPTIONS}
                  sx={selectStyle}
                  valueKey="value"
                />

                {allFields.dates[0].option === "specific-date" && (
                  <Controller
                    control={control}
                    name="dates.0.date"
                    render={({ field: { value, onChange } }) => (
                      <PatternFormat
                        customInput={TextField}
                        data-testid="dt-date-filter-from-specific-date"
                        format="##/##/####"
                        mask="_"
                        placeholder="mm/dd/yyyy"
                        sx={(theme: Theme) => ({
                          fontSize: theme.spacing(1.5),
                          width: theme.spacing(12),
                        })}
                        value={getFormattedDate(value)}
                        onValueChange={({ formattedValue }) =>
                          isValidDateString(formattedValue, "MM/dd/yyyy") &&
                          onChange(parseDate(formattedValue, "MM/dd/yyyy"))
                        }
                      />
                    )}
                  />
                )}
              </Box>

              {allFields.dates[0].option === "periods-before-after" && (
                <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                  <TextField
                    type="number"
                    {...register("dates.0.duration")}
                    data-testid="dt-date-filter-from-periods-duration"
                    sx={(theme) => ({ width: theme.spacing(6) })}
                  />
                  <SelectElement
                    SelectProps={selectProps}
                    control={control}
                    data-testid="dt-date-filter-from-periods-unit"
                    labelKey="label"
                    name="dates.0.durationUnit"
                    options={DATE_FILTER_PERIOD_OPTIONS}
                    sx={selectStyle}
                    valueKey="value"
                  />

                  <SelectElement
                    SelectProps={selectProps}
                    control={control}
                    data-testid="dt-date-filter-from-periods-pointer"
                    labelKey="label"
                    name="dates.0.durationPointer"
                    options={DATE_FILTER_PERIOD_DIRECTIONS}
                    sx={selectStyle}
                    valueKey="value"
                  />
                </Box>
              )}

              {allFields.dates[0].option === "start-end-of-periods" && (
                <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                  <SelectElement
                    SelectProps={selectProps}
                    control={control}
                    data-testid="dt-date-filter-from-periods-option"
                    labelKey="label"
                    name="dates.0.startOrEnd"
                    options={DATE_FILTER_START_END_OPTIONS}
                    sx={selectStyle}
                    valueKey="value"
                  />

                  <SelectElement
                    SelectProps={selectProps}
                    control={control}
                    data-testid="dt-date-filter-from-periods-pointer"
                    labelKey="label"
                    name="dates.0.startOrEndPointer"
                    options={DATE_FILTER_START_END_PERIOD_POINTERS}
                    sx={selectStyle}
                    valueKey="value"
                  />

                  <SelectElement
                    SelectProps={selectProps}
                    control={control}
                    data-testid="dt-date-filter-from-periods-unit"
                    labelKey="label"
                    name="dates.0.startOrEndUnit"
                    options={DATE_FILTER_START_END_OF_PERIODS}
                    sx={selectStyle}
                    valueKey="value"
                  />
                </Box>
              )}

              {allFields.dates[0].option === "specific-date" && (
                <Box sx={{ mt: 2 }}>
                  <Controller
                    control={control}
                    name="dates.0.date"
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        fixedHeight
                        inline
                        data-testid="dt-date-filter-from-date-picker"
                        dateFormat="MM/dd/yyyy"
                        selected={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography
                fontWeight="500"
                lineHeight={1}
                sx={{ mb: 1.5 }}
                variant="body2"
              >
                To
              </Typography>
              <Box
                sx={(theme) => ({ display: "flex", gap: theme.spacing(1.5) })}
              >
                <SelectElement
                  SelectProps={selectProps}
                  control={control}
                  data-testid="dt-date-filter-to-date-options"
                  disabled={isBlankOrNonBlank}
                  labelKey="label"
                  name="dates.1.option"
                  options={DATE_FILTER_TO_OPTIONS}
                  sx={selectStyle}
                  valueKey="value"
                />

                {allFields.dates[1].option === "specific-date" && (
                  <Controller
                    control={control}
                    name="dates.1.date"
                    render={({ field: { value, onChange } }) => (
                      <PatternFormat
                        customInput={TextField}
                        data-testid="dt-date-filter-to-specific-date"
                        format="##/##/####"
                        mask="_"
                        placeholder="mm/dd/yyyy"
                        sx={(theme: Theme) => ({
                          fontSize: theme.spacing(1.5),
                          width: theme.spacing(12),
                        })}
                        value={getFormattedDate(value)}
                        onValueChange={({ formattedValue }) =>
                          isValidDateString(formattedValue, "MM/dd/yyyy") &&
                          onChange(parseDate(formattedValue, "MM/dd/yyyy"))
                        }
                      />
                    )}
                  />
                )}
              </Box>

              {allFields.dates[1].option === "periods-before-after" && (
                <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                  <TextField
                    type="number"
                    {...register("dates.1.duration")}
                    data-testid="dt-date-filter-to-periods-duration"
                    sx={(theme) => ({ width: theme.spacing(6) })}
                  />
                  <SelectElement
                    SelectProps={selectProps}
                    control={control}
                    data-testid="dt-date-filter-to-periods-unit"
                    labelKey="label"
                    name="dates.1.durationUnit"
                    options={DATE_FILTER_PERIOD_OPTIONS}
                    sx={selectStyle}
                    valueKey="value"
                  />
                  <SelectElement
                    SelectProps={selectProps}
                    control={control}
                    data-testid="dt-date-filter-to-periods-pointer"
                    labelKey="label"
                    name="dates.1.durationPointer"
                    options={DATE_FILTER_PERIOD_DIRECTIONS}
                    sx={selectStyle}
                    valueKey="value"
                  />
                </Box>
              )}

              {allFields.dates[1].option === "start-end-of-periods" && (
                <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                  <SelectElement
                    SelectProps={selectProps}
                    control={control}
                    data-testid="dt-date-filter-to-periods-option"
                    labelKey="label"
                    name="dates.1.startOrEnd"
                    options={DATE_FILTER_START_END_OPTIONS}
                    sx={selectStyle}
                    valueKey="value"
                  />

                  <SelectElement
                    SelectProps={selectProps}
                    control={control}
                    data-testid="dt-date-filter-to-periods-pointer"
                    labelKey="label"
                    name="dates.1.startOrEndPointer"
                    options={DATE_FILTER_START_END_PERIOD_POINTERS}
                    sx={selectStyle}
                    valueKey="value"
                  />

                  <SelectElement
                    SelectProps={selectProps}
                    control={control}
                    data-testid="dt-date-filter-to-periods-unit"
                    labelKey="label"
                    name="dates.1.startOrEndUnit"
                    options={DATE_FILTER_START_END_OF_PERIODS}
                    sx={selectStyle}
                    valueKey="value"
                  />
                </Box>
              )}

              {allFields.dates[1].option === "specific-date" && (
                <Box sx={{ mt: 2 }}>
                  <Controller
                    control={control}
                    name="dates.1.date"
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        fixedHeight
                        inline
                        data-testid="dt-date-filter-to-date-picker"
                        dateFormat="MM/dd/yyyy"
                        selected={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            px: 1,
          }}
        >
          <Button
            data-testid="dt-column-filter-facet-clear-btn"
            variant="outlined"
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button
            color="primary"
            data-testid="dt-column-filter-facet-apply-btn"
            type="submit"
            variant="contained"
          >
            Apply
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default memo(DateFilter) as typeof DateFilter;
