import { ImportWizardFileRecord, ClientResourceDto } from "../types";
import { getArrayValue, getStringValue } from "./importWizard";

export const generateClientResourceEntity = (
  columnMapping: Record<string, number | null>,
  record: ImportWizardFileRecord,
): ClientResourceDto => ({
  crm: getStringValue(record, columnMapping.crm),
  crmClientCode: getStringValue(record, columnMapping.crmClientCode),
  erp: getStringValue(record, columnMapping.erp),
  erpAccounts: getArrayValue(record, columnMapping.erpAccounts),
});
