import { useMemo, useState } from "react";
import { OrderListing } from "../../api";
import ListingPage from "../../components/ListingPage";
import EmailDetailModal from "../../components/modals/EmailDetailModal";
import { withPageTitle } from "../../hocs";
import { useOrderEmail } from "../../hooks/api/mutations";
import { ActionRowItem } from "../../types";

const OrdersList = () => {
  const [selectedOrders, setSelectedOrders] = useState<OrderListing[]>([]);
  const [
    isEmailSelectedOrdersModalOpened,
    setIsEmailSelectedOrdersModalOpened,
  ] = useState(false);

  const actionRowItems: ActionRowItem[] = useMemo(
    () => [
      {
        isVisible: !!selectedOrders.length,
        label: "Email Selected",
        onClick: () => setIsEmailSelectedOrdersModalOpened(true),
      },
    ],
    [selectedOrders],
  );

  return (
    <>
      <ListingPage<OrderListing>
        isSelectable
        actionRowItems={actionRowItems}
        identifier="orderNum"
        listingName="order_list"
        selectedRows={selectedOrders}
        onRowsSelect={setSelectedOrders}
      />

      {isEmailSelectedOrdersModalOpened && (
        <EmailDetailModal
          isOpen
          accountCodes={selectedOrders
            .map((order) => order.accountCode?.toUpperCase())
            .filter((value, index, array) => {
              return array.indexOf(value) === index;
            })}
          idsWithAccounts={selectedOrders.map((order) => {
            return { accountCode: order.accountCode, id: order.orderNum };
          })}
          typeLabel="Order"
          useSendEmailHook={useOrderEmail}
          onClose={() => {
            setIsEmailSelectedOrdersModalOpened(false);
          }}
        />
      )}
    </>
  );
};

export default withPageTitle("Your Orders", OrdersList);
