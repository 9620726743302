import { createSvgIcon } from "@mui/material";

export const DownloadIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
    <title>download</title>
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fillRule="nonzero"
        id="main-nav"
        transform="translate(-1352.000000, -265.000000)"
      >
        <g id="Group-16" transform="translate(29.000000, 254.000000)">
          <g id="export" transform="translate(1302.000000, 0.000000)">
            <g id="download" transform="translate(21.000000, 11.000000)">
              <path
                d="M0.5,8.9 C0.776142375,8.9 1,9.12385763 1,9.4 L1,11.9 C1,12.4522847 1.44771525,12.9 2,12.9 L14,12.9 C14.5522847,12.9 15,12.4522847 15,11.9 L15,9.4 C15,9.12385763 15.2238576,8.9 15.5,8.9 C15.7761424,8.9 16,9.12385763 16,9.4 L16,11.9 C16,13.0045695 15.1045695,13.9 14,13.9 L2,13.9 C0.8954305,13.9 0,13.0045695 0,11.9 L0,9.4 C0,9.12385763 0.223857625,8.9 0.5,8.9 Z"
                fill="currentColor"
                id="Path"
              ></path>
              <path
                d="M7.646,10.854 C7.73980426,10.9480417 7.86717274,11.0008938 8,11.0008938 C8.13282726,11.0008938 8.26019574,10.9480417 8.354,10.854 L11.354,7.854 C11.5495088,7.6584912 11.5495088,7.3415088 11.354,7.146 C11.1584912,6.9504912 10.8415088,6.9504912 10.646,7.146 L8.5,9.293 L8.5,0.5 C8.5,0.223857625 8.27614237,0 8,0 C7.72385763,0 7.5,0.223857625 7.5,0.5 L7.5,9.293 L5.354,7.146 C5.1584912,6.9504912 4.8415088,6.9504912 4.646,7.146 C4.4504912,7.3415088 4.4504912,7.6584912 4.646,7.854 L7.646,10.854 Z"
                fill="currentColor"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "Download",
);
