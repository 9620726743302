/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DealResponseDto } from './DealResponseDto';
import type { DeviceDto } from './DeviceDto';
import type { OrderLineAppleCareDto } from './OrderLineAppleCareDto';
import type { OrderLineBundleDto } from './OrderLineBundleDto';
import type { ProductFeeDto } from './ProductFeeDto';
import type { ReturnLabelDto } from './ReturnLabelDto';
import type { ShipmentDto } from './ShipmentDto';
export type OrderLineDto = {
  /**
   * Response Only
   */
  _labels?: Record<string, string>;
  /**
   * Response Only
   */
  _links?: Record<string, Array<string>>;
  appleDep?: OrderLineAppleCareDto;
  assetTags?: Array<string>;
  averagePrice?: number;
  backorderDate?: string;
  backorderNotes?: string;
  baseUnitCode?: string;
  bookCost?: number;
  comment?: string;
  componentType?: OrderLineDto.componentType;
  connectionSku?: number;
  contractPrice?: number;
  custom1?: string;
  custom2?: string;
  custom3?: string;
  custom4?: string;
  deal?: DealResponseDto;
  devices?: Array<DeviceDto>;
  dispatchDate?: string;
  dispatchMethod?: string;
  dispatchTime?: string;
  dnoFlag?: string;
  endUserLicenseEligible?: boolean;
  endUserLicenses?: Record<string, string>;
  expirationDate?: string;
  extended?: number;
  fee?: ProductFeeDto;
  globalLoad?: number;
  gpPercentage?: number;
  invoiceNumbers?: Array<number>;
  isVirtual?: boolean;
  largeImageUrl?: string;
  lineType?: string;
  macAddresses?: Array<string>;
  manufacturerId?: number;
  manufacturerProductId?: number;
  manufacturerProductName?: string;
  memberPrice?: number;
  mfgName?: string;
  mfgPart?: string;
  orderLineBundles?: Array<OrderLineBundleDto>;
  orderLineId?: number;
  orderNum?: number;
  orderType?: string;
  primaryImageUrl?: string;
  prodDesc?: string;
  projectedCost?: number;
  qtyBackorder?: number;
  qtyInvoiced?: number;
  qtyOrdered?: number;
  qtyReceivable?: number;
  qtyReceived?: number;
  qtyReturnable?: number;
  qtyReturned?: number;
  qtyShipped?: number;
  quantityBundle1?: number;
  quantityBundle10?: number;
  quantityBundle2?: number;
  quantityBundle3?: number;
  quantityBundle4?: number;
  quantityBundle5?: number;
  quantityBundle6?: number;
  quantityBundle7?: number;
  quantityBundle8?: number;
  quantityBundle9?: number;
  repGpPercentage?: number;
  returnLabels?: Array<ReturnLabelDto>;
  returnTrackingCodes?: Array<string>;
  screenSize?: number;
  sequence?: number;
  serialNumbers?: Array<string>;
  shipments?: Array<ShipmentDto>;
  showClientBackorderNotes?: boolean;
  specialBid?: string;
  subtotalWeight?: number;
  suppCode?: string;
  suppMfg?: string;
  suppPart?: string;
  supplierProductId?: number;
  totalCost?: number;
  totalGp?: number;
  totalLoadedCost?: number;
  totalRepGp?: number;
  unitCost?: number;
  unitGp?: number;
  unitLoadedCost?: number;
  unitRepGp?: number;
  unitWeight?: number;
  width400ImageUrl?: string;
  xmitDate?: string;
  xmitStatus?: string;
  xmitTime?: string;
  xmitType?: string;
};
export namespace OrderLineDto {
  export enum componentType {
    COMPONENT = 'Component',
    FEE = 'Fee',
    MASTER = 'Master',
    PICK_PACK = 'Pick-Pack',
  }
}

