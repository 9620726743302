import { useEffect, useMemo, useRef } from "react";
import { Chart, ReactGoogleChartEvent } from "react-google-charts";
import { CHART_COLORS } from "../../constants/chart";
import { GOOGLE_CHART_VERSION } from "../../constants/chat";
import { usePurchaseAnalysisContext } from "../../contexts/PurchaseAnalysisContext";
import { useCookiesLive } from "../../hooks";
import { useSalesByLocation } from "../../hooks/api/queries";
import { PurchaseAnalysisChartOption1 } from "../../types";
import { formatCurrency } from "../../utils/currency";
import { getAppLink } from "../../utils/link";
import ChartDateFilterButton from "./ChartDateFilterButton";
import ChartWidget from "./ChartWidget";

const SpendByLocation = () => {
  const [accountCode] = useCookiesLive(["accountCode"]);
  const { config } = usePurchaseAnalysisContext();
  const chartConfig =
    config.spendByLocation as PurchaseAnalysisChartOption1 | null;
  const { data, isFetching } = useSalesByLocation(
    chartConfig?.startDate,
    chartConfig?.endDate,
    accountCode,
  );
  const chartConfigRef = useRef<PurchaseAnalysisChartOption1 | null>(null);

  useEffect(() => {
    chartConfigRef.current = chartConfig;
  }, [chartConfig]);

  const oldChartData = useMemo(
    () => [
      ["Company", "Sales"],
      ...(data?.map((item: any) => [
        item.compName
          ? `${item.compName} - ${item.shipCity}, ${item.shipState} ${item.shipZip} | ${item.shipNo}`
          : "All Others",
        { f: formatCurrency(item.lastYear, 0), v: item.lastYear },
      ]) ?? []),
    ],
    [data],
  );

  const newChartData = useMemo(
    () => [
      ["Company", "Sales"],
      ...(data?.map((item: any) => [
        item.compName
          ? `${item.compName} - ${item.shipCity}, ${item.shipState} ${item.shipZip} | ${item.shipNo}`
          : "All Others",
        { f: formatCurrency(item.thisYear, 0), v: item.thisYear },
      ]) ?? []),
    ],
    [data],
  );

  const chartEvents: ReactGoogleChartEvent[] = [
    {
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper?.getChart();
        const selection = chart?.getSelection();

        if (selection && selection.length === 1) {
          const [selectedItem] = selection;
          const dataTable = chartWrapper?.getDataTable();
          const { row, column } = selectedItem;
          const value = dataTable?.getValue(row ?? 0, column ?? 0) as string;

          if (value && value !== "All Others") {
            const searchParams = new URLSearchParams();
            searchParams.set(
              "params",
              JSON.stringify({
                d: 1,
                disp: [
                  "reserved_select_row",
                  "invoiceNum",
                  "custPo",
                  "invoiceDate",
                  "orderNum",
                  "reference",
                  "subtotal",
                  "shipAndHand",
                  "salesTax",
                  "fees",
                  "total",
                  "balance",
                  "shipToId",
                  "address",
                  "city",
                  "state",
                  "zip",
                  "reserved_actions",
                ],
                fs: {
                  invoiceDate: {
                    end: chartConfigRef.current?.endDate,
                    start: chartConfigRef.current?.startDate,
                  },
                  shipToId: [value.split("|")[1].trim()],
                },
                fs_clear: false,
                o: "invoiceNum",
                p: "1",
                r: "50",
              }),
            );
            window.location.href = getAppLink(
              `app/Invoice?${searchParams.toString()}`,
            );
          }
        }
      },
      eventName: "select",
    },
  ];

  return (
    <ChartWidget
      actions={<ChartDateFilterButton configKey="spendByLocation" />}
      hasNoData={!isFetching && !data?.length}
      id="spendByLocation"
      isLoading={isFetching}
      title="Spend By Location"
    >
      <Chart
        chartEvents={chartEvents}
        chartType="PieChart"
        chartVersion={GOOGLE_CHART_VERSION}
        diffdata={{
          new: newChartData,
          old: oldChartData,
        }}
        graphID="spendByLocation"
        height="100%"
        options={{
          colors: CHART_COLORS,
          legend: {
            pagingTextStyle: {
              color: "#2481C8",
            },
            scrollArrows: {
              activeColor: "#28A2E1",
              cursor: "pointer",
              inactiveColor: "#CCE3E3",
            },
          },
          pieSliceText: "none",
          responsive: true,
        }}
        width="100%"
      />
    </ChartWidget>
  );
};
export default SpendByLocation;
