import { useState } from "react";
import { EmailIcon } from "../icons";
import ExportListingEmail from "../modals/ExportListingEmail";
import { LoadingButton } from "../ui";

const EmailExportButton = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <LoadingButton
        data-testid="dt-controls-email-export"
        variant="outlined"
        onClick={() => setIsOpen(true)}
      >
        <EmailIcon sx={{ width: 16 }} />
      </LoadingButton>
      {isOpen && (
        <ExportListingEmail isOpen={isOpen} onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};

export default EmailExportButton;
