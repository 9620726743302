import { Box, Menu, MenuItem } from "@mui/material";
import download from "downloadjs";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { useMemo } from "react";
import { ViewRes } from "../../api";
import {
  EXPORT_LISTING_CONTENT_TYPES,
  EXPORT_LISTING_FILE_EXTENSIONS,
  EXPORT_LISTING_LIMITATIONS,
} from "../../constants/exportListings";
import { useCookiesLive } from "../../hooks";
import { useExportListing } from "../../hooks/api/mutations/useExportListing";
import { ExportListingType } from "../../types";
import {
  DownloadIcon,
  FileCsvIcon,
  FileExcelIcon,
  FilePdfIcon,
  FileTxtIcon,
} from "../icons";
import { LoadingButton } from "../ui";
import { useDataTableContext } from "./DataTableContext";

const ExportButton = ({
  totalElements,
  activeView,
}: {
  totalElements: number;
  activeView?: ViewRes;
}) => {
  const [accountCode] = useCookiesLive(["accountCode"]);
  const { listingName, criteria, additionalColumnId, additionalColumnState } =
    useDataTableContext();
  const { mutate: exportListing, isLoading } = useExportListing();
  const fileName = useMemo(
    () =>
      `${activeView ? activeView.name : listingName}${
        accountCode ? " - " + accountCode : ""
      }`,
    [activeView, listingName, accountCode],
  );
  const visibleAdditionalColumnId = useMemo(
    () =>
      additionalColumnId && additionalColumnState === "subRow"
        ? additionalColumnId
        : null,
    [additionalColumnId, additionalColumnState],
  );
  const formattedCriteria = useMemo(
    () => ({
      ...criteria,
      fields: [
        ...(criteria.fields ?? []).filter(
          (columnId) => columnId !== visibleAdditionalColumnId,
        ),
        ...(visibleAdditionalColumnId ? [visibleAdditionalColumnId] : []),
      ],
    }),
    [criteria, visibleAdditionalColumnId],
  );

  const handleExportExcel = async (exportListingType: ExportListingType) => {
    exportListing(
      {
        criteria: JSON.stringify(formattedCriteria),
        exportListingType,
        listingName: listingName ?? "",
      },
      {
        onSuccess: ({ data }) => {
          download(
            data,
            `${fileName}.${EXPORT_LISTING_FILE_EXTENSIONS[exportListingType]}`,
            EXPORT_LISTING_CONTENT_TYPES[exportListingType],
          );
        },
      },
    );
  };

  return (
    <PopupState popupId="export-list" variant="popover">
      {(popupState) => (
        <Box>
          <Box {...bindTrigger(popupState)}>
            <LoadingButton
              data-testid="dt-controls-download"
              loading={isLoading}
              variant="outlined"
            >
              <DownloadIcon sx={{ width: 16 }} />
            </LoadingButton>
          </Box>

          <Menu {...bindMenu(popupState)}>
            {EXPORT_LISTING_LIMITATIONS["excel"] > totalElements && (
              <MenuItem
                onClick={() => {
                  handleExportExcel("excel");
                  popupState.close();
                }}
              >
                <FileExcelIcon sx={{ mr: 1.5, width: 16 }} />
                <span>Excel</span>
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                handleExportExcel("comma-separated");
                popupState.close();
              }}
            >
              <FileCsvIcon sx={{ mr: 1.5, width: 16 }} />
              <span>Comma-separated</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleExportExcel("tab-separated");
                popupState.close();
              }}
            >
              <FileTxtIcon sx={{ mr: 1.5, width: 16 }} />
              <span>Tab-separated</span>
            </MenuItem>
            {EXPORT_LISTING_LIMITATIONS["pdf"] > totalElements && (
              <MenuItem
                onClick={() => {
                  handleExportExcel("pdf");
                  popupState.close();
                }}
              >
                <FilePdfIcon sx={{ mr: 1.5, width: 16 }} />
                <span>PDF</span>
              </MenuItem>
            )}
          </Menu>
        </Box>
      )}
    </PopupState>
  );
};

export default ExportButton;
