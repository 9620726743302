import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useSnackbar } from "notistack";
import { ClientListing } from "../../api";
import { useClientResourceDelete } from "../../hooks/api/mutations/useClientResourceDelete";
import { Button, IconButton, LoadingButton } from "../ui";

const DeleteCrmClientConfirmModal = ({
  crmClient,
  onClose,
}: {
  crmClient: ClientListing;
  onClose: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteCrmClient, isLoading } = useClientResourceDelete();

  const handleClickConfirm = () => {
    deleteCrmClient(crmClient.clientId!, {
      onError: (error) => {
        enqueueSnackbar({
          message: error.message
            ? error.message
            : `Unable to delete CRM Client ${crmClient.crmClientCode}.`,
          variant: "error",
        });
      },
      onSuccess: () => {
        enqueueSnackbar({
          message: `CRM Client ${crmClient.crmClientCode} successfully deleted.`,
          variant: "success",
        });
        onClose();
      },
    });
  };

  return (
    <Dialog
      data-testid="delete-crm-client-confirmation-modal"
      maxWidth="xs"
      open={true}
      onClose={onClose}
    >
      <DialogTitle
        data-testid="delete-crm-client-confirmation-modal-title"
        sx={(theme) => ({
          fontSize: theme.spacing(2.25),
          m: 0,
          pb: 0,
          pt: 3.5,
          px: 2,
        })}
      >
        Are you sure you want to delete CRM Client {crmClient.crmClientCode}?
      </DialogTitle>
      <IconButton
        disabled={isLoading}
        sx={{
          color: (theme) => theme.palette.grey[900],
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pb: 2,
          px: 3,
        }}
      >
        <Button
          data-testid="crm-client-cancel-delete-button"
          disabled={isLoading}
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          color="error"
          data-testid="crm-client-delete-button"
          loading={isLoading}
          variant="outlined"
          onClick={handleClickConfirm}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCrmClientConfirmModal;
