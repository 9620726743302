import CheckIcon from "@mui/icons-material/Check";
import { Box, Typography } from "@mui/material";
import { sumBy } from "lodash";
import { useMemo } from "react";
import { useCookiesLive } from "../../hooks";
import { useProductAvailabilityPricing } from "../../hooks/api/queries";
import { ExclamationIcon } from "../icons";

export const SearchResultEntityInStockStatus = ({
  manufacturerProductId,
}: {
  manufacturerProductId?: number;
}) => {
  const [accountCode] = useCookiesLive(["accountCode"]);

  const { data: pricing, isLoading: isLoadingPricing } =
    useProductAvailabilityPricing(accountCode, manufacturerProductId);

  const inStock = useMemo(() => {
    let hasValidNumber = false;

    const totalQuantity = sumBy(pricing ?? [], (item) => {
      const sum = sumBy(item.warehouseAvailability, (wa) => {
        if (typeof wa.quantity === "number") {
          hasValidNumber = true;
          return wa.quantity;
        } else {
          return 0;
        }
      });
      return sum;
    });

    if (hasValidNumber) {
      return totalQuantity > 0;
    }

    return (pricing ?? []).some(({ availability }) => availability);
  }, [pricing]);

  if (!accountCode || (!isLoadingPricing && pricing === undefined)) {
    return null;
  }

  return (
    <Box
      data-testid="sr-result-in-stock-availability"
      sx={(theme) => ({
        alignItems: "center",
        display: "flex",
        justifyContent: "start",
        width: 150,
      })}
    >
      {inStock ? (
        <CheckIcon
          sx={(theme) => ({
            color: inStock ? theme.palette.green["200"] : "#D93232",
            fontSize: 10,
            mr: theme.spacing(0.5),
          })}
        />
      ) : (
        <ExclamationIcon
          sx={(theme) => ({
            color: inStock ? theme.palette.green["200"] : "#D93232",
            fontSize: 10,
            mr: theme.spacing(0.5),
          })}
        />
      )}
      <Typography
        data-testid="sr-result-entity-in-stock-availability-text"
        sx={(theme) => ({
          color: inStock ? theme.palette.green["200"] : "#D93232",
          fontSize: 14,
        })}
      >
        {inStock ? "In Stock" : "Backordered"}
      </Typography>
    </Box>
  );
};
