import download from "downloadjs";
import { useEffect } from "react";
import { useOrderExport } from "../../hooks/api/mutations";

const ExportOrderWrapper = () => {
  const { mutate: exportOrder } = useOrderExport();

  useEffect(() => {
    const handleExportOrderEvent = (event: Event) => {
      const orderId = (event as CustomEvent).detail;
      if (orderId) {
        exportOrder(orderId, {
          onSuccess: ({ data }) => {
            download(data, `Order#${orderId}.pdf`, "application/pdf");
          },
        });
      }
    };

    document.addEventListener("exportOrder", handleExportOrderEvent);

    return () =>
      document.removeEventListener("exportOrder", handleExportOrderEvent);
  }, [exportOrder]);

  return null;
};

export default ExportOrderWrapper;
