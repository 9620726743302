import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import download from "downloadjs";
import { chunk } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { DownloadIcon, EmailIcon } from "../../components/icons/";
import EmailDetailModal from "../../components/modals/EmailDetailModal";
import { Button, LoadingButton } from "../../components/ui";
import { useInvoiceEmail, useInvoiceExport } from "../../hooks/api/mutations";
import { useInvoice } from "../../hooks/api/queries";
import usePageTitle from "../../hooks/store/usePageTitle";
import { formatCurrency } from "../../utils/currency";
import { getFormattedDate } from "../../utils/datetime";

const InfoPanelBox = styled(Box)(({ theme }) => ({
  borderColor: theme.palette.grey["400"],
  borderRadius: 3,
  borderWidth: 1,
  boxShadow: "0px 2px 4px rgba(227,227,227, 0.5)",
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  paddingTop: theme.spacing(1.5),
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey["50"],
  fontSize: 14,
  fontWeight: "medium",
  py: theme.spacing(1.75),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  lineHeight: theme.spacing(3),
  verticalAlign: "top",
}));

const InvoiceDetail = () => {
  const [, setPageTitle] = usePageTitle();
  const [isEmailModalOpened, setIsEmailModalOpened] = useState(false);
  const { invoiceId } = useParams();
  const invoiceNum = invoiceId ? +invoiceId : undefined;
  const { data: invoiceData } = useInvoice(invoiceNum);
  const { mutate: exportInvoice, isLoading } = useInvoiceExport();

  const onExport = useCallback(() => {
    if (invoiceNum) {
      exportInvoice(invoiceNum, {
        onSuccess: ({ data }) => {
          download(data, `Invoice#${invoiceNum}.pdf`, "application/pdf");
        },
      });
    }
  }, [exportInvoice, invoiceNum]);

  useEffect(() => {
    setPageTitle(
      <Box
        sx={{
          "& .MuiTypography-body1": {
            fontSize: 20,
          },
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography>
          Invoice #{invoiceData?.invoiceHeader?.invoiceNum}
        </Typography>
        {invoiceData?.invoiceHeader?.invoicePrev ? (
          <Typography>
            (Original Invoice #
            <Typography
              component="span"
              sx={(theme) => ({ color: theme.palette.blue["600"] })}
            >
              {invoiceData?.invoiceHeader?.invoicePrev}
            </Typography>{" "}
            | Order #
            <Typography
              component="span"
              sx={(theme) => ({ color: theme.palette.blue["600"] })}
            >
              {invoiceData?.invoiceHeader?.orderNum}
            </Typography>
            )
          </Typography>
        ) : (
          <Typography>
            (Order #
            <Typography
              component="span"
              sx={(theme) => ({ color: theme.palette.blue["600"] })}
            >
              {invoiceData?.invoiceHeader?.orderNum}
            </Typography>
            )
          </Typography>
        )}
      </Box>,
    );
  }, [invoiceData, setPageTitle]);

  return (
    <>
      <Box
        sx={(theme) => ({
          color: theme.palette.grey["700"],
        })}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            gap: 2,
            justifyContent: "end",
            pr: theme.spacing(1.75),
          })}
        >
          <Button
            aria-label="Email"
            data-testid="invoice-email-button"
            sx={{ minWidth: 58 }}
            variant="outlined"
            onClick={() => setIsEmailModalOpened(true)}
          >
            <EmailIcon sx={{ fontSize: 14 }} />
          </Button>
          <LoadingButton
            aria-label="Download"
            data-testid="invoice-download-button"
            loading={isLoading}
            sx={{ minWidth: 58 }}
            variant="outlined"
            onClick={onExport}
          >
            <DownloadIcon sx={{ fontSize: 14 }} />
          </LoadingButton>
        </Box>
        <Box display="flex" gap={4} mt={4}>
          <Box display="flex" flex="1 1 auto" flexDirection="column" gap={4}>
            <Box display="flex" gap={4}>
              <InfoPanelBox sx={{ maxWidth: "35rem", width: "100%" }}>
                <Typography sx={{ fontSize: 18, fontWeight: "medium", mb: 2 }}>
                  Billing Address
                </Typography>
                <Box
                  sx={(theme) => ({
                    "& .MuiTypography-root": {
                      lineHeight: theme.spacing(3),
                    },
                  })}
                >
                  <Typography>
                    Attention: {invoiceData?.invoiceHeader?.atten}
                  </Typography>
                  <Typography>
                    {invoiceData?.invoiceHeader?.compname}
                  </Typography>
                  <Typography>{invoiceData?.invoiceHeader?.address}</Typography>
                  {invoiceData?.invoiceHeader?.address2 && (
                    <Typography>
                      {invoiceData?.invoiceHeader?.address2}
                    </Typography>
                  )}
                  <Typography>
                    {invoiceData?.invoiceHeader?.city},{" "}
                    {invoiceData?.invoiceHeader?.state}{" "}
                    {invoiceData?.invoiceHeader?.zip}
                  </Typography>
                </Box>
              </InfoPanelBox>

              <InfoPanelBox sx={{ maxWidth: "35rem", width: "100%" }}>
                <Typography sx={{ fontSize: 18, fontWeight: "medium", mb: 2 }}>
                  Shipping Address
                </Typography>
                <Box
                  sx={(theme) => ({
                    "& .MuiTypography-root": {
                      lineHeight: theme.spacing(3),
                    },
                  })}
                >
                  <Typography>
                    Attention: {invoiceData?.invoiceHeader?.shipAttn}
                  </Typography>
                  <Typography>{invoiceData?.invoiceHeader?.shipto}</Typography>
                  <Typography>
                    {invoiceData?.invoiceHeader?.shipaddr}
                  </Typography>
                  {invoiceData?.invoiceHeader?.shipaddr2 && (
                    <Typography>
                      {invoiceData?.invoiceHeader?.shipaddr2}
                    </Typography>
                  )}
                  <Typography>
                    {invoiceData?.invoiceHeader?.shipcity},{" "}
                    {invoiceData?.invoiceHeader?.shipstat}{" "}
                    {invoiceData?.invoiceHeader?.shipzip}
                  </Typography>
                </Box>
              </InfoPanelBox>
            </Box>

            <Box display="flex" gap={4}>
              <InfoPanelBox sx={{ maxWidth: "35rem", width: "100%" }}>
                <Box
                  sx={(theme) => ({
                    "& .MuiTypography-root": {
                      lineHeight: theme.spacing(3),
                    },
                  })}
                >
                  <Typography>
                    <Typography component="span" sx={{ fontWeight: "medium" }}>
                      {invoiceData?._labels?.custPo}:{" "}
                    </Typography>
                    {invoiceData?.invoiceHeader?.custPo}
                  </Typography>
                  <Typography>
                    <Typography component="span" sx={{ fontWeight: "medium" }}>
                      Payment Terms:{" "}
                    </Typography>
                    {invoiceData?.invoiceHeader?.terms}
                  </Typography>
                  <Typography>
                    <Typography component="span" sx={{ fontWeight: "medium" }}>
                      Shipper:{" "}
                    </Typography>
                    {invoiceData?.orderHeader?.cardesc}
                  </Typography>
                </Box>
              </InfoPanelBox>

              <InfoPanelBox
                sx={(theme) => ({
                  "& .MuiTypography-root": {
                    lineHeight: theme.spacing(3),
                  },
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                })}
              >
                <Box>
                  <Typography>
                    <Typography component="span" sx={{ fontWeight: "medium" }}>
                      {invoiceData?._labels?.project}:{" "}
                    </Typography>
                    {invoiceData?.orderHeader?.project}
                  </Typography>
                  <Typography>
                    <Typography component="span" sx={{ fontWeight: "medium" }}>
                      {invoiceData?._labels?.refNum}:{" "}
                    </Typography>
                    {invoiceData?.orderHeader?.refNum}
                  </Typography>
                  <Typography>
                    <Typography component="span" sx={{ fontWeight: "medium" }}>
                      {invoiceData?._labels?.costCtr}:{" "}
                    </Typography>
                    {invoiceData?.orderHeader?.costCtr}
                  </Typography>
                </Box>

                {chunk(invoiceData?.customOrderHeaders, 3).map((cog, index) => (
                  <Box key={index}>
                    {cog.map((field) => (
                      <Typography key={field.name}>
                        <Typography
                          component="span"
                          sx={{ fontWeight: "medium" }}
                        >
                          {field.fieldLabel}:{" "}
                        </Typography>
                        {field.fieldValue}
                      </Typography>
                    ))}
                  </Box>
                ))}
              </InfoPanelBox>
            </Box>
          </Box>
        </Box>

        <InfoPanelBox mt={4}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableHeadCell>LINE #</StyledTableHeadCell>
                <StyledTableHeadCell>ITEM</StyledTableHeadCell>
                <StyledTableHeadCell>QTY</StyledTableHeadCell>
                <StyledTableHeadCell>UNIT PRICE</StyledTableHeadCell>
                <StyledTableHeadCell>TOTAL PRICE</StyledTableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceData?.invoiceLines?.map((invoiceLine, index) => (
                <TableRow key={invoiceLine.invoiceLineId}>
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell>
                    <Typography>
                      {invoiceLine.mfgName} {invoiceLine.mfgPart}
                    </Typography>
                    <Typography>
                      {invoiceLine.suppCode} / {invoiceLine.suppPart}
                    </Typography>
                    <Typography
                      sx={(theme) => ({ color: theme.palette.blue["600"] })}
                    >
                      {invoiceLine.prodDesc}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box alignItems="center" display="inline-flex" gap={1}>
                      {invoiceLine.qtyShipped}
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatCurrency(invoiceLine.memberPrice)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatCurrency(
                      (invoiceLine.qtyShipped ?? 0) *
                        (invoiceLine.memberPrice ?? 0),
                    )}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Box display="flex" mt={3}>
            <Box
              sx={(theme) => ({
                "& .MuiTypography-root": {
                  fontSize: 14,
                  lineHeight: theme.spacing(3),
                },
                flexGrow: 1,
                textAlign: "right",
              })}
            >
              <Typography sx={{ pr: 5 }}>Subtotal:</Typography>
              <Typography sx={{ pr: 5 }}>Freight:</Typography>
              <Typography sx={{ pr: 5 }}>Tax:</Typography>
              <Typography sx={{ pr: 5 }}>Total:</Typography>
              {invoiceData?.invoiceHeader?.balance ? (
                <>
                  <Typography
                    sx={(theme) => ({
                      backgroundColor: theme.palette.grey["50"],
                      fontSize: 16,
                      fontWeight: 600,
                      mt: 3,
                      pb: 0,
                      pr: 5,
                      pt: 2,
                    })}
                  >
                    Total Due:
                  </Typography>
                  <Typography
                    sx={(theme) => ({
                      backgroundColor: theme.palette.grey["50"],
                      color: theme.palette.red["500"],
                      fontSize: 16,
                      fontWeight: 600,
                      pb: 2,
                      pr: 5,
                      pt: 0,
                    })}
                  >
                    Due Date:
                  </Typography>
                </>
              ) : null}
            </Box>

            <Box
              sx={(theme) => ({
                "& .MuiTypography-root": {
                  fontSize: 14,
                  lineHeight: theme.spacing(3),
                },
                minWidth: theme.spacing(12),
              })}
            >
              <Typography>
                {formatCurrency(invoiceData?.invoiceHeader?.subtotal)}
              </Typography>
              <Typography>
                {formatCurrency(invoiceData?.invoiceHeader?.freight)}
              </Typography>
              <Typography>
                {formatCurrency(invoiceData?.invoiceHeader?.salesTax)}
              </Typography>
              <Typography>
                {formatCurrency(
                  (invoiceData?.invoiceHeader?.subtotal ?? 0) +
                    (invoiceData?.invoiceHeader?.freight ?? 0) +
                    (invoiceData?.invoiceHeader?.salesTax ?? 0),
                )}
              </Typography>
              {invoiceData?.invoiceHeader?.balance ? (
                <>
                  <Typography
                    sx={(theme) => ({
                      backgroundColor: theme.palette.grey["50"],
                      fontSize: 16,
                      fontWeight: 600,
                      mt: 3,
                      pb: 0,
                      pt: 2,
                    })}
                  >
                    {formatCurrency(invoiceData?.invoiceHeader?.balance)}
                  </Typography>
                  <Typography
                    sx={(theme) => ({
                      backgroundColor: theme.palette.grey["50"],
                      color: theme.palette.red["500"],
                      fontSize: 16,
                      fontWeight: 600,
                      pb: 2,
                      pt: 0,
                    })}
                  >
                    {getFormattedDate(invoiceData.dueDate)}
                  </Typography>
                </>
              ) : null}
            </Box>
          </Box>
        </InfoPanelBox>

        <Box display="flex" gap={4} mt={5}>
          <InfoPanelBox
            sx={(theme) => ({ flexGrow: 1, minWidth: theme.spacing(22) })}
          >
            <Typography sx={{ fontSize: "md", fontWeight: "medium", mb: 2 }}>
              {invoiceData?._labels?.configNotes}
            </Typography>
            <Typography>
              {invoiceData?.orderHeader?.configNotes1}
              {invoiceData?.orderHeader?.configNotes2}
              {invoiceData?.orderHeader?.configNotes3}
            </Typography>
          </InfoPanelBox>
          <InfoPanelBox flexGrow={1}>
            <Typography sx={{ fontSize: "md", fontWeight: "medium", mb: 2 }}>
              {invoiceData?._labels?.instructions}
            </Typography>
            <Typography>
              {invoiceData?.orderHeader?.instr1}
              {invoiceData?.orderHeader?.instr2}
            </Typography>
          </InfoPanelBox>
          <InfoPanelBox flexGrow={1}>
            <Typography sx={{ fontSize: "md", fontWeight: "medium", mb: 2 }}>
              Internal Notes
            </Typography>
            <Typography>
              {invoiceData?.orderHeader?.orderNotes1}
              {invoiceData?.orderHeader?.orderNotes2}
              {invoiceData?.orderHeader?.orderNotes3}
            </Typography>
          </InfoPanelBox>
        </Box>
      </Box>

      {invoiceNum && (
        <EmailDetailModal
          accountCodes={[invoiceData?.invoiceHeader?.accountCode]}
          idsWithAccounts={[
            {
              accountCode: invoiceData?.invoiceHeader?.accountCode,
              id: invoiceNum,
            },
          ]}
          isOpen={isEmailModalOpened}
          typeLabel="Invoice"
          useSendEmailHook={useInvoiceEmail}
          onClose={() => setIsEmailModalOpened(false)}
        />
      )}
    </>
  );
};

export default memo(InvoiceDetail);
