import { useCallback, useMemo, useState } from "react";
import { ManufacturerProductSolrDto } from "../../api";
import ImportTaxExemptions from "../../components/ImportTaxExemptions";
import ListingPage from "../../components/ListingPage";
import EditEolDate from "../../components/modals/EditEolDate";
import SkuAndLicenseProgram from "../../components/modals/SkuAndLicenseProgram";
import { withPageTitle } from "../../hocs";
import { useUserRights } from "../../hooks/api/queries";
import { ActionColumnItem, ActionRowItem, UserPermission } from "../../types";
import { hasPermission } from "../../utils/user";

enum ActionModalType {
  IMPORT_TAX_EXEMPTIONS,
}

const ProductsReport = () => {
  const { data: userRights } = useUserRights();
  const [selectedActionModal, setSelectedActionModal] =
    useState<ActionModalType>();
  const [selectedProduct, setSelectedProduct] =
    useState<ManufacturerProductSolrDto>();
  const canUserManageCatalog = hasPermission(
    userRights,
    UserPermission.MANAGE_CATALOG_USERS,
  );
  const canUserManageTaxExemptions = hasPermission(
    userRights,
    UserPermission.MANAGE_TAX_EXEMPTION_USERS,
  );
  const [eolDateEditingProduct, setEolDateEditingProduct] =
    useState<ManufacturerProductSolrDto>();
  const actionItems: ActionColumnItem<ManufacturerProductSolrDto>[] = useMemo(
    () => [
      {
        isVisible: () => canUserManageCatalog,
        label: "Edit E1 SKU and License Program",
        onClick: setSelectedProduct,
      },
      {
        isVisible: () => canUserManageCatalog,
        label: "Edit EOL Date",
        onClick: setEolDateEditingProduct,
      },
    ],
    [canUserManageCatalog],
  );
  const handleCloseModal = useCallback(() => {
    setSelectedActionModal(undefined);
  }, []);
  const actionRowItems: ActionRowItem[] = useMemo(
    () => [
      {
        isVisible: canUserManageTaxExemptions,
        label: "Manage Tax Exemptions",
        onClick: () =>
          setSelectedActionModal(ActionModalType.IMPORT_TAX_EXEMPTIONS),
      },
    ],
    [canUserManageTaxExemptions],
  );

  return (
    <>
      <ListingPage
        actionColumnItems={actionItems}
        actionRowItems={actionRowItems}
        additionalColumnId="description"
        identifier="manufacturerProductId"
        listingName="product"
      />
      {selectedProduct && (
        <SkuAndLicenseProgram
          isOpen
          product={selectedProduct}
          onClose={() => setSelectedProduct(undefined)}
        />
      )}
      {eolDateEditingProduct && (
        <EditEolDate
          isOpen
          product={eolDateEditingProduct}
          onClose={() => setEolDateEditingProduct(undefined)}
        />
      )}
      {selectedActionModal === ActionModalType.IMPORT_TAX_EXEMPTIONS && (
        <ImportTaxExemptions onClose={handleCloseModal} />
      )}
    </>
  );
};

export default withPageTitle("Products Report", ProductsReport);
