import { GlobalStyles } from "@mui/material";

export default (
  <GlobalStyles
    styles={{
      ".MuiBox-root": {
        borderStyle: "solid",
        borderWidth: 0,
      },
      ".react-datepicker__day--selected": {
        backgroundColor: "#2FA3DE",
      },
      body: {
        margin: 0,
      },
      "ul.szh-menu": {
        zIndex: 10000,
      },
    }}
  />
);
