/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SearchSuggestionDto } from '../models/SearchSuggestionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SearchSuggestionsResourceService {
  /**
   * @deprecated
   * Search autocomplete
   * Search Autocomplete
   * @param accountCode accountCode
   * @param entities entities
   * @param showOffCatalog showOffCatalog
   * @param showZeroPrice showZeroPrice
   * @param terms terms
   * @returns SearchSuggestionDto Success
   * @throws ApiError
   */
  public static listUsingGet19(
    accountCode?: string,
    entities?: string,
    showOffCatalog: number = -1,
    showZeroPrice: string = 'true',
    terms?: string,
  ): CancelablePromise<Array<SearchSuggestionDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/search/autocomplete',
      query: {
        'accountCode': accountCode,
        'entities': entities,
        'showOffCatalog': showOffCatalog,
        'showZeroPrice': showZeroPrice,
        'terms': terms,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Search Term suggest
   * Search term Suggest
   * @param accountCode accountCode
   * @param limit limit
   * @param terms terms
   * @param type type
   * @returns SearchSuggestionDto Success
   * @throws ApiError
   */
  public static listUsingGet20(
    accountCode?: string,
    limit: number = 10,
    terms?: string,
    type: 'all' | 'assets' | 'keywords' | 'products' | 'supplierParts' = 'all',
  ): CancelablePromise<Array<SearchSuggestionDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/search/suggest',
      query: {
        'accountCode': accountCode,
        'limit': limit,
        'terms': terms,
        'type': type,
      },
      errors: {
        400: `Invalid request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `There was an error getting the search results`,
      },
    });
  }
}
