import { Typography, Skeleton, Box } from "@mui/material";
import { get } from "lodash";
import { useMemo } from "react";
import { useCookiesLive } from "../../hooks";
import { useProductAvailabilityPricing } from "../../hooks/api/queries";
import { convertNumberToCurrencyFormat } from "../../utils/number";

export const SearchResultEntityPrice = ({
  manufacturerProductId,
}: {
  manufacturerProductId?: number;
}) => {
  const [accountCode] = useCookiesLive(["accountCode"]);

  const { data: pricing, isLoading: isLoadingPricing } =
    useProductAvailabilityPricing(accountCode, manufacturerProductId);

  const price = useMemo<number | undefined>(
    () => get(pricing, "[0].price"),
    [pricing],
  );

  if (!accountCode || (!isLoadingPricing && typeof price !== "number")) {
    return null;
  }

  return (
    <Box data-testid="sr-result-entity-price-wrapper">
      <Typography
        sx={(theme) => ({
          color: theme.palette.grey["500"],
          fontSize: 12,
        })}
      >
        Price starting at
      </Typography>
      {isLoadingPricing ? (
        <Skeleton
          data-testid="sr-result-entity-price-loading-skeleton"
          height="36px"
          variant="text"
        />
      ) : (
        <Typography
          data-testid="sr-result-entity-price-text"
          sx={(theme) => ({
            color: theme.palette.grey["700"],
            fontSize: 22,
            fontWeight: 500,
          })}
        >
          {typeof price === "number"
            ? `${convertNumberToCurrencyFormat(price)}`
            : "-"}
        </Typography>
      )}
    </Box>
  );
};
