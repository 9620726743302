import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { Column } from "@tanstack/react-table";
import { kebabCase } from "lodash";
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AdditionalColumnState } from "../../types";
import { useDataTableContext } from "../DataTable/DataTableContext";
import SeachResultText from "../SearchResultText";
import { MinusSquareIcon, PlusSquareIcon } from "../icons";
import { Button, TextField } from "../ui";

type ColumnsState = { [id: string]: { name: string; isVisible: boolean } };

const SelectColumns = <TData,>({
  isOpen,
  columns,
  onClose,
}: {
  isOpen: boolean;
  columns: Column<TData>[];
  onClose: () => void;
}) => {
  const {
    updateColumns,
    additionalColumnState,
    setAdditionalColumnState,
    possibleAdditionalColumnId,
  } = useDataTableContext();

  const [columnsState, setColumnsState] = useState<ColumnsState>({});
  const [columnSearchValue, setColumnSearchValue] = useState("");
  const [additionalColumnExpanded, setAdditionalColumnExpanded] =
    useState(true);
  const [scopedAdditionalColumnState, setScopedAdditionalColumnState] =
    useState<AdditionalColumnState>(additionalColumnState);

  const columnsVisibility = useMemo(
    () =>
      columns.map((column) => ({
        element: column.id ?? "",
        label: column.columnDef.header as string,
        visible: column.getIsVisible(),
      })),
    [columns],
  );

  useEffect(() => {
    if (columnsVisibility) {
      setColumnsState(
        columnsVisibility.reduce<ColumnsState>((prev, next) => {
          prev[next.element] = {
            isVisible: next.visible,
            name: next.label,
          };
          return prev;
        }, {}),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(columnsVisibility)]);

  const updateColumnsState = useCallback(
    (columnId: string, value?: boolean) => {
      setColumnsState((prevState) => ({
        ...prevState,
        [columnId]: {
          ...prevState[columnId],
          isVisible:
            value !== undefined ? value : !prevState[columnId].isVisible,
        },
      }));
    },
    [],
  );

  const handleSubmit = useCallback(() => {
    for (const column of columns) {
      if (columnsState[column.id].isVisible !== column.getIsVisible()) {
        column.toggleVisibility(columnsState[column.id].isVisible);
      }
    }
    onClose();
    updateColumns(
      columns
        .filter((column) => columnsState[column.id].isVisible)
        .map((column) => column.id),
    );
    setAdditionalColumnState(scopedAdditionalColumnState);
  }, [
    columns,
    columnsState,
    onClose,
    updateColumns,
    scopedAdditionalColumnState,
    setAdditionalColumnState,
  ]);

  const handleAdditionalColumnChange = useCallback(
    (checked: boolean, columnState: AdditionalColumnState) => {
      setScopedAdditionalColumnState((prevState) => {
        const newColumnState =
          checked || prevState !== columnState ? columnState : "hidden";
        updateColumnsState(
          possibleAdditionalColumnId ?? "",
          newColumnState !== "hidden",
        );

        return newColumnState;
      });
    },
    [
      possibleAdditionalColumnId,
      updateColumnsState,
      setScopedAdditionalColumnState,
    ],
  );

  const filteredColumnState = useMemo(
    () =>
      Object.keys(columnsState)
        .filter(
          (columnId) =>
            columnsState[columnId].name.search(
              new RegExp(columnSearchValue, "i"),
            ) !== -1,
        )
        .reduce<ColumnsState>(
          (prev, cur) => ({
            ...prev,
            [cur]: columnsState[cur],
          }),
          {},
        ),
    [columnsState, columnSearchValue],
  );

  return (
    <Dialog
      fullWidth
      data-testid="select-columns-modal"
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle
        data-testid="select-columns-modal-title"
        sx={(theme) => ({
          fontSize: theme.spacing(2.25),
          m: 0,
          pb: 0,
          pt: 3.5,
          px: 2,
        })}
      >
        Select Columns
      </DialogTitle>
      <IconButton
        data-testid="select-columns-modal-close-icon"
        sx={{
          color: (theme) => theme.palette.grey[900],
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent data-testid="select-columns-modal-body">
        <TextField
          fullWidth
          InputProps={{
            startAdornment: (
              <SearchIcon
                sx={(theme) => ({ color: theme.palette.grey["300"] })}
              />
            ),
          }}
          data-testid="select-columns-search-input"
          placeholder="Search for Column(s)"
          sx={{
            mb: 2.5,
          }}
          value={columnSearchValue}
          onChange={(e) => setColumnSearchValue(e.target.value)}
        />
        <Typography fontWeight="600" variant="body1">
          Filter Column:
        </Typography>

        <Box
          sx={{
            alignItems: "stretch",
            display: "flex",
            flexDirection: "column",
            height: "60vh",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          {Object.keys(filteredColumnState).map((id) => (
            <Fragment key={id}>
              {id === possibleAdditionalColumnId ? (
                <Box
                  sx={{
                    alignItems: "stretch",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={(theme) => ({
                      alignItems: "center",
                      display: "flex",
                      gap: theme.spacing(1.5),
                      px: 0.125,
                    })}
                  >
                    <Box
                      data-testid="select-columns-modal-additional-row-check"
                      sx={{
                        cursor: "pointer",
                        display: "inline-flex",
                        py: 0.5,
                      }}
                      onClick={() =>
                        setAdditionalColumnExpanded((value) => !value)
                      }
                    >
                      {additionalColumnExpanded ? (
                        <MinusSquareIcon sx={{ width: 14 }} />
                      ) : (
                        <PlusSquareIcon sx={{ width: 14 }} />
                      )}
                    </Box>
                    <SeachResultText
                      searchValue={columnSearchValue}
                      text={filteredColumnState[id].name}
                    />
                  </Box>
                  {additionalColumnExpanded && (
                    <Box
                      sx={{
                        alignItems: "stretch",
                        display: "flex",
                        flexDirection: "column",
                        pl: 3,
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={scopedAdditionalColumnState === "subRow"}
                            size="small"
                            onChange={(e) =>
                              handleAdditionalColumnChange(
                                e.target.checked,
                                "subRow",
                              )
                            }
                          />
                        }
                        data-testid="select-columns-modal-additional-sub-row-check"
                        label="Presented as sub-row"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={scopedAdditionalColumnState === "column"}
                            size="small"
                            onChange={(e) =>
                              handleAdditionalColumnChange(
                                e.target.checked,
                                "column",
                              )
                            }
                          />
                        }
                        data-testid="select-columns-modal-additional-column-check"
                        label="Presented as column"
                      />
                    </Box>
                  )}
                </Box>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={filteredColumnState[id].isVisible}
                      size="small"
                      onChange={() => updateColumnsState(id)}
                    />
                  }
                  data-testid={`select-columns-modal-column-${kebabCase(
                    filteredColumnState[id].name,
                  )}`}
                  label={
                    <SeachResultText
                      searchValue={columnSearchValue}
                      text={filteredColumnState[id].name}
                    />
                  }
                />
              )}
            </Fragment>
          ))}
        </Box>
      </DialogContent>

      <DialogActions
        sx={{ justifyContent: "space-between", pb: 2, pt: 0, px: 2 }}
      >
        <Button
          data-testid="select-columns-modal-close-btn"
          sx={(theme) => ({ width: theme.spacing(12.5) })}
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          data-testid="select-columns-modal-save-btn"
          sx={(theme) => ({ width: theme.spacing(12.5) })}
          variant="contained"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default memo(SelectColumns) as typeof SelectColumns;
