import {
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Typography,
} from "@mui/material";
import { ManufacturerPartSearchResultDto } from "../../api";
import { BookmarkHeartIcon } from "../icons";
import { Button } from "../ui";
import { SearchResultEntityInStockStatus, SearchResultEntityPrice } from ".";

export const SearchResultEntity = ({
  entity,
}: {
  entity: ManufacturerPartSearchResultDto;
}) => {
  return (
    <Box
      data-testid="sr-result-entity"
      sx={(theme) => ({
        height: "100%",
        p: theme.spacing(2),
        width: "100%",
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: theme.spacing(2.5),
          justifyContent: "space-between",
          width: "100%",
        })}
      >
        <Box
          sx={(theme) => ({
            alignItems: "center",
            display: "flex",
            gap: theme.spacing(2),
            width: theme.spacing(85),
          })}
        >
          <FormControlLabel
            control={
              <Checkbox
                data-testid="sr-result-entity-compare-checkbox"
                size="small"
              />
            }
            label="Compare"
            slotProps={{
              typography: {
                sx: { fontSize: 14 },
              },
            }}
            sx={{ height: "20px" }}
          />
          <Box
            data-testid="sr-result-entity-save-button"
            sx={(theme) => ({
              alignItems: "center",
              cursor: "pointer",
              display: "flex",
              gap: theme.spacing(0.5),
            })}
          >
            <BookmarkHeartIcon sx={{ fontSize: 16 }} />
            <Typography sx={{ fontSize: 14 }}>Save</Typography>
          </Box>
        </Box>
        <SearchResultEntityInStockStatus
          manufacturerProductId={entity.manufacturerProductId}
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: theme.spacing(2.5),
          mt: theme.spacing(2),
          width: "100%",
        })}
      >
        <Box
          sx={(theme) => ({
            position: "relative",
            width: 170,
          })}
        >
          <img
            alt={entity.name}
            data-testid="sr-result-entity-image"
            src={entity.primaryImage}
            style={{
              height: "auto",
              maxHeight: "100%",
              maxWidth: "100%",
              width: "auto",
            }}
          />
        </Box>
        <Box flex={1}>
          <Link
            data-testid="sr-result-entity-name"
            href="#"
            sx={{ textDecoration: "none" }}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.blue["600"],
                fontSize: 18,
                fontWeight: 500,
              })}
            >
              {entity.name}
            </Typography>
          </Link>
          <Typography
            data-testid="sr-result-entity-description"
            sx={(theme) => ({
              color: theme.palette.grey["700"],
              fontSize: 14,
              fontWeight: 500,
              mt: theme.spacing(1),
            })}
          >
            Mfg. Part #: {entity.manufacturerPart}
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            ml: theme.spacing(2.5),
            width: 150,
          })}
        >
          <SearchResultEntityPrice
            manufacturerProductId={entity.manufacturerProductId}
          />
          <Box
            alignItems="start"
            color="gray.700"
            data-testid="sr-result-entity-shipt-status"
            display="flex"
          >
            {/* <TruckIcon h="12px" mr={2} mt={1} w="16px" /> */}
            <Typography sx={{ fontSize: 12 }}></Typography>
          </Box>
          <Button
            color="primary"
            data-testid="sr-result-entity-add-to-cart-button"
            sx={() => ({
              height: "35px",
            })}
            variant="contained"
          >
            Add to Cart
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
