import React from "react";
import { BrowserRouter } from "react-router-dom";
import PurchaseAnalysis from "../../pages/PurchaseAnalysis";

const PurchaseAnalysisWrapper = () => {
  return (
    <BrowserRouter>
      <PurchaseAnalysis />
    </BrowserRouter>
  );
};

export default PurchaseAnalysisWrapper;
