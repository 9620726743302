/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type RecentlyViewedRequestDto = {
  resourceId: number;
  type: RecentlyViewedRequestDto.type;
};
export namespace RecentlyViewedRequestDto {
  export enum type {
    BUNDLE = 'BUNDLE',
    PRODUCT = 'PRODUCT',
  }
}

