import { useQuery } from "@tanstack/react-query";
import { QuotesResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useQuote = (quoteNumber?: number) => {
  return useQuery(
    [QUERY_KEYS["quote"], quoteNumber],
    () => QuotesResourceService.getQuoteDetailsUsingGet(quoteNumber!),
    { enabled: !!quoteNumber },
  );
};
