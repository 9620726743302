import { createSvgIcon } from "@mui/material";

export const ExclamationIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 2 11" xmlns="http://www.w3.org/2000/svg">
    <title>exclamation</title>
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="#D93232"
        fillRule="nonzero"
        id="pg1"
        transform="translate(-1312, -550)"
      >
        <g id="Group-16" transform="translate(1312, 547)">
          <g id="exclamation-lg" transform="translate(0, 3)">
            <path
              d="M0.00501637938,1.09987492 C-0.0330561796,0.720578542 0.147827593,0.35262875 0.47142016,0.151126607 C0.795012728,-0.0503755357 1.20502003,-0.0503755357 1.5286126,0.151126607 C1.85220517,0.35262875 2.03308894,0.720578542 1.99501638,1.09987492 L1.60701638,7.44987492 C1.57596328,7.76182686 1.31351009,7.99945142 1.00001638,7.99945142 C0.686522668,7.99945142 0.424069476,7.76182686 0.393016379,7.44987492 L0.00501637938,1.09987492 Z M1.63793809e-05,9.99987492 C1.63793809e-05,9.44759017 0.44773163,8.99987492 1.00001638,8.99987492 C1.55230113,8.99987492 2.00001638,9.44759017 2.00001638,9.99987492 C2.00001638,10.5521597 1.55230113,10.9998749 1.00001638,10.9998749 C0.44773163,10.9998749 1.63793809e-05,10.5521597 1.63793809e-05,9.99987492 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "Exclamation",
);
