import { Box, Link, styled, Typography } from "@mui/material";
import { kebabCase } from "lodash";
import { memo } from "react";
import LogoSvg from "../../assets/svgs/logo.svg";
import useMenus from "../../hooks/store/useMenus";
import useQuickLinks from "../../hooks/store/useQuickLinks";
import { getAppLink } from "../../utils/link";
import AccountSelector from "../AccountSelector";
import NavigationMenu from "../NavigationMenu";
import ProductSearchBox from "../ProductSearchBox";
import ProfileMenu from "../ProfileMenu";
import SalesSummary from "../SalesSummary";
import { VCenterBox } from "../ui";

const MarketplaceLogoImg = styled("img")(({ theme: { spacing } }) => ({
  height: spacing(3),
}));

const Header = memo(() => {
  const menus = useMenus();
  const quickLinks = useQuickLinks();

  return (
    <Box data-testid="page-header-desktop">
      <VCenterBox
        sx={({ spacing }) => ({
          gap: spacing(2),
          height: spacing(14.75),
          justifyContent: "space-between",
          px: spacing(4),
        })}
      >
        <VCenterBox
          sx={(theme) => ({
            gap: theme.spacing(3),
            height: theme.spacing(14.75),
            width: "100%",
          })}
        >
          <AccountSelector />
          <ProductSearchBox />
          <Box sx={{ flexGrow: 1 }} />
          <ProfileMenu />
        </VCenterBox>
      </VCenterBox>

      <Box
        sx={({ spacing }) => ({
          display: "flex",
          gap: spacing(4),
          justifyContent: "flex-end",
          px: spacing(7),
          py: spacing(1),
        })}
      >
        {quickLinks.map((link) => (
          <Link
            key={link.name}
            data-testid={`quick-link-${kebabCase(link.name)}`}
            href={link.internal ? link.path : getAppLink(link.path ?? "/")}
            sx={(theme) => ({
              "&:hover": {
                color: theme.palette.blue["700"],
              },
              color: theme.palette.grey["900"],
            })}
            underline="none"
          >
            {link.name}
          </Link>
        ))}
      </Box>

      <VCenterBox
        justifyContent="space-between"
        sx={(theme) => ({
          background: theme.palette.grey["50"],
          border: `1px solid ${theme.palette.grey["100"]}`,
          px: theme.spacing(4),
        })}
      >
        <VCenterBox
          sx={(theme) => ({
            gap: theme.spacing(8),
          })}
        >
          <MarketplaceLogoImg data-testid="app-logo" src={LogoSvg} />

          <VCenterBox sx={{ gap: (theme) => theme.spacing(5) }}>
            {menus.map((item) =>
              item.children ? (
                <NavigationMenu
                  key={item.name}
                  buttonText={item.name}
                  items={item.children}
                />
              ) : (
                <Link
                  key={item.name}
                  data-testid="navigation-link-help"
                  href={item.path}
                  sx={(theme) => ({
                    "&:hover": {
                      borderBottomColor: theme.palette.blue["800"],
                    },
                    alignItems: "center",
                    borderBottom: "4px solid transparent",
                    borderTop: "4px solid transparent",
                    display: "flex",
                    height: theme.spacing(5.25),
                    px: 2,
                  })}
                  target="_blank"
                  underline="none"
                >
                  <Typography component="span" fontWeight={500} variant="body1">
                    Help
                  </Typography>
                </Link>
              ),
            )}
          </VCenterBox>
        </VCenterBox>

        <SalesSummary />
      </VCenterBox>
    </Box>
  );
});

export default Header;
