import { isEmpty } from "lodash";
import { enqueueSnackbar } from "notistack";
import {
  OrderLineSolrDto,
  RmaRequestDto,
  type RmaResponseDto,
} from "../../../api";
import {
  useReturnCreate,
  useReturnNoteCreate,
} from "../../../hooks/api/mutations";
import BasicDeviceInformation from "./BasicDeviceInformation";
import ContactDetails from "./ContactDetails";
import ReasonAndCondition from "./ReasonAndCondition";
import { RmaContextProvider, useRmaContext } from "./RmaContext";

enum RmaRequestStep {
  BasicDeviceInformation = "BasicDeviceInformation",
  ReasonAndCondition = "ReasonAndCondition",
  ContactDetails = "ContactDetails",
}

const RmaRequestSteps = () => {
  const { step, setStep, onClose, numberToReturn } = useRmaContext();
  const { mutate: submitReturn } = useReturnCreate();
  const { mutateAsync: createReturnNote } = useReturnNoteCreate();

  const handleSubmitRequest = async (
    returnPayload: RmaRequestDto,
    notes: string[],
  ) => {
    let returnId: number;

    submitReturn(returnPayload, {
      onError: () => {
        enqueueSnackbar({
          message: `The return request was unsuccessful.`,
          variant: "error",
        });
      },
      onSuccess: (response: RmaResponseDto) => {
        returnId = response.returnId as number;

        const promises = notes
          .filter((note) => !isEmpty(note))
          .map((note) =>
            createReturnNote({
              note: {
                note: note,
              },
              returnId: returnId,
            }),
          );

        Promise.allSettled(promises).then(() => {
          enqueueSnackbar({
            message: `${numberToReturn} item(s) submitted for return successfully.`,
            variant: "success",
          });
          onClose();
        });
      },
    });
  };

  return (
    <>
      {step === RmaRequestStep.BasicDeviceInformation && (
        <BasicDeviceInformation
          onNext={() => setStep(RmaRequestStep.ReasonAndCondition)}
        />
      )}
      {step === RmaRequestStep.ReasonAndCondition && (
        <ReasonAndCondition
          onNext={() => setStep(RmaRequestStep.ContactDetails)}
        />
      )}
      {step === RmaRequestStep.ContactDetails && (
        <ContactDetails onSubmitRequest={handleSubmitRequest} />
      )}
    </>
  );
};

const RmaRequestModal = ({
  orderLine,
  onClose,
}: {
  orderLine: OrderLineSolrDto;
  onClose: () => void;
}) => {
  return (
    <RmaContextProvider
      accountCode={orderLine.accountCode}
      defaultStep={RmaRequestStep.BasicDeviceInformation}
      orderId={orderLine?.orderNum}
      orderLineId={orderLine?.orderLineId}
      partDetails={{
        category: orderLine.catName,
        supplierCode: orderLine.suppCode,
        supplierPart: orderLine.suppPart,
      }}
      onClose={onClose}
    >
      <RmaRequestSteps />
    </RmaContextProvider>
  );
};

export default RmaRequestModal;
