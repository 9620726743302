import { useMutation } from "@tanstack/react-query";
import {
  QuotesDetailEmailRequestDto,
  QuotesResourceService,
} from "../../../api";

export const useQuoteEmail = () => {
  return useMutation((payload: QuotesDetailEmailRequestDto) =>
    QuotesResourceService.sendQuoteDetailsEmailUsingPost(payload),
  );
};
