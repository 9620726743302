import { useQuery } from "@tanstack/react-query";
import { SearchResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useSearchCategories = (enabled?: boolean) => {
  return useQuery(
    [QUERY_KEYS["search-categories"]],
    () => SearchResourceService.getSearchCategoriesUsingGet(),
    {
      enabled,
    },
  );
};
