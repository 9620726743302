import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { ExternalLicenseDto, ExternalLicenseResourceService } from "../../api";
import { useCookiesLive } from "../../hooks";
import { ImportWizardColumnDef } from "../../types";
import { generateExternalResourceEntity } from "../../utils/externalLicenses";
import {
  ImportWizardContextProvider,
  useImportWizardContext,
} from "../ImportWizard/ImportWizardContext";
import { Import, MapFields, SelectFile } from "../ImportWizard/modals";
import { MapCatalog } from "./MapCatalog";

enum ImportExternalLicensesStep {
  SelectFile = "SelectFile",
  MapFields = "MapFields",
  MapCatalog = "MapCatalog",
  Import = "Import",
}

const columnDefs: ImportWizardColumnDef[] = [
  {
    label: "Manufacturer Name",
    name: "manufacturer",
    required: true,
    suggestions: ["Mfg Name"],
  },
  {
    label: "Manufacturer Part",
    name: "manufacturerPart",
    required: true,
    suggestions: ["Mfg Part"],
  },
  {
    label: "Quantity",
    name: "quantity",
    required: true,
    suggestions: ["qty"],
  },
  {
    label: "Expiration Date",
    name: "expirationDate",
    required: true,
    suggestions: [],
  },
  {
    label: "Expiration Type",
    name: "expirationType",
    required: true,
    suggestions: [],
  },
  {
    label: "License Key",
    name: "licenseKey",
    required: false,
    suggestions: [],
  },
  {
    label: "Reference",
    name: "reference",
    required: false,
    suggestions: [],
  },
  {
    label: "Notes",
    name: "notes",
    required: false,
    suggestions: [],
  },
  {
    label: "EUL Company Name",
    name: "companyName",
    required: false,
    suggestions: [],
  },
  {
    label: "EUL Name",
    name: "name",
    required: false,
    suggestions: [],
  },
  {
    label: "EUL Email",
    name: "email",
    required: false,
    suggestions: [],
  },
  {
    label: "EUL Phone",
    name: "phone",
    required: false,
    suggestions: [],
  },
];

const ImportExternalLicensesSteps = () => {
  const { step, setStep, onClose, progress } = useImportWizardContext();

  const [accountId] = useCookiesLive(["accountId"]);
  const { enqueueSnackbar } = useSnackbar();
  const [importFinished, setImportFinished] = useState(false);
  const [importedCount, setImportedCount] = useState(0);

  const handleImport = async (externalLicenses: ExternalLicenseDto[]) => {
    const promises = externalLicenses.map((externalLicense) =>
      ExternalLicenseResourceService.createUsingPost3(
        externalLicense,
        accountId ? parseInt(accountId) : undefined,
      ),
    );

    Promise.allSettled(promises).then((results) => {
      const fulfilledCount = results.reduce(
        (acc, cur) => acc + (cur.status === "fulfilled" ? 1 : 0),
        0,
      );
      setImportedCount(fulfilledCount);
      setImportFinished(true);
    });
  };

  useEffect(() => {
    if (importFinished && progress > 90) {
      enqueueSnackbar({
        message: `(${importedCount}) licenses imported successfully.`,
        variant: "success",
      });

      onClose();
      setTimeout(() => window.location.reload(), 2000);
    }
  }, [importFinished, progress, onClose, importedCount, enqueueSnackbar]);

  return (
    <>
      {step === ImportExternalLicensesStep.SelectFile && (
        <SelectFile
          headline="Select an Excel or CSV file with License Data"
          subHeadline="NOTE: Must include manufacturer name, manufacturer part & quantity."
          title="Import Licenses - Choose a File"
          onNext={() => setStep(ImportExternalLicensesStep.MapFields)}
        />
      )}
      {step === ImportExternalLicensesStep.MapFields && (
        <MapFields
          columnDefs={columnDefs}
          generateEntity={generateExternalResourceEntity}
          title="Import Licenses - Map Fields to Upload File"
          onNext={() => setStep(ImportExternalLicensesStep.MapCatalog)}
        />
      )}
      {step === ImportExternalLicensesStep.MapCatalog && (
        <MapCatalog
          onImport={handleImport}
          onNext={() => setStep(ImportExternalLicensesStep.Import)}
        />
      )}
      {step === ImportExternalLicensesStep.Import && (
        <Import title="Importing Licenses … do not close this window" />
      )}
    </>
  );
};

const ImportExternalLicenses = ({ onClose }: { onClose: () => void }) => {
  return (
    <ImportWizardContextProvider
      defaultStep={ImportExternalLicensesStep.SelectFile}
      onClose={onClose}
    >
      <ImportExternalLicensesSteps />
    </ImportWizardContextProvider>
  );
};

export default ImportExternalLicenses;
