/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CartLineOverrideDto } from './CartLineOverrideDto';
import type { EulPatchRequestDto } from './EulPatchRequestDto';
export type CartLinePostRequestDto = {
  componentType?: CartLinePostRequestDto.componentType;
  custom1?: string;
  custom2?: string;
  custom3?: string;
  custom4?: string;
  endUserLicenses?: EulPatchRequestDto;
  originalLineNumber?: number;
  override?: CartLineOverrideDto;
  productId: number;
  productType: CartLinePostRequestDto.productType;
  quantity: number;
  quantityBundle1?: number;
  quantityBundle10?: number;
  quantityBundle2?: number;
  quantityBundle3?: number;
  quantityBundle4?: number;
  quantityBundle5?: number;
  quantityBundle6?: number;
  quantityBundle7?: number;
  quantityBundle8?: number;
  quantityBundle9?: number;
  scheduleNumber?: number;
  unitPrice?: number;
};
export namespace CartLinePostRequestDto {
  export enum componentType {
    COMPONENT = 'Component',
    FEE = 'Fee',
    MASTER = 'Master',
    PICK_PACK = 'Pick-Pack',
  }
  export enum productType {
    BUNDLE = 'BUNDLE',
    PRODUCT = 'PRODUCT',
  }
}

