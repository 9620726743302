import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { memo, useCallback } from "react";
import { type RecentElectronicPoResponseDto } from "../../api";
import { TABLE_ROW_HEIGHT } from "../../constants";
import { useCookiesLive } from "../../hooks";
import { useAccount } from "../../hooks/api/queries";
import { useRecentElectronicPos } from "../../hooks/api/queries/useRecentElectronicPos";
import theme from "../../theme";
import { getFormattedDate } from "../../utils/datetime";
import { getAppLink } from "../../utils/link";
import { TableCell, TableHeaderCell } from "../ui/Table";

const RecentElectronicPosTable = memo(() => {
  const [accountId, accountCode] = useCookiesLive(["accountId", "accountCode"]);
  const { data: recentElectronicPos, isLoading } = useRecentElectronicPos(
    accountId ? +accountId : 0,
  );
  const { data: accountData } = useAccount(accountCode);

  const handleElectronicPoRowClick = useCallback(
    (electronicPo: RecentElectronicPoResponseDto) => {
      if (accountData && accountData["ediDetailsPagePath"]) {
        window.location.href = getAppLink(
          `${accountData["ediDetailsPagePath"]}?ordernum=${encodeURIComponent(
            electronicPo?.electronicPoId ?? "",
          )}`,
        );
      }
    },
    [accountData],
  );

  return (
    <Box
      data-testid="recent-epo-table-container"
      sx={{ maxHeight: "600px", overflowY: `${isLoading ? "hidden" : "auto"}` }}
    >
      <Table data-testid="recent-epo-table">
        <TableHead data-testid="recent-epo-table-header">
          <TableRow>
            <TableHeaderCell>Number</TableHeaderCell>
            <TableHeaderCell>PO</TableHeaderCell>
            <TableHeaderCell>Date</TableHeaderCell>
            <TableHeaderCell>Attention</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody data-testid="recent-epo-table-body">
          {isLoading && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={4}
                data-testid="recent-epo-loading-spinner-cell"
                sx={{ borderBottomWidth: "0" }}
              >
                <CircularProgress className="homepage-table-loading-spinner" />
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            recentElectronicPos &&
            recentElectronicPos.map((electronicPo) => (
              <TableRow
                key={electronicPo.electronicPoId}
                sx={{
                  "&:hover": {
                    backgroundColor: theme.palette.grey["100"],
                    cursor: accountData ? "pointer" : "auto",
                  },
                  height: TABLE_ROW_HEIGHT,
                }}
                onClick={() => handleElectronicPoRowClick(electronicPo)}
              >
                <TableCell>
                  {accountData && (
                    <Typography
                      className="text-link"
                      component="span"
                      sx={{
                        "&:hover": { textDecoration: "underline" },
                        color: theme.palette.blue["400"],
                        fontSize: "14px",
                      }}
                    >
                      {electronicPo.electronicPoId}
                    </Typography>
                  )}
                  {!accountData && electronicPo.electronicPoId}
                </TableCell>
                <TableCell>{electronicPo.custPo}</TableCell>
                <TableCell>{getFormattedDate(electronicPo.date)}</TableCell>
                <TableCell>{electronicPo.attention}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
});

export default RecentElectronicPosTable;
