/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TemplateVariable = {
  composite?: boolean;
  description?: string;
  name?: string;
  singular?: boolean;
  type?: TemplateVariable.type;
};
export namespace TemplateVariable {
  export enum type {
    COMPOSITE_PARAM = 'COMPOSITE_PARAM',
    DOT = 'DOT',
    FRAGMENT = 'FRAGMENT',
    PATH_SEGMENT = 'PATH_SEGMENT',
    PATH_STYLE_PARAMETER = 'PATH_STYLE_PARAMETER',
    PATH_VARIABLE = 'PATH_VARIABLE',
    REQUEST_PARAM = 'REQUEST_PARAM',
    REQUEST_PARAM_CONTINUED = 'REQUEST_PARAM_CONTINUED',
    RESERVED_STRING = 'RESERVED_STRING',
    SEGMENT = 'SEGMENT',
    SIMPLE = 'SIMPLE',
  }
}

