import { ImportWizardFileRecord } from "../types";
import { GlobalInvoiceLineDto } from "../types/GlobalInvoiceLine";
import { getDateValue, getNumberValue, getStringValue } from "./importWizard";

export const generateGlobalInvoiceLineResourceEntity = (
  columnMapping: Record<string, number | null>,
  record: ImportWizardFileRecord,
): GlobalInvoiceLineDto => ({
  category: getStringValue(record, columnMapping.category),
  custPo: getStringValue(record, columnMapping.custPo),
  description: getStringValue(record, columnMapping.description),
  invoiceDate: getDateValue(record, columnMapping.invoiceDate),
  lineNumber: getNumberValue(record, columnMapping.lineNumber),
  manufacturerPart: getStringValue(record, columnMapping.manufacturerPart),
  mfgName: getStringValue(record, columnMapping.manufacturerName),
  orderDate: getDateValue(record, columnMapping.orderDate),
  orderNum: getNumberValue(record, columnMapping.orderNum),
  serialNumber: getStringValue(record, columnMapping.serialNumber),
  shippingAddress: {
    address: getStringValue(record, columnMapping.address),
    address2: getStringValue(record, columnMapping.address2),
    city: getStringValue(record, columnMapping.city),
    countryCode: getStringValue(record, columnMapping.countryCode),
    postalCode: getStringValue(record, columnMapping.postalCode),
    stateOrProvince: getStringValue(record, columnMapping.stateOrProvince),
  },
  suppName: getStringValue(record, columnMapping.suppName),
  unitPriceUsd: getNumberValue(record, columnMapping.unitPriceUsd),
});
