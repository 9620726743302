/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CartLineOverrideResponseDto } from './CartLineOverrideResponseDto';
export type CartLineResponseDto = {
  /**
   * Response Only
   */
  _labels?: Record<string, string>;
  /**
   * Response Only
   */
  _links?: Record<string, Array<string>>;
  cartHeaderId: number;
  cartLineId: number;
  componentType?: string;
  connectionSku?: string;
  custom1?: string;
  custom2?: string;
  custom3?: string;
  custom4?: string;
  description: string;
  endUserLicenses?: Record<string, string>;
  largeImageUrl?: string;
  manufacturerName?: string;
  manufacturerPart?: string;
  manufacturerProductId?: number;
  operationType?: CartLineResponseDto.operationType;
  originalLineNumber?: number;
  originalUnitPrice?: number;
  override?: CartLineOverrideResponseDto;
  primaryImageUrl: string;
  productId: number;
  productType: CartLineResponseDto.productType;
  quantity: number;
  quantityBundle1?: number;
  quantityBundle10?: number;
  quantityBundle2?: number;
  quantityBundle3?: number;
  quantityBundle4?: number;
  quantityBundle5?: number;
  quantityBundle6?: number;
  quantityBundle7?: number;
  quantityBundle8?: number;
  quantityBundle9?: number;
  quantityBundleTotal?: number;
  scheduleNumber?: number;
  sequence: number;
  standard: boolean;
  supplierPartDescription?: string;
  title: string;
  totalPrice: number;
  unitPrice: number;
  updateMessage?: string;
  width400ImageUrl?: string;
};
export namespace CartLineResponseDto {
  export enum operationType {
    DELETE = 'DELETE',
    INSERT = 'INSERT',
    NONE = 'NONE',
    RELEASE = 'RELEASE',
    REPLACE = 'REPLACE',
    SYNC = 'SYNC',
    UPDATE = 'UPDATE',
    VFP = 'VFP',
  }
  export enum productType {
    BUNDLE = 'BUNDLE',
    PRODUCT = 'PRODUCT',
  }
}

