/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SqlReportSchedulePatchRequestDto = {
  deliveryMethod?: SqlReportSchedulePatchRequestDto.deliveryMethod;
  email?: string;
  emailFileNameDatestamp?: boolean;
  format?: SqlReportSchedulePatchRequestDto.format;
  ftpFilename?: string;
  ftpFilepath?: string;
  ftpPassword?: string;
  ftpPort?: number;
  ftpServer?: string;
  ftpUsername?: string;
  name?: string;
  sendBlank?: boolean;
};
export namespace SqlReportSchedulePatchRequestDto {
  export enum deliveryMethod {
    EMAIL = 'email',
    FTP = 'ftp',
    SFTP = 'sftp',
  }
  export enum format {
    CSV = 'csv',
    PDF = 'pdf',
    TSV = 'tsv',
    TXT = 'txt',
    XLSX = 'xlsx',
  }
}

