import { useQuery } from "@tanstack/react-query";
import { TransactionStatisticsResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useSalesByManufacturer = (
  startDate?: string,
  endDate?: string,
  accountCode?: string,
  showAll?: boolean,
) => {
  return useQuery(
    [
      QUERY_KEYS["sales-by-manufacturer"],
      startDate,
      endDate,
      accountCode,
      showAll,
    ],
    () =>
      TransactionStatisticsResourceService.getSalesByManufacturerUsingGet(
        endDate!,
        startDate!,
        accountCode,
        showAll,
      ),
    { enabled: !!startDate && !!endDate },
  );
};
