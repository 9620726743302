import { styled, TextField as MuiTextField } from "@mui/material";

export const TextField = styled(MuiTextField)(
  ({ theme, variant, size = "small" }) => ({
    "& .MuiFormHelperText-root:not(.Mui-error)": {
      color: theme.palette.green["200"],
    },
    "& .MuiInputBase-input": {
      "&::placeholder": {
        color: theme.palette.grey["800"],
      },
      color: theme.palette.grey["900"],
      height: theme.spacing(4.5),
      padding: `0 ${theme.spacing(1)}`,
    },
    "& .MuiInputBase-root": {
      ...(size === "small" && {
        fontSize: theme.spacing(1.75),
      }),
      ...(size === "medium" && {
        fontSize: theme.spacing(2),
      }),
      "&.Mui-disabled": {
        background: theme.palette.grey["50"],
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.grey["200"],
      },

      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.blue["400"],
          borderWidth: 1,
        },
        "&:hover": {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.blue["500"],
          },
        },
      },

      "&.MuiInputBase-adornedEnd": {
        paddingRight: 1,
      },

      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.grey["700"],
      },

      background: theme.palette.white,
      borderRadius: theme.spacing(0.25),
      fontWeight: 400,
      paddingLeft: theme.spacing(1),
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey["400"],
    },
  }),
);
