import { createSvgIcon } from "@mui/material";

export const FileExcelIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 16 19" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M7.5,0 L9.57016536,2.36268872 L12,5.13586957 L12,15.9782609 C12,17.2389108 11.1045695,18.2608696 10,18.2608696 L9,18.26 L9,17.119 L10,17.1195652 C10.5154658,17.1195652 10.9398399,16.6744454 10.9941321,16.1026186 L11,15.9782609 L11,5.13586957 L9,5.13586957 C8.17157288,5.13586957 7.5,4.36940052 7.5,3.42391304 L7.5,1.14130435 L2,1.14130435 C1.44771525,1.14130435 1,1.65228371 1,2.2826087 L1,12.173 L0,12.173913 L0,2.2826087 C0,1.02195872 0.8954305,0 2,0 L7.5,0 Z"
        id="path-1"
      ></path>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
      id="Page-1"
      stroke="none"
      stroke-width="1"
    >
      <g id="main-nav" transform="translate(-1110, -298)">
        <g id="Group-23" transform="translate(1110.975, 298)">
          <g id="Rectangle" transform="translate(3.025, 0)">
            <mask fill="white" id="mask-2">
              <use xlinkHref="#path-1"></use>
            </mask>
            <use
              fill="#26C184"
              fill-rule="nonzero"
              id="Combined-Shape"
              xlinkHref="#path-1"
            ></use>
          </g>
          <g
            fill="#26C184"
            fill-rule="nonzero"
            id="file-earmark-excel"
            transform="translate(6.025, 6)"
          >
            <path
              d="M0.742101725,0.161967664 C0.648216541,0.0402747889 0.497584461,-0.0195386972 0.348543001,0.00569205938 C0.199501541,0.0309228159 0.0754572724,0.137235391 0.0244506553,0.283456539 C-0.0265559617,0.429677688 0.00348552872,0.592843761 0.102940514,0.709763018 L1.96133502,3.00365606 L0.102940514,5.29754911 C-0.0374867672,5.4795693 -0.0110406261,5.74357686 0.162520669,5.89232817 C0.336081965,6.04107949 0.593343502,6.02022459 0.742101725,5.84534446 L2.50312402,3.67213758 L4.26414632,5.84620039 C4.35944094,5.96362545 4.50840853,6.01984451 4.65493442,5.99368039 C4.80146032,5.96751627 4.92328376,5.86294392 4.97451503,5.71935475 C5.0257463,5.57576557 4.99860215,5.41497417 4.90330753,5.29754911 L3.04491302,3.00365606 L4.90330753,0.709763018 C5.04373481,0.527742823 5.01728867,0.26373527 4.84372737,0.114983954 C4.67016608,-0.0337673621 4.41290454,-0.0129124684 4.26414632,0.161967664 L2.50312402,2.33517454 L0.742101725,0.161111733 L0.742101725,0.161967664 Z"
              id="Path"
            ></path>
          </g>
          <g
            fill="#26C184"
            fill-rule="nonzero"
            id="XLS"
            transform="translate(0, 13)"
          >
            <polygon
              id="Path"
              points="1.345 1.845 0.085 0.065 0.89 0.065 1.675 1.22 1.78 1.415 1.82 1.415 1.925 1.22 2.715 0.065 3.51 0.065 2.225 1.87 3.605 3.765 2.8 3.765 1.91 2.47 1.815 2.3 1.775 2.3 1.685 2.47 0.8 3.765 0 3.765"
            ></polygon>
            <polygon
              id="Path"
              points="3.96 0.065 4.67 0.065 4.67 3.21 6.29 3.21 6.29 3.765 3.96 3.765"
            ></polygon>
            <path
              d="M7.84,3.825 C7.75,3.825 7.65166667,3.82083333 7.545,3.8125 C7.43833333,3.80416667 7.32916667,3.79333333 7.2175,3.78 C7.10583333,3.76666667 6.99416667,3.75 6.8825,3.73 C6.77083333,3.71 6.66333333,3.68833333 6.56,3.665 L6.56,3.085 C6.78666667,3.13833333 7.005,3.18 7.215,3.21 C7.425,3.24 7.61333333,3.255 7.78,3.255 C8.28666667,3.255 8.54,3.09666667 8.54,2.78 C8.54,2.69 8.5225,2.615 8.4875,2.555 C8.4525,2.495 8.40333333,2.44333333 8.34,2.4 C8.27666667,2.35666667 8.19833333,2.32 8.105,2.29 C8.01166667,2.26 7.90833333,2.23166667 7.795,2.205 C7.59166667,2.15833333 7.41583333,2.10416667 7.2675,2.0425 C7.11916667,1.98083333 6.99583333,1.905 6.8975,1.815 C6.79916667,1.725 6.72666667,1.61916667 6.68,1.4975 C6.63333333,1.37583333 6.61,1.23166667 6.61,1.065 C6.61,0.901666667 6.6425,0.754166667 6.7075,0.6225 C6.7725,0.490833333 6.865,0.379166667 6.985,0.2875 C7.105,0.195833333 7.25083333,0.125 7.4225,0.075 C7.59416667,0.025 7.78833333,0 8.005,0 C8.15833333,0 8.3175,0.01 8.4825,0.03 C8.6475,0.05 8.84666667,0.085 9.08,0.135 L9.08,0.71 C8.91666667,0.663333333 8.745,0.6275 8.565,0.6025 C8.385,0.5775 8.22166667,0.565 8.075,0.565 C7.845,0.565 7.66583333,0.6075 7.5375,0.6925 C7.40916667,0.7775 7.345,0.896666667 7.345,1.05 C7.345,1.12666667 7.35916667,1.195 7.3875,1.255 C7.41583333,1.315 7.46,1.3675 7.52,1.4125 C7.58,1.4575 7.65833333,1.49833333 7.755,1.535 C7.85166667,1.57166667 7.97,1.60666667 8.11,1.64 C8.29333333,1.68333333 8.45666667,1.73333333 8.6,1.79 C8.74333333,1.84666667 8.865,1.91666667 8.965,2 C9.065,2.08333333 9.14166667,2.18416667 9.195,2.3025 C9.24833333,2.42083333 9.275,2.56333333 9.275,2.73 C9.275,3.08666667 9.15333333,3.35833333 8.91,3.545 C8.66666667,3.73166667 8.31,3.825 7.84,3.825 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "FileExcel",
);
