import { useQuery } from "@tanstack/react-query";
import { TransactionStatisticsResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useSalesByState = (
  startDate?: string,
  endDate?: string,
  accountCode?: string,
) => {
  return useQuery(
    [QUERY_KEYS["sales-by-state"], startDate, endDate, accountCode],
    () =>
      TransactionStatisticsResourceService.getSalesByStateUsingGet(
        endDate!,
        startDate!,
        accountCode,
      ),
    { enabled: !!startDate && !!endDate },
  );
};
