import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import LinearProgressBar from "../../LinearProgressBar";
import { HCenterBox } from "../../ui";
import { useImportWizardContext } from "../ImportWizardContext";

export const Import = ({ title }: { title: string }) => {
  const { setProgress } = useImportWizardContext();
  return (
    <Dialog fullWidth open maxWidth="sm" onClose={() => {}}>
      <DialogTitle sx={{ px: 2, py: 4 }}>{title}</DialogTitle>
      <DialogContent>
        <HCenterBox sx={{ mb: 2.5, width: "100%" }}>
          <LinearProgressBar onChange={setProgress} />
        </HCenterBox>
      </DialogContent>
    </Dialog>
  );
};
