import { Box, Grid, Typography } from "@mui/material";
import { Header } from "@tanstack/react-table";
import { camelCase } from "lodash";
import { Fragment, memo, useCallback, useEffect, useState } from "react";
import { BOOLEAN_VALUES } from "../../../constants/dataTable";
import { Button } from "../../ui";
import { useDataTableContext } from "../DataTableContext";

const FILTER_OPTIONS = ["Both", BOOLEAN_VALUES.true, BOOLEAN_VALUES.false];

const BooleanFilter = <TData, TValue>({
  header,
  onClose,
}: {
  header: Header<TData, TValue>;
  onClose: () => void;
}) => {
  const { criteria, updateFilter } = useDataTableContext();
  const { header: label, id: element } = header.column.columnDef;
  const initialValue = criteria.eq?.[0]?.[element ?? ""]?.[0];
  const initialOption =
    initialValue === true
      ? FILTER_OPTIONS[1]
      : initialValue === false
        ? FILTER_OPTIONS[2]
        : FILTER_OPTIONS[0];

  const [filterOption, setFilterOption] =
    useState<(typeof FILTER_OPTIONS)[number]>(initialOption);

  useEffect(() => {
    setFilterOption(initialOption);
  }, [initialOption]);

  const handleOptionClick = useCallback(
    (option: string) => {
      setFilterOption(option);

      if (element) {
        updateFilter([
          {
            field: element,
            key: "eq",
            values: option === FILTER_OPTIONS[0] ? [] : [option],
          },
        ]);
      }
      onClose();
    },
    [element, onClose, updateFilter],
  );

  return (
    <Fragment>
      <Box sx={{ px: 1, py: 1.5 }}>
        <Typography fontWeight="500" variant="body2">
          {`Filter by ${label}:`}
        </Typography>
      </Box>
      <Grid
        container
        spacing={1}
        sx={(theme) => ({ background: theme.palette.grey["50"], p: 1 })}
      >
        {FILTER_OPTIONS.map((option) => (
          <Grid key={option} item>
            <Button
              color={filterOption === option ? "blue" : undefined}
              data-testid={`dt-boolean-filter-option-${camelCase(option)}`}
              sx={{
                width: "100%",
              }}
              variant="outlined"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};

export default memo(BooleanFilter) as typeof BooleanFilter;
