/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllowedValueDto } from './AllowedValueDto';
import type { RequiredCondition } from './RequiredCondition';
export type EulDataField = {
  allowedValues?: Array<AllowedValueDto>;
  label?: string;
  name?: string;
  required?: boolean;
  requiredCondition?: RequiredCondition;
  type?: EulDataField.type;
};
export namespace EulDataField {
  export enum type {
    COUNTRY = 'country',
    DATE = 'date',
    EMAIL = 'email',
    PHONE = 'phone',
    TEXT = 'text',
  }
}

