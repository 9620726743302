import { createSvgIcon } from "@mui/material";

export const MinusSquareIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="#000000"
        fillRule="nonzero"
        id="q-l-cl-expand-view"
        transform="translate(-597.000000, -682.000000)"
      >
        <g id="popup" transform="translate(574.000000, 234.000000)">
          <g id="Group-13" transform="translate(23.000000, 174.000000)">
            <g id="dash-square" transform="translate(0.000000, 274.000000)">
              <path
                d="M14,1 C14.5522847,1 15,1.44771525 15,2 L15,14 C15,14.5522847 14.5522847,15 14,15 L2,15 C1.44771525,15 1,14.5522847 1,14 L1,2 C1,1.44771525 1.44771525,1 2,1 L14,1 Z M2,0 C0.8954305,0 0,0.8954305 0,2 L0,14 C0,15.1045695 0.8954305,16 2,16 L14,16 C15.1045695,16 16,15.1045695 16,14 L16,2 C16,0.8954305 15.1045695,0 14,0 L2,0 Z"
                id="Shape"
              ></path>
              <path
                d="M4,8 C4,7.72385763 4.22385763,7.5 4.5,7.5 L11.5,7.5 C11.7761424,7.5 12,7.72385763 12,8 C12,8.27614237 11.7761424,8.5 11.5,8.5 L4.5,8.5 C4.22385763,8.5 4,8.27614237 4,8 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "MinusSquare",
);
