import { useQuery } from "@tanstack/react-query";
import { ClientResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useClientResource = (clientId?: number) => {
  return useQuery(
    [QUERY_KEYS["client-resource"], clientId],
    () => ClientResourceService.getClientUsingGet(clientId!),
    { enabled: !!clientId },
  );
};
