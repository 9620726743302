import { createSvgIcon } from "@mui/material";

export const GraphUpIcon = createSvgIcon(
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <title>graph-up</title>
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="warning-status"
        transform="translate(-35, -144)"
      >
        <g id="sales-data-btn" transform="translate(29, 137)">
          <g id="graph-up" transform="translate(6, 7)">
            <path
              d="M0,0 L1,0 L1,15 L16,15 L16,16 L0,16 L0,0 Z M14.817,3.113 C14.9196984,3.19703395 14.98478,3.31844544 14.9979096,3.45049194 C15.0110392,3.58253845 14.9711399,3.71438838 14.887,3.817 L10.387,9.317 C10.2975604,9.42616741 10.1661515,9.49245335 10.0252,9.49950092 C9.88424863,9.50654849 9.74688305,9.45370128 9.647,9.354 L7.06,6.767 L3.404,11.794 C3.23778514,12.0053549 2.93418095,12.0474115 2.7167718,11.8891979 C2.49936265,11.7309843 2.446008,11.4291607 2.596,11.206 L6.596,5.706 C6.68192354,5.58763754 6.81536502,5.51283234 6.96117079,5.50129099 C7.10697656,5.48974964 7.25052504,5.54262951 7.354,5.646 L9.963,8.256 L14.113,3.183 C14.197034,3.0803016 14.3184454,3.01522004 14.4504919,3.00209042 C14.5825384,2.98896079 14.7143884,3.02886011 14.817,3.113 L14.817,3.113 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "GraphUp",
);
