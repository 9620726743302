import { useEffect, useState } from "react";
import { AbstractUserDto } from "../../api";
import AuthenticatedLink from "../UserList/AuthenticatedLink";

const ManageAuthenticatedLinkWrapper = () => {
  const [selectedUser, setSelectedUser] = useState<AbstractUserDto>();

  useEffect(() => {
    document.addEventListener(
      "selectUserForAuthenticatedLink",
      (event: any) => {
        const entity = event.detail as AbstractUserDto;
        setSelectedUser(entity);
      },
    );
  }, []);

  return (
    <>
      {selectedUser && (
        <AuthenticatedLink
          userId={selectedUser.userId}
          onClose={() => setSelectedUser(undefined)}
        />
      )}
    </>
  );
};

export default ManageAuthenticatedLinkWrapper;
