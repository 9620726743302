import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ClientListing, ClientResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useClientResourceDelete = () => {
  const queryClient = useQueryClient();

  return useMutation<ClientListing, Error, number>(
    (clientId) => {
      return ClientResourceService.deleteClientUsingDelete(clientId);
    },
    {
      onSuccess: (clientId) => {
        queryClient.invalidateQueries([
          QUERY_KEYS["client-resource"],
          clientId,
        ]);
        queryClient.refetchQueries();
      },
    },
  );
};
