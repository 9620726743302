import { Theme, Typography } from "@mui/material";
import { isUndefined } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { TextField, VCenterBox } from "../../../ui";
import DialPlate from "./DialPlate";

const timeInputSxProps = (theme: Theme, active: boolean) => ({
  "& .MuiInputBase-input": {
    color: !active ? theme.palette.grey["900"] : theme.palette.blue["700"],
    height: 54,
    textAlign: "center",
  },
  "& .MuiInputBase-root": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: !active
        ? theme.palette.grey["400"]
        : theme.palette.blue["300"],
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.blue["300"],
      },
      "&:hover": {
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.blue["300"],
        },
      },
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: !active
        ? theme.palette.grey["400"]
        : theme.palette.blue["300"],
    },
    background: !active ? theme.palette.grey["100"] : theme.palette.blue["200"],
    fontSize: theme.spacing(4.5),
    px: 0,
  },
  height: 54,
  width: 70,
});

const TimePicker = memo(
  ({
    value = "00:00",
    onChange,
  }: {
    value?: string;
    onChange: (value: string) => void;
  }) => {
    const [plateType, setPlateType] = useState(0);
    const [hour, setHour] = useState(0);
    const [minute, setMinute] = useState(0);

    const handleChange = useCallback(
      (type: "hour" | "minute", value: number) => {
        let newHour = hour;
        let newMinute = minute;
        if (type === "hour") {
          setHour(value);
          newHour = value;
        } else {
          setMinute(value);
          newMinute = value;
        }

        const formattedHour = String(newHour).padStart(2, "0");
        const formattedMinute = String(newMinute).padStart(2, "0");
        const formattedValue = `${formattedHour}:${formattedMinute}`;

        onChange(formattedValue);
      },
      [hour, minute, onChange],
    );

    useEffect(() => {
      const [h, m] = value.split(":");

      setHour(+h);
      setMinute(+m);
    }, [value]);

    return (
      <VCenterBox sx={{ flexDirection: "column", gap: 4 }}>
        <VCenterBox
          sx={{
            gap: 1.5,
          }}
        >
          <NumericFormat
            allowLeadingZeros
            valueIsNumericString
            allowNegative={false}
            customInput={TextField}
            isAllowed={({ floatValue }) =>
              !isUndefined(floatValue) && floatValue < 24
            }
            sx={(theme: Theme) => timeInputSxProps(theme, plateType === 0)}
            value={String(hour).padStart(2, "0")}
            onFocus={() => setPlateType(0)}
            onValueChange={({ floatValue }) =>
              handleChange("hour", floatValue ?? 0)
            }
          />
          <Typography
            fontWeight="500"
            sx={(theme) => ({
              fontSize: theme.spacing(4.5),
            })}
          >
            :
          </Typography>
          <NumericFormat
            allowLeadingZeros
            valueIsNumericString
            allowNegative={false}
            customInput={TextField}
            isAllowed={({ floatValue }) =>
              !isUndefined(floatValue) && floatValue < 60
            }
            sx={(theme: Theme) => timeInputSxProps(theme, plateType === 1)}
            value={String(minute).padStart(2, "0")}
            onFocus={() => setPlateType(1)}
            onValueChange={({ floatValue }) =>
              handleChange("minute", floatValue ?? 0)
            }
          />
        </VCenterBox>

        {plateType ? (
          <DialPlate
            max={60}
            min={0}
            size={290}
            step={5}
            value={minute}
            onChange={(value) => handleChange("minute", value)}
          />
        ) : (
          <DialPlate
            invisibleTickersClickable
            max={24}
            min={0}
            size={290}
            step={2}
            value={hour}
            onChange={(value) => handleChange("hour", value)}
          />
        )}
      </VCenterBox>
    );
  },
);

export default TimePicker;
