import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BundleLinePatchRequestDto } from "../../api";
import { useBundleLineUpdate } from "../../hooks/api/mutations";
import { useBundle, useBundleLines } from "../../hooks/api/queries";
import theme from "../../theme";
import { Alert, Button, VCenterBox } from "../ui";
import { TableHeaderCell } from "../ui/Table";
import MovableLine from "./MovableLine";

const MoveBundleLines = ({
  orderNum,
  isOpen,
  onClose,
}: {
  orderNum: number;
  isOpen: boolean;
  onClose: (isUpdated: boolean) => void;
}) => {
  const { data: bundleData, isFetching } = useBundle(orderNum);
  const [isUpdatingBundleLines, setIsUpdatingBundleLines] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const bundleLineIds = useMemo(
    () => [
      ...((bundleData?.accessories?.map(
        (bundleLine) => bundleLine.bundleLineId,
      ) || []) as number[]),
      ...((bundleData?.primaryPackage?.map(
        (bundleLine) => bundleLine.bundleLineId,
      ) || []) as number[]),
    ],
    [bundleData],
  );
  const bundleLineData = useBundleLines(bundleLineIds);

  const { orderData, isFetchedBundleLines } = useMemo(
    () => ({
      isFetchedBundleLines: bundleLineData.every(
        (bundleLine) => bundleLine.isFetched,
      ),
      orderData: bundleLineData
        ?.map((bundleLine) => bundleLine?.data)
        ?.filter((bundleLine) => bundleLine?.bundleLineId)
        ?.sort(
          (bundleLine1, bundleLine2) =>
            (bundleLine1?.sequence ?? 0) - (bundleLine2?.sequence ?? 0),
        )
        .map((bundleLine) => ({
          ...bundleLine,
          id: bundleLine?.bundleLineId!,
        })),
    }),
    [bundleLineData],
  );

  useEffect(() => {
    if (isFetchedBundleLines && !isFetching) {
      setIsUpdatingBundleLines(false);
    }
  }, [isFetchedBundleLines, isFetching]);

  const { mutate: updateBundle } = useBundleLineUpdate(orderNum);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const srcBundleLine = orderData.find(
        (bundleLine) => bundleLine.id === event.active?.id,
      );
      const destBundleLineIndex = orderData.findIndex(
        (bundleLine) => bundleLine.id === event.over?.id,
      );

      if (
        event.active?.id !== event.over?.id &&
        srcBundleLine &&
        destBundleLineIndex >= 0
      ) {
        setIsUpdatingBundleLines(true);
        updateBundle(
          {
            bundleLineId: srcBundleLine.id,
            request: {
              sequence: (destBundleLineIndex + 1) * 10,
            } as BundleLinePatchRequestDto,
          },
          {
            onError: (error) => {
              enqueueSnackbar({
                message: error.message
                  ? error.message
                  : "Unable to move bundle line.",
                variant: "error",
              });
            },
            onSuccess: () => {
              setIsUpdated(true);
            },
          },
        );
      }
    },
    [enqueueSnackbar, orderData, updateBundle],
  );

  return (
    <Dialog
      fullWidth
      className="move-lines-modal"
      data-testid="move-lines-modal"
      maxWidth="lg"
      open={isOpen}
      onClose={() => onClose(isUpdated)}
    >
      <DialogTitle
        data-testid="move-lines-modal-title"
        sx={(theme) => ({
          fontSize: theme.spacing(2.25),
          m: 0,
          pb: 0,
          pt: 3.5,
          px: 2,
        })}
      >
        Move Lines On Standard {orderNum}
      </DialogTitle>
      <IconButton
        data-testid="move-lines-modal-close-icon"
        sx={{
          color: (theme) => theme.palette.grey[900],
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={() => onClose(isUpdated)}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent data-testid="move-lines-modal-body">
        <Alert
          icon={false}
          severity="info"
          sx={{
            borderRadius: 0,
            display: "inline-flex",
            paddingLeft: "5",
            paddingRight: "20",
            paddingTop: "4",
            width: "300px",
          }}
        >
          To move the lines&nbsp;
          <Typography
            sx={{
              color: theme.palette.green["100"],
              display: "inline-block",
              fontWeight: "600",
            }}
          >
            drag and drop
          </Typography>
          &nbsp;them
        </Alert>
        <Box sx={{ position: "relative" }}>
          <DndContext
            collisionDetection={closestCenter}
            sensors={sensors}
            onDragEnd={handleDragEnd}
          >
            <Table sx={{ mt: 5 }}>
              <TableHead data-testid="att-table-header">
                <TableRow>
                  <TableHeaderCell>LINE #</TableHeaderCell>
                  <TableHeaderCell>MANUFACTURER</TableHeaderCell>
                  <TableHeaderCell>MANUFACTURER PART</TableHeaderCell>
                  <TableHeaderCell>SUPPLIER</TableHeaderCell>
                  <TableHeaderCell>SUPPLIER PART</TableHeaderCell>
                  <TableHeaderCell>UNIT PRICE</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <SortableContext
                  items={orderData}
                  strategy={rectSortingStrategy}
                >
                  {orderData?.map((bundleLine, index) => (
                    <MovableLine
                      key={bundleLine.id}
                      index={index}
                      lineId={bundleLine.id}
                      manufacturerName={bundleLine.manufacturerName}
                      manufacturerPart={bundleLine.manufacturerPart}
                      supplierName={bundleLine.supplierName}
                      supplierPart={bundleLine.supplierPart}
                      unitPrice={bundleLine.unitCost}
                    />
                  ))}
                </SortableContext>
              </TableBody>
            </Table>
          </DndContext>
          {isUpdatingBundleLines && (
            <VCenterBox
              sx={{
                align: "center",
                backgroundColor: theme.palette.grey["200"],
                bottom: "0",
                justify: "center",
                left: "0",
                opacity: 0.35,
                position: "absolute",
                right: "0",
                top: "0",
                zIndex: "top",
              }}
            >
              <CircularProgress
                sx={{
                  left: "50%",
                  position: "fixed",
                  top: "500px",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </VCenterBox>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="move-lines-modal-close-btn"
          variant="outlined"
          onClick={() => onClose(isUpdated)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoveBundleLines;
