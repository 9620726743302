/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Accessory } from '../models/Accessory';
import type { DiscontinuedProductResponseDto } from '../models/DiscontinuedProductResponseDto';
import type { ManufacturerPartSuggestionDto } from '../models/ManufacturerPartSuggestionDto';
import type { PriceHistory } from '../models/PriceHistory';
import type { PriceHistoryDto } from '../models/PriceHistoryDto';
import type { ProductFeeResponseDto } from '../models/ProductFeeResponseDto';
import type { ProductImageDto } from '../models/ProductImageDto';
import type { ProductLineResponseDto } from '../models/ProductLineResponseDto';
import type { ProductPatchRequestDto } from '../models/ProductPatchRequestDto';
import type { RestCollection_ManufacturerProductSolrDto_ } from '../models/RestCollection_ManufacturerProductSolrDto_';
import type { SupplierPartApprovalStatusDto } from '../models/SupplierPartApprovalStatusDto';
import type { SupplierPartAvailabilityDto } from '../models/SupplierPartAvailabilityDto';
import type { SupplierPartDetail } from '../models/SupplierPartDetail';
import type { SupplierPartDetailsDto } from '../models/SupplierPartDetailsDto';
import type { SupplierPartPatchRequestDto } from '../models/SupplierPartPatchRequestDto';
import type { SupplierPartSuggestionDto } from '../models/SupplierPartSuggestionDto';
import type { SupplierProductDto } from '../models/SupplierProductDto';
import type { SupplierProductPostRequestDto } from '../models/SupplierProductPostRequestDto';
import type { SupplierProductReactivationRequestDto } from '../models/SupplierProductReactivationRequestDto';
import type { WarrantyData } from '../models/WarrantyData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProductsResourceService {
  /**
   * Gets product accessories.
   * Gets product details for account by mfg part and mfg name.
   * @param accountCode accountCode
   * @param accessories accessories
   * @returns Accessory Success
   * @throws ApiError
   */
  public static getProductAccessoriesForAccountAndAccessoriesUsingGet(
    accountCode: string,
    accessories?: string,
  ): CancelablePromise<Array<Accessory>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/account/{accountCode}/products/accessories',
      path: {
        'accountCode': accountCode,
      },
      query: {
        'accessories': accessories,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting product accessories`,
      },
    });
  }
  /**
   * Gets product details for account by mfg part and mfg name.
   * Gets product details for account by mfg part and mfg name.
   * @param accountCode accountCode
   * @param mfgName mfgName
   * @param mfgPart mfgPart
   * @returns ProductLineResponseDto Success
   * @throws ApiError
   */
  public static getProductDetailsForAccountByMfgPartAndMfgNameUsingGet(
    accountCode: string,
    mfgName: string,
    mfgPart: string,
  ): CancelablePromise<ProductLineResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/account/{accountCode}/products/manufacturer/{mfgName}/part/{mfgPart}',
      path: {
        'accountCode': accountCode,
        'mfgName': mfgName,
        'mfgPart': mfgPart,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting product details for account by mfg part and mfg name`,
      },
    });
  }
  /**
   * Gets suplier products pricing by manufacturer name and manufacturer part.
   * Gets suplier products pricing by manufacturer name and manufacturer part.
   * @param accountCode accountCode
   * @param mfgName mfgName
   * @param mfgPart mfgPart
   * @returns SupplierProductDto Success
   * @throws ApiError
   */
  public static getProductPricingByMfgNameAndMfgPartUsingGet(
    accountCode: string,
    mfgName: string,
    mfgPart: string,
  ): CancelablePromise<Array<SupplierProductDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/account/{accountCode}/products/manufacturer/{mfgName}/part/{mfgPart}/pricing',
      path: {
        'accountCode': accountCode,
        'mfgName': mfgName,
        'mfgPart': mfgPart,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting suplier products pricing by manufacturer name and manufacturer part`,
      },
    });
  }
  /**
   * Gets product details for account by id.
   * Gets product details for account by id.
   * @param accountCode accountCode
   * @param productId productId
   * @returns ProductLineResponseDto Success
   * @throws ApiError
   */
  public static getProductDetailsForAccountByIdUsingGet(
    accountCode: string,
    productId: number,
  ): CancelablePromise<ProductLineResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/account/{accountCode}/products/{productId}',
      path: {
        'accountCode': accountCode,
        'productId': productId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting product details for account by id`,
      },
    });
  }
  /**
   * Gets product accessories.
   * Gets product details for account by mfg part and mfg name.
   * @param accountCode accountCode
   * @param productId productId
   * @returns Accessory Success
   * @throws ApiError
   */
  public static getProductAccessoriesForAccountUsingGet(
    accountCode: string,
    productId: number,
  ): CancelablePromise<Array<Accessory>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/account/{accountCode}/products/{productId}/accessories',
      path: {
        'accountCode': accountCode,
        'productId': productId,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting product accessories`,
      },
    });
  }
  /**
   * Gets product accessories.
   * Gets product details for account by mfg part and mfg name.
   * @param accountCode accountCode
   * @param categoryId categoryId
   * @param productId productId
   * @returns Accessory Success
   * @throws ApiError
   */
  public static getProductAccessoriesForAccountAndCategoryUsingGet(
    accountCode: string,
    categoryId: string,
    productId: number,
  ): CancelablePromise<Array<Accessory>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/account/{accountCode}/products/{productId}/accessories/{categoryId}',
      path: {
        'accountCode': accountCode,
        'categoryId': categoryId,
        'productId': productId,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting product accessories`,
      },
    });
  }
  /**
   * Gets supplier products pricing by account code and product id.
   * Gets supplier products pricing by account code and product id.
   * @param accountCode accountCode
   * @param productId productId
   * @returns SupplierProductDto Success
   * @throws ApiError
   */
  public static getProductPricingByProductIdUsingGet(
    accountCode: string,
    productId: number,
  ): CancelablePromise<Array<SupplierProductDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/account/{accountCode}/products/{productId}/pricing',
      path: {
        'accountCode': accountCode,
        'productId': productId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting suplier products pricing by account code and product id`,
      },
    });
  }
  /**
   * Gets product warranties.
   * Gets product warranties.
   * @param accountCode accountCode
   * @param productId productId
   * @returns WarrantyData Success
   * @throws ApiError
   */
  public static getProductWarrantiesForAccountUsingGet(
    accountCode: string,
    productId: number,
  ): CancelablePromise<WarrantyData> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/account/{accountCode}/products/{productId}/warranties',
      path: {
        'accountCode': accountCode,
        'productId': productId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting product warranties`,
      },
    });
  }
  /**
   * Gets product price history by account code, manufacturer name and manufacturer part.
   * Gets product price history by account code, manufacturer name and manufacturer part.
   * @param account account
   * @param mfgName mfgName
   * @param mfgPart mfgPart
   * @returns PriceHistory Success
   * @throws ApiError
   */
  public static getPriceHistoryUsingGet1(
    account: string,
    mfgName: string,
    mfgPart: string,
  ): CancelablePromise<Array<PriceHistory>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/account/{account}/mfg_name/{mfgName}/mfg_part/{mfgPart}',
      path: {
        'account': account,
        'mfgName': mfgName,
        'mfgPart': mfgPart,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting product price history by account code, manufacturer name and manufacturer part`,
      },
    });
  }
  /**
   * Get the product availability and pricing by account and manufacturer name and part
   * Get the product availability and pricing by account and manufacturer name and part
   * @param account account
   * @param mfgName mfgName
   * @param mfgPart mfgPart
   * @returns SupplierProductDto Success
   * @throws ApiError
   */
  public static getProductAvailabilityAndPricingByMfgNameAndMfgPartUsingGet(
    account: string,
    mfgName: string,
    mfgPart: string,
  ): CancelablePromise<Array<SupplierProductDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/account/{account}/products/manufacturer/{mfgName}/part/{mfgPart}/availabilityPricing',
      path: {
        'account': account,
        'mfgName': mfgName,
        'mfgPart': mfgPart,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting product availability and price by manufacturer name/part`,
      },
    });
  }
  /**
   * Get the part availability by account code and supplier part id
   * Get the part availability by account code and supplier part id
   * @param account account
   * @param supplierPartId supplierPartId
   * @returns SupplierPartAvailabilityDto Success
   * @throws ApiError
   */
  public static getPartAvailabilityByAccountAndSupplierPartIdUsingGet(
    account: string,
    supplierPartId: number,
  ): CancelablePromise<SupplierPartAvailabilityDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/account/{account}/products/supplier/part/{supplierPartId}/availabilityPricing',
      path: {
        'account': account,
        'supplierPartId': supplierPartId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting the part availability by account and supplier part id`,
      },
    });
  }
  /**
   * Get the product availability and pricing by account code and manufacturer product id
   * Get the product availability and pricing by account code and manufacturer product id
   * @param account account
   * @param productId productId
   * @returns SupplierProductDto Success
   * @throws ApiError
   */
  public static getProductAvailabilityAndPricingByProductIdUsingGet(
    account: string,
    productId: number,
  ): CancelablePromise<Array<SupplierProductDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/account/{account}/products/{productId}/availabilityPricing',
      path: {
        'account': account,
        'productId': productId,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting product availability and price`,
      },
    });
  }
  /**
   * Get product listing report
   * Get product listing report
   * @param criteria criteria
   * @param page page
   * @param returnAllLabels returnAllLabels
   * @param returnPagination returnPagination
   * @param returnSavedViews returnSavedViews
   * @param size size
   * @param useDefaultView useDefaultView
   * @returns RestCollection_ManufacturerProductSolrDto_ Ok
   * @throws ApiError
   */
  public static listUsingGet13(
    criteria: string = '{}',
    page: number = 1,
    returnAllLabels: boolean = false,
    returnPagination: boolean = true,
    returnSavedViews: boolean = true,
    size: number = 50,
    useDefaultView: boolean = false,
  ): CancelablePromise<RestCollection_ManufacturerProductSolrDto_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/listing/products',
      query: {
        'criteria': criteria,
        'page': page,
        'returnAllLabels': returnAllLabels,
        'returnPagination': returnPagination,
        'returnSavedViews': returnSavedViews,
        'size': size,
        'useDefaultView': useDefaultView,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Product(s) Not Found`,
        500: `Internal Error fetching products`,
      },
    });
  }
  /**
   * Gets product details by connection sku
   * Gets product details by connection sku
   * @param connectionSku connectionSku
   * @returns ProductLineResponseDto Success
   * @throws ApiError
   */
  public static getProductDetailsByConnectionSkuUsingGet(
    connectionSku: number,
  ): CancelablePromise<ProductLineResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/connectionSku/{connectionSku}',
      path: {
        'connectionSku': connectionSku,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting product details by connection sku`,
      },
    });
  }
  /**
   * Get Product Fees
   * Get Product Fees
   * @returns ProductFeeResponseDto Success
   * @throws ApiError
   */
  public static getProductFeesUsingGet(): CancelablePromise<Array<ProductFeeResponseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/fees',
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `There was an error getting the supplier part approval status`,
      },
    });
  }
  /**
   * GET Manufacturer Part Suggestion
   * GET Manufacturer Part Suggestions
   * @param terms terms
   * @param limit limit
   * @param type type
   * @returns ManufacturerPartSuggestionDto Success
   * @throws ApiError
   */
  public static getManufacturerPartSuggestionUsingGet(
    terms: string,
    limit: number = 10,
    type: 'active' | 'all' = 'all',
  ): CancelablePromise<Array<ManufacturerPartSuggestionDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/manufacturer/part/suggest',
      query: {
        'limit': limit,
        'terms': terms,
        'type': type,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error Getting Manufacturer Part Suggestions`,
      },
    });
  }
  /**
   * Gets product details by mfg part and mfg name.
   * Gets product details by mfg part and mfg name.
   * @param mfgName mfgName
   * @param part part
   * @returns ProductLineResponseDto Success
   * @throws ApiError
   */
  public static getProductDetailsByMfgPartAndMfgNameUsingGet(
    mfgName: string,
    part: string,
  ): CancelablePromise<ProductLineResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/manufacturer/{mfgName}/',
      path: {
        'mfgName': mfgName,
      },
      query: {
        'part': part,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting product details by mfg part and mfg name`,
      },
    });
  }
  /**
   * Gets supplier products availability by manufacturer name and manufactures part.
   * Gets supplier products availability by manufacturer name and manufactures part.
   * @param mfgName mfgName
   * @param mfgPart mfgPart
   * @returns SupplierProductDto Success
   * @throws ApiError
   */
  public static getProductAvailabilityByMfgNameAndMfgPartUsingGet(
    mfgName: string,
    mfgPart: string,
  ): CancelablePromise<Array<SupplierProductDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/manufacturer/{mfgName}/part/{mfgPart}/availability',
      path: {
        'mfgName': mfgName,
        'mfgPart': mfgPart,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting suplier products availability by manufacturer name and manufactures part`,
      },
    });
  }
  /**
   * Part reactivation
   * Part reactivation
   * @param supplierProductRequestDto supplierProductRequestDto
   * @returns any Success
   * @throws ApiError
   */
  public static sendReactivateSupplierProductsEmailRequestUsingPost(
    supplierProductRequestDto: SupplierProductReactivationRequestDto,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/products/reactivate',
      body: supplierProductRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error with part reactivation`,
      },
    });
  }
  /**
   * Get Product By Supp Code and Supp Part
   * Get Product By Supp Code and Supp Part
   * @param suppCode suppCode
   * @param suppPart suppPart
   * @returns SupplierPartDetail Success
   * @throws ApiError
   */
  public static getPartBySuppCodeSuppPartUsingGet(
    suppCode: string,
    suppPart: string,
  ): CancelablePromise<SupplierPartDetail> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/supp_code/{suppCode}/supp_part/{suppPart}',
      path: {
        'suppCode': suppCode,
        'suppPart': suppPart,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Product Not Found`,
        500: `Internal Error Getting Product`,
      },
    });
  }
  /**
   * POST Supplier Product
   * POST Supplier Product
   * @param supplierProductRequestDto supplierProductRequestDto
   * @returns SupplierPartApprovalStatusDto Success
   * @throws ApiError
   */
  public static createSupplierProductUsingPost(
    supplierProductRequestDto: SupplierProductPostRequestDto,
  ): CancelablePromise<SupplierPartApprovalStatusDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/products/supplier/part',
      body: supplierProductRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error Creating Supplier Product`,
      },
    });
  }
  /**
   * Get supplier part approval status=
   * Get Supplier Part ApprovaL Status
   * @param supplierCode supplierCode
   * @param supplierPart supplierPart
   * @returns SupplierPartApprovalStatusDto Success
   * @throws ApiError
   */
  public static getSupplierPartApprovalStatusUsingGet(
    supplierCode: string,
    supplierPart: string,
  ): CancelablePromise<SupplierPartApprovalStatusDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/supplier/part/approvalStatus',
      query: {
        'supplierCode': supplierCode,
        'supplierPart': supplierPart,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `There was an error getting the supplier part approval status`,
      },
    });
  }
  /**
   * GET Supplier Part Suggestion
   * GET Supplier Part Suggestions
   * @param terms terms
   * @param cspSupplierId cspSupplierId
   * @param limit limit
   * @param parentAccountId parentAccountId
   * @param type type
   * @returns SupplierPartSuggestionDto Success
   * @throws ApiError
   */
  public static getSupplierPartSuggestionUsingGet(
    terms: string,
    cspSupplierId?: number,
    limit: number = 10,
    parentAccountId?: number,
    type: 'active' | 'all' = 'all',
  ): CancelablePromise<Array<SupplierPartSuggestionDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/supplier/part/suggest',
      query: {
        'cspSupplierId': cspSupplierId,
        'limit': limit,
        'parentAccountId': parentAccountId,
        'terms': terms,
        'type': type,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error Getting Supplier Part suggestions`,
      },
    });
  }
  /**
   * Patch supplier product
   * Patch supplier product
   * @param patch patch
   * @param supplierPartId supplierPartId
   * @returns SupplierPartDetailsDto Success
   * @throws ApiError
   */
  public static patchSupplierProductUsingPatch(
    patch: SupplierPartPatchRequestDto,
    supplierPartId: number,
  ): CancelablePromise<SupplierPartDetailsDto> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/products/supplier/part/{supplierPartId}',
      path: {
        'supplierPartId': supplierPartId,
      },
      body: patch,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Supplier Part not found`,
        500: `There was an error patching the supplier part`,
      },
    });
  }
  /**
   * Get the part availability by supplier part id
   * Get the part availability by supplier part id
   * @param supplierPartId supplierPartId
   * @returns SupplierPartAvailabilityDto Success
   * @throws ApiError
   */
  public static getPartAvailabilityBySupplierPartIdUsingGet(
    supplierPartId: number,
  ): CancelablePromise<SupplierPartAvailabilityDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/supplier/part/{supplierPartId}/availability',
      path: {
        'supplierPartId': supplierPartId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting the part availability by supplier part id`,
      },
    });
  }
  /**
   * Supplier Part Details
   * Supplier Part Details
   * @param supplierPartId supplierPartId
   * @param accountCode accountCode
   * @returns SupplierPartDetailsDto Success
   * @throws ApiError
   */
  public static getPartBySupplierPartIdUsingGet(
    supplierPartId: number,
    accountCode?: string,
  ): CancelablePromise<SupplierPartDetailsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/supplier/part/{supplierPartId}/details',
      path: {
        'supplierPartId': supplierPartId,
      },
      query: {
        'accountCode': accountCode,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Supplier Part No content found.`,
        500: `There was an error getting the supplier part`,
      },
    });
  }
  /**
   * Gets product details by product id.
   * Gets product details by product id.
   * @param productId productId
   * @returns ProductLineResponseDto Success
   * @throws ApiError
   */
  public static getProductDetailsByProductIdUsingGet(
    productId: number,
  ): CancelablePromise<ProductLineResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/{productId}',
      path: {
        'productId': productId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting product details by product id`,
      },
    });
  }
  /**
   * Update Product
   * Update product
   * @param productId productId
   * @param productRequestDto productRequestDto
   * @returns ProductLineResponseDto Success
   * @throws ApiError
   */
  public static updateProductUsingPatch(
    productId: number,
    productRequestDto: ProductPatchRequestDto,
  ): CancelablePromise<ProductLineResponseDto> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/products/{productId}',
      path: {
        'productId': productId,
      },
      body: productRequestDto,
      errors: {
        400: `Bad request.`,
        401: `Unauthorized`,
        403: `You are not authorized to access this account.`,
        404: `Product not found.`,
        500: `Internal Error updating product`,
      },
    });
  }
  /**
   * Gets product accessories.
   * Gets product details for account by mfg part and mfg name.
   * @param productId productId
   * @returns Accessory Success
   * @throws ApiError
   */
  public static getProductAccessoriesUsingGet(
    productId: number,
  ): CancelablePromise<Array<Accessory>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/{productId}/accessories',
      path: {
        'productId': productId,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting product accessories`,
      },
    });
  }
  /**
   * Gets product accessories.
   * Gets product details for account by mfg part and mfg name.
   * @param categoryId categoryId
   * @param productId productId
   * @returns Accessory Success
   * @throws ApiError
   */
  public static getProductAccessoriesForCategoryUsingGet(
    categoryId: string,
    productId: number,
  ): CancelablePromise<Array<Accessory>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/{productId}/accessories/{categoryId}',
      path: {
        'categoryId': categoryId,
        'productId': productId,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting product accessories`,
      },
    });
  }
  /**
   * Gets products availability by product id.
   * Gets products availability by product id.
   * @param productId productId
   * @returns SupplierProductDto Success
   * @throws ApiError
   */
  public static getProductAvailabilityByProductIdUsingGet(
    productId: number,
  ): CancelablePromise<Array<SupplierProductDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/{productId}/availability',
      path: {
        'productId': productId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting products availability by product id`,
      },
    });
  }
  /**
   * Gets discontinued products by manufacturer product id
   * Gets discontinued products by manufacturer product id
   * @param productId productId
   * @returns DiscontinuedProductResponseDto Success
   * @throws ApiError
   */
  public static getDiscontinuedProductsByMfgProductIdUsingGet(
    productId: number,
  ): CancelablePromise<Array<DiscontinuedProductResponseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/{productId}/discontinued',
      path: {
        'productId': productId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting discontinued products by manufacturer product id`,
      },
    });
  }
  /**
   * Gets product images product id.
   * Gets product images product id.
   * @param productId productId
   * @returns ProductImageDto Success
   * @throws ApiError
   */
  public static getPrimaryProductImageUsingGet(
    productId: number,
  ): CancelablePromise<ProductImageDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/{productId}/image',
      path: {
        'productId': productId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting product images product id`,
      },
    });
  }
  /**
   * Get Price History
   * Get Price History
   * @param accountId accountId
   * @param productId productId
   * @returns PriceHistoryDto Success
   * @throws ApiError
   */
  public static getPriceHistoryUsingGet(
    accountId: number,
    productId: number,
  ): CancelablePromise<PriceHistoryDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/{productId}/priceHistory',
      path: {
        'productId': productId,
      },
      query: {
        'accountId': accountId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `There was an error getting the price history by mfgProductId and accountId`,
      },
    });
  }
  /**
   * Gets product warranties.
   * Gets product warranties.
   * @param productId productId
   * @returns WarrantyData Success
   * @throws ApiError
   */
  public static getProductWarrantiesUsingGet(
    productId: number,
  ): CancelablePromise<WarrantyData> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/products/{productId}/warranties',
      path: {
        'productId': productId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `The user does not have the right privilege for this request`,
        404: `Not Found`,
        500: `Internal Error getting product warranties`,
      },
    });
  }
}
