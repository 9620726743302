import { createSvgIcon } from "@mui/material";

export const ArrowUpIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 14 7" xmlns="http://www.w3.org/2000/svg">
    <title>arrow-up</title>
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="final-main-nav"
        transform="translate(-1413.000000, -736.000000)"
      >
        <g
          id="sales-data-btn-active"
          transform="translate(1089.000000, 725.000000)"
        >
          <g
            id="chevron-down"
            transform="translate(331.398732, 14.500894) scale(1, -1) translate(-331.398732, -14.500894) translate(324.860058, 11.000000)"
          >
            <path
              d="M0.147750307,0.146893784 C0.242101534,0.052852083 0.370212683,0 0.503814447,0 C0.637416211,0 0.76552736,0.052852083 0.859878587,0.146893784 L6.53879987,5.79389378 L12.2177212,0.146893784 C12.3449306,0.0204217649 12.5303425,-0.028971181 12.7041138,0.0173207907 C12.8778852,0.0636127625 13.0136161,0.1985568 13.060178,0.371320791 C13.1067398,0.544084781 13.0570589,0.728421765 12.9298494,0.854893784 L6.89486401,6.85489378 C6.80051278,6.94893548 6.67240163,7.00178757 6.53879987,7.00178757 C6.40519811,7.00178757 6.27708696,6.94893548 6.18273573,6.85489378 L0.147750307,0.854893784 C0.0531602584,0.761089521 0,0.633721045 0,0.500893784 C0,0.368066523 0.0531602584,0.240698047 0.147750307,0.146893784 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "ArrowUp",
);
