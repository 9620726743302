import { Box, Typography } from "@mui/material";
import { Header } from "@tanstack/react-table";
import { isUndefined } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { Button } from "../../../ui";
import { useDataTableContext } from "../../DataTableContext";
import TimePicker from "./TimePicker";

const DEFAULT_TIME = "00:00";

const TimeFilter = <TData, TValue>({
  header,
  onClose,
}: {
  header: Header<TData, TValue>;
  onClose: () => void;
}) => {
  const { criteria, updateFilter } = useDataTableContext();
  const { id: element, header: label } = header.column.columnDef;

  // Eastern Time
  const [fromTime, setFromTime] = useState<string>(DEFAULT_TIME);
  const [toTime, setToTime] = useState<string>(DEFAULT_TIME);

  const initialFromValue = criteria.ge?.[0]?.[element ?? ""]?.[0];
  const initialToValue = criteria.le?.[0]?.[element ?? ""]?.[0];

  useEffect(() => {
    if (
      !isUndefined(initialFromValue) &&
      typeof initialFromValue === "string"
    ) {
      setFromTime(initialFromValue);
    }
  }, [initialFromValue]);

  useEffect(() => {
    if (!isUndefined(initialToValue) && typeof initialToValue === "string") {
      setToTime(initialToValue);
    }
  }, [initialFromValue, initialToValue]);

  const handleApply = useCallback(() => {
    if (element) {
      updateFilter([
        { field: element, key: "ge", values: [fromTime] },
        { field: element, key: "le", values: [toTime] },
      ]);
    }
    onClose();
  }, [element, updateFilter, fromTime, onClose, toTime]);

  const handleClear = useCallback(() => {
    if (element) {
      updateFilter([
        { field: element, key: "ge", values: [] },
        { field: element, key: "le", values: [] },
      ]);
      setFromTime("00:00");
      setToTime("00:00");
    }
    onClose();
  }, [element, updateFilter, onClose]);

  return (
    <Box sx={{ px: 2.5, py: 3 }}>
      <Typography fontWeight="500" sx={{ mb: 3 }} variant="body2">
        {`Filter By ${label}`}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", px: 2 }}>
        <Box>
          <Typography
            fontWeight="500"
            lineHeight={1}
            sx={{ mb: 2 }}
            variant="body2"
          >
            From:
          </Typography>
          <TimePicker
            value={fromTime}
            onChange={(value) => setFromTime(value)}
          />
        </Box>

        <Box>
          <Typography
            fontWeight="500"
            lineHeight={1}
            sx={{ mb: 2 }}
            variant="body2"
          >
            To:
          </Typography>

          <TimePicker value={toTime} onChange={(value) => setToTime(value)} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
          px: 1,
        }}
      >
        <Button variant="outlined" onClick={handleClear}>
          Clear
        </Button>

        <Button color="primary" variant="contained" onClick={handleApply}>
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default memo(TimeFilter) as typeof TimeFilter;
