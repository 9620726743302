import { useEffect, useState } from "react";
import ImportExternalLicenses from "../ImportExternalLicenses";

const ImportExternalLicensesWrapper = () => {
  const [importing, setImporting] = useState(false);

  useEffect(() => {
    document.addEventListener("startImportExternalLicenses", () =>
      setImporting(true),
    );
  }, []);

  const handleCancel = () => setImporting(false);
  return <>{importing && <ImportExternalLicenses onClose={handleCancel} />}</>;
};

export default ImportExternalLicensesWrapper;
