import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  LicenseProgramResponseDto,
  ManufacturerProductSolrDto,
  ProductLineResponseDto,
} from "../../api";
import { useProductUpdate } from "../../hooks/api/mutations/useProductUpdate";
import { useLicensePrograms, useProductDetails } from "../../hooks/api/queries";
import { Button, LoadingButton, TextField } from "../ui";

const schema = yup
  .object({
    connectionSku: yup
      .number()
      .optional()
      .min(1, "Must be greater than or equal to 1")
      .max(100000000, "Must be less than or equal to 100000000")
      .test("no-leading-zero", "Must be a valid integer", (value, context) => {
        if (!context.originalValue) {
          return true;
        }
        return (
          context.originalValue &&
          !context.originalValue.toString().startsWith("0")
        );
      })
      .label("E1 SKU")
      .transform((_, val) => (val !== "" ? Number(val) : undefined)),
    licenseProgramId: yup.string(),
  })
  .required();

type FormValues = {
  connectionSku?: number;
  licenseProgramId?: string;
};

const FormFieldLabel = styled(Typography)(({ theme }) => ({
  flexShrink: 0,
  fontSize: 14,
  fontWeight: "medium",
  lineHeight: theme.spacing(4.5),
  width: theme.spacing(14),
}));

const SkuAndLicenseProgram = ({
  product,
  isOpen,
  onClose,
}: {
  product: ManufacturerProductSolrDto;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { data: productDetails } = useProductDetails(
    product.manufacturerProductId,
  );
  const { data: licensePrograms } = useLicensePrograms();

  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={onClose}>
      {productDetails && licensePrograms ? (
        <SkuAndLicenseProgramForm
          licensePrograms={licensePrograms}
          product={product}
          productDetails={productDetails}
          onClose={onClose}
        />
      ) : (
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "center",
            m: theme.spacing(1.5),
          })}
        >
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};

const SkuAndLicenseProgramForm = ({
  product,
  productDetails,
  licensePrograms,
  onClose,
}: {
  product: ManufacturerProductSolrDto;
  productDetails: ProductLineResponseDto;
  licensePrograms: LicenseProgramResponseDto[];
  onClose: () => void;
}) => {
  const { mutate: updateProduct, isLoading } = useProductUpdate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      connectionSku: productDetails.connectionSku
        ? parseInt(productDetails.connectionSku)
        : undefined,
      licenseProgramId: productDetails.licenseProgramId?.toString() ?? "",
    },
    resolver: yupResolver<FormValues>(schema),
  });

  const onSubmit = useCallback(
    (values: FormValues) => {
      if (product.manufacturerProductId) {
        updateProduct(
          {
            productId: product.manufacturerProductId,
            request: {
              connectionSku: (values.connectionSku
                ? +values.connectionSku
                : null) as number,
              licenseProgramId: (values.licenseProgramId
                ? +values.licenseProgramId
                : null) as number,
            },
          },
          {
            onError: () => {
              enqueueSnackbar({
                message: (
                  <>
                    <strong>
                      {product.mfgName} | {product.mfgPart}{" "}
                    </strong>
                    Unable to update E1 SKU and License Program.
                  </>
                ),
                variant: "error",
              });
            },
            onSettled: () => {
              onClose();
            },
            onSuccess: () => {
              enqueueSnackbar({
                message: (
                  <>
                    <strong>
                      {product.mfgName} | {product.mfgPart}{" "}
                    </strong>
                    E1 SKU and License Program updated.
                  </>
                ),
                variant: "success",
              });
            },
          },
        );
      }
    },
    [
      product.manufacturerProductId,
      product.mfgName,
      product.mfgPart,
      updateProduct,
      onClose,
      enqueueSnackbar,
    ],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle
        sx={(theme) => ({
          fontSize: theme.spacing(2.25),
          m: 0,
          pb: 0,
          pt: 3.5,
          px: 2,
        })}
      >
        {product.mfgName} | {product.mfgPart}
      </DialogTitle>
      <IconButton
        sx={{
          color: (theme) => theme.palette.grey[900],
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box
          sx={(theme) => ({
            display: "flex",
          })}
        >
          <FormFieldLabel>E1 SKU</FormFieldLabel>
          <TextField
            error={!!errors.connectionSku}
            helperText={errors.connectionSku?.message ?? ""}
            size="small"
            {...register("connectionSku")}
            InputProps={{
              endAdornment: !!errors.connectionSku ? (
                <InputAdornment position="end">
                  <ErrorOutlineIcon
                    sx={(theme) => ({
                      color: theme.palette.red["500"],
                    })}
                  />
                </InputAdornment>
              ) : null,
            }}
            sx={{ flex: 1 }}
            type="number"
          />
        </Box>

        <Box
          sx={(theme) => ({
            display: "flex",
            mt: theme.spacing(2),
          })}
        >
          <FormFieldLabel>License Program</FormFieldLabel>
          <Box flex="1">
            <Select
              {...register("licenseProgramId")}
              displayEmpty
              fullWidth
              size="small"
              value={watch("licenseProgramId")}
            >
              <MenuItem value="">None</MenuItem>
              {licensePrograms?.map((program) => (
                <MenuItem
                  key={program.licenseProgramId}
                  value={program.licenseProgramId}
                >
                  {program.licenseProgramName}
                </MenuItem>
              ))}
            </Select>
            {!!errors.licenseProgramId && (
              <FormHelperText error>
                {errors.licenseProgramId.message}
              </FormHelperText>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pb: 2,
          px: 3,
        }}
      >
        <Button
          data-testid="sku-modal-close-btn"
          variant="outlined"
          onClick={onClose}
        >
          Close
        </Button>
        <LoadingButton
          color="primary"
          data-testid="sku-modal-save-btn"
          loading={isLoading}
          type="submit"
          variant="contained"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default SkuAndLicenseProgram;
