import { createSvgIcon } from "@mui/material";

export const ScheduleIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <title>schedule</title>
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fillRule="nonzero"
        id="main-nav"
        transform="translate(-1047.000000, -264.000000)"
      >
        <g id="Group-16" transform="translate(29.000000, 254.000000)">
          <g id="schedule" transform="translate(997.000000, 0.000000)">
            <g id="calendar2-plus" transform="translate(21.000000, 10.000000)">
              <path
                d="M3.5,0 C3.77614237,0 4,0.223857625 4,0.5 L4,1 L12,1 L12,0.5 C12,0.223857625 12.2238576,0 12.5,0 C12.7761424,0 13,0.223857625 13,0.5 L13,1 L14,1 C15.1045695,1 16,1.8954305 16,3 L16,14 C16,15.1045695 15.1045695,16 14,16 L2,16 C0.8954305,16 0,15.1045695 0,14 L0,3 C0,1.8954305 0.8954305,1 2,1 L3,1 L3,0.5 C3,0.223857625 3.22385763,0 3.5,0 Z M2,2 C1.44771525,2 1,2.44771525 1,3 L1,14 C1,14.5522847 1.44771525,15 2,15 L14,15 C14.5522847,15 15,14.5522847 15,14 L15,3 C15,2.44771525 14.5522847,2 14,2 L2,2 Z"
                fill="currentColor"
                id="Shape"
              ></path>
              <path
                d="M2.5,4 C2.5,3.72385763 2.72385763,3.5 3,3.5 L13,3.5 C13.2761424,3.5 13.5,3.72385763 13.5,4 L13.5,5 C13.5,5.27614237 13.2761424,5.5 13,5.5 L3,5.5 C2.72385763,5.5 2.5,5.27614237 2.5,5 L2.5,4 Z M8,8 C8.27614237,8 8.5,8.22385763 8.5,8.5 L8.5,10 L10,10 C10.2761424,10 10.5,10.2238576 10.5,10.5 C10.5,10.7761424 10.2761424,11 10,11 L8.5,11 L8.5,12.5 C8.5,12.7761424 8.27614237,13 8,13 C7.72385763,13 7.5,12.7761424 7.5,12.5 L7.5,11 L6,11 C5.72385763,11 5.5,10.7761424 5.5,10.5 C5.5,10.2238576 5.72385763,10 6,10 L7.5,10 L7.5,8.5 C7.5,8.22385763 7.72385763,8 8,8 Z"
                fill="currentColor"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "Schedule",
);
