import { useQuery } from "@tanstack/react-query";
import { QuotesResourceService } from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useRecentQuotes = (accountId: number) => {
  return useQuery(
    [QUERY_KEYS["recent-quotes"], accountId],
    () => QuotesResourceService.getRecentQuotesUsingGet(accountId),
    { enabled: !!accountId },
  );
};
