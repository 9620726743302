import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { OrderLineDto } from "../../api";

export const OrderLineShippingInfoPopoverButton = ({
  orderLine,
}: {
  orderLine: OrderLineDto;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <IconButton
        aria-label="Order Info"
        data-testid="order-line-dispatch-btn"
        size="small"
        sx={(theme) => ({
          color: theme.palette.blue["600"],
        })}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <ErrorOutlineIcon
          sx={(theme) => ({ color: theme.palette.blue["600"], fontSize: 18 })}
        />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "right",
          vertical: "center",
        }}
        open={Boolean(anchorEl)}
        transformOrigin={{
          horizontal: "left",
          vertical: "center",
        }}
        onClose={() => setAnchorEl(null)}
      >
        <Box sx={(theme) => ({ px: theme.spacing(3.5), py: theme.spacing(3) })}>
          <Typography fontWeight="medium">Shipping Information</Typography>
          <Box
            data-testid="order-line-dispatch-info"
            sx={(theme) => ({
              "& .MuiTypography-root": {
                fontSize: 14,
              },
              alignItems: "center",
              display: "flex",
              gap: theme.spacing(0.5),
              mt: theme.spacing(1.5),
              whiteSpace: "nowrap",
            })}
          >
            <Typography>Dispatched on</Typography>
            <Typography fontWeight="500">
              {orderLine.dispatchDate
                ? DateTime.fromISO(orderLine.dispatchDate).toFormat("M/d/yyyy")
                : ""}
            </Typography>
            <Typography>at</Typography>
            <Typography fontWeight="500">
              {orderLine.dispatchTime?.replace("AM", "am")?.replace("PM", "pm")}
            </Typography>
            <Typography>via</Typography>
            <Typography fontWeight="500">{orderLine.dispatchMethod}</Typography>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
