import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ClientRequestDto,
  ClientResourceService,
  ClientResponseDto,
} from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useClientResourceCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<ClientResponseDto, Error, { request: ClientRequestDto }>(
    ({ request }) => {
      return ClientResourceService.createClientUsingPost(request);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries([QUERY_KEYS["client-resource"]]);
        queryClient.refetchQueries();
      },
    },
  );
};
