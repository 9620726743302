import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { ChangeEvent, useRef, useState } from "react";
import readXlsxFile from "read-excel-file";
import { ImportWizardFileRecord } from "../../../types";
import { readCsvFile } from "../../../utils/importWizard";
import { Button, VCenterBox } from "../../ui";
import { useImportWizardContext } from "../ImportWizardContext";

export const SelectFile = ({
  onNext,
  title,
  headline,
  subHeadline,
}: {
  onNext: () => void;
  title: string;
  headline: string;
  subHeadline: string;
}) => {
  const { setFileContents, onClose } = useImportWizardContext();
  const [file, setFile] = useState<File | null>(null);
  const fileInput = useRef<HTMLInputElement>(null);

  const handleClickChooseFile = () => {
    fileInput.current?.click();
  };
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };
  const handleClickUpload = async () => {
    if (!file) {
      return;
    }

    setFileContents(
      (file.name.endsWith(".csv")
        ? await readCsvFile(file)
        : await readXlsxFile(file)) as ImportWizardFileRecord[],
    );

    onNext();
  };

  return (
    <Dialog fullWidth open maxWidth="md" onClose={onClose}>
      <DialogTitle sx={{ m: 0, px: 2, py: 4 }}>{title}</DialogTitle>
      <IconButton
        sx={{
          color: (theme) => theme.palette.grey[900],
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ px: 3, py: 0 }}>
        <Typography fontWeight="500" variant="body1">
          {headline}
        </Typography>

        <Typography color="red.500" fontWeight="500" variant="caption">
          {subHeadline}
        </Typography>
        <Box
          sx={(theme) => ({
            border: `1px solid ${theme.palette.grey["400"]}`,
            borderRadius: "2px",
            display: "flex",
            height: theme.spacing(4.5),
            my: 4,
            overflow: "hidden",
          })}
        >
          <Button
            sx={(theme) => ({
              borderBottomWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 1,
              borderTopWidth: 0,
              width: theme.spacing(14),
            })}
            variant="outlined"
            onClick={handleClickChooseFile}
          >
            Choose File
          </Button>
          <VCenterBox
            height="100%"
            sx={(theme) => ({
              flexGrow: 1,
              height: "100%",
              pl: theme.spacing(2.5),
            })}
          >
            <Typography color="grey.500" variant="body1">
              {file ? file.name : "No file chosen"}
            </Typography>
          </VCenterBox>
        </Box>
        <input
          ref={fileInput}
          accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          style={{ display: "none" }}
          type="file"
          onChange={handleFileChange}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", pb: 3, px: 2 }}>
        <Button
          sx={(theme) => ({ width: theme.spacing(12.5) })}
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={file === null}
          sx={(theme) => ({ width: theme.spacing(12.5) })}
          variant="contained"
          onClick={handleClickUpload}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};
