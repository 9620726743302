import { createSvgIcon } from "@mui/material";

export const FileTxtIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 15 19" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M7.5,0 L9.57016536,2.36268872 L12,5.13586957 L12,15.9782609 C12,17.2389108 11.1045695,18.2608696 10,18.2608696 L9,18.26 L9,17.119 L10,17.1195652 C10.5154658,17.1195652 10.9398399,16.6744454 10.9941321,16.1026186 L11,15.9782609 L11,5.13586957 L9,5.13586957 C8.17157288,5.13586957 7.5,4.36940052 7.5,3.42391304 L7.5,1.14130435 L2,1.14130435 C1.44771525,1.14130435 1,1.65228371 1,2.2826087 L1,12.173 L0,12.173913 L0,2.2826087 C0,1.02195872 0.8954305,0 2,0 L7.5,0 Z"
        id="path-1"
      ></path>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
      id="Page-1"
      stroke="none"
      stroke-width="1"
    >
      <g id="main-nav" transform="translate(-1111, -366)">
        <g id="Group" transform="translate(1111.09, 366)">
          <g id="Group-23" transform="translate(-0, 0)">
            <g id="Rectangle" transform="translate(2.91, 0)">
              <mask fill="white" id="mask-2">
                <use xlinkHref="#path-1"></use>
              </mask>
              <use
                fill="#242021"
                fill-rule="nonzero"
                id="Combined-Shape"
                xlinkHref="#path-1"
              ></use>
            </g>
            <g
              fill="#242021"
              fill-rule="nonzero"
              id="TXT"
              transform="translate(0, 13.2686)"
            >
              <polygon
                id="Path"
                points="1.13898305 0.574576271 0 0.574576271 0 0 3 0 3 0.574576271 1.86101695 0.574576271 1.86101695 3.76271186 1.13898305 3.76271186"
              ></polygon>
              <polygon
                id="Path"
                points="4.36 1.81135593 3.1 0.0313559322 3.905 0.0313559322 4.69 1.18635593 4.795 1.38135593 4.835 1.38135593 4.94 1.18635593 5.73 0.0313559322 6.525 0.0313559322 5.24 1.83635593 6.62 3.73135593 5.815 3.73135593 4.925 2.43635593 4.83 2.26635593 4.79 2.26635593 4.7 2.43635593 3.815 3.73135593 3.015 3.73135593"
              ></polygon>
              <polygon
                id="Path"
                points="7.795 0.596355932 6.675 0.596355932 6.675 0.0313559322 9.625 0.0313559322 9.625 0.596355932 8.505 0.596355932 8.505 3.73135593 7.795 3.73135593"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "FileTxt",
);
