import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import { useAuthContext } from "./contexts/AuthContext";
import { withUserPermission } from "./hocs";
import ClientResourceReport from "./pages/ClientResourceReport";
import DealsList from "./pages/DealsList/DealsList";
import GlobalInvoiceLineList from "./pages/GlobalInvoiceLineList";
import InvoiceDetail from "./pages/InvoiceDetail";
import NotFound from "./pages/NotFound";
import OrderDetail from "./pages/OrderDetail";
import OrderLinesReport from "./pages/OrderLinesReport/OrderLinesReport";
import OrdersList from "./pages/OrdersList";
import ProductsReport from "./pages/ProductsReport/ProductsReport";
import PurchaseAnalysis from "./pages/PurchaseAnalysis";
import QuotesList from "./pages/QuotesList";
import SearchResults from "./pages/SearchResults";
import ShippingAddressesList from "./pages/ShippingAddressesList";
import StaffList from "./pages/StaffList/StaffList";
import StandardItemsList from "./pages/StandardItemsList/StandardItemsList";
import StockingOrderDetail from "./pages/StockingOrderDetail";
import UserList from "./pages/UserList";
import { UserPermission } from "./types";
import { redirectToAuthPage } from "./utils/link";

const App = () => {
  const { token } = useAuthContext();

  useEffect(() => {
    if (!token) {
      redirectToAuthPage();
    }
  }, [token]);

  return token ? (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route element={<ProductsReport />} path="products/view?/:view?" />
          <Route
            Component={withUserPermission(
              StaffList,
              UserPermission.MANAGE_APPLICATION_USERS,
            )}
            path="staff/view?/:view?"
          />
          <Route element={<OrderDetail />} path="orders/:orderId" />
          <Route
            element={<OrderLinesReport />}
            path="orderlines/view?/:view?"
          />
          <Route
            element={<StockingOrderDetail />}
            path="stockingorders/:stockingOrderId"
          />
          <Route element={<InvoiceDetail />} path="invoices/:invoiceId" />
          <Route
            element={<StandardItemsList />}
            path="standarditems/view?/:view?"
          />
          <Route element={<DealsList />} path="deals/view?/:view?" />
          <Route element={<UserList />} path="users/view?/:view?" />
          <Route
            element={<GlobalInvoiceLineList />}
            path="globalinvoicelines/view?/:view?"
          />
          <Route element={<QuotesList />} path="quotes/view?/:view?" />
          <Route element={<OrdersList />} path="orders/view?/:view?" />
          <Route
            element={<ShippingAddressesList />}
            path="shippingaddresses/view?/:view?"
          />
          <Route element={<SearchResults />} path="results" />
          <Route element={<PurchaseAnalysis />} path="purchaseanalysis" />
          <Route
            Component={withUserPermission(
              ClientResourceReport,
              UserPermission.MANAGE_CLIENT_USERS,
            )}
            path="crmclients/view?/:view?"
          />
        </Route>
        <Route element={<NotFound />} path="*" />
      </Routes>
    </BrowserRouter>
  ) : null;
};

export default App;
