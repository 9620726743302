import { Box, FormControl, Theme, Typography } from "@mui/material";
import { Header } from "@tanstack/react-table";
import { isUndefined } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useListingFacets } from "../../../hooks/api/queries";
import { ColumnType } from "../../../types";
import { Button, TextField } from "../../ui";
import { useDataTableContext } from "../DataTableContext";

const NumberFilter = <TData, TValue>({
  header,
  onClose,
}: {
  header: Header<TData, TValue>;
  onClose: () => void;
}) => {
  const [fromValue, setFromValue] = useState<number>();
  const [toValue, setToValue] = useState<number>();
  const { criteria, updateFilter, listingName } = useDataTableContext();
  const { header: label, id: element, meta } = header.column.columnDef;
  const type = meta as ColumnType;

  const initialFromValue = criteria.ge?.[0]?.[element ?? ""]?.[0];
  const initialToValue = criteria.le?.[0]?.[element ?? ""]?.[0];

  useEffect(() => {
    if (!isUndefined(initialFromValue)) {
      setFromValue(+initialFromValue);
    }
  }, [initialFromValue]);

  useEffect(() => {
    if (!isUndefined(initialToValue)) {
      setToValue(+initialToValue);
    }
  }, [initialFromValue, initialToValue]);

  const { data } = useListingFacets({
    column: element ?? "",
    enabled: !!listingName && criteria.fields?.includes(element ?? ""),
    listingName: listingName!,
  });

  const onApply = useCallback(() => {
    if (element) {
      updateFilter([
        {
          field: element,
          key: "ge",
          values: isUndefined(fromValue) ? [] : [fromValue.toString()],
        },
        {
          field: element,
          key: "le",
          values: isUndefined(toValue) ? [] : [toValue.toString()],
        },
      ]);
    }
    onClose();
  }, [element, fromValue, onClose, toValue, updateFilter]);

  const onClear = useCallback(() => {
    if (element) {
      updateFilter([
        {
          field: element,
          key: "ge",
          values: [],
        },
        {
          field: element,
          key: "le",
          values: [],
        },
      ]);
    }

    onClose();
    setFromValue(undefined);
    setToValue(undefined);
  }, [element, onClose, updateFilter]);

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ py: 1 }}>
        <Typography fontSize="sm" fontWeight="medium">
          {`Filter by ${label}:`}
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: theme.spacing(1.5),
          mt: theme.spacing(3),
        })}
      >
        <FormControl>
          <Typography sx={{ pb: 0.5 }} variant="body2">
            From:
          </Typography>
          <NumericFormat
            FormHelperTextProps={{
              sx: (theme: Theme) => ({
                color: theme.palette.grey["500"],
                fontSize: theme.spacing(1.5),
                ml: 0,
              }),
            }}
            customInput={TextField}
            decimalScale={type === "integer" ? 0 : 2}
            fixedDecimalScale={type !== "integer"}
            helperText={
              <span data-testid="dt-number-filter-min-limit">
                Min: {data?.minimum ?? "-"}
              </span>
            }
            inputProps={{
              "data-testid": "dt-number-filter-min-input",
            }}
            prefix={type === "currency" ? "$" : undefined}
            suffix={type === "percentage" ? "%" : undefined}
            thousandSeparator=","
            value={isUndefined(fromValue) ? "" : fromValue}
            onValueChange={(values) => setFromValue(values.floatValue)}
          />
        </FormControl>

        <FormControl>
          <Typography sx={{ pb: 0.5 }} variant="body2">
            To:
          </Typography>
          <NumericFormat
            FormHelperTextProps={{
              sx: (theme: Theme) => ({
                color: theme.palette.grey["500"],
                fontSize: theme.spacing(1.5),
                ml: 0,
              }),
            }}
            customInput={TextField}
            decimalScale={type === "integer" ? 0 : 2}
            fixedDecimalScale={type !== "integer"}
            helperText={
              <span data-testid="dt-number-filter-max-limit">
                Max: {data?.maximum ?? "-"}
              </span>
            }
            inputProps={{
              "data-testid": "dt-number-filter-max-input",
            }}
            prefix={type === "currency" ? "$" : undefined}
            suffix={type === "percentage" ? "%" : undefined}
            thousandSeparator=","
            value={isUndefined(toValue) ? "" : toValue}
            onValueChange={({ floatValue }) => setToValue(floatValue)}
          />
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2.5 }}>
        <Button variant="outlined" onClick={onClear}>
          Clear
        </Button>
        <Button
          color="primary"
          disabled={Boolean(fromValue && toValue && toValue < fromValue)}
          variant="contained"
          onClick={() => onApply()}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default memo(NumberFilter) as typeof NumberFilter;
