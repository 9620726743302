import { Box, CssBaseline, Stack, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CloudMaskSvg from "../../assets/svgs/cloud-mask.svg";
import GraphicLaptopSvg from "../../assets/svgs/graphic-laptop.svg";
import { Button } from "../../components/ui";
import { withPageTitle } from "../../hocs";
import theme from "../../theme";

const Forbidden = () => {
  const handleClickBack = () => {
    window.location.href = "/";
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          position: "relative",
          width: "100%",
        }}
      >
        <img
          alt="Not Found Cloud"
          src={CloudMaskSvg}
          style={{ position: "absolute", right: 0, top: 0 }}
        />
        <Stack sx={{ alignItems: "center" }}>
          <img alt="Laptop" src={GraphicLaptopSvg} />
          <Typography
            sx={(theme) => ({
              color: "#2FA3DE",
              fontSize: 150,
              fontWeight: 600,
              lineHeight: "150px",
            })}
          >
            403
          </Typography>
          <Typography sx={{ color: "#231F20", fontSize: 48 }}>
            Forbidden
          </Typography>
          <Typography
            sx={(theme) => ({
              color: "#231F20",
              fontSize: 24,
              py: theme.spacing(2.5),
            })}
          >
            We are sorry, but you do not have access to this page or resource.
          </Typography>
          <Button
            color="primary"
            size="large"
            sx={{
              border: "solid 1px #0D99D7",
              borderRadius: 0.5,
              fontSize: 16,
              px: 6,
              py: 3,
            }}
            variant="contained"
            onClick={handleClickBack}
          >
            Back to Home
          </Button>
        </Stack>
      </Box>
      <CssBaseline />
    </ThemeProvider>
  );
};

export default withPageTitle("Forbidden", Forbidden);
