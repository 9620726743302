import CloseIcon from "@mui/icons-material/Close";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { StaffListing } from "../../api";
import { useUserUpdate } from "../../hooks/api/mutations";
import {
  includesLowerCase,
  includesNumber,
  includesUpperCase,
} from "../../utils/string";
import { BulletIcon, EyeIcon, EyeSlashIcon } from "../icons";
import { Button, TextField } from "../ui";

const StaffChangePasswordModal = ({
  isOpen,
  staff,
  onClose,
}: {
  isOpen: boolean;
  staff: StaffListing;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { mutate: updateUser } = useUserUpdate();

  const handleSubmit = useCallback(() => {
    if (staff.userId) {
      updateUser(
        {
          userData: {
            password,
          },
          userId: staff.userId,
        },
        {
          onError: () => {
            enqueueSnackbar({
              message: `Unable to update password for ${staff.firstName} ${staff.lastName}`,
              variant: "error",
            });
          },
          onSettled: () => {
            onClose();
          },
          onSuccess: () => {
            enqueueSnackbar({
              message: `Password updated for ${staff.firstName} ${staff.lastName}`,
              variant: "success",
            });
          },
        },
      );
    }
  }, [updateUser, password, staff, onClose, enqueueSnackbar]);

  const passwordStrengthIndicatorConfig = [
    {
      indicatorColor: theme.palette.red[600],
      text: "Very weak",
      textColor: theme.palette.red[500],
    },
    {
      indicatorColor: theme.palette.yellow[100],
      text: "Fair",
      textColor: theme.palette.yellow[400],
    },
    {
      indicatorColor: theme.palette.blue[800],
      text: "Good",
      textColor: theme.palette.blue[700],
    },
    {
      indicatorColor: theme.palette.green[500],
      text: "Strong",
      textColor: theme.palette.green[200],
    },
  ];

  const passwordStrength = useMemo(() => {
    let strength = 1;
    if (includesLowerCase(password) && includesUpperCase(password)) strength++;
    if (includesNumber(password)) strength++;
    if (password.length >= 8) strength++;

    return strength;
  }, [password]);

  const passwordPolicies = [
    {
      isActive: password.length >= 8,
      key: 1,
      label: "Must be at least 8 characters.",
    },
    {
      isActive: includesLowerCase(password) && includesUpperCase(password),
      key: 2,
      label: "Must include at least one lower case and one upper case letter.",
    },
    {
      isActive: includesNumber(password),
      key: 3,
      label: "Must include at least one number.",
    },
  ];

  return (
    <Dialog
      fullWidth
      data-testid="staff-change-password-modal"
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle
        data-testid="staff-change-password-modal-title"
        sx={(theme) => ({
          fontSize: theme.spacing(2.25),
          m: 0,
          pb: 0,
          pt: 3.5,
          px: 2,
        })}
      >
        Change Password for {staff.firstName} {staff.lastName} ({staff.username}
        )
      </DialogTitle>
      <IconButton
        data-testid="staff-change-password-modal-close-icon"
        sx={{
          color: (theme) => theme.palette.grey[900],
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent
        className="change-password-modal"
        data-testid="staff-change-password-modal-body"
      >
        <Box
          sx={(theme) => ({ display: "flex", gap: theme.spacing(4), mt: 2 })}
        >
          <Typography
            component="label"
            htmlFor="password"
            lineHeight={(theme) => theme.spacing(4.5)}
            variant="body2"
          >
            Password:
          </Typography>

          <Box sx={{ width: "100%" }}>
            <Box sx={{ position: "relative" }}>
              <TextField
                fullWidth
                autoComplete="new-password"
                id="password"
                name="password"
                type={isPasswordVisible ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <IconButton
                sx={(theme) => ({
                  color: theme.palette.blue["800"],
                  position: "absolute",
                  right: theme.spacing(1),
                  top: "50%",
                  transform: "translateY(-50%)",
                })}
                onClick={() => setIsPasswordVisible((prev) => !prev)}
              >
                {isPasswordVisible ? (
                  <EyeIcon sx={{ height: 11, width: 16 }} />
                ) : (
                  <EyeSlashIcon sx={{ height: 13, width: 16 }} />
                )}
              </IconButton>
            </Box>

            {password ? (
              <Box sx={{ mt: 0.5 }}>
                <Box
                  sx={(theme) => ({
                    display: "grid",
                    gap: theme.spacing(0.25),
                    gridTemplateColumns: "repeat(4, 1fr)",
                  })}
                >
                  {Array(passwordStrength)
                    .fill(0)
                    .map((_, index) => (
                      <Box
                        key={index}
                        sx={{
                          background:
                            passwordStrengthIndicatorConfig[
                              passwordStrength - 1
                            ].indicatorColor,
                          height: 3,
                          width: "100%",
                        }}
                      />
                    ))}
                  {Array(4 - passwordStrength)
                    .fill(0)
                    .map((_, index) => (
                      <Box
                        key={index}
                        sx={(theme) => ({
                          background: theme.palette.grey["100"],
                          height: 3,
                          width: "100%",
                        })}
                      />
                    ))}
                </Box>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 1.5 }}
                >
                  <Typography
                    fontWeight={500}
                    sx={{
                      color:
                        passwordStrengthIndicatorConfig[passwordStrength - 1]
                          .textColor,
                    }}
                    variant="caption"
                  >
                    {passwordStrengthIndicatorConfig[passwordStrength - 1].text}
                  </Typography>
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>

        <List sx={{ mt: 1 }}>
          {passwordPolicies.map((policy) => (
            <ListItem key={policy.key} sx={{ px: 1, py: 0.5 }}>
              <ListItemIcon
                sx={(theme) => ({
                  color: policy.isActive
                    ? theme.palette.blue["800"]
                    : theme.palette.grey["500"],
                  minWidth: theme.spacing(2.75),
                })}
              >
                <BulletIcon sx={{ height: 8, width: 8 }} />
              </ListItemIcon>
              <ListItemText
                primary={policy.label}
                primaryTypographyProps={{
                  color: policy.isActive ? "grey.900" : "grey.500",
                  variant: "body2",
                }}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "space-between", pb: 2, pt: 0, px: 2 }}
      >
        <Button
          data-testid="staff-change-password-modal-close-btn"
          sx={(theme) => ({ width: theme.spacing(12.5) })}
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          data-testid="staff-change-password-modal-save-btn"
          disabled={!password || passwordStrength <= passwordPolicies.length}
          sx={(theme) => ({ width: theme.spacing(12.5) })}
          variant="contained"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default StaffChangePasswordModal;
