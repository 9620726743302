import { differenceInDays } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { OrderLineSolrDto } from "../../api";
import ListingPage from "../../components/ListingPage";
import ReturnTrackingCodesModal from "../../components/modals/ReturnTrackingCodesModal";
import RmaRequestModal from "../../components/modals/RmaRequest/RmaRequestModal";
import { withPageTitle } from "../../hocs";
import { ActionColumnItem } from "../../types";

enum ActionModalType {
  VIEW_RETURN_TRACKING_CODES,
  SUBMIT_RMA_REQUEST,
}

const OrderLinesReport = () => {
  const [selectedOrderLine, setSelectedOrderLine] =
    useState<OrderLineSolrDto>();
  const [selectedActionModal, setSelectedActionModal] =
    useState<ActionModalType>();
  const actionItems: ActionColumnItem<OrderLineSolrDto>[] = useMemo(
    () => [
      {
        isVisible: () => true,
        label: "View Return Tracking Codes",
        onClick: (orderLine: OrderLineSolrDto) => {
          setSelectedOrderLine(orderLine);
          setSelectedActionModal(ActionModalType.VIEW_RETURN_TRACKING_CODES);
        },
      },
      {
        isVisible: (orderLine: OrderLineSolrDto) => {
          if (!orderLine?.lastInvoiceDate) {
            return false;
          }

          const today = new Date();
          const utcToday = new Date(
            today.getUTCFullYear(),
            today.getUTCMonth(),
            today.getUTCDate(),
          );
          const lastInvoiceDate = new Date(
            orderLine?.lastInvoiceDate as string,
          );
          const utcLastInvoiceDate = new Date(
            lastInvoiceDate.getUTCFullYear(),
            lastInvoiceDate.getUTCMonth(),
            lastInvoiceDate.getUTCDate(),
          );

          return differenceInDays(utcToday, utcLastInvoiceDate) <= 30;
        },
        label: "Submit RMA Request",
        onClick: (orderLine: OrderLineSolrDto) => {
          setSelectedOrderLine(orderLine);
          setSelectedActionModal(ActionModalType.SUBMIT_RMA_REQUEST);
        },
      },
    ],
    [],
  );
  const handleCloseModal = useCallback(() => {
    setSelectedOrderLine(undefined);
    setSelectedActionModal(undefined);
  }, []);

  return (
    <>
      <ListingPage<OrderLineSolrDto>
        actionColumnItems={actionItems}
        additionalColumnId="description"
        identifier="orderLineId"
        listingName="orderlines"
      />
      {selectedOrderLine &&
        selectedActionModal === ActionModalType.VIEW_RETURN_TRACKING_CODES && (
          <ReturnTrackingCodesModal
            isOpen
            orderLine={selectedOrderLine}
            onClose={handleCloseModal}
          />
        )}
      {selectedOrderLine &&
        selectedActionModal === ActionModalType.SUBMIT_RMA_REQUEST && (
          <RmaRequestModal
            orderLine={selectedOrderLine}
            onClose={handleCloseModal}
          />
        )}
    </>
  );
};

export default withPageTitle("Order Lines Report", OrderLinesReport);
