import { createSvgIcon } from "@mui/material";

export const GripVerticalIcon = createSvgIcon(
  <svg version="1.1" viewBox="0 0 16 11" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="pg1"
        transform="translate(-316, -367)"
      >
        <g id="table" transform="translate(307, 312)">
          <g id="tr" transform="translate(9, 53)">
            <g id="grip-vertical" transform="translate(0, 2)">
              <path
                d="M2,1 C2,1.55228475 1.55228475,2 1,2 C0.44771525,2 0,1.55228475 0,1 C0,0.44771525 0.44771525,0 1,0 C1.55228475,0 2,0.44771525 2,1 Z M5,1 C5,1.55228475 4.55228475,2 4,2 C3.44771525,2 3,1.55228475 3,1 C3,0.44771525 3.44771525,0 4,0 C4.55228475,0 5,0.44771525 5,1 Z M2,4 C2,4.55228475 1.55228475,5 1,5 C0.44771525,5 0,4.55228475 0,4 C0,3.44771525 0.44771525,3 1,3 C1.55228475,3 2,3.44771525 2,4 Z M5,4 C5,4.55228475 4.55228475,5 4,5 C3.44771525,5 3,4.55228475 3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 Z M2,7 C2,7.55228475 1.55228475,8 1,8 C0.44771525,8 0,7.55228475 0,7 C0,6.44771525 0.44771525,6 1,6 C1.55228475,6 2,6.44771525 2,7 Z M5,7 C5,7.55228475 4.55228475,8 4,8 C3.44771525,8 3,7.55228475 3,7 C3,6.44771525 3.44771525,6 4,6 C4.55228475,6 5,6.44771525 5,7 Z M2,10 C2,10.5522847 1.55228475,11 1,11 C0.44771525,11 0,10.5522847 0,10 C0,9.44771525 0.44771525,9 1,9 C1.55228475,9 2,9.44771525 2,10 Z M5,10 C5,10.5522847 4.55228475,11 4,11 C3.44771525,11 3,10.5522847 3,10 C3,9.44771525 3.44771525,9 4,9 C4.55228475,9 5,9.44771525 5,10 Z M2,13 C2,13.5522847 1.55228475,14 1,14 C0.44771525,14 0,13.5522847 0,13 C0,12.4477153 0.44771525,12 1,12 C1.55228475,12 2,12.4477153 2,13 Z M5,13 C5,13.5522847 4.55228475,14 4,14 C3.44771525,14 3,13.5522847 3,13 C3,12.4477153 3.44771525,12 4,12 C4.55228475,12 5,12.4477153 5,13 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "GripVertical",
);
