import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import EventNoteIcon from "@mui/icons-material/EventNote";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
  FormHelperText,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import * as yup from "yup";
import { ManufacturerProductSolrDto, ProductLineResponseDto } from "../../api";
import { useProductUpdate } from "../../hooks/api/mutations";
import { useProductDetails } from "../../hooks/api/queries";
import {
  getFormattedDate,
  isValidDateString,
  parseDate,
} from "../../utils/datetime";
import { Button, LoadingButton, TextField } from "../ui";

const schema = yup
  .object({
    eolDateTime: yup.date().nullable(),
  })
  .required();

type FormValues = {
  eolDateTime?: Date | null;
};

const FormFieldLabel = styled(Typography)(({ theme }) => ({
  flexShrink: 0,
  fontSize: 14,
  fontWeight: "medium",
  lineHeight: theme.spacing(4.5),
  width: theme.spacing(14),
}));

const EditEolDate = ({
  product,
  isOpen,
  onClose,
}: {
  product: ManufacturerProductSolrDto;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { data: productDetails } = useProductDetails(
    product.manufacturerProductId,
  );

  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={onClose}>
      {productDetails ? (
        <EditEolDateForm
          product={product}
          productDetails={productDetails}
          onClose={onClose}
        />
      ) : (
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "center",
            m: theme.spacing(1.5),
          })}
        >
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};

const EditEolDateForm = ({
  product,
  productDetails,
  onClose,
}: {
  product: ManufacturerProductSolrDto;
  productDetails: ProductLineResponseDto;
  onClose: () => void;
}) => {
  const { mutate: updateProduct, isLoading } = useProductUpdate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    defaultValues: {
      eolDateTime: productDetails.eolDateTime
        ? parseDate(productDetails.eolDateTime)
        : undefined,
    },
    resolver: yupResolver<FormValues>(schema),
  });

  const onSubmit = useCallback(
    (values: FormValues) => {
      if (product.manufacturerProductId) {
        updateProduct(
          {
            productId: product.manufacturerProductId,
            request: {
              eolDateTime: (values.eolDateTime
                ? getFormattedDate(values.eolDateTime, "yyyy-MM-dd")
                : null) as string,
            },
          },
          {
            onError: (error) => {
              enqueueSnackbar({
                message: error.message
                  ? error.message
                  : "Failed to update EOL Date.",
                variant: "error",
              });
            },
            onSuccess: () => {
              onClose();
              enqueueSnackbar({
                message: "Updated EOL Date successfully.",
                variant: "success",
              });
            },
          },
        );
      }
    },
    [onClose, product.manufacturerProductId, updateProduct, enqueueSnackbar],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle
        sx={(theme) => ({
          fontSize: theme.spacing(2.25),
          m: 0,
          pb: 0,
          pt: 3.5,
          px: 2,
        })}
      >
        Edit EOL for {product.mfgName} {product.mfgPart}
      </DialogTitle>
      <IconButton
        sx={{
          color: (theme) => theme.palette.grey[900],
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box
          sx={(theme) => ({
            display: "flex",
          })}
        >
          <FormFieldLabel>Select EOL Date:</FormFieldLabel>
          <Box flex="1">
            <Controller
              control={control}
              name="eolDateTime"
              render={({ field: { value, onChange } }) => (
                <>
                  <PatternFormat
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EventNoteIcon
                            sx={(theme) => ({
                              color: theme.palette.grey["300"],
                            })}
                          />
                        </InputAdornment>
                      ),
                    }}
                    customInput={TextField}
                    format="##/##/####"
                    mask="_"
                    placeholder="mm/dd/yyyy"
                    sx={{ width: "100%" }}
                    value={getFormattedDate(value)}
                    onValueChange={({ formattedValue }) =>
                      isValidDateString(formattedValue, "MM/dd/yyyy")
                        ? onChange(parseDate(formattedValue, "MM/dd/yyyy"))
                        : onChange(null)
                    }
                  />
                  <Box
                    sx={(theme) => ({
                      borderColor: theme.palette.grey["300"],
                      borderWidth: 1,
                      mt: 1,
                    })}
                  >
                    <DatePicker
                      fixedHeight
                      inline
                      dateFormat="MM/dd/yyyy"
                      selected={value}
                      onChange={(date) => onChange(date ?? undefined)}
                    />
                  </Box>
                </>
              )}
            />
            {!!errors.eolDateTime && (
              <FormHelperText error>
                {errors.eolDateTime.message}
              </FormHelperText>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pb: 2,
          px: 3,
        }}
      >
        <Button
          data-testid="sku-modal-close-btn"
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          data-testid="sku-modal-save-btn"
          loading={isLoading}
          type="submit"
          variant="contained"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default EditEolDate;
