import { createSvgIcon } from "@mui/material";

export const FileEdit = createSvgIcon(
  <svg version="1.1" viewBox="0 0 23 31" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        id="main-nav"
        transform="translate(-1312, -383)"
      >
        <g
          id="file-earmark-minus"
          transform="translate(1323.5, 398.5) rotate(-360) translate(-1323.5, -398.5)translate(1312, 383)"
        >
          <path
            d="M23,8.71875 L23,27.125 C23,29.2651034 21.2837582,31 19.1666667,31 L3.83333333,31 C1.71624179,31 0,29.2651034 0,27.125 L0,3.875 C0,1.73489659 1.71624179,0 3.83333333,0 L14.375,0 L23,8.71875 Z M17.25,8.71875 C15.6621813,8.71875 14.375,7.41757755 14.375,5.8125 L14.375,1.9375 L3.83333333,1.9375 C2.77478756,1.9375 1.91666667,2.8049483 1.91666667,3.875 L1.91666667,27.125 C1.91666667,28.1950517 2.77478756,29.0625 3.83333333,29.0625 L19.1666667,29.0625 C20.2252124,29.0625 21.0833333,28.1950517 21.0833333,27.125 L21.0833333,8.71875 L17.25,8.71875 Z"
            id="Shape"
          ></path>
          <g id="pencil" transform="translate(4, 11)">
            <path
              d="M12.1452783,0.146887227 C12.2390784,0.0528497238 12.3664412,-3.3305204e-16 12.4992625,-3.3305204e-16 C12.6320838,-3.3305204e-16 12.7594466,0.0528497238 12.8532467,0.146887227 L15.8531128,3.14675331 C15.9471503,3.24055339 16,3.36791618 16,3.50073751 C16,3.63355884 15.9471503,3.76092163 15.8531128,3.85472171 L5.85355916,13.8542753 C5.80557625,13.9019236 5.74842637,13.9393431 5.68556666,13.9642704 L0.685789847,15.9641811 C0.50006728,16.0385215 0.287910214,15.9950009 0.146454642,15.8535454 C0.00499906912,15.7120898 -0.0385214764,15.4999327 0.035818862,15.3142102 L2.03572959,10.3144333 C2.06065688,10.2515736 2.09807644,10.1944238 2.14572468,10.1464408 L12.1452783,0.146887227 Z M11.2063202,2.50078215 L13.4992179,4.79367979 L14.7921601,3.50073751 L12.4992625,1.20783987 L11.2063202,2.50078215 Z M12.7922494,5.50064823 L10.4993518,3.20775059 L3.99964192,9.70746044 L3.99964192,10.0004474 L4.4996196,10.0004474 C4.77574965,10.0004474 4.99959728,10.224295 4.99959728,10.500425 L4.99959728,11.0004027 L5.49957496,11.0004027 C5.77570501,11.0004027 5.99955264,11.2242504 5.99955264,11.5003804 L5.99955264,12.0003581 L6.29253956,12.0003581 L12.7922494,5.50064823 L12.7922494,5.50064823 Z M3.03168513,10.6754172 L2.92568986,10.7814125 L1.39775806,14.6022419 L5.2185875,13.0743101 L5.32458277,12.9683149 C5.12927482,12.8953416 4.99975361,12.708831 4.99959728,12.5003358 L4.99959728,12.0003581 L4.4996196,12.0003581 C4.22348955,12.0003581 3.99964192,11.7765105 3.99964192,11.5003804 L3.99964192,11.0004027 L3.49966423,11.0004027 C3.29116898,11.0002464 3.10465843,10.8707252 3.03168513,10.6754172 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  "FileEdit",
);
