/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { QuoteDetailDto } from '../models/QuoteDetailDto';
import type { QuoteLinePatchRequestDto } from '../models/QuoteLinePatchRequestDto';
import type { QuoteLinePatchResponseDto } from '../models/QuoteLinePatchResponseDto';
import type { QuoteLinePriceChangeResponseDto } from '../models/QuoteLinePriceChangeResponseDto';
import type { QuoteLineRequestDto } from '../models/QuoteLineRequestDto';
import type { RestCollection_QuoteLineSolrDto_ } from '../models/RestCollection_QuoteLineSolrDto_';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class QuoteLineResourceService {
  /**
   * Get Quote Lines listing report
   * Get Quote Lines listing report
   * @param criteria criteria
   * @param page page
   * @param returnAllLabels returnAllLabels
   * @param returnPagination returnPagination
   * @param returnSavedViews returnSavedViews
   * @param size size
   * @param useDefaultView useDefaultView
   * @returns RestCollection_QuoteLineSolrDto_ Ok
   * @throws ApiError
   */
  public static listUsingGet15(
    criteria: string = '{}',
    page: number = 1,
    returnAllLabels: boolean = false,
    returnPagination: boolean = true,
    returnSavedViews: boolean = true,
    size: number = 50,
    useDefaultView: boolean = false,
  ): CancelablePromise<RestCollection_QuoteLineSolrDto_> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/listing/quote_lines',
      query: {
        'criteria': criteria,
        'page': page,
        'returnAllLabels': returnAllLabels,
        'returnPagination': returnPagination,
        'returnSavedViews': returnSavedViews,
        'size': size,
        'useDefaultView': useDefaultView,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Quote Line(s) Not Found`,
        500: `Internal Error fetching Quote Lines`,
      },
    });
  }
  /**
   * POST Quote Line
   * POST Quote Line
   * @param quoteLineRequestDto quoteLineRequestDto
   * @returns QuoteDetailDto Success
   * @throws ApiError
   */
  public static createQuoteLineUsingPost(
    quoteLineRequestDto: QuoteLineRequestDto,
  ): CancelablePromise<Array<QuoteDetailDto>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/service/rest/quoteLines',
      body: quoteLineRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Quote Not Found`,
        409: `Product is already present`,
        500: `Internal Error Creating Quote Line`,
      },
    });
  }
  /**
   * Find All Quote Line Details By Quote Line Id
   * Find All Quote Line Details By Quote Line Id
   * @param quoteLineId quoteLineId
   * @returns QuoteDetailDto Success
   * @throws ApiError
   */
  public static findQuoteLineDetailsByIdUsingGet(
    quoteLineId: number,
  ): CancelablePromise<QuoteDetailDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/service/rest/quoteLines/{quoteLineId}',
      path: {
        'quoteLineId': quoteLineId,
      },
      errors: {
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `No Quote Line Found`,
        500: `Internal Error Finding Quote Line Details`,
      },
    });
  }
  /**
   * Delete Quote Line
   * Delete Quote Line
   * @param quoteLineId quoteLineId
   * @returns QuoteLinePriceChangeResponseDto Success
   * @throws ApiError
   */
  public static deleteQuoteLineUsingDelete(
    quoteLineId: number,
  ): CancelablePromise<Array<QuoteLinePriceChangeResponseDto>> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/service/rest/quoteLines/{quoteLineId}',
      path: {
        'quoteLineId': quoteLineId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Quote Line Not Found`,
        500: `Internal Error Deleting Quote Line`,
      },
    });
  }
  /**
   * Patch Quote Line by ID
   * Patch Quote Line
   * @param quoteLineId quoteLineId
   * @param quoteLinePatchRequestDto quoteLinePatchRequestDto
   * @returns QuoteLinePatchResponseDto Success
   * @throws ApiError
   */
  public static updateQuoteLineUsingPatch(
    quoteLineId: number,
    quoteLinePatchRequestDto: QuoteLinePatchRequestDto,
  ): CancelablePromise<QuoteLinePatchResponseDto> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/service/rest/quoteLines/{quoteLineId}',
      path: {
        'quoteLineId': quoteLineId,
      },
      body: quoteLinePatchRequestDto,
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `The user doesn't have the right privilege for this request`,
        404: `Quote Line Not Found`,
        500: `Internal Error Patching Quote Line`,
      },
    });
  }
}
