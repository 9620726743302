import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ClientPatchRequestDto,
  ClientResourceService,
  ClientResponseDto,
} from "../../../api";
import { QUERY_KEYS } from "../../../constants/query";

export const useClientResourceUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ClientResponseDto,
    Error,
    { clientId: number; request: ClientPatchRequestDto }
  >(
    ({ clientId, request }) => {
      return ClientResourceService.updateClientUsingPatch(clientId, request);
    },
    {
      onSuccess: (response, { clientId }) => {
        queryClient.invalidateQueries([
          QUERY_KEYS["client-resource"],
          clientId,
        ]);
        queryClient.refetchQueries();
      },
    },
  );
};
