export const getAppLink = (link: string) => {
  if (link.startsWith("http")) {
    return link;
  }

  if (window.location.hostname === "localhost") {
    return `http://localhost:8080${link.startsWith("/") ? "" : "/"}${link}`;
  }

  return `${window.location.origin}${link.startsWith("/") ? "" : "/"}${link}`;
};

export const redirectToAuthPage = () => {
  window.location.href =
    getAppLink("/app/Auth/logout?") +
    "url=" +
    encodeURIComponent(window.location.pathname + window.location.search);
};
