import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { ImportWizardDto, ImportWizardFileRecord } from "../../types";

type ContextState = {
  step?: string;
  setStep: Dispatch<SetStateAction<string>>;
  fileContents: ImportWizardFileRecord[];
  setFileContents: Dispatch<SetStateAction<ImportWizardFileRecord[]>>;
  records: ImportWizardDto[];
  setRecords: Dispatch<SetStateAction<ImportWizardDto[]>>;
  onClose: () => void;
  progress: number;
  setProgress: Dispatch<SetStateAction<number>>;
};

const ImportWizardContext = createContext<ContextState>({
  fileContents: [],
  onClose: () => {},
  progress: 0,
  records: [],
  setFileContents: () => {},
  setProgress: () => {},
  setRecords: () => {},
  setStep: () => {},
  step: undefined,
});

interface ImportWizardContextProviderProps {
  children: ReactNode;
  onClose: () => void;
  defaultStep: string;
}

export const ImportWizardContextProvider = ({
  children,
  onClose,
  defaultStep,
}: ImportWizardContextProviderProps) => {
  const [step, setStep] = useState<string>(defaultStep);
  const [progress, setProgress] = useState(0);
  const [fileContents, setFileContents] = useState<ImportWizardFileRecord[]>(
    [],
  );
  const [records, setRecords] = useState<ImportWizardDto[]>([]);

  return (
    <ImportWizardContext.Provider
      value={{
        fileContents,
        onClose,
        progress,
        records,
        setFileContents,
        setProgress,
        setRecords,
        setStep,
        step,
      }}
    >
      {children}
    </ImportWizardContext.Provider>
  );
};

export const useImportWizardContext = () => {
  return useContext<ContextState>(ImportWizardContext);
};
